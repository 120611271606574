<template>
    <div class="app-container1">
        <div>
            <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="100px">
                <el-form-item label="月份" prop="datetime">
                    <datetime-select v-model="queryParams.datetime" type="month" @change="onQueryDatetimeChange" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div ref="chart1" class="chart"></div>
        <div ref="chart2" class="chart"></div>
        <div ref="chart3" class="chart"></div>
        <div ref="chart4" class="chart"></div>
        <div ref="chart5" class="chart"></div>
        <div ref="chart6" class="chart"></div>
    </div>
</template>

<script>
import echarts from 'echarts';
import { listUserScoreStatEx } from "@/api/core/system/userscore";

export default {
    data() {
        return {
            queryParams: {
                datetime: parseInt(new Date().getTime() / 1000)
            }
        }
    },
    created() {
    },
    mounted() {
        this.chart1 = echarts.init(this.$refs.chart1);
        this.chart2 = echarts.init(this.$refs.chart2);
        this.chart3 = echarts.init(this.$refs.chart3);
        this.chart4 = echarts.init(this.$refs.chart4);
        this.chart5 = echarts.init(this.$refs.chart5);
        this.chart6 = echarts.init(this.$refs.chart6);
        this.loadData()
    },
    methods: {
        loadData() {
            if (!this.queryParams.datetime) {
                this.msgError("请选择月份");
                return
            }

            const date = new Date(this.queryParams.datetime * 1000)
            const year = date.getFullYear()
            const month = date.getMonth() + 1
            let quarter = 1
            if (month >= 1 && month <= 3) {
                quarter = 1
            } else if (month >= 4 && month <= 6) {
                quarter = 2
            } else if (month >= 7 && month <= 9) {
                quarter = 3
            } else if (month >= 10 && month <= 12) {
                quarter = 4
            }

            // 个人月份
            this.loadBarData(this.chart1, year+'年'+month+'月个人积分前10名', 'user_month')

            // 个人季度
            this.loadBarData(this.chart2, year+'年'+quarter+'季度个人积分前10名', 'user_quarter')

            // 个人年份
            this.loadBarData(this.chart3, year+'年累计(YTD)个人积分前10名', 'user_year')

            // 部门月份
            this.loadBarData(this.chart4, year+'年'+month+'月部门积分前10名', 'dept_month')

            // 部门季度
            this.loadBarData(this.chart5, year+'年'+quarter+'季度部门积分前10名', 'dept_quarter')

            // 部门年份
            this.loadBarData(this.chart6, year+'年累计(YTD)部门积分前10名', 'dept_year')
        },
        loadBarData(chart, title, type) {
            this.setBarOption(chart, title, [], [])
            listUserScoreStatEx({
                type: type,
                datetime: this.queryParams.datetime,
                limit: 10
            }).then(response => {
                const xdata = []
                const sdata = []
                for (let i = 0; i < response.data.list.length; i++) {
                    const d = response.data.list[i]
                    xdata.push(d.key)
                    sdata.push(parseInt(d.value))
                }
                this.setBarOption(chart, title, xdata, sdata)
            })
        },
        setBarOption(chart, title, xdata, sdata) {
            chart.clear()
            chart.setOption({
                tooltip: {
                    show: true
                },
                title: {
                    text: title,
                    left: 'center'
                },
                xAxis: {
                    type: 'category',
                    data: xdata,
                    axisLabel: {
                        interval: 0,
                        rotate: 10
                    }
                },
                yAxis: {
                    type: 'value',
                    name: '积分'
                },
                series: [
                    {
                        data: sdata,
                        type: 'bar',
                        label: {
                            show: true,
                            position: 'top'
                        }
                    }
                ],
                color: ['rgb(51,152,219)']
            })
        },
        onQueryDatetimeChange(e) {
            if (!this.queryParams.datetime) {
                this.queryParams.datetime = parseInt(new Date().getTime() / 1000)
            }
        },
        handleQuery() {
            this.loadData()
        }
    }
}
</script>

<style scoped>
.app-container1 {
    width: 100%;
    padding-top: 10px;
}
.chart {
    width: 100%;
    height: 500px;
    margin-top: 20px;
}
</style>