<template>
  <div
    class="count-to"
    :style="{
      color: color,
      fontSize: `${size}px`,
      fontWeight: bold ? 'bold' : 'normal',
    }"
  >
    {{ displayedValue }}
  </div>
</template>

<script>
export default {
  name: "CountTo",
  props: {
    startVal: {
      type: Number,
      default: 0,
    },
    endVal: {
      type: Number,
      required: true,
    },
    duration: {
      type: Number,
      default: 1000,
    },
    decimals: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: "#000",
    },
    size: {
      type: Number,
      default: 16,
    },
    bold: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      displayedValue: this.startVal,
      startTime: null,
      endTime: null,
      animationFrameId: null,
    };
  },
  mounted() {
    this.startAnimation();
  },
  beforeDestroy() {
    this.clearAnimationFrame();
  },
  errorCaptured() {
    this.clearAnimationFrame();
  },
  watch: {
    startVal: {
      handler(newVal) {
        this.clearAnimationFrame();
        this.displayedValue = newVal;
        this.startAnimation();
      },
      deep: true,
    },
    endVal: {
      handler() {
        this.clearAnimationFrame();
        this.startAnimation();
      },
      deep: true,
    },
    duration: {
      handler() {
        this.clearAnimationFrame();
        this.startAnimation();
      },
      deep: true,
    },
  },
  methods: {
    startAnimation() {
      this.startTime = Date.now();
      this.endTime = this.startTime + this.duration;
      this.updateValue();
    },
    updateValue() {
      const currentTime = Date.now();
      if (currentTime >= this.endTime) {
        this.displayedValue = this.endVal;
        this.clearAnimationFrame();
      } else {
        const progress = (currentTime - this.startTime) / this.duration;
        this.displayedValue =
          this.startVal + (this.endVal - this.startVal) * progress;
        this.displayedValue = parseFloat(
          this.displayedValue.toFixed(this.decimals)
        );
        this.requestAnimationFrame();
      }
    },
    requestAnimationFrame() {
      this.animationFrameId = window.requestAnimationFrame(() => {
        this.updateValue();
      });
    },
    clearAnimationFrame() {
      if (this.animationFrameId) {
        window.cancelAnimationFrame(this.animationFrameId);
        this.animationFrameId = null;
      }
    },
  },
};
</script>

<style scoped>
.count-to {
  font-weight: bold;
}
</style>
