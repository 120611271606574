@import "@/styles/variables.scss"; 

















































































































.sub-form-container {
  //width: 100%;
  padding: 8px;
  border: 1px dashed #336699;

  ::v-deep .grid-sub-form {
    min-height: 68px;
  }

  ::v-deep .ghost{
    content: '';
    font-size: 0;
    height: 3px;
    width: 100%;
    //height: 74px;
    //width: 1px;
    box-sizing: border-box;
    display: inline-block;
    background: $--color-primary;
    border: 2px solid $--color-primary;
    outline-width: 0;
    padding: 0;
    margin: 0;
    overflow: hidden;
  }
}

.sub-form-container.selected {
  outline: 2px solid $--color-primary !important;
}

