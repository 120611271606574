@import "@/styles/variables.scss"; 


















































































































































































































































































































































































































































































































.container {
  padding: 15px;
  .query-form {
    margin-bottom: 15px;
  }
  .top-panel {
    min-height: 32px;
    margin: 15px 0 10px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    ::v-deep {
      .el-form-item {
        margin-left: 20px;
      }
      .el-form-item:last-child {
        margin-left: 40px;
      }
    }
  }
  .left-panel {
    &::v-deep {
      .el-button {
        margin: 0 10px 10px 0 !important;
      }
    }
  }
  .el-input-small-size {
    width: 150px;
  }
  .el-input-normal-size {
    width: 250px;
  }
  /* 解决按钮点击后任然保持点击态问题 */
  /*鼠标点击后移开，恢复本身样式*/
  .button-div,
  .button-div:focus:not(.button-div:hover) {
    margin-right: 12px;
    border: 1px solid #fff;
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 #fff;
    color: var(--color-primary);
    background: #fff;
  }
  /*鼠标悬浮，没有按下；鼠标按下后抬起，没有移开*/
  .button-div:focus,
  .button-div:active,
  .button-div:hover {
    background: #f5f7fa;
    border: 1px solid #fff !important;
    color: var(--color-primary);
  }
  /*鼠标按下，没有抬起*/
  .button-div:active {
    /* background: var(--color-primary);
        color: white; */
  }

  .el-link {
    &.primary {
      color: $--color-primary;
    }
    &.warning {
      color: $--color-warning;
    }
    &.danger {
      color: $--color-danger;
    }
    &.success {
      color: $--color-success;
    }
  }
}
