@import "@/styles/variables.scss"; 














































































































































































































































































































































































































































































































































































.task-card {
  margin-top: 15px;
  margin-bottom: 60px;
}

.card-bottom,
.history-card {
  margin-bottom: 60px;
}

.opinion-role {
  position: relative;

  .action {
    position: absolute;
    top: -1px;
    left: 210px;
  }
}

.task-footer {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 200px;
  height: 60px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  z-index: 100;
  background: #fff;
  box-shadow: 0 -3px 5px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 -3px 5px 0 rgba(0, 0, 0, 0.12);
  transition: inline-block 0.3s, left 0.3s, width 0.3s, margin-left 0.3s,
    font-size 0.3s;
  -webkit-transition: inline-block 0.3s, left 0.3s, width 0.3s, margin-left 0.3s,
    font-size 0.3s;
}

.el-button [class*="fa-"] + span {
  margin-left: 5px;
}

.hideSidebar .task-footer {
  left: 54px;
}

.task-tab {
}

.flow-action-btn {
  right: 20px;
  position: absolute;
  top: 25px;
  z-index: 500;
}

.chart-card {
  margin-bottom: 60px;

  .el-card__body {
    /*padding: 0;*/
    /*background: #f5f5f7;*/
  }

  .flow-container {
    display: inline-block;
    padding: 20px 0;
    background: #f5f5f7;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    text-align: center;
    overflow: auto;
  }
}

.expanded-col {
  margin-right: -1px;
  border-right: 1px solid #e6ebf5;
}
