@import "@/styles/variables.scss"; 



























































































































































































































































































































































































.screen {
  width: 100%;
  background: #fff;
  border-radius: 12px;
  padding: 20px;
}
.dashboard-editor-container {
  width: 100%;
  height: 100%;
  background: #f6f8f9 !important;
}
.active {
  color: red !important;
}
.active2 {
  color: #fd8282 !important;
}
.box {
  background-color: #fff;
  border-radius: 12px;
  padding: 0px 3px 32px 20px;
  box-sizing: border-box;
  h4 {
    border-bottom: 1px solid #f9f9f9;
    line-height: 40px;
  }
  &-content {
    display: flex;
    justify-content: space-around;

    &:nth-child(1) {
      border-right: 1px solid #ebebeb;
    }
    &-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .h2 {
        font-size: 36px;
        font-weight: normal;
      }
      span {
        color: #101010;
        font-weight: bold;
      }
    }
  }
}

.business {
  background-color: #fff;
  border-radius: 12px;
  padding: 3px 3px 32px 20px;
  box-sizing: border-box;
  margin-bottom: 30px;
  position: relative;
  h4 {
    border-bottom: 1px solid #f9f9f9;
    line-height: 40px;
  }
  &-content {
    display: flex;
    justify-content: space-around;
    &-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      .h2 {
        font-size: 36px;
        font-weight: normal;
      }
      span {
        color: #8b8b8b;
      }
    }
  }

  &-img {
    position: absolute;
    right: 0px;
    top: -20px;
    width: 90px;
    height: 80px;
  }
}
