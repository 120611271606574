
































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.drawer {
    >>> .el-drawer__header {
        margin-bottom: 0;
        border-bottom: 1px solid #c5c5c5;
        padding-bottom: 8px;
        padding-left: 0px;
    }

    >>> .el-drawer__body {
        padding-bottom: 44px;
        overflow: hidden;
    }

    .pane-tab {
        height: 100%;
    }

    .pane-tab >>> .el-tabs__item.is-top:nth-child(2) {
        padding-left: 20px;
    }

    >>> .el-tabs__item:focus {
        box-shadow: none !important;
    }

    >>> .el-tabs__header {
        margin-bottom: 0;
    }
}

.header {
    line-height: 28px;
}

.actions {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 6px 12px;
    width: 100%;
    box-sizing: border-box;
    text-align: right;
}

.radio-item {
    width: 110px;
    padding: 6px;
}

.priority-select {
    width: 118px!important;
    position: absolute;
     
}

.form-auth-table {
    font-size: 14px;

    .auth-table-header {
        background: #fafafa;
        line-height: 40px;
    }

    .row {
        display: flex;
        align-items: center;
        line-height: 32px;
        padding: 8px 12px;
        border-bottom: 1px solid #efefef;

        &:hover {
            background: #f5f7fa;
        }

        .label {
            flex: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            .required {
                color: #f2725e;
            }
        }

        .radio-group {
            flex: 2;
            display: flex;
            justify-content: space-between;
        }
    }
}

.approver-pane {
    overflow-y: scroll;
    overflow-x: hidden;

    .option-box {
        font-size: 14px;
        padding-left: 1rem;
    }
}

.condition-pane {
    height: 100%;
    overflow: scroll;
}
