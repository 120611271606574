<template>
	<div class="app-container">
		<el-row :gutter="20">
			<!--机构数据-->
			<el-col :span="4" :xs="24">
				<div class="head-container">
					<el-input v-model="name" placeholder="请输入机构名称" clearable size="small" prefix-icon="el-icon-search"
						style="margin-bottom: 20px" />
				</div>
				<div class="head-container">
					<el-tree :data="deptOptions" :props="defaultProps" :expand-on-click-node="false"
						:highlight-current="true" :filter-node-method="filterNode" ref="tree" node-key="id"
						@node-click="handleNodeClick" />
				</div>
			</el-col>
			<!--用户数据-->
			<el-col :span="20" :xs="24">
				<el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px" label-position="left">
					<el-form-item label="关键字" prop="keyword">
						<el-input v-model="queryParams.keyword" placeholder="账号、姓名、昵称、工号、手机号" clearable size="small"
							style="width: 250px" @keyup.enter.native="handleQuery" />
					</el-form-item>
					<!--<el-form-item label="工号" prop="no">
            <el-input
              v-model="queryParams.no"
              placeholder="请输入工号"
              clearable
              size="small"
              style="width: 160px"
              @keyup.enter.native="handleQuery"
            />
          </el-form-item>-->
					<!--<el-form-item label="手机号码" prop="phone">
            <el-input
              v-model="queryParams.phone"
              placeholder="请输入手机号码"
              clearable
              size="small"
              style="width: 160px"
              @keyup.enter.native="handleQuery"
            />
          </el-form-item>-->
					<el-form-item label="类型" prop="type">
						<el-select v-model="queryParams.type" placeholder="用户类型" clearable size="small"
							style="width: 250px !important">
							<el-option v-for="dict in typeOptions.values" :key="dict.key" :label="dict.value"
								:value="dict.key" />
						</el-select>
					</el-form-item>
					<el-form-item label="角色" prop="role_ids">
						<el-select v-model="queryParams.role_ids" placeholder="用户角色" clearable size="small"
							style="width: 250px !important">
							<el-option v-for="item in roleList" :key="item.id" :label="item.name" :value="item.id" />
						</el-select>
					</el-form-item>
					<el-form-item label="状态" prop="status">
						<el-select v-model="queryParams.status" placeholder="用户状态" clearable size="small"
							style="width: 250px !important"> <el-option v-for="dict in statusOptions.values"
								:key="dict.key" :label="dict.value" :value="dict.key" />
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
						<el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
					</el-form-item>
				</el-form>

				<!-- 	<vxe-grid resizable ref="xGrid" stripe show-overflow highlight-hover-row :loading="loading"
					:toolbar-config="tableToolbar" :columns="tableColumn" :data="userList"
					@checkbox-change="checkboxChangeEvent" @checkbox-all="checkboxChangeEvent">
					<template #toolbar_buttons>
						<el-button style="margin-left: 10px" type="primary" icon="el-icon-plus" size="mini"
							@click="handleAdd" v-hasPermi="['/user/add']">新增</el-button>
						<el-button type="success" icon="el-icon-edit" size="mini" :disabled="single"
							@click="handleUpdate" v-hasPermi="['/user/edit']">修改</el-button>
						<el-button type="danger" icon="el-icon-delete" size="mini" :disabled="multiple"
							@click="handleDelete" v-hasPermi="['/user/del']">删除</el-button>
						<el-button type="warning" icon="el-icon-file" size="mini" @click="handleImport"
							v-hasPermi="['/user/import']">导入</el-button>
						<el-button type="warning" icon="el-icon-file" size="mini" @click="handleExport"
							v-hasPermi="['/user/export']">导出</el-button>
						<el-button type="warning" icon="el-icon-refresh" size="mini" @click="handleSyncRolePost"
							v-hasPermi="['/user/syncrolepost']">同步角色岗位</el-button>
					</template>

<template #device_id="{row}">
						<el-button v-if="row.device_id" type="text" @click.stop="handleUnBindDevice(row)">解绑</el-button>
						<div v-else class="unbind-device">未绑定</div>
					</template>

<template #status="{row}">
						<el-switch v-model="row.status" active-value="1" inactive-value="0"
							@change="handleStatusChange(row)"></el-switch>
					</template>

<template #roles="{row}">
						<span v-for="(item,index) of row.roles" :key="index"> {{item.name}} </span>
					</template> -->

				<!--默认操作按钮-->
				<!-- 		<template #defaultopr="{ row }">
						<el-button size="mini" type="text" icon="el-icon-view" @click="handleUpdate(row, true)"
							v-hasPermi="['/user/get']">查看
						</el-button>
						<el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(row)"
							v-hasPermi="['/user/edit']">修改
						</el-button>
						<el-button v-if="row.id !== 1" size="mini" type="text" icon="el-icon-delete"
							v-hasPermi="['/user/del']" @click="handleDelete(row)">删除
						</el-button>
						<el-button size="mini" type="text" icon="el-icon-key" v-hasPermi="['/user/resetPwd']"
							@click="handleResetPwd(row)">重置</el-button>
					</template> -->

				<!--自定义空数据模板-->
				<!-- 				<template #empty>
						<span>
							<p>暂无数据</p>
						</span>
					</template>

				</vxe-grid>
				<vxe-pager background size="small" :loading="loading" :current-page="queryParams.pageNum"
					:page-size="queryParams.pageSize" :total="total" @page-change="handleSizeChange">
				</vxe-pager> -->

				<jf-table :loading="loading" :defaultoprWidth="250" :columns="tableColumn" :data="userList"
					:toolbar-config="tableToolbar" :query-params="queryParams" @checkbox-change="checkboxChangeEvent"
					@page-change="handleSizeChange" :total="total" @change="tableChange">
					<template slot="toolbar_btn_left">
						<el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd"
							v-hasPermi="['/user/add']">新增</el-button>
						<el-button type="success" icon="el-icon-edit" size="mini" :disabled="single"
							@click="handleUpdate" v-hasPermi="['/user/edit']">修改</el-button>
						<el-button v-if="showReview" type="warning" icon="el-icon-eidt" size="mini" :disabled="multiple"
							@click="handleReview" v-hasPermi="['/user/review']">审核</el-button>
						<el-button type="danger" icon="el-icon-delete" size="mini" :disabled="multiple"
							@click="handleDelete" v-hasPermi="['/user/del']">删除</el-button>
						<!-- <el-button type="warning" icon="el-icon-file" size="mini" @click="handleImport"
							v-hasPermi="['/user/import']">导入</el-button>
						<el-button type="warning" icon="el-icon-file" size="mini" @click="handleExport"
							v-hasPermi="['/user/export']">导出</el-button> -->
						<el-button type="warning" size="mini" @click="handleImport" v-hasPermi="['/user/import']">
							<i class="iconfont icon-daoru_o"></i>
							导入
						</el-button>

						<el-button class="btn-orange" size="mini" @click="handleExport" v-hasPermi="['/user/export']">
							<i class="iconfont icon-daochu_o"></i>
							导出
						</el-button>
						<el-button type="warning" icon="el-icon-refresh" size="mini" @click="handleSyncRolePost"
							v-hasPermi="['/user/syncrolepost']">同步角色岗位</el-button>
					</template>

					<template slot="device_id" slot-scope="{ row }">
						<el-button v-if="row.device_id" type="text" @click.stop="handleUnBindDevice(row)">解绑</el-button>
						<div v-else class="unbind-device">未绑定</div>
					</template>
					<template slot="review_status" slot-scope="{ row }">
						<el-tag v-if="row.review_status == '1'">通过</el-tag>
						<el-tag v-else-if="row.review_status == '2'" type="danger">未通过</el-tag>
						<el-tag v-else type="info">待审核</el-tag>
					</template>
					<template slot="status" slot-scope="{ row }">
						<el-switch v-model="row.status" active-value="1" inactive-value="0"
							@change="handleStatusChange(row)"></el-switch>
					</template>
					<!-- 	<template #status="{row}">
						<el-switch v-model="row.status" active-value="1" inactive-value="0"
							@change="handleStatusChange(row)"></el-switch>
					</template> -->

					<template slot="roles" slot-scope="{ row }">
						<span v-for="(item, index) of row.roles" :key="index"> {{ item.name }} </span>
					</template>
					<template slot="toolbar_btn_row" slot-scope="{ row }">
						<el-button size="mini" type="text" icon="el-icon-view" @click="handleUpdate(row, true)"
							v-hasPermi="['/user/get']">查看
						</el-button>
						<el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(row)"
							v-hasPermi="['/user/edit']">修改
						</el-button>
						<el-button v-if="row.id !== 1" size="mini" type="text" icon="el-icon-delete"
							v-hasPermi="['/user/del']" @click="handleDelete(row)">删除
						</el-button>
						<el-button size="mini" type="text" icon="el-icon-key" v-hasPermi="['/user/resetPwd']"
							@click="handleResetPwd(row)">重置</el-button>
					</template>


				</jf-table>

			</el-col>
		</el-row>

		<!-- 添加或修改用户对话框 -->
		<el-dialog :title="title" :visible.sync="open" width="800px" :close-on-click-modal="false" append-to-body>
			<jf-form ref="form" :model="form" :rules="rules" label-width="80px" :readonly="readonly">
				<el-row>
					<el-col :span="12">
						<el-form-item label="用户昵称" prop="nickname">
							<el-input v-model="form.nickname" placeholder="请输入用户昵称" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="归属机构" prop="dept_id">
							<treeselect v-model="form.dept_id" :options="deptOptions" placeholder="请选择归属机构"
								:normalizer="normalizerDeptTreeNode" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item v-if="form.id == undefined || readonly" label="用户账号" prop="username">
							<el-input v-model="form.username" placeholder="请输入用户账号" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item v-if="form.id == undefined" label="用户密码" prop="password">
							<el-input v-model="form.password" placeholder="请输入用户密码" type="password" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="工号" prop="no">
							<el-input v-model="form.no" placeholder="请输入工号" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="姓名" prop="realname">
							<el-input v-model="form.realname" placeholder="请输入姓名" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="身份证号" prop="idcard">
							<el-input v-model="form.idcard" placeholder="请输入身份证号" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="手机号码" prop="phone">
							<el-input v-model="form.phone" placeholder="请输入手机号码" maxlength="11" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="邮箱" prop="email">
							<el-input v-model="form.email" placeholder="请输入邮箱" maxlength="50" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="用户性别">
							<el-select v-model="form.gender" placeholder="请选择">
								<el-option v-for="dict in genderOptions.values" :key="dict.key" :label="dict.value"
									:value="dict.key"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="用户类型">
							<el-select v-model="form.type" placeholder="请选择">
								<el-option v-for="dict in typeOptions.values" :key="dict.key" :label="dict.value"
									:value="dict.key"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="岗位">
							<el-select v-model="form.postIds" multiple placeholder="请选择">
								<el-option v-for="item in postOptions" :key="item.id" :label="item.name"
									:value="item.id"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="角色" prop="roleIds">
							<el-select v-model="form.roleIds" multiple placeholder="请选择">
								<el-option v-for="item in roleOptions" :key="item.id" :label="item.name"
									:value="item.id"></el-option>
							</el-select>
						</el-form-item>
					</el-col>

					<el-col :span="12">
						<el-form-item label="主管" prop="leader_nos">
							<!--<el-input v-model="form.leader_nos" placeholder="请输入主管" maxlength="20" />-->
							<admin-selectex size="small" placeholder="请选择用户" title="用户" idField="no"
								:value="form.leader_nos" :label="form.leader_names" :readonly="true"
								@getValue='(ids, names) => { form.leader_nos = ids; form.leader_names = names; }' />
						</el-form-item>
					</el-col>

					<el-col :span="12">
						<el-form-item label="备注">
							<el-input v-model="form.remark" type="textarea" placeholder="请输入内容"></el-input>
						</el-form-item>
					</el-col>

					<el-col :span="12">
						<el-form-item label="状态">
							<el-radio-group v-model="form.status">
								<el-radio v-for="dict in statusOptions.values" :key="dict.key"
									:label="dict.key">{{ dict.value }}</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>

					<el-col v-if="subordinateShow && form.id" :span="24">
						<div style="display: flex; margin-bottom: 10px;">
							<div
								style="width: 80px; text-align: right; font-size: 14px; font-weight: 700; padding-right: 12px;">
								下属</div>
							<el-button type="primary" size="mini" @click="clickAddSubordinate">添加</el-button>
							<admin-selectex ref="addSubordinateDialog" size="small" placeholder="请选择用户" title="用户" idField="id" style="display: none"
								:readonly="true"
								@getValue='handleAddSubordinate' />
						</div>
						<el-form-item label="" prop="subordinate_nos">
							<el-table :data="subordinateData" border size="mini" style="width: 100%" height="200px">
								<el-table-column prop="index" label="序号" width="80" align="center">
								</el-table-column>
								<el-table-column prop="dept_name" label="部门" align="center">
								</el-table-column>
								<el-table-column prop="name" label="姓名" align="center">
								</el-table-column>
								<el-table-column label="操作" width="80" align="center">
									<template slot-scope="scope">
										<el-button @click="handleDeleteSubordinate(scope.row)" type="text" size="mini">删除</el-button>
									</template>
								</el-table-column>
								<template slot="empty">
									<div>{{ subordinateLoading ? '正在加载...' : '暂无数据' }}</div>
								</template>
							</el-table>
						</el-form-item>
					</el-col>

				</el-row>
			</jf-form>
			<div slot="footer" class="dialog-footer">
				<template v-if="!readonly">
					<el-button type="primary" @click="submitForm">确 定</el-button>
					<el-button @click="cancel">取 消</el-button>
				</template>
				<template v-else>
					<el-button @click="cancel">关 闭</el-button>
				</template>
			</div>
		</el-dialog>

		<el-dialog :title="upload.title" :visible.sync="upload.open" width="400px" append-to-body>
			<el-upload ref="upload" :limit="1" accept=".xlsx" :headers="upload.headers"
				:action="upload.url + '?update=' + upload.update" :disabled="upload.isUploading"
				:on-progress="handleImportProgress" :on-success="handleImportSuccess" :on-error="handleImportError"
				:auto-upload="false" drag>
				<i class="el-icon-upload"></i>
				<div class="el-upload__text">
					将文件拖到此处，或
					<em>点击上传</em>
				</div>
				<div class="el-upload__tip" slot="tip">
					<el-checkbox v-model="upload.update" />是否更新已经存在的数据
					<el-link type="info" style="font-size:12px" @click="handleImportTemplate">下载模板</el-link>
				</div>
				<div class="el-upload__tip" style="color:red" slot="tip">提示：仅允许导入“xlsx”格式文件！</div>
			</el-upload>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitImportForm">确 定</el-button>
				<el-button @click="upload.open = false">取 消</el-button>
			</div>
		</el-dialog>

		<!-- 审核对话框 -->
		<el-dialog title="审核" :visible.sync="openReview" width="800px" :close-on-click-modal="false" append-to-body>
			<el-form ref="reviewForm" :model="reviewForm" :rules="reviewRules" label-width="80px">
				<el-row>
					<el-col :span="24">
						<el-form-item label="审核状态">
							<el-radio-group v-model="reviewForm.review_status" prop="review_status">
								<el-radio :label="1">通过</el-radio>
								<el-radio :label="2">不通过</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
					<el-col v-if="reviewForm.review_status == '2'" :span="24">
						<el-form-item label="原因" prop="review_reason">
							<el-input v-model="reviewForm.review_reason" type="textarea" placeholder="请输入原因" />
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitReviewForm">确 定</el-button>
				<el-button @click="openReview = false">取 消</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import {
	listUser,
	getUser,
	getEditUser,
	delUser,
	addUser,
	updateUser,
	exportUser,
	resetUserPwd,
	unBindDevice,
	changeUserStatus,
	importTemplate,
	syncRolePost,
	reviewUser,
	addSubordinate,
	delSubordinate
} from "@/api/core/system/user";
import {
	listRole
} from "@/api/core/system/role";
import { listPost } from "@/api/core/system/post";
import {
	listUserPool
} from "@/api/core/system/userpool";
import Qs from 'qs'
import {
	getAccessToken
} from '@/utils/tokenTool'
import {
	treeselect
} from "@/api/core/system/dept";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
	name: "admin-list",
	components: {
		Treeselect
	},
	data() {
		return {
			// 工具栏
			tableToolbar: {
				perfect: false,
				zoom: true,
				custom: false,
				refresh: {
					query: this.handleQuery
				},
				slots: {
					buttons: 'toolbar_buttons'
				}
			},
			// 列
			tableColumn: [
				// {
				// 	type: 'checkbox',
				// 	width: 60,
				// 	fixed: "left"
				// },
				//{field: 'id', title: '用户编号', width: 100, fixed: "left"},
				{
					field: 'username',
					title: '用户账号'
				},
				{
					field: 'nickname',
					title: '用户昵称'
				},
				{
					field: 'no',
					title: '工号',
					visible: false
				},
				{
					field: 'realname',
					title: '姓名'
				},
				{
					field: 'dept.name',
					title: '机构'
				},
				{
					field: 'post_ids',
					title: '岗位',
					formatter: this.formatPost
				},
				{
					field: 'roles',
					title: '角色',
					slots: {
						default: 'roles'
					}
				},
				{
					field: 'phone',
					title: '手机号码'
				},
				/*{
					field: 'device_id',
					title: '绑定设备',
					align: "center",
					width: 100,
					slots: {
						default: 'device_id'
					}
				},*/
				{
					field: 'leader_names',
					title: '主管'
				},
				{
					field: 'type',
					title: '部门负责人',
					formatter: (e) => {
						if (e.cellValue == 'deptmanager') {
							return '是'
						} else {
							return '否'
						}
					}
				},
				{
					field: 'review_status',
					title: '审核状态',
					align: "center",
					width: 100,
					visible: false,
					slots: {
						default: 'review_status'
					}
				},
				{
					field: 'status',
					title: '状态',
					align: "center",
					width: 100,
					visible: false,
					slots: {
						default: 'status'
					}
				},
				{
					field: 'create_time',
					title: '创建时间',
					formatter: "formatDate"
				},
				//{field: 'update_time', title: '更新时间', formatter: "formatDate"},
				// {
				// 	field: '',
				// 	title: '操作',
				// 	resizable: false,
				// 	width: 250,
				// 	fixed: "right",
				// 	align: "center",
				// 	slots: {
				// 		default: 'defaultopr'
				// 	}
				// }
			],
			// 遮罩层
			loading: true,
			// 选中数组
			ids: [],
			// 非单个禁用
			single: true,
			// 非多个禁用
			multiple: true,
			// 总条数
			total: 0,
			// 用户表格数据
			userList: [],
			// 弹出层标题
			title: "",
			// 是否查看
			readonly: false,
			// 机构树选项
			deptOptions: undefined,
			// 是否显示弹出层
			open: false,
			// 机构名称
			name: undefined,
			// 默认密码
			initPassword: undefined,
			// 日期范围
			dateRange: [],
			// 状态数据字典
			statusOptions: [],
			// 性别状态字典
			genderOptions: [],
			typeOptions: [],
			// 岗位选项
			postOptions: [],
			postList: [],
			// 角色选项
			roleOptions: [],
			roleList: [],
			// 表单参数
			form: {

			},
			defaultProps: {
				children: "children",
				label: "name"
			},
			upload: {
				open: false,
				title: "",
				isUploading: false,
				update: false,
				headers: {
					Authorization: getAccessToken()
				},
				url: process.env.VUE_APP_BASE_API + "/user/import"
			},
			// 查询参数
			queryParams: {
				pageNum: 1,
				pageSize: 10,
				keyword: undefined,
				no: undefined,
				phone: undefined,
				status: undefined,
				dept_id: undefined,
				with_sub_dept_user: true
			},
			// 表单校验
			rules: {
				username: [{
					required: true,
					message: "用户账号不能为空",
					trigger: "blur"
				}],
				/*no: [{
					required: true,
					message: "工号不能为空",
					trigger: "blur"
				}],*/
				nickname: [{
					required: true,
					message: "用户昵称不能为空",
					trigger: "blur"
				}],
				dept_id: [{
					required: true,
					message: "归属机构不能为空",
					trigger: "blur"
				}],
				roleIds: [{
					required: true,
					message: "角色不能为空",
					trigger: "blur"
				}],
				password: [{
					required: true,
					message: "用户密码不能为空",
					trigger: "blur"
				}],
				email: [{
					required: false,
					message: "邮箱地址不能为空",
					trigger: "blur"
				},
				{
					type: "email",
					message: "'请输入正确的邮箱地址",
					trigger: ["blur", "change"]
				}
				],
				phone: [{
					required: false,
					message: "手机号码不能为空",
					trigger: "blur"
				},
				{
					pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
					message: "请输入正确的手机号码",
					trigger: "blur"
				}
				]
			},
			showReview: false,
			openReview: false,
			reviewForm: {},
			reviewRules: {
				review_status: [{
					required: true,
					message: "请选择审核状态",
					trigger: "blur"
				}],
				review_reason: [{
					required: false,
					message: "请选择输入原因",
					trigger: "blur"
				}]
			},
			subordinateShow: process.env.VUE_APP_SHOW_SUBORDINATE==='true',
			subordinateData: [],
			subordinateLoading: false
		};
	},
	watch: {
		// 根据名称筛选机构树
		name(val) {
			this.$refs.tree.filter(val);
		}
	},
	created() {
        let opt= this.$route
        if(opt.query&&opt.query.keyword){
            this.queryParams.keyword = opt.query.keyword
        }
		this.getList();
		this.getTreeselect();        
		listRole({
			pageSize: 1000
		}).then(response => {
			this.roleList = response.data.list
		})
		listPost({
			pageSize: 1000
		}).then(response => {
			this.postList = response.data.list
			if (this.userList.length > 0) {
				this.userList = [].concat(this.userList)
			}
		})
		listUserPool({
			name: 'admin'
		}).then(res => {
			if (res.data.list.length > 0) {
				if (res.data.list[0].register_review == '1') {
					for (let i = 0; i < this.tableColumn.length; i++) {
						const d = this.tableColumn[i]
						if (d.field == 'review_status') {
							d.visible = true
							this.showReview = true
							break
						}
					}
				}
			}
		})
	},
	methods: {
		//列表筛选加排序
		tableChange(e) {
			console.log(e)
		},
		handleSizeChange({
			currentPage,
			pageSize
		}) {
			this.queryParams.pageNum = currentPage;
			if (this.queryParams.pageSize == pageSize) {
				this.getList();
			} else {
				this.queryParams.pageSize = pageSize;
				this.handleQuery();
			}

		},
		checkboxChangeEvent({
			records
		}) {
			this.ids = records.map(item => item.id)
			this.single = records.length != 1;
			this.multiple = !records.length

		},

		/** 查询用户列表 */
		getList() {
			this.loading = true;
			listUser(this.addDateRange(this.queryParams, this.dateRange)).then(response => {
				let list = []
				if (response.data.list) {
					response.data.list.forEach(item => {
						item.status = '' + item.status
						list.push(item)
					})
				}
				this.single = true;
				this.multiple = true;
				this.userList = list;
				this.total = response.data.total;
				this.statusOptions = response.data.statusOptions;
				this.genderOptions = response.data.genderOptions;
				this.typeOptions = response.data.typeOptions;
				this.loading = false;
			});
		},
		/** 查询机构下拉树结构 */
		getTreeselect() {
			treeselect().then(response => {
				this.deptOptions = response.data;
			});
		},
		// 筛选节点
		filterNode(value, data) {
			console.log(value, data)
			if (!value) return true;
			return data.name.indexOf(value) !== -1;
		},
		// 节点单击事件
		handleNodeClick(data) {
			this.queryParams.pageNum = 1;
			this.queryParams.dept_id = data.id;
			this.getList();
		},
		// 用户解绑设备
		handleUnBindDevice(row) {
			this.$confirm('确认解绑"' + row.username + '"用户设备吗?', "警告", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(function () {
				return unBindDevice(row.id);
			}).then(() => {
				this.msgSuccess("解绑设备成功");
				row.device_id = '';
			}).catch(function () { });
		},
		// 用户状态修改
		handleStatusChange(row) {
			let text = row.status === '1' ? "启用" : "停用";
			this.$confirm('确认要' + text + '"' + row.username + '"用户吗?', "警告", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(function () {
				return changeUserStatus(row.id, row.status);
			}).then(() => {
				this.msgSuccess(text + "成功");
			}).catch(function () {
				row.status = row.status === "0" ? "1" : "0";
			});
		},
		// 取消按钮
		cancel() {
			this.open = false;
			this.reset();
		},
		// 表单重置
		reset() {
			this.form = {
				id: undefined,
				dept_id: undefined,
				username: undefined,
				no: undefined,
				nickname: undefined,
				password: undefined,
				phone: undefined,
				email: undefined,
				gender: undefined,
				status: "1",
				leader_nos: undefined,
				leader_names: undefined,
				remark: undefined,
				postIds: [],
				post_ids: "",
				roleIds: [],
				role_ids: "",
				type: "",
			};
			this.resetForm("form");
		},
		/** 搜索按钮操作 */
		handleQuery() {
			this.queryParams.pageNum = 1;
			this.getList();
		},
		/** 重置按钮操作 */
		resetQuery() {
			this.$refs.tree.setCurrentKey(null)
			this.queryParams.dept_id = 0;
			this.dateRange = [];
          
			this.resetForm("queryForm");
            this.queryParams.keyword =''
			this.handleQuery();
		},
		// 多选框选中数据
		handleSelectionChange(selection) {
			this.ids = selection.map(item => item.id);
			this.single = selection.length != 1;
			this.multiple = !selection.length;
		},
		/** 新增按钮操作 */
		handleAdd() {
			this.reset();
			//this.getTreeselect();
			const loading = this.$loading({
				text: '正在加载'
			})
			getEditUser('').then(response => {
				loading.close()
				this.postOptions = response.data.posts;
				this.roleOptions = response.data.roles;
				this.open = true;
				this.readonly = false;
				this.title = "添加用户";
				this.form.password = '';
			}).catch(e => {
				loading.close()
			});
		},
		/** 修改按钮操作 */
		handleUpdate(row, readonly) {
			this.reset();
			//this.getTreeselect();
			const id = row.id || this.ids;
			this.subordinateData = []
			this.subordinateLoading = true
			const loading = this.$loading({
				text: '正在加载'
			})
			getEditUser(id).then(response => {
				loading.close()
				const user = response.data.user
				const postIdsStr = user.post_ids ? user.post_ids.split(',') : []
				const postIds = []
				for (let i = 0; i < postIdsStr.length; i++) {
					postIds.push(parseInt(postIdsStr[i]))
				}
				const roleIdsStr = user.role_ids ? user.role_ids.split(',') : []
				const roleIds = []
				for (let i = 0; i < roleIdsStr.length; i++) {
					roleIds.push(parseInt(roleIdsStr[i]))
				}
				this.form = {
					id: user.id,
					userpool: user.userpool,
					dept_id: user.dept_id,
					username: user.username,
					no: user.no,
					realname: user.realname,
					idcard: user.idcard,
					nickname: user.nickname,
					password: undefined,
					phone: user.phone,
					email: user.email,
					gender: '' + user.gender,
					status: "" + user.status,
					leader_nos: user.leader_nos,
					leader_names: user.leader_names,
					remark: user.remark,
					postIds: postIds,
					post_ids: user.post_ids,
					roleIds: roleIds,
					role_ids: user.role_ids,
					type: user.type
				}
				this.postOptions = response.data.posts;
				this.roleOptions = response.data.roles;
				this.form.password = "";
				this.readonly = readonly;
				this.title = readonly ? "查看用户" : "修改用户";
				this.open = true;
				if (response.data.user.no) {
					this.loadSubordinateData(response.data.user.no)
				} else {
					this.subordinateLoading = false
				}
			}).catch(e => {
				this.subordinateLoading = false
				loading.close()
			});
		},
		handleReview(row) {
			this.reviewIds = this.ids.join(',')
			this.reviewForm = {}
			this.openReview = true
		},
		loadSubordinateData(no) {
			if (!this.subordinateShow || !no) {
				this.subordinateLoading = false
				return
			}
			// 获取下属
			this.subordinateLoading = true
			listUser({
				pageSize: 1000,
				leader_nos: no
			}).then(response => {
				this.subordinateLoading = false
				if (no != this.form.no) {
					return
				}
				const subordinateData = []
				for (let i = 0; i < response.data.list.length; i++) {
					const d = response.data.list[i]
					subordinateData.push({
						index: i + 1,
						id: d.id,
						userpool: d.userpool,
						no: d.no,
						name: d.realname || d.nickname || d.username,
						dept_id: d.dept_id,
						dept_name: d.dept && d.dept.name || ''
					})
				}
				this.subordinateData = subordinateData
			}).catch(e => {
				this.subordinateLoading = false
			})
		},
		/** 重置密码按钮操作 */
		handleResetPwd(row) {
			this.$prompt('请输入"' + row.username + '"的新密码', "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消"
			}).then(({
				value
			}) => {
				if (!value || value == '') {
					this.msgError('密码不能为空');
					return
				}
				resetUserPwd(row.id, value).then(response => {
					if (response.code === 0) {
						this.msgSuccess("修改成功，新密码是：" + value);
					} else {
						this.msgError(response.msg);
					}
				});
			}).catch(() => { });
		},
		/** 提交按钮 */
		submitForm: function () {
			this.$refs["form"].validate(valid => {
				if (valid) {
					this.form.post_ids = this.form.postIds.join(',')
					this.form.role_ids = this.form.roleIds.join(',')
					if (this.form.id != undefined) {
						let subordinate_nos = ''
						for (let i = 0; i < this.subordinateData.length; i++) {
							const d = this.subordinateData[i]
							if (d.no) {
								if (subordinate_nos != '') {
									subordinate_nos += ','
								}
								subordinate_nos += d.no
							}
						}
						this.form.subordinate_nos = subordinate_nos
						updateUser(this.form).then(response => {
							if (response.code === 0) {
								this.msgSuccess("修改成功");
								this.open = false;
								this.getList();
							} else {
								this.msgError(response.msg);
							}
						});
					} else {
						this.form.subordinate_nos = ''
						addUser(this.form).then(response => {
							if (response.code === 0) {
								this.msgSuccess("新增成功");
								this.open = false;
								this.getList();
							} else {
								this.msgError(response.msg);
							}
						});
					}
				}
			});
		},
		submitReviewForm() {
			if (!this.reviewForm.review_status) {
				this.msgError('请选择审核状态')
				return
			}
			this.$refs["reviewForm"].validate(valid => {
				if (valid) {
					reviewUser('admin', this.reviewIds, this.reviewForm.review_status, this.reviewForm.review_reason).then(res => {
						this.msgSuccess("审核成功");
						this.openReview = false;
						this.getList();
					})
				}
			})
		},
		/** 删除按钮操作 */
		handleDelete(row) {
			const userIds = row.id || (this.ids ? this.ids.join(',') : '');
			this.$confirm('确定删除吗?', "警告", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(function () {
				return delUser(userIds);
			}).then(() => {
				this.getList();
				this.msgSuccess("删除成功");
			}).catch(function () { });
		},

		handleDeleteSubordinate(row) {
			this.$confirm('确定删除吗?', "警告", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(() => {
				const loading = this.$loading({
					text: '正在提交'
				})
				delSubordinate(this.form.userpool, this.form.id, row.id).then(response => {
					loading.close()
					const subordinateData = []
					for (let i = 0; i < this.subordinateData.length; i++) {
						const d = this.subordinateData[i]
						if (d != row) {
							subordinateData.push(d)
						}
					}
					this.subordinateData = subordinateData
					this.msgSuccess("删除成功");
				}).catch(e => {
					loading.close()
				})
			}).catch(function () { });
		},

		handleAddSubordinate(ids, names) {
			if (ids) {
				const loading = this.$loading({
					text: '正在提交'
				})
				addSubordinate(this.form.userpool, this.form.id, ids).then(response => {
					loading.close()
					this.msgSuccess("添加成功");
					this.subordinateLoading = true
					this.subordinateData = []
					this.loadSubordinateData(this.form.no)
				}).catch(e => {
					loading.close()
				})
			}
		},

		clickAddSubordinate() {
			this.$refs["addSubordinateDialog"].clear()
			this.$refs["addSubordinateDialog"].showUserSelect()
		},

		handleImport() {
			this.upload.title = "数据导入"
			this.upload.update = false
			this.upload.open = true
		},

		handleImportProgress(event, file, fileList) {
			this.upload.isUploading = true;
			if (event.percent == 100) {
				this.upload.open = false
				this.upload.loading = this.$loading({
					lock: true,
					text: '正在导入数据',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
			}
		},

		handleImportSuccess(response, file, fileList) {
			if (this.upload.loading) {
				this.upload.loading.close();
			}
			this.upload.open = false;
			this.upload.isUploading = false;
			this.$refs.upload.clearFiles();
			this.$alert(response.code == 0 ? '导入成功 ' : response.msg, "提示", {
				dangerouslyUseHTMLString: true
			});
			this.getList();
		},

		handleImportError(err, file, fileList) {
			if (this.upload.loading) {
				this.upload.loading.close();
			}
			this.upload.isUploading = false;
			this.$alert('导入失败', "提示", {
				dangerouslyUseHTMLString: true
			});
		},

		submitImportForm() {
			this.$refs.upload.submit();
		},

		handleImportTemplate() {
			this.downloadbyurl('/user/importTemplate')
		},

		handleExport() {
			this.$confirm('确定导出数据吗?', "警告", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					this.downloadbyurl('/user/export?' + Qs.stringify(this.queryParams))
				})
		},

		handleSyncRolePost() {
			this.$confirm('确定同步角色岗位吗?', "警告", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					return syncRolePost()
				}).then(() => {
					this.getList();
					this.msgSuccess("同步成功");
				})
		},

		normalizerDeptTreeNode(e) {
			e.id = e.id
			e.label = e.name
			return e
		},

		formatPost(e) {
			if (e.cellValue) {
				let posts = ''
				const sps = e.cellValue.split(',')
				for (let j = 0; j < sps.length; j++) {
					const s = sps[j]
					for (let i = 0; i < this.postList.length; i++) {
						const d = this.postList[i]
						if (d.id == s) {
							if (posts) {
								posts += ' '
							}
							posts += d.name
							break
						}
					}
				}
				return posts
			}
			return ''
		}
	}
};
</script>

<style scoped>
.unbind-device {
	color: #ccc;
	font-size: 14px;
}

.btn-orange {
	background-color: #ff9648;
	color: #fff;
}
</style>