<template>
    <div class="app-container">
        <el-form :inline="true" label-width="68px" label-position="left">
            <el-form-item>
                <el-button
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    :loading="btnLoading"
                    @click="handleAdd"
                    >上传</el-button
                >

                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :loading="btnLoading"
                    @click="handleDelete"
                    >删除</el-button
                >
            </el-form-item>
        </el-form> 
        <div v-if="subtotalinfo" class="sisetutil" style="text-align: right;font-size: 14px;    margin-bottom: 20px;">
            公司风险统计:<span v-for="(item,index) in subtotalinfo" :key="index">
                <span  class="__fxdj" :style="'background-color:'+item.color+' ;'">&nbsp;</span>{{index}} {{ item.value }} </span>
        </div>
            


        <template v-if="pdata.purl">
            
            <div class="sisetutil">{{ pdata.pname }}</div>
            <div class="sisetudiv">
                <img v-if="pdata.purl" :src="pdata.purl" />
            </div>
        </template>

        <template v-if="!pdata.purl">
            <div class="sisetutil">暂无图片,请上传</div>
        </template>

        <el-dialog
            title="上传"
            :visible.sync="open"
            width="80%"
            :close-on-click-modal="false"
            append-to-body
        >
            <el-form
                v-if="open"
                ref="form"
                :model="form"
                :rules="rules"
                label-width="100px"
            >
                <el-form-item label="图片名称" prop="pname">
                    <el-input
                        v-model="form.pname"
                        placeholder="请输入图片名称"
                    />
                </el-form-item>

                <el-form-item label="图片" prop="purl">
                    <el-upload
                        class="upload-demo"
                        accept="image/png, image/jpeg"
                        ref="fieldEditor"
                        action="/file/upload"
                        :headers="uploadHeaders"
                        :data="uploadData"
                        :multiple="false"
                        :limit="1"
                        :file-list="imageList"
                        :on-success="handleUploadSuccess"
                        :on-error="handleUploadError"
                        :on-exceed="handleUploadExceed"
                        :on-remove="handleUploadRemove"
                        :http-request="uploadDss"
                        list-type="picture-card"
                    >
                        <el-button size="small" type="primary"
                            >点击上传</el-button
                        >
                        <div slot="tip" class="el-upload__tip">
                            只能上传jpg/png文件，且不超过2M
                        </div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button
                    type="primary"
                    @click="submitForm"
                    :loading="btnLoading"
                    >确 定</el-button
                >
                <el-button @click="open = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>



<script>
import { getAccessToken } from "@/utils/tokenTool";
import { deepClone } from "@/vform/utils/util";
import {
    sisetuUpload,
    sisetuDel,
    sisetuGet,
    sisetuSubtotal,
} from "@/api/form/data";
import { uploadServer } from "@/api/dss/fastdfsServer.js";
export default {
    data() {
        return {
            subtotalinfo: null,
            pdata: {},
            open: false,
            btnLoading: false,
            form: {},
            rules: {
                pname: [
                    {
                        required: true,
                        message: "名称不能为空",
                        trigger: "blur",
                    },
                ],
                purl: [
                    {
                        required: true,
                        message: "图片不能为空",
                        trigger: "blur",
                    },
                ],
            },
            uploadHeaders: {},
            uploadData: {},
            imageList: [],
        };
    },
    created() {
        this.initdata();
    },
    methods: {
        initdata() {
            this.btnLoading = true;
            sisetuGet()
                .then((rs) => {
                    this.pdata = rs.data.data || {};
                    this.btnLoading = false;
                })
                .catch((e) => {
                    this.btnLoading = false;
                });

            sisetuSubtotal().then((rs) => {
                var data = rs.data.data;
                var fxdj = [ "重大风险", "较大风险","一般风险", "低风险"];
                var info = {};
                for (var i in fxdj) {
                    var key = fxdj[i];
                    info[key] = {
                        value: data[key],
                        color: data["color_" + key],
                    };
                }
                this.subtotalinfo = info;
            });
        },
        handleDelete() {
            if (this.pdata.id != null) {
                this.$confirm("确定删除吗?", "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        return sisetuDel({ ids: this.pdata.id })
                            .then((_) => {
                                this.pdata = {};
                            })
                            .catch((e) => {
                                this.btnLoading = false;
                            });
                    })
                    .then(() => {
                        this.btnLoading = false;
                        this.msgSuccess("删除成功");
                    })
                    .catch(() => {
                        this.btnLoading = false;
                    });
            } else {
                this.msgError("请先上传图片");
            }
        },
        handleAdd() {
            this.form = {};
            this.open = true;
        },
        submitForm() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.btnLoading = true;
                    sisetuUpload(this.form).then((_) => {
                        this.open = false;
                        this.initdata();
                    });
                }
            });

            this.open = false;
        },
        //自定义上传
        async uploadDss(request) {
            // 默认需要登录
            let tokenReq = {
                platform: "jpgfast",
                type: "general",
                source: "system",
                public: 1,
            };
            this.loading = true;
            uploadServer(tokenReq, request.file).then((resp) => {
                // console.log(123);
                if (resp.code == 0) {
                    // const value =
                    // //console.log(value)
                    // this.$emit("input", value);
                    // this.$emit("change", value);
                    this.form.purl = resp.data.url;

                    // this.imageList.push({
                    //     url: resp.data.url,
                    //     name: resp.data.filename,
                    // });
                } else {
                    this.$refs.fieldEditor.uploadFiles =
                        this.$refs.fieldEditor.uploadFiles.filter((e) => {
                            if (e.name !== file.name || e.status !== "ready") {
                                return e;
                            }
                        });

                    this.$message.error({
                        message: "上传失败,原因：" + res.msg,
                        duration: 2000,
                    });
                }
            });
        },

        handleUploadSuccess(response, file, fileList) {
            // console.log(response, file, fileList);
            // file.url = response.data.url;
            // this.form.image = response.data.url;
        },
        handleUploadError(err) {
            console.log(err);
            this.form.image = "";
            if (
                err &&
                (err.message.indexOf("larger than") > 0 ||
                    err.message.indexOf("413") > 0)
            ) {
                this.msgError("上传文件失败，文件大小超过限制");
            } else {
                this.msgError("上传文件失败");
            }
        },
        handleUploadExceed() {
            this.msgError("只能上传一个文件，请删除后重新上传");
        },
        handleUploadRemove() {
            this.form.image = "";
        },
    },
};
</script>

<style rel="stylesheet/scss" scoped>
.__fxdj{
    display: inline-block;
    text-align: center;
    width: 21px;
    margin-right: 5px;
}
.sisetutil {
    text-align: center;
    font-size: 18px;
    line-height: 1.5;
}
.sisetudiv {
    margin: 50px;
}
.sisetudiv img {
    max-width: 100%;
}
</style>