@import "@/styles/variables.scss"; 














































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.setting-form {
    ::v-deep .el-form-item__label {
        font-size: 13px;
        //text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    ::v-deep .el-form-item--mini.el-form-item {
        margin-bottom: 6px;
    }

    .radio-group-custom {
        ::v-deep .el-radio-button__inner {
            padding-left: 12px;
            padding-right: 12px;
        }
    }

    .custom-divider.el-divider--horizontal {
        margin: 10px 0;
    }
}

.setting-collapse {
    ::v-deep .el-collapse-item__content {
        padding-bottom: 6px;
    }

    ::v-deep .el-collapse-item__header {
        font-style: italic;
        font-weight: bold;
    }
}

.small-padding-dialog {
    ::v-deep .el-dialog__body {
        padding: 6px 15px 12px 15px;
    }
}
.btns-list {
    margin: -2.5px;
    display: flex;
    flex-wrap: wrap;
    .btns-item {
        margin: 2.5px;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        padding: 6px 12px;
        line-height: 1;
        font-size: 12px;
        cursor: pointer;
        &.active {
            color: #ffffff;
            background-color: #1890ff;
            border-color: #1890ff;
        }
    }
}
.survey-setting-box {
    display: flex;
    flex-wrap: wrap;
    margin: -10px;
    max-height: 750px;
    overflow-y: auto;
}
.box-card {
    width: 350px;
    margin: 10px;
    min-height: 350px;
}
