<template>
    <div class="app-container">
        <el-form :inline="true" label-width="68px" label-position="left">
            <el-form-item>
                <el-date-picker
                    v-model="echart_selny"
                    type="month"
                    value-format="yyyy-MM"
                    format="yyyy-MM"
                    @change="echart_selnyChange"
                    :loading="btnLoading"
                    placeholder="选择日期"
                >
                </el-date-picker>
                <div>
                <el-radio v-model="ty" label="2">按整改部门</el-radio>
                <el-radio v-model="ty" label="1">按提交部门</el-radio>
      </div>
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    size="mini"
                    :loading="btnLoading"
                    @click="handleSearch"
                    >查询</el-button
                >
            </el-form-item>
        </el-form>

        <div>
            <el-tabs
                v-model="activeName"
                type="border-card"
                @tab-click="handletabsClick"
            >
                <el-tab-pane label="报表" name="chart">
                    <div class="chart" ref="bmwcqk"></div>
                    <div class="chart" ref="bmwcl"></div>
                    <div class="chart" ref="bmwcqk_q"></div>
                    <div class="chart" ref="bmwcl_q"></div>
                    <div class="chart" ref="bmwcqk_y"></div>
                    <div class="chart" ref="bmwcl_y"></div>
                </el-tab-pane>
                <el-tab-pane label="列表" name="table">
                    <vxe-grid
                        resizable
                        ref="xGrid"
                        :loading="btnLoading"
                        :toolbar-config="tableToolbar"
                        :columns="tableColumn"
                        :data="dataList"
                    ></vxe-grid>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>



<script>
import { yhhbechartstable } from "@/api/form/data";
import echarts from "echarts";

export default {
    data() {
        return {
            ty: "2",
            dataList: [],
            tableColumn: [],
            tableToolbar: {
                perfect: false,
                zoom: true,
                custom: false,
            },

            echart_y: 0,
            echart_q: 0,
            echart_m: 0,
            echart_selny: null,
            btnLoading: true,
            activeName: "chart",
        };
    },
    created() {
        var _now = new Date();
        var _m = _now.getMonth() + 1;
        this.echart_selny =
            _now.getFullYear() + "-" + (_m < 10 ? "0" + _m : _m);
        this.echart_selnyChange(this.echart_selny);
        setTimeout(() => {
            this.initdata();
        }, 500);

        var onerow = [
                    "部门",
                    "月汇报数量",
                    "月整改完成数量",
                    "月整改完成率",
                    "季度汇报数量",
                    "季度整改完成数量",
                    "季度整改完成率",
                    "年度汇报数量",
                    "年度整改完成数量",
                    "年度整改完成率",
                ];
                for (var ik in onerow) {
                    var tf = onerow[ik];
                    this.tableColumn.push({
                        field: tf,
                        title: tf,
                        width: 150,

                        visible: true,
                    });
                }
    },
    methods: {
        echart_selnyChange(val) {
            //console.log("echart_selnyChange", val);
            var ta = val.split("-");
            this.echart_y = ta[0];
            this.echart_m = ta[1] * 1;
            if (this.echart_m >= 1 && this.echart_m <= 3) {
                this.echart_q = 1;
            } else if (this.echart_m >= 4 && this.echart_m <= 6) {
                this.echart_q = 2;
            } else if (this.echart_m >= 7 && this.echart_m <= 9) {
                this.echart_q = 3;
            } else if (this.echart_m >= 10 && this.echart_m <= 12) {
                this.echart_q = 4;
            }
        },
        myparseInt(v) {
            var val = parseInt(v);
            if (isNaN(val)) {
                return 0;
            } else {
                return val;
            }
        },
        myparseFloat(v) {
            var val = parseFloat(v);
            if (isNaN(val)) {
                return 0;
            } else {
                return val;
            }
        },
        handleSearch() {
            this.initdata();
        },
        handletabsClick() {},
        initdata() {
            

            this.btnLoading = true;

            var rotate = 10;
            var w1 = 50;

            var toolbox = {
                feature: {
                    saveAsImage: {},
                },
            };

            yhhbechartstable(this.echart_selny,this.ty).then((res) => {
                var option = {};
                var charttitle = "";
                var barWidth = 30;
                var mdepts = new Array();
                var monthcheck_mbcs = [];
                var monthcheck_wccs = [];
                var monthcheck_zgwcl = [];
                var qcheck_zgwcl = [];
                var ycheck_zgwcl = [];

                var qcheck_mbcs = [];
                var qcheck_wccs = [];

                var ycheck_mbcs = [];
                var ycheck_wccs = [];

                var datalist = res.data.rows;

               
                this.dataList = datalist;

                for (var ik in datalist) {
                    var onedata = datalist[ik];
                    mdepts.push(onedata["部门"]);

                    monthcheck_mbcs.push(
                        this.myparseInt(onedata["月汇报数量"])
                    );
                    monthcheck_wccs.push(
                        this.myparseInt(onedata["月整改完成数量"])
                    );

                    qcheck_mbcs.push(this.myparseInt(onedata["季度汇报数量"]));

                    qcheck_wccs.push(this.myparseInt(onedata["季度整改完成数量"]));

                    ycheck_mbcs.push(this.myparseInt(onedata["年度汇报数量"]));

                    ycheck_wccs.push(this.myparseInt(onedata["年度整改完成数量"]));

                    monthcheck_zgwcl.push(
                        this.myparseFloat(onedata["月整改完成率"])
                    );
                    qcheck_zgwcl.push(
                        this.myparseFloat(onedata["季度整改完成率"])
                    );
                    ycheck_zgwcl.push(
                        this.myparseFloat(onedata["年度整改完成率"])
                    );
                }

                var maxbarvalue = Math.max.apply(null, monthcheck_mbcs) + 10;

                var myChart = echarts.init(this.$refs["bmwcqk"]);
                //隐患汇报部门完成情况 月度
                charttitle = `${this.echart_y}年${this.echart_m}月隐患汇报部门完成情况`;
                myChart = echarts.init(this.$refs["bmwcqk"]);

                option = {
                    title: {
                        text: charttitle,
                        x: "center",
                    },
                    toolbox: toolbox,
                    color: ["#3398DB"],
                    tooltip: {
                        trigger: "axis",
                        axisPointer: {
                            type: "cross",
                            crossStyle: {
                                color: "#fff",
                            },
                        },
                    },
                    legend: {
                        bottom: "bottom",
                        data: ["汇报数量", "整改完成"],
                    },
                    xAxis: [
                        {
                            type: "category",
                            data: mdepts,
                            axisPointer: {
                                type: "shadow",
                            },
                            axisLabel: {
                                interval: 0,
                                rotate: rotate,
                            },
                        },
                    ],
                    yAxis: [
                        {
                            type: "value",
                            min: 0,
                            max: maxbarvalue,
                            axisLabel: {
                                formatter: "{value} ",
                            },
                        },
                        {
                            type: "value",

                            min: 0,
                            max: maxbarvalue,
                            axisLabel: {
                                formatter: "{value} ",
                            },
                        },
                    ],
                    dataZoom: [
                        {
                            type: "slider",
                            height: 10,
                            show: true,
                            start: 0,
                            end: w1,
                            xAxisIndex: [0],
                            backgroundColor: "#C0C0C0",
                            fillerColor: "#eceeee",
                        },
                    ],
                    series: [
                        {
                            name: "汇报数量",
                            type: "bar",
                            data: monthcheck_mbcs,
                            barWidth: barWidth + "%",
                            label: {
                                normal: {
                                    show: true,
                                    position: "top",
                                    formatter: function (val) {
                                        return val.value;
                                    },
                                },
                            },
                        },
                        {
                            name: "整改完成",
                            color: ["#00B050"],
                            barGap: "0%",
                            type: "bar",
                            barWidth: barWidth + "%",
                            yAxisIndex: 1,
                            data: monthcheck_wccs,
                            label: {
                                normal: {
                                    show: true,
                                    position: "top",
                                    formatter: function (val) {
                                        return val.value;
                                    },
                                },
                            },
                        },
                    ],
                };

                myChart.setOption(option);

                myChart = echarts.init(this.$refs["bmwcl"]);
                //隐患汇报部门问题整改完成率 月度
                charttitle = `${this.echart_y}年${this.echart_m}月隐患汇报部门问题整改完成率`;
                option = {
                    title: {
                        text: charttitle,
                        x: "center",
                    },
                    toolbox: toolbox,
                    color: ["#3398DB"],
                    tooltip: {
                        trigger: "axis",
                        axisPointer: {
                            type: "cross",
                            crossStyle: {
                                color: "#fff",
                            },
                        },
                    },
                    legend: {
                        bottom: "bottom",
                        data: ["完成率"],
                    },
                    xAxis: [
                        {
                            type: "category",
                            data: mdepts,
                            axisPointer: {
                                type: "shadow",
                            },
                            axisLabel: {
                                interval: 0,
                                rotate: rotate,
                            },
                        },
                    ],
                    yAxis: [
                        {
                            type: "value",
                            name: "完成率",
                            min: 0,
                            max: 100,
                            interval: 20,
                            axisLabel: {
                                formatter: "{value} %",
                            },
                        },
                    ],
                    dataZoom: [
                        {
                            type: "slider",
                            height: 10,
                            show: true,
                            start: 0,

                            xAxisIndex: [0],
                            backgroundColor: "#C0C0C0",
                            fillerColor: "#eceeee",
                        },
                    ],
                    series: [
                        {
                            name: "完成率",
                            type: "bar",
                            barWidth: barWidth + "%",
                            data: monthcheck_zgwcl,
                            label: {
                                normal: {
                                    show: true,
                                    position: "top",
                                    formatter: function (val) {
                                        return val.value + "%";
                                    },
                                },
                            },
                        },
                    ],
                };
                myChart.setOption(option);

                myChart = echarts.init(this.$refs["bmwcqk_q"]);
                //隐患汇报部门完成情况 季度
                charttitle = `${this.echart_y}年${this.echart_q}季度隐患汇报部门完成情况`;

                option = {
                    title: {
                        text: charttitle,
                        x: "center",
                    },
                    toolbox: toolbox,
                    color: ["#3398DB"],
                    tooltip: {
                        trigger: "axis",
                        axisPointer: {
                            type: "cross",
                            crossStyle: {
                                color: "#fff",
                            },
                        },
                    },
                    legend: {
                        bottom: "bottom",
                        data: ["季度目标次数", "季度完成次数"],
                    },
                    xAxis: [
                        {
                            type: "category",
                            data: mdepts,
                            axisPointer: {
                                type: "shadow",
                            },
                            axisLabel: {
                                interval: 0,
                                rotate: rotate,
                            },
                        },
                    ],
                    yAxis: [
                        {
                            type: "value",
                            min: 0,
                            //max: maxbarvalue,
                            axisLabel: {
                                formatter: "{value} ",
                            },
                        },
                        {
                            type: "value",

                            min: 0,
                            //max: maxbarvalue,
                            axisLabel: {
                                formatter: "{value} ",
                            },
                        },
                    ],
                    dataZoom: [
                        {
                            type: "slider",
                            height: 10,
                            show: true,
                            start: 0,
                            end: w1,
                            xAxisIndex: [0],
                            backgroundColor: "#C0C0C0",
                            fillerColor: "#eceeee",
                        },
                    ],
                    series: [
                        {
                            name: "季度目标次数",
                            type: "bar",
                            data: qcheck_mbcs,
                            barWidth: barWidth + "%",
                            label: {
                                normal: {
                                    show: true,
                                    position: "top",
                                    formatter: function (val) {
                                        return val.value;
                                    },
                                },
                            },
                        },
                        {
                            name: "季度完成次数",
                            color: ["#00B050"],
                            barGap: "0%",
                            type: "bar",
                            barWidth: barWidth + "%",
                            yAxisIndex: 1,
                            data: qcheck_wccs,
                            label: {
                                normal: {
                                    show: true,
                                    position: "top",
                                    formatter: function (val) {
                                        return val.value;
                                    },
                                },
                            },
                        },
                    ],
                };

                myChart.setOption(option);

                myChart = echarts.init(this.$refs["bmwcl_q"]);
                //隐患汇报部门问题整改完成率 季度
                charttitle = `${this.echart_y}年${this.echart_q}季度隐患汇报部门问题整改完成率`;
                option = {
                    title: {
                        text: charttitle,
                        x: "center",
                    },
                    toolbox: toolbox,
                    color: ["#3398DB"],
                    tooltip: {
                        trigger: "axis",
                        axisPointer: {
                            type: "cross",
                            crossStyle: {
                                color: "#fff",
                            },
                        },
                    },
                    legend: {
                        bottom: "bottom",
                        data: ["完成率"],
                    },
                    xAxis: [
                        {
                            type: "category",
                            data: mdepts,
                            axisPointer: {
                                type: "shadow",
                            },
                            axisLabel: {
                                interval: 0,
                                rotate: rotate,
                            },
                        },
                    ],
                    yAxis: [
                        {
                            type: "value",
                            name: "完成率",
                            min: 0,
                            max: 100,
                            interval: 20,
                            axisLabel: {
                                formatter: "{value} %",
                            },
                        },
                    ],
                    dataZoom: [
                        {
                            type: "slider",
                            height: 10,
                            show: true,
                            start: 0,

                            xAxisIndex: [0],
                            backgroundColor: "#C0C0C0",
                            fillerColor: "#eceeee",
                        },
                    ],
                    series: [
                        {
                            name: "完成率",
                            type: "bar",
                            barWidth: barWidth + "%",
                            data: qcheck_zgwcl,
                            label: {
                                normal: {
                                    show: true,
                                    position: "top",
                                    formatter: function (val) {
                                        return val.value + "%";
                                    },
                                },
                            },
                        },
                    ],
                };
                myChart.setOption(option);

                myChart = echarts.init(this.$refs["bmwcqk_y"]);
                //隐患汇报部门完成情况 年度
                charttitle = `${this.echart_y}年度隐患汇报部门完成情况`;

                option = {
                    title: {
                        text: charttitle,
                        x: "center",
                    },
                    toolbox: toolbox,
                    color: ["#3398DB"],
                    tooltip: {
                        trigger: "axis",
                        axisPointer: {
                            type: "cross",
                            crossStyle: {
                                color: "#fff",
                            },
                        },
                    },
                    legend: {
                        bottom: "bottom",
                        data: ["年度目标次数", "年度完成次数"],
                    },
                    xAxis: [
                        {
                            type: "category",
                            data: mdepts,
                            axisPointer: {
                                type: "shadow",
                            },
                            axisLabel: {
                                interval: 0,
                                rotate: rotate,
                            },
                        },
                    ],
                    yAxis: [
                        {
                            type: "value",
                            min: 0,
                            //max: maxbarvalue,
                            axisLabel: {
                                formatter: "{value} ",
                            },
                        },
                        {
                            type: "value",

                            min: 0,
                            //max: maxbarvalue,
                            axisLabel: {
                                formatter: "{value} ",
                            },
                        },
                    ],
                    dataZoom: [
                        {
                            type: "slider",
                            height: 10,
                            show: true,
                            start: 0,
                            end: w1,
                            xAxisIndex: [0],
                            backgroundColor: "#C0C0C0",
                            fillerColor: "#eceeee",
                        },
                    ],
                    series: [
                        {
                            name: "年度目标次数",
                            type: "bar",
                            data: ycheck_mbcs,
                            barWidth: barWidth + "%",
                            label: {
                                normal: {
                                    show: true,
                                    position: "top",
                                    formatter: function (val) {
                                        return val.value;
                                    },
                                },
                            },
                        },
                        {
                            name: "年度完成次数",
                            color: ["#00B050"],
                            barGap: "0%",
                            type: "bar",
                            barWidth: barWidth + "%",
                            yAxisIndex: 1,
                            data: ycheck_wccs,
                            label: {
                                normal: {
                                    show: true,
                                    position: "top",
                                    formatter: function (val) {
                                        return val.value;
                                    },
                                },
                            },
                        },
                    ],
                };
                myChart.setOption(option);

                myChart = echarts.init(this.$refs["bmwcl_y"]);
                //隐患汇报部门问题整改完成率 年度
                charttitle = `${this.echart_y}年度隐患汇报部门问题整改完成率`;
                option = {
                    title: {
                        text: charttitle,
                        x: "center",
                    },
                    toolbox: toolbox,
                    color: ["#3398DB"],
                    tooltip: {
                        trigger: "axis",
                        axisPointer: {
                            type: "cross",
                            crossStyle: {
                                color: "#fff",
                            },
                        },
                    },
                    legend: {
                        bottom: "bottom",
                        data: ["完成率"],
                    },
                    xAxis: [
                        {
                            type: "category",
                            data: mdepts,
                            axisPointer: {
                                type: "shadow",
                            },
                            axisLabel: {
                                interval: 0,
                                rotate: rotate,
                            },
                        },
                    ],
                    yAxis: [
                        {
                            type: "value",
                            name: "完成率",
                            min: 0,
                            max: 100,
                            interval: 20,
                            axisLabel: {
                                formatter: "{value} %",
                            },
                        },
                    ],
                    dataZoom: [
                        {
                            type: "slider",
                            height: 10,
                            show: true,
                            start: 0,

                            xAxisIndex: [0],
                            backgroundColor: "#C0C0C0",
                            fillerColor: "#eceeee",
                        },
                    ],
                    series: [
                        {
                            name: "完成率",
                            type: "bar",
                            barWidth: barWidth + "%",
                            data: ycheck_zgwcl,
                            label: {
                                normal: {
                                    show: true,
                                    position: "top",
                                    formatter: function (val) {
                                        return val.value + "%";
                                    },
                                },
                            },
                        },
                    ],
                };
                myChart.setOption(option);
                this.btnLoading = false;
            });
        },
    },
};
</script>

<style rel="stylesheet/scss" scoped>
.chart {
    min-height: 500px;
    margin-bottom: 50px;
}
.__fxdj {
    display: inline-block;
    text-align: center;
    width: 21px;
    margin-right: 5px;
}
.sisetutil {
    text-align: center;
    font-size: 18px;
    line-height: 1.5;
}
.sisetudiv {
    margin: 50px;
}
.sisetudiv img {
    max-width: 100%;
}
</style>