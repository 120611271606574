<!-- 生成时间:2023-10-29 17:04:49 -->
<template>
    <div class="app-container">
        <!-- 渲染查询表单 -->
        <VFormRender
            v-if="queryformData"
            class="query-form-data"
            ref="queryform"
            :formJson="queryformData"
        ></VFormRender>

        <el-form>
            <!-- label-width="68px" -->
            <el-form-item>
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleQuery"
                    >搜索</el-button
                >
                <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                >
            </el-form-item>
        </el-form>

        <jf-table
            ref="jftable"
            :tree-config="treeConfig"
            :loading="loading"
            :query-params="queryParams"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="dataList"
            @sort-change="sortChange"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent"
            @toggle-tree-expand="toggleExpandChangeEvent"
            @cell-dblclick="Oncelldblclick"
            @page-change="handleSizeChange"
            :total="total"
            :filterBtn="switchshowbtn('filter')"
            :sortBtn="switchshowbtn('sort')"
            :operate="dialogType !== 'association'"
            @change="tableChange"
        >
            <template
                slot="toolbar_btn_left"
                v-if="dialogType !== 'association'"
            >
                <!-- <el-dropdown
                    style="margin-right: 10px"
                    @command="typeOptionsChange"
                > -->
                <el-button
                    v-if="switchshowbtn('add')"
                    @click="typeOptionsChange"
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    v-hasPermiZc="['/survey/question/add']"
                    >新增</el-button
                >
                <!-- <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                            v-for="item in typeOptions"
                            :key="item.key"
                            :value="item.key"
                            :command="item.key"
                            >{{ item.value }}</el-dropdown-item
                        >
                    </el-dropdown-menu>
                </el-dropdown> -->

                <el-button
                    v-if="switchshowbtn('edit')"
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    v-hasPermiZc.all="[
                        '/survey/question/get',
                        '/survey/question/edit',
                    ]"
                    >修改</el-button
                >
                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    v-hasPermiZc="['/survey/question/del']"
                    v-if="switchshowbtn('delete')"
                    >删除</el-button
                >
                <el-button
                    v-if="switchshowbtn('import')"
                    type="warning"
                    size="mini"
                    @click="handleImport(1)"
                    v-hasPermiZc="['/survey/question/import']"
                >
                    <i class="iconfont icon-daoru_o"></i>
                    导入
                </el-button>

                <el-button
                    v-if="switchshowbtn('export')"
                    class="btn-orange"
                    size="mini"
                    @click="handleImport(2)"
                    v-hasPermiZc="['/survey/question/export']"
                >
                    <i class="iconfont icon-daochu_o"></i>
                    导出
                </el-button>

                <el-dropdown @command="handleCommand">
                    <el-button
                        size="mini"
                        type="text"
                        style="margin-left: 10px"
                    >
                        更多<i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="operationLogOpen"
                            >操作记录</el-dropdown-item
                        >
                    </el-dropdown-menu>
                </el-dropdown>
            </template>
            <!-- 	<template slot="toolbar_btn_right">

					</template> -->
            <template
                slot="toolbar_btn_row"
                slot-scope="{ row }"
                v-if="dialogType !== 'association'"
            >
                <!-- <el-button
                    size="mini"
                    v-if="switchshowbtn('get')"
                    type="text"
                    icon="el-icon-view"
                    @click="Oncelldblclick({ row })"
                    v-hasPermiZc="['/survey/question/get']"
                    >查看
                </el-button> -->

                <el-button
                    v-if="switchshowbtn('edit')"
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click="handleUpdate(row)"
                    v-hasPermiZc="['/survey/question/edit']"
                    >修改
                </el-button>

                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    v-hasPermiZc="['/survey/question/del']"
                    @click="handleDelete(row)"
                    v-if="switchshowbtn('delete')"
                    >删除
                </el-button>
            </template>
            <template slot="status" slot-scope="{ row }">
                <el-switch
                    v-model="row.status"
                    active-value="1"
                    inactive-value="0"
                    @change="handleStatusChange(row)"
                ></el-switch>
            </template>
            <template slot="roles" slot-scope="{ row }">
                <span v-for="(item, index) of row.roles" :key="index">
                    {{ item.name }}
                </span>
            </template>
            <template
                :slot="item.slots.default"
                slot-scope="{ row }"
                v-for="item in columnSlots"
            >
                <div v-html="getFun(item, row)"></div>
            </template>
        </jf-table>

        <!-- 添加或修改对话框  数据 -->
        <!-- formData &&
                formData.formConfig &&
                formData.formConfig.dialogWidth
                    ? formData.formConfig.dialogWidth
                    : -->
        <!-- <el-dialog
            :title="title"
            :visible.sync="open"
            :width="'90%'"
            :close-on-click-modal="false"
            append-to-body
            :before-close="cancel"
            class="vFormDialog"
        >
           
            <div class="btn-submit">
                <div class="flex-1 ml-5">{{ title }}</div>
                <div>
                    <el-button v-if="!isview" type="primary" @click="submitForm"
                        >确 定</el-button
                    >
                    <el-button @click="cancel">返 回</el-button>
                </div>
            </div>
            <VFormDesigner
                ref="VFormDesigner"
                :is-topic="true"
                :modleid="mid"
            ></VFormDesigner>
        </el-dialog> -->

        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>

        <!-- 导入导出 -->
        <jf-import
            :show.sync="importOpen"
            v-if="importOpen"
            :type="importType"
            :columns="tableColumn"
            :isfinish="isupladfinish"
            :downloadtplurl="downloadtplurl"
            @importsubmit="importsubmit"
            :importError="importError"
            :exportTotal="total"
            @exportSubmit="exportSubmit"
            @saveExportTemplate="saveExportTemplate"
            :exportTemplateList="exportTemplateList"
        ></jf-import>
        <!-- 操作记录 -->
        <jf-operation-log
            :show.sync="operationLogOpen"
            :default-mid="mid"
            v-if="operationLogOpen"
        ></jf-operation-log>

        <!-- 导入提示 -->
        <el-dialog
            title="导入提示"
            :close-on-click-modal="false"
            :visible.sync="showerrinfo"
            width="750px"
            append-to-body
            custom-class="scrolldialog"
        >
            <p class="erinfo" v-for="(item, idx) in upload_errinfo" :key="idx">
                {{ item }}
            </p>
        </el-dialog>
        <topic
            ref="topicForm"
            v-if="dialogType !== 'association'"
            :isview="isview"
            @submit="submitForm"
            @cancel="isview = false"
        ></topic>
    </div>
</template>

<script>
import questionService from "@/api/survey/question";
import { form } from "@/mixins/form.js";

import { customFields } from "@/vform/components/form-designer/widget-panel/widgetsConfig.js";

import { execModel } from "@/api/core/data/datasource/model";
import { getToken } from "@/utils/auth";
import Topic from "../topic/index";
import Treeselect from "@riophae/vue-treeselect";

import "@riophae/vue-treeselect/dist/vue-treeselect.css";

import jfImport from "@/components/jfimport/jfimport";
import JfOperationLog from "@/components/jfoperationlog/jfoperationlog";

import { getFieldWidgetByName, deepClone } from "@/vform/utils/util";
import { loadExtension } from "@/vform/extension/extension-loader";
import VFormRender from "@/vform/components/form-render/index.vue";
import { getUserProfile } from "@/api/core/system/user";
// loadExtension();

export default {
    mixins: [form],
    name: "QuestionList",
    componentName: "QuestionList",
    components: {
        Topic,
        Treeselect,
        VFormRender,
        jfImport,
        JfOperationLog,
        // VFormDesigner,
    },

    props: {
        qbid: {
            //题库id
            type: [Number, String],
            default: undefined,
        },
        type: {
            //题目类型
            type: [Number, String],
            default: undefined,
        },
        dialogType: {
            //弹窗类型
            type: String,
            default: "",
        },
        maxNum: {
            //最大选择数量
            type: [Number, String],
            default: undefined,
        },
        selquestionList: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            checkedList: [],
            org_optionItems: {},
            istree: false,
            hidequeryparamform: false,
            exportTemplateList: [],
            listconf: null,

            importError: false,
            downloadtplurl: "",
            upload_errinfo: null,
            showerrinfo: false,
            isupladfinish: false,
            //导入导出弹窗
            importOpen: false,
            //导入导出类型
            imporType: 1,
            //操作记录
            operationLogOpen: false,
            //功能id
            mid: "980035418117", //不可修改
            queryformData: null, //查询表单数据
            queryformDataorgstr: "",

            formData: null, //表单数据
            isview: false,
            model_info: {},
            child_model_info: {},
            childcollist: [],
            collist: [],
            LeftTreeData: [],
            LeftTreeDataProc: {},
            treeOptions: [],
            treeselectprops: {},
            treeConfig: {
                trigger: "row",
                expandRowKeys: [],
            },
            TreeAttr: {},

            //左侧分栏比例
            leftrolspan: 0,
            //checkbox 的数据
            CheckBoxData: {},
            //字典库数据
            DictsData: {},
            DsData: {},

            area_dist: {},
            province_dist: {},
            city_dist: {},

            //上传的文件列表
            upload_fileList: {},

            //上传文件参数
            upload: {
                headers: {
                    Authorization: getToken(),
                },
                url: process.env.VUE_APP_BASE_API + "/file/upload?public=1",
            },

            //编辑器配置
            ueConfig: {
                // 初始容器高度
                initialFrameHeight: 300,
                // 初始容器宽度
                initialFrameWidth: "100%",
                // 上传文件接口
                serverUrl: process.env.VUE_APP_BASE_API + "/file/ueditorupload",
                enableAutoSave: true,
                elementPathEnable: false,
                wordCount: false,
                UEDITOR_HOME_URL: "/ueditor/",
            },

            //图片预览地址
            dialogImageUrl: "",
            //图片预览对话框
            dialogVisible: false,

            loadingstu: false,
            openselstu: false,

            // 工具栏
            tableToolbar: {
                perfect: false,
                zoom: true,
                custom: false,
                refresh: {
                    query: this.handleQuery,
                },
                slots: {
                    buttons: "toolbar_buttons",
                },
            },
            // 数据列
            tableColumn: [
                // {
                // 	type: "checkbox",
                // 	width: 60,
                // 	fixed: "left"
                // },
                {
                    field: "id",
                    title: "ID",
                    width: 100,
                    fixed: "left",
                },

                // {
                // 	field: "",
                // 	title: "操作",
                // 	resizable: false,
                // 	width: 180,
                // 	fixed: "right",
                // 	align: "center",
                // 	slots: {
                // 		default: "defaultopr"
                // 	},
                // },
            ],

            orgcolumnList: [],
            // 遮罩层
            loading: false,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 总条数
            total: 0,
            totalstu: 0,
            // 表格数据
            dataList: [],
            // 弹出层标题
            title: "",
            // 快速检索数据
            Quitfilter_data: {},
            // 快速检索数据 字典类型
            Quitfilter_keys: [],
            // 是否显示弹出层
            open: false,
            // 机构名称
            deptname: undefined,

            //表单
            form: {},
            defaultProps: {
                children: "children",
                label: "name",
            },

            // 表单校验
            rules: {},
            userInfo: {},
            typeOptions: [],
        };
    },

    computed: {
        columnSlots: function () {
            return this.tableColumn.filter((item) => {
                if (item.title === "答案") {
                    let obj = {
                        slots: {
                            default: "answer",
                        },
                    };
                    item = Object.assign(item, obj);
                }
                if (item.title === "分值") {
                    let obj = {
                        slots: {
                            default: "score",
                        },
                    };
                    item = Object.assign(item, obj);
                }
                if (item.title === "选项") {
                    let obj = {
                        slots: {
                            default: "option",
                        },
                    };
                    item = Object.assign(item, obj);
                }
                return item.slots;
            });
        },
    },
    watch: {
        // 根据名称筛选机构树
        deptname(val) {
            this.$refs.tree.filter(val);
        },
        isview: {
            handler(val) {
                console.log(val, "isview");
            },
            deep: true,
            immediate: true,
        },
        selquestionList: {
            handler(val) {
                this.checkedList = JSON.parse(JSON.stringify(val));
                if (this.dataList.length > 0) {
                    this.setSelectRow();
                }
            },
            immediate: true,
            deep: true,
        },
        qbid:{
            handler(val) {
                this.queryParams.fieldval["qbid"]=val
            },
            immediate: true,
            deep: true,
        }
    },
    beforeCreate() {},
    created() {
        // console.log("this.qbid",this.qbid)
        this.$nextTick(() => {
            if (!customFields || !customFields.length) {
                loadExtension();
            }
        });
        this.downloadtplurl = questionService.questionimportTemplate(); //下载模版地址
        this.queryParams.sortinfo = this.defsort;

        this.getforminfo(this.mid);
        this.getUser();

        this.getDicts("survey_type").then((res) => {
            this.typeOptions = res.data.values;
        });
    },
    methods: {
        reduceSame(arr) {
            let obj = {};
            return arr.reduce(function (prev, item) {
                obj[item.id] ? "" : (obj[item.id] = 1 && prev.push(item));
                return prev;
            }, []);
        },
        checkboxChangeEvent({ records }) {
            
            if (
                this.maxNum &&
                (this.checkedList.length > this.maxNum * 1 ||
                    records.length > this.maxNum)
            ) {
                this.$message.error(`最多选择${this.maxNum}个`);

                records.forEach((item) => {
                    if (
                        this.checkedList.findIndex((o) => o.id === item.id) ===
                        -1
                    ) {
                        this.$refs.jftable.$refs.xGrid.setCheckboxRow(
                            item,
                            false
                        );
                    }
                });
                return;
            }
            this.checkedList = this.checkedList.concat(records);
            this.checkedList = this.reduceSame(this.checkedList);
            //过滤一个未选中的noCheckedList
            let noChecklist = this.dataList.filter(
                (v) => !records.some((vi) => vi.id == v.id)
            );
            this.checkedList = this.checkedList.filter(
                (v) => !noChecklist.some((vi) => vi.id == v.id)
            );
            this.$emit("radioChangeEvent", this.checkedList);
            // this.checkedList = records;
            this.ids = records.map((item) => item.id);
            this.single = records.length != 1;
            this.multiple = !records.length;
        },
        // 设置选中的方法
        setSelectRow() {
            if (this.checkedList.length) {
                console.log(this.checkedList, "this.checkedList.");
                this.dataList.forEach((item) => {
                    if (
                        this.checkedList.some(
                            (checkedItem) => checkedItem.id === item.id
                        )
                    ) {
                        this.$refs.jftable.$refs.xGrid.setCheckboxRow(
                            item,
                            true
                        );
                    }
                });
            }
        },
        typeOptionsChange(e) {
            // this.open = true;
            this.isview = false;
            let data = { title: "新增题库", mid: this.mid };
            this.form = {};
            console.log(this.dialogType);
            this.$nextTick(() => {
                this.$refs.topicForm.getInit(data);
            });
        },
        settagtitle(title) {},
        getUser() {
            getUserProfile().then((response) => {
                this.userInfo = response.data.user;
            });
        },
        getName(row) {},

        exportSubmit(fieldlist) {
            var that = this;
            var tfa = [];
            for (var i in fieldlist) {
                var byname = "";
                if (fieldlist[i].byname != null) {
                    byname = fieldlist[i].byname;
                }
                tfa.push(fieldlist[i].field + "=" + byname);
            }
            var durl = questionService.questionExportUrl(
                this.mid,
                this.queryParams,
                tfa
            );
            try {
                this.downloadbyurl(durl, null, function (ers) {
                    if (ers.currentTarget.status != 200) {
                        that.msgError("导出错误");
                    }
                });
            } catch (error) {
                console.log(chalk.red(error));
            }
        },
        importsubmit(rows) {
            //开始导入
            questionService
                .questionImport(this.mid, rows.datalist, rows.importoption)
                .then((rs) => {
                    if (
                        rs.data.row.Failmsg != null &&
                        rs.data.row.Failmsg != ""
                    ) {
                        this.upload_errinfo = rs.data.row.Failmsg.split("|");
                        this.showerrinfo = true;
                        this.importError = true;
                    } else {
                        this.isupladfinish = true;
                        this.msgSuccess("导入成功");
                        this.getList(this.mid);
                    }
                });
        },
        //导入导出
        handleImport(type) {
            this.importError = false;
            this.isupladfinish = false;
            this.importType = type;
            this.importOpen = true;
        },
        handleCommand(e) {
            //  console.log(e);
            this[e] = true;
        },
        getFun(item, row) {
            if (!item.slotsFun) {
                if (
                    item.slots &&
                    item.slots.default &&
                    item.slots.default === "answer"
                ) {
                    if (!row[item.field]) {
                        return "";
                    }
                    let answer =
                        row[item.field].indexOf("[") > -1
                            ? JSON.parse(row[item.field])
                            : row[item.field];
                    return Array.isArray(answer) ? answer.join("、") : answer;
                }
                if (
                    item.slots &&
                    item.slots.default &&
                    item.slots.default === "score"
                ) {
                    if (!row[item.field]) {
                        return "";
                    }
                    let score =
                        row[item.field].indexOf("{") > -1
                            ? JSON.parse(row[item.field])
                            : {};
                    let arr = [];
                    let html = "";
                    for (let i in score) {
                        if (i === "answer") {
                            html = score[i];
                        } else {
                            html += "答案" + i + "：" + score[i] + "；";
                        }
                    }

                    arr.join("、");
                    return html;
                }
                if (
                    item.slots &&
                    item.slots.default &&
                    item.slots.default === "option"
                ) {
                    if (!row[item.field]) {
                        return "";
                    }
                    let html = "";
                    let option = JSON.parse(row[item.field]);
                    option.forEach((o) => {
                        html += o.label;
                    });

                    return html;
                }
            } else {
                return this[item.slotsFun.funName](
                    item.slotsFun.dt,
                    row[item.slotsFun.key],
                    item.slotsFun.key
                );
            }
        },

        //查看
        Oncelldblclick(row) {
            if (this.dialogType === "association") return;
            this.handleUpdate(row.row, "查看");
        },

        getTreeselect() {
            if (
                this.model_info.Category != "4" &&
                this.model_info.Category != "5"
            ) {
                return false;
            }
            this.treeOptions = [];
            let opt = {
                id: 0,
                children: [],
            };
            opt[this.TreeAttr.tree_name] = "(无上级)";

            if (this.model_info.Category == "5") {
                opt.children = this.LeftTreeData;
            } else {
                opt.children = this.dataList;
            }

            this.treeOptions.push(opt);
            // console.log(this.treeOptions);
        },

        /** 查询数据 */
        getList(mid) {
            this.loading = true;
            if(!this.queryParams.fieldval.qbid){
                this.queryParams.fieldval.qbid = this.qbid
            }
            questionService
                .listQuestion(this.queryParams)
                .then((response) => {
                    this.loading = false;
                    if (this.treeConfig.parentField != null) {
                        var tlist = [];
                        for (let k in response.data.list) {
                            let tonef = response.data.list[k];
                            tonef["label"] = tonef[this.treeConfig.treeNode];

                            tlist.push(JSON.parse(JSON.stringify(tonef)));
                        }
                        try {
                            this.dataList = this.handleTree(
                                tlist,
                                "id",
                                this.treeConfig.parentField
                            );
                        } catch (error) {
                            console.log(" this.dataList error", error);
                        }
                    } else {
                        this.dataList = response.data.list;
                    }
                    this.setSelectRow();
                    this.total = response.data.total;
                })
                .catch(() => {
                    this.loading = false;
                    // this.msgError("加载数据出错,请先检查表单配置!");
                });
        },

        // 筛选节点
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },

        // 取消按钮
        cancel() {
            // this.reset();
            this.form = {};
            this.formData = null;
            this.$refs["topicForm"].cancel();
            this.open = false;
            this.isview = false;
        },
        // 表单重置
        reset() {
            this.form = {};
            this.formData = null;

            this.resetQuery();
        },

        /** 新增按钮操作 */
        handleAdd() {
            //this.reset();
            // this.getTreeselect();
            this.hidequeryparamform = true;
            this.formData = null;
            let tformdata = JSON.parse(this.model_info.Formresource);
            tformdata.formstatus = "add";

            this.$nextTick((_) => {
                this.formData = this.binddicttotablecolumn(tformdata);
            });

            this.open = true;
            this.title = "添加数据";
        },

        /** 修改按钮操作 */
        handleUpdate(row, til) {
            this.reset();
            // this.getTreeselect();
            //this.getTreeselect();
            if (til === "查看") {
                this.isview = true;
            }
            const id = row.id || this.ids[0];
            questionService.getQuestion(id).then((response) => {
                this.form.id = response.data.row.id;

                //填充表单值
                let formvalue = response.data.row; //表单填写内容

                let array = Object.keys(formvalue);
                this.reformatformdata(formvalue);
                let list = [JSON.parse(formvalue.options).field];

                let formData = {
                    widgetList: list,
                    formstatus: "edit",
                }; //表单数据(字段,布局等)
                this.formData = formData;
                this.$nextTick((_) => {
                    // this.formData = this.binddicttotablecolumn(formData);

                    let data = {
                        title: "修改题库",
                        mid: this.mid,
                        formData: formData,
                    };
                    this.$refs.topicForm.getInit(data);
                });

                // this.open = true;
                // this.title = til || "修改";
            });
        },

        /** 提交按钮 */
        submitForm: function (formData) {
            var data = {};
            data.options = JSON.stringify(formData.FieldWidgets);

            data.qbid = this.qbid;
            data.select83373 = this.type;
            let form = {
                mid: this.mid,
                formdata: JSON.stringify(data),
            };
            if (this.form.id) {
                form.dataid = this.form.id;
            }
            questionService
                .addQuestion(form)
                .then((rs) => {
                    this.msgSuccess("操作成功");
                    this.cancel();
                    this.handleQuery();
                })
                .catch(() => {
                    // this.msgError("提交失败!");
                });

            // this.$refs.form
            //     .getFormData()
            //     .then((data) => {
            //         if (this.form.id != null) {
            //             //修改
            //             data.id = this.form.id;

            //             questionService
            //                 .editQuestion(this.mid, data)
            //                 .then((rs) => {
            //                     this.msgSuccess("操作成功");
            //                     this.cancel();
            //                     this.handleQuery();
            //                 })
            //                 .catch(() => {
            //                     // this.msgError("提交失败!");
            //                 });
            //         } else {
            //             //新增
            //             data.qbid = this.qbid;
            //             questionService
            //                 .addQuestion(this.mid, data)
            //                 .then((rs) => {
            //                     this.msgSuccess("操作成功");
            //                     this.cancel();
            //                     this.handleQuery();
            //                 })
            //                 .catch(() => {
            //                     // this.msgError("提交失败!");
            //                 });
            //         }
            //     })
            //     .catch((e) => {
            //         this.msgError("表单验证不通过,请检查输入");
            //     });

            return true;
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            const did = row.id || (this.ids ? this.ids.join(",") : "");
            this.$confirm("是否确认删除所选数据项?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    return questionService.delQuestion(did);
                })
                .then(() => {
                    this.handleQuery();
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },

        normalizerDeptTreeNode(e) {
            //      e.id = e.id
            e.label = e.name;
            return e;
        },
    },
};
</script>
<style>
.vxe-table--tooltip-wrapper {
    z-index: 10000 !important;
}
</style>
<style rel="stylesheet/scss" scoped>
.form-data {
    margin: 0 -2rem;
}
.btn-orange {
    background-color: #ff9648;
    color: #fff;
}

.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
    background-color: #2196f3;
    color: #fff;
}

::v-deep .scrolldialog ::v-deep .el-dialog__body {
    height: 70vh;
    overflow-y: scroll;
}

.head-container {
    font-size: 14px;
}
.vFormDialog ::v-deep .el-dialog__header {
    display: none;
}
.vFormDialog .btn-submit {
    font-size: 18px;
    border-bottom: #9e9e9e38 solid 1px;
    padding-bottom: 10px;
    margin-top: -7px;
    font-weight: bold;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>