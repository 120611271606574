@import "@/styles/variables.scss"; 









































































































































































































































































































































































































































































































































.vFormDialog ::v-deep .el-dialog__header {
    display: none;
}
.vFormDialog ::v-deep .el-el-dialog__body {
    height: 100vh !important;
}
.vFormDialog .btn-submit {
    font-size: 18px;
    border-bottom: #9e9e9e38 solid 1px;
    padding-bottom: 10px;
    margin-top: -7px;
    font-weight: bold;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
