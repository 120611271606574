@import "@/styles/variables.scss"; 

































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.screen {
  width: 100%;
  background: #fff;
  border-radius: 12px;
  padding: 20px;
}
.dashboard-editor-container {
  width: 100%;
  height: 100%;
  background: #f6f8f9 !important;
}
.active {
  color: red !important;
}
.active2 {
  color: #0099ff !important;
}
.box {
  background-color: #fff;
  border-radius: 12px;
  padding: 0px 3px 32px 20px;
  box-sizing: border-box;
  h4 {
    border-bottom: 1px solid #f9f9f9;
    line-height: 40px;
  }
  &-content {
    display: flex;
    justify-content: space-around;
    &:nth-child(1) {
      border-right: 1px solid #f9f9f9;
    }
    &-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .h2 {
        font-size: 36px;
        font-weight: normal;
      }
      span {
        color: #8b8b8b;
      }
    }
  }
}

.business {
  background-color: #fff;
  border-radius: 12px;
  padding: 3px 3px 32px 20px;
  box-sizing: border-box;
  margin-top: 30px;
  position: relative;
  h4 {
    border-bottom: 1px solid #f9f9f9;
    line-height: 40px;
  }
  &-content {
    display: flex;
    justify-content: space-around;
    &-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      .h2 {
        font-size: 36px;
        font-weight: normal;
      }
      span {
        color: #8b8b8b;
      }
    }
  }

  &-img {
    position: absolute;
    right: 0px;
    top: -20px;
    width: 90px;
    height: 80px;
  }
}

.sale {
  background-color: #fff;
  border-top: 1p solid #fff;
  margin-top: 20px;
  padding: 0 65px;
  box-sizing: border-box;
  .saleTop {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px 0;
    font-size: 16px;
  }
  .saleOfLeave {
    padding: 0px 20px;

    @keyframes animame {
      0% {
        transform: translateY(0%);
      }
      100% {
        transform: translateY(-50%);
      }
    }
    height: 40vh;
    overflow: hidden;
    &-content {
      // animation: animame 5s linear infinite;
      &-item {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 20px;
        div {
          width: 30%;
          &:nth-child(1) {
            color: rgba(24, 144, 255, 1);
            font-size: 16px;
            font-weight: bold;
          }
          &:nth-child(2) {
            color: rgba(165, 165, 165, 1);
          }
          &:nth-child(3) {
            color: #ff0000;
            text-align: right;
          }
          &:nth-child(4),
          &:nth-child(5) {
            color: rgba(165, 165, 165, 1);
            margin-top: 5px;
          }
          &:nth-child(5) {
            text-align: right;
          }
        }
      }
    }
  }

  &-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    .content {
      background-color: rgba(236, 237, 241, 0.5);
      color: rgba(16, 16, 16, 1);
      font-size: 18px;
      margin: 20px;
      padding: 10px 8vw;
      border-radius: 5px;
    }
  }
}
