<template>
    <div class="app-container student-container">
        <!-- <el-form
            :model="queryParams"
            ref="queryForm"
            :inline="true"
            label-width="68px"
        > -->
        <!--<el-form-item label="id" prop="id">
			<el-input
				v-model="queryParams.id"
				placeholder="请输入id"
				clearable
				size="small"
				@keyup.enter.native="handleQuery"
			/>
		</el-form-item>-->
        <!-- <el-form-item label="学号" prop="no">
                <el-input
                    v-model="queryParams.no"
                    placeholder="请输入学号"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="姓名" prop="name">
                <el-input
                    v-model="queryParams.name"
                    placeholder="请输入姓名"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="教学院系" prop="dept_no">
                <dept-select
                    v-model="queryParams.dept_no"
                    placeholder="请选择教学院系"
                    valueField="no"
                    size="small"
                />
            </el-form-item>
            <el-form-item label="专业" prop="major_no">
                <major-select
                    v-model="queryParams.major_no"
                    placeholder="请选择专业"
                    :dept_no="queryParams.dept_no"
                    valueField="no"
                    size="small"
                />
            </el-form-item>
            <el-form-item label="班级" prop="classes_no">
                <el-input
                    v-model="queryParams.classes_no"
                    placeholder="请输入班级"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="辅导员" prop="assistant_nos">
                <el-input
                    v-model="queryParams.assistant_nos"
                    placeholder="请输入辅导员姓名或工号"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>

            <el-form-item
                v-for="item in extListSearch"
                :key="item.name"
                :label="item.label"
                :prop="extFieldPrefix + item.name"
            >
                <dict-select
                    v-if="
                        item.type == 'select' ||
                        item.type == 'selectm' ||
                        item.type == 'radio' ||
                        item.type == 'checkbox'
                    "
                    v-model="queryParams[extFieldPrefix + item.name]"
                    :placeholder="'请选择' + item.label"
                    :type="item.ext"
                    :multiple="
                        item.type == 'selectm' || item.type == 'checkbox'
                    "
                    size="small"
                /> -->
        <!--<dict-radio v-else-if="item.type=='radio'"
        v-model="queryParams[extFieldPrefix+item.name]"
        :placeholder="'请选择'+item.label"
        :type="item.ext"
        size="small"
      />
      <dict-checkbox v-else-if="item.type=='checkbox'"
        v-model="queryParams[extFieldPrefix+item.name]"
        :placeholder="'请选择'+item.label"
        :type="item.ext"
        size="small"
      />-->
        <!-- <datetime-select
                    v-else-if="item.type == 'date'"
                    v-model="queryParams[extFieldPrefix + item.name]"
                    type="date"
                    :placeholder="'请选择' + item.label"
                    size="small"
                />
                <datetime-select
                    v-else-if="item.type == 'datetime'"
                    v-model="queryParams[extFieldPrefix + item.name]"
                    type="datetime"
                    :placeholder="'请选择' + item.label"
                    size="small"
                />
                <el-input-number
                    v-else-if="item.type == 'number'"
                    v-model="queryParams[extFieldPrefix + item.name]"
                    :placeholder="'请输入' + item.label"
                    clearable
                    controls-position="right"
                    size="small"
                />
                <el-input
                    v-else
                    v-model="queryParams[extFieldPrefix + item.name]"
                    :placeholder="'请输入' + item.label"
                    clearable
                />
            </el-form-item>

            <el-form-item>
                <el-button
                    type="cyan"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleQuery"
                    >搜索</el-button
                >
                <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                >
            </el-form-item>
        </el-form> -->
        <!-- 
        <vxe-grid
            resizable
            ref="xGrid"
            row-id="id"
            stripe
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="dataList"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent"
        >
            <template #toolbar_buttons>
                <el-button
                    style="margin-left: 10px"
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    v-hasPermi="['/stu/stustudent/add']"
                    >新增</el-button
                >

                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    v-hasPermi="['/stu/stustudent/get', '/stu/stustudent/edit']"
                    >修改</el-button
                >

                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    v-hasPermi="['/stu/stustudent/del']"
                    >删除</el-button
                >

                <el-button
                    type="warning"
                    icon="el-icon-refresh-left"
                    size="mini"
                    @click="handleSyncAccount"
                    v-hasPermi="['/stu/stustudent/syncAccount']"
                    >同步账号</el-button
                >

                <el-button
                    type="warning"
                    icon="el-icon-upload"
                    size="mini"
                    @click="handleImport"
                    v-hasPermi="['/stu/stustudent/import']"
                    >导入</el-button
                >

                <el-button
                    type="warning"
                    icon="el-icon-download"
                    size="mini"
                    @click="handleExport"
                    v-hasPermi="['/stu/stustudent/export']"
                    >导出</el-button
                >
            </template>

            <template #defaultopr="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click.stop="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click.stop="handleDelete(row)"
                    >删除
                </el-button>
            </template>

            <template #empty>
                <span>
                    <p>暂无数据</p>
                </span>
            </template>
        </vxe-grid>

        <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page.sync="queryParams.pageNum"
            :page-size.sync="queryParams.pageSize"
            :total="total"
            @page-change="handlePageChange"
        >
        </vxe-pager> -->
        <jf-table
            :loading="loading"
            :columns="tableColumn"
            :data="dataList"
            :toolbar-config="tableToolbar"
            :query-params="queryParams"
            @checkbox-change="checkboxChangeEvent"
            @page-change="handlePageChange"
            :total="total"
            :dicts-data="dictsData"
            @change="tableChange"
            @handleQuery="handleQuery"
        >
            <template slot="query_qarams_item">
                <el-form-item label="辅导员" prop="assistant_nos">
                    <el-input
                        v-model="queryParams.assistant_nos"
                        placeholder="请输入辅导员姓名或工号"
                        clearable
                        size="small"
                        @keyup.enter.native="handleQuery"
                    />
                </el-form-item>
                <el-form-item label="教学院系" prop="dept_no">
                    <dept-select
                        v-model="queryParams.dept_no"
                        placeholder="请选择教学院系"
                        valueField="no"
                        size="small"
                    />
                </el-form-item>
                <el-form-item label="专业" prop="major_no">
                    <major-select
                        v-model="queryParams.major_no"
                        placeholder="请选择专业"
                        :dept_no="queryParams.dept_no"
                        valueField="no"
                        size="small"
                    />
                </el-form-item>
                <el-form-item
                    v-for="item in extListSearch"
                    :key="item.name"
                    :label="item.label"
                    :prop="extFieldPrefix + item.name"
                >
                    <dict-select
                        v-if="
                            item.type == 'select' ||
                            item.type == 'selectm' ||
                            item.type == 'radio' ||
                            item.type == 'checkbox'
                        "
                        v-model="queryParams[extFieldPrefix + item.name]"
                        :placeholder="'请选择' + item.label"
                        :type="item.ext"
                        :multiple="
                            item.type == 'selectm' || item.type == 'checkbox'
                        "
                        size="small"
                    />
                    <!--<dict-radio v-else-if="item.type=='radio'"
        v-model="queryParams[extFieldPrefix+item.name]"
        :placeholder="'请选择'+item.label"
        :type="item.ext"
        size="small"
      />
      <dict-checkbox v-else-if="item.type=='checkbox'"
        v-model="queryParams[extFieldPrefix+item.name]"
        :placeholder="'请选择'+item.label"
        :type="item.ext"
        size="small"
      />-->
                    <datetime-select
                        v-else-if="item.type == 'date'"
                        v-model="queryParams[extFieldPrefix + item.name]"
                        type="date"
                        :placeholder="'请选择' + item.label"
                        size="small"
                    />
                    <datetime-select
                        v-else-if="item.type == 'datetime'"
                        v-model="queryParams[extFieldPrefix + item.name]"
                        type="datetime"
                        :placeholder="'请选择' + item.label"
                        size="small"
                    />
                    <el-input-number
                        v-else-if="item.type == 'number'"
                        v-model="queryParams[extFieldPrefix + item.name]"
                        :placeholder="'请输入' + item.label"
                        clearable
                        controls-position="right"
                        size="small"
                    />
                    <el-input
                        v-else
                        v-model="queryParams[extFieldPrefix + item.name]"
                        :placeholder="'请输入' + item.label"
                        clearable
                    />
                </el-form-item>
            </template>
            <template slot="toolbar_btn_left">
                <el-button
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    v-hasPermi="['/stu/stustudent/add']"
                    >新增</el-button
                >

                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    v-hasPermi="['/stu/stustudent/get', '/stu/stustudent/edit']"
                    >修改</el-button
                >

                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    v-hasPermi="['/stu/stustudent/del']"
                    >删除</el-button
                >

                <el-button
                    type="warning"
                    icon="el-icon-refresh-left"
                    size="mini"
                    @click="handleSyncAccount"
                    v-hasPermi="['/stu/stustudent/syncAccount']"
                    >同步账号</el-button
                >

                <el-button
                    type="warning"
                    size="mini"
                    @click="handleImport"
                    v-hasPermi="['/stu/stustudent/import']"
                >
                    <i class="iconfont icon-daoru_o"></i>
                    导入
                </el-button>

                <el-button
                    class="btn-orange"
                    size="mini"
                    @click="handleExport"
                    v-hasPermi="['/stu/stustudent/import']"
                >
                    <i class="iconfont icon-daochu_o"></i>
                    导出
                </el-button>

                <el-button
                    type="success"
                    size="mini"
                    @click="handleImportPhoto"
                    v-hasPermi="['/stu/stustudent/import']"
                >
                    <i class="iconfont icon-daoru_o"></i>
                    导入照片
                </el-button>
            </template>
            <template slot="toolbar_btn_row" slot-scope="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-view"
                    @click.stop="handleView(row)"
                    >档案
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click.stop="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click.stop="handleDelete(row)"
                    >删除
                </el-button>
            </template>
        </jf-table>

        <el-dialog
            :close-on-click-modal="false"
            :title="title"
            :visible.sync="open"
            width="960px"
            append-to-body
            custom-class="scrolldialog"
        >
            <el-form
                ref="form"
                :model="form"
                :rules="rules"
                label-width="150px"
            >
                <el-row>
                    <el-col :span="20">
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="学号" prop="no">
                                    <el-input
                                        v-model="form.no"
                                        placeholder="请输入学号"
                                        clearable
                                    />
                                </el-form-item>
                            </el-col>
                            <!--<el-col :span="12">
                            <el-form-item label="校园卡号" prop="card_no">
                                <el-input
                                    v-model="form.card_no"
                                    placeholder="请输入校园卡号"
                                    clearable
                                />
                            </el-form-item> 
                        </el-col>-->
                            <el-col :span="12">
                                <el-form-item label="姓名" prop="name">
                                    <el-input
                                        v-model="form.name"
                                        placeholder="请输入姓名"
                                        clearable
                                    />
                                </el-form-item>
                            </el-col>
                            <!--<el-col :span="12">
                            <el-form-item label="别名" prop="nickname">
                                <el-input
                                    v-model="form.nickname"
                                    placeholder="请输入别名"
                                    clearable
                                />
                            </el-form-item> 
                        </el-col>-->
                            <el-col :span="12">
                                <el-form-item label="教学院系" prop="dept_no">
                                    <dept-select
                                        v-model="form.dept_no"
                                        :defaultLabel="form.dept_name"
                                        placeholder="请选择教学院系"
                                        valueField="no"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="教学专业" prop="major_no">
                                    <major-select
                                        v-model="form.major_no"
                                        :defaultLabel="form.major_name"
                                        placeholder="请选择教学专业"
                                        :dept_no="form.dept_no"
                                        valueField="no"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="教学班级" prop="classes_no">
                                    <el-input
                                        v-model="form.classes_no"
                                        placeholder="请输入教学班级"
                                        clearable
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="年级" prop="grade">
                                    <dict-select
                                        v-model="form.grade"
                                        placeholder="请选择年级"
                                        type="stu_grade"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="辅导员" prop="assistant_nos">
                                    <assistant-select
                                        v-model="form.assistant_nos"
                                        :defaultLabel="form.assistant_names"
                                        placeholder="请选择辅导员"
                                        @change="handleAssistantChange"
                                    />
                                </el-form-item>
                            </el-col>
                            <!--<el-col :span="12">
                            <el-form-item label="考生考号" prop="candidate_no">
                                <el-input
                                    v-model="form.candidate_no"
                                    placeholder="请输入考生考号"
                                    clearable
                                />
                            </el-form-item> 
                        </el-col>-->
                            <el-col :span="12">
                                <el-form-item label="身份证号" prop="idcard">
                                    <el-input
                                        v-model="form.idcard"
                                        placeholder="请输入身份证号"
                                        clearable
                                    />
                                </el-form-item>
                            </el-col>
                            <!--<el-col :span="12">
                            <el-form-item label="身份证地址" prop="idcard_addr">
                                <el-input
                                    v-model="form.idcard_addr"
                                    placeholder="请输入身份证地址"
                                    clearable
                                />
                            </el-form-item> 
                        </el-col>-->
                            <el-col :span="12">
                                <el-form-item label="性别" prop="gender">
                                    <dict-select
                                        v-model="form.gender"
                                        placeholder="请选择性别"
                                        type="stu_gender"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="民族" prop="ethnicity">
                                    <dict-select
                                        v-model="form.ethnicity"
                                        placeholder="请选择民族"
                                        type="stu_ethnicity"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="出生年月" prop="birthday">
                                    <datetime-select
                                        v-model="form.birthday"
                                        type="date"
                                        placeholder="请选择出生年月"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="政治面貌" prop="politics">
                                    <dict-select
                                        v-model="form.politics"
                                        placeholder="请选择政治面貌"
                                        type="stu_politics"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="学籍状态" prop="status">
                                    <dict-select
                                        v-model="form.status"
                                        placeholder="请选择学籍状态"
                                        type="stu_status"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="学制" prop="school_system">
                                    <dict-select
                                        v-model="form.school_system"
                                        placeholder="请选择学制"
                                        type="stu_school_system"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="培养层次" prop="stu_level">
                                    <dict-select
                                        v-model="form.stu_level"
                                        placeholder="请选择培养层次"
                                        type="stu_level"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="入学时间" prop="in_date">
                                    <datetime-select
                                        v-model="form.in_date"
                                        type="date"
                                        placeholder="请选择入学时间"
                                    />
                                </el-form-item>
                            </el-col>
                            <!--<el-col :span="12">
                            <el-form-item label="省份" prop="province">
                                <dict-select
                                    v-model="form.province"
                                    placeholder="请选择省份"
                                    type="stu_province"
                                />
                            </el-form-item> 
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="户口" prop="residence">
                                <dict-select
                                    v-model="form.residence"
                                    placeholder="请选择户口"
                                    type="stu_residence"
                                />
                            </el-form-item> 
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="毕业高中" prop="senior_school">
                                <el-input
                                    v-model="form.senior_school"
                                    placeholder="请输入毕业高中"
                                    clearable
                                />
                            </el-form-item> 
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="联系电话" prop="mobile">
                                <el-input
                                    v-model="form.mobile"
                                    placeholder="请输入联系电话"
                                    clearable
                                />
                            </el-form-item> 
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="邮箱" prop="mail">
                                <el-input
                                    v-model="form.mail"
                                    placeholder="请输入邮箱"
                                    clearable
                                />
                            </el-form-item> 
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="扩展信息" prop="ext">
                                <el-input
                                    v-model="form.ext"
                                    placeholder="请输入扩展信息"
                                    clearable
                                />
                            </el-form-item> 
                        </el-col>-->
                            <el-col :span="12" v-for="item in extList" :key="item.name">
                                <el-form-item
                                    v-if="item.hidden != '1'"
                                    :label="item.label"
                                    :prop="extFieldPrefix + item.name"
                                >
                                    <dict-select
                                        v-if="
                                            item.type == 'select' ||
                                            item.type == 'selectm'
                                        "
                                        v-model="form[extFieldPrefix + item.name]"
                                        :placeholder="'请选择' + item.label"
                                        :type="item.ext"
                                        :multiple="item.type == 'selectm'"
                                    />
                                    <dict-radio
                                        v-else-if="item.type == 'radio'"
                                        v-model="form[extFieldPrefix + item.name]"
                                        :placeholder="'请选择' + item.label"
                                        :type="item.ext"
                                    />
                                    <dict-checkbox
                                        v-else-if="item.type == 'checkbox'"
                                        v-model="form[extFieldPrefix + item.name]"
                                        :placeholder="'请选择' + item.label"
                                        :type="item.ext"
                                    />
                                    <datetime-select
                                        v-else-if="item.type == 'date'"
                                        v-model="form[extFieldPrefix + item.name]"
                                        type="date"
                                        :placeholder="'请选择' + item.label"
                                    />
                                    <datetime-select
                                        v-else-if="item.type == 'datetime'"
                                        v-model="form[extFieldPrefix + item.name]"
                                        type="datetime"
                                        :placeholder="'请选择' + item.label"
                                    />
                                    <el-input-number
                                        v-else-if="item.type == 'number'"
                                        v-model="form[extFieldPrefix + item.name]"
                                        :placeholder="'请输入' + item.label"
                                        clearable
                                        controls-position="right"
                                    />
                                    <el-input
                                        v-else-if="item.type == 'textarea'"
                                        v-model="form[extFieldPrefix + item.name]"
                                        :placeholder="'请输入' + item.label"
                                        clearable
                                        :rows="item.ext ? parseInt(item.ext) : 2"
                                        type="textarea"
                                    />
                                    <el-input
                                        v-else
                                        v-model="form[extFieldPrefix + item.name]"
                                        :placeholder="'请输入' + item.label"
                                        clearable
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item
                                    label="是否已生成账号"
                                    prop="sync_account"
                                >
                                    <dict-select
                                        v-model="form.sync_account"
                                        placeholder="请选择是否已生成账号"
                                        type="stu_yes_no"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="备注信息" prop="remark">
                                    <el-input
                                        v-model="form.remark"
                                        placeholder="请输入备注信息"
                                        clearable
                                        type="textarea"
                                    />
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :span="4">
                        <div style="margin-left: 30px;">
                            <image-upload v-model="form.photo" tip="" :multiple="false" :disabled="false" />
                            <div style="margin-left: 60px; margin-top: 10px">头像</div>
                        </div>
                    </el-col>
                </el-row>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">返 回</el-button>
            </div>
        </el-dialog>
        <el-dialog
            :close-on-click-modal="false"
            title="档案"
            :visible.sync="openDa"
            width="1080px"
            append-to-body
            custom-class="scrolldialog"
        >
            <el-tabs v-if="openDa" type="border-card">
                <el-tab-pane label="学生信息">
                    <table style="width: 100%;">
                        <tr>
                            <td class="theader ttitle tfirst">学生基本信息</td>
                            <td class="theader tvalue tfirst" colspan="7"></td>
                        </tr>
                        <tr>
                            <td class="ttitle">姓名</td>
                            <td class="tvalue">{{ this.form.name }}</td>
                            <td class="ttitle">学号</td>
                            <td class="tvalue">{{ this.form.no }}</td>
                            <td class="ttitle">性别</td>
                            <td class="tvalue">{{ this.dictFormatEx('stu_gender', this.form.gender) }}</td>
                            <td class="ttitle">证件类型</td>
                            <td class="tvalue">身份证</td>
                        </tr>
                        <tr>
                            <td class="ttitle">证件号</td>
                            <td class="tvalue">{{ this.form.idcard }}</td>
                            <td class="ttitle">出生日期</td>
                            <td class="tvalue">{{ this.parseTime(this.form.birthday, '{y}-{m}-{d}') }}</td>
                            <td class="ttitle">民族</td>
                            <td class="tvalue">{{ this.dictFormatEx('stu_ethnicity', this.form.ethnicity) }}</td>
                            <td class="ttitle">政治面貌</td>
                            <td class="tvalue">{{ this.dictFormatEx('stu_politics', this.form.politics) }}</td>
                        </tr>
                        <tr>
                            <td class="ttitle">联系电话</td>
                            <td class="tvalue">{{ this.form.mobile }}</td>
                            <td class="ttitle">班级</td>
                            <td class="tvalue">{{ this.form.classes_no }}</td>
                            <td class="ttitle">专业</td>
                            <td class="tvalue">{{ this.form.major_name }}</td>
                            <td class="ttitle">院系</td>
                            <td class="tvalue">{{ this.form.dept_name }}</td>
                        </tr>
                        <tr>
                            <td class="theader ttitle">家庭信息</td>
                            <td class="theader tvalue" colspan="7"></td>
                        </tr>
                        <tr>
                            <td class="ttitle"></td>
                            <td class="tvalue" colspan="7">
                                <el-table
                                    :data="[]"
                                    border
                                    style="width: 100%">
                                    <el-table-column
                                    prop="name"
                                    label="姓名"
                                    width="100">
                                    </el-table-column>
                                    <el-table-column
                                    prop="age"
                                    label="年龄">
                                    </el-table-column>
                                    <el-table-column
                                    prop="gx"
                                    label="与学生关系">
                                    </el-table-column>
                                    <el-table-column
                                    prop="gzdw"
                                    label="工作单位">
                                    </el-table-column>
                                    <el-table-column
                                    prop="zy"
                                    label="职业">
                                    </el-table-column>
                                    <el-table-column
                                    prop="nsr"
                                    label="年收入（元）">
                                    </el-table-column>
                                    <el-table-column
                                    prop="phone"
                                    label="联系电话">
                                    </el-table-column>
                                    <el-table-column
                                    prop="jk"
                                    label="健康状况">
                                    </el-table-column>
                                </el-table>
                            </td>
                        </tr>
                        <tr>
                            <td class="ttitle">户籍所在地</td>
                            <td class="tvalue"></td>
                            <td class="ttitle">家庭人口数</td>
                            <td class="tvalue"></td>
                            <td class="ttitle">家庭年收入（元）</td>
                            <td class="tvalue"></td>
                            <td class="ttitle">是否建档立卡</td>
                            <td class="tvalue"></td>
                        </tr>
                        <tr>
                            <td class="ttitle">家庭地址</td>
                            <td class="tvalue" colspan="7">
                            </td>
                        </tr>
                        <tr>
                            <td class="ttitle">毕业总评</td>
                            <td class="tvalue" colspan="7">
                            </td>
                        </tr>
                    </table>
                </el-tab-pane>
                <el-tab-pane label="学年学期评语">
                    <el-table
                        :data="[]"
                        border
                        style="width: 100%">
                        <el-table-column
                        prop="no"
                        label="学号"
                        width="100">
                        </el-table-column>
                        <el-table-column
                        prop="name"
                        label="姓名"
                        width="100">
                        </el-table-column>
                        <el-table-column
                        prop="grade"
                        label="年级">
                        </el-table-column>
                        <el-table-column
                        prop="classes_no"
                        label="班级">
                        </el-table-column>
                        <el-table-column
                        prop="dept_name"
                        label="院系">
                        </el-table-column>
                        <el-table-column
                        prop="major_name"
                        label="专业">
                        </el-table-column>
                        <el-table-column
                        prop="turn"
                        label="学年学期">
                        </el-table-column>
                        <el-table-column
                        prop="oper"
                        label="操作">
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="就业推荐评语">
                    <el-table
                        :data="[]"
                        border
                        style="width: 100%">
                        <el-table-column
                        prop="no"
                        label="学号"
                        width="100">
                        </el-table-column>
                        <el-table-column
                        prop="name"
                        label="姓名"
                        width="100">
                        </el-table-column>
                        <el-table-column
                        prop="grade"
                        label="年级">
                        </el-table-column>
                        <el-table-column
                        prop="classes_no"
                        label="班级">
                        </el-table-column>
                        <el-table-column
                        prop="dept_name"
                        label="院系">
                        </el-table-column>
                        <el-table-column
                        prop="major_name"
                        label="专业">
                        </el-table-column>
                        <el-table-column
                        prop="score"
                        label="就业推荐成绩">
                        </el-table-column>
                        <el-table-column
                        prop="oper"
                        label="操作">
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="操行评定成绩">
                    <el-table
                        :data="[]"
                        border
                        style="width: 100%">
                        <el-table-column
                        prop="no"
                        label="学号"
                        width="100">
                        </el-table-column>
                        <el-table-column
                        prop="name"
                        label="姓名"
                        width="100">
                        </el-table-column>
                        <el-table-column
                        prop="grade"
                        label="年级">
                        </el-table-column>
                        <el-table-column
                        prop="classes_no"
                        label="班级">
                        </el-table-column>
                        <el-table-column
                        prop="dept_name"
                        label="院系">
                        </el-table-column>
                        <el-table-column
                        prop="major_name"
                        label="专业">
                        </el-table-column>
                        <el-table-column
                        prop="turn"
                        label="学年学期">
                        </el-table-column>
                        <el-table-column
                        prop="score"
                        label="综合评测分">
                        </el-table-column>
                        <el-table-column
                        prop="level"
                        label="操行等级">
                        </el-table-column>
                        <el-table-column
                        prop="oper"
                        label="操作">
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="助学金申请">
                    <el-table
                        :data="[]"
                        border
                        style="width: 100%">
                        <el-table-column
                        prop="type"
                        label="助学金类型名称">
                        </el-table-column>
                        <el-table-column
                        prop="name"
                        label="项目名称">
                        </el-table-column>
                        <el-table-column
                        prop="turn"
                        label="申请学年">
                        </el-table-column>
                        <el-table-column
                        prop="date"
                        label="申请日期">
                        </el-table-column>
                        <el-table-column
                        prop="amount"
                        label="申请金额">
                        </el-table-column>
                        <el-table-column
                        prop="oper"
                        label="操作">
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="困难学生信息">
                    <el-table
                        :data="[]"
                        border
                        style="width: 100%">
                        <el-table-column
                        prop="batch"
                        label="申请批次">
                        </el-table-column>
                        <el-table-column
                        prop="turn"
                        label="学年">
                        </el-table-column>
                        <el-table-column
                        prop="level"
                        label="困难级别">
                        </el-table-column>
                        <el-table-column
                        prop="reason"
                        label="困难原因">
                        </el-table-column>
                        <el-table-column
                        prop="date"
                        label="申请日期">
                        </el-table-column>
                        <el-table-column
                        prop="oper"
                        label="操作">
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="技能证书">
                    <el-table
                        :data="[]"
                        border
                        style="width: 100%">
                        <el-table-column
                        prop="name"
                        label="名称">
                        </el-table-column>
                        <el-table-column
                        prop="type"
                        label="类型">
                        </el-table-column>
                        <el-table-column
                        prop="unit"
                        label="颁证单位">
                        </el-table-column>
                        <el-table-column
                        prop="date"
                        label="获得日期">
                        </el-table-column>
                        <el-table-column
                        prop="oper"
                        label="操作">
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="处分情况">
                    <el-table
                        :data="[]"
                        border
                        style="width: 100%">
                        <el-table-column
                        prop="no"
                        label="学号">
                        </el-table-column>
                        <el-table-column
                        prop="name"
                        label="姓名">
                        </el-table-column>
                        <el-table-column
                        prop="turn"
                        label="处分学年">
                        </el-table-column>
                        <el-table-column
                        prop="wjtype"
                        label="违纪类型">
                        </el-table-column>
                        <el-table-column
                        prop="time"
                        label="违纪时间">
                        </el-table-column>
                        <el-table-column
                        prop="type"
                        label="处分类型">
                        </el-table-column>
                        <el-table-column
                        prop="status"
                        label="处分状态">
                        </el-table-column>
                        <el-table-column
                        prop="oper"
                        label="操作">
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="课程成绩">
                    <el-table
                        :data="[]"
                        border
                        style="width: 100%">
                        <el-table-column
                        prop="turn"
                        label="学期">
                        </el-table-column>
                        <el-table-column
                        prop="no"
                        label="学号">
                        </el-table-column>
                        <el-table-column
                        prop="name"
                        label="姓名">
                        </el-table-column>
                        <el-table-column
                        prop="grade"
                        label="年级">
                        </el-table-column>
                        <el-table-column
                        prop="classes_no"
                        label="班级">
                        </el-table-column>
                        <el-table-column
                        prop="dept_name"
                        label="院系">
                        </el-table-column>
                        <el-table-column
                        prop="major_name"
                        label="专业">
                        </el-table-column>
                        <el-table-column
                        prop="kcdm"
                        label="课程代码">
                        </el-table-column>
                        <el-table-column
                        prop="kcmc"
                        label="课程名称">
                        </el-table-column>
                        <el-table-column
                        prop="cj"
                        label="成绩">
                        </el-table-column>
                        <el-table-column
                        prop="xf"
                        label="学分">
                        </el-table-column>
                        <el-table-column
                        prop="kcxz"
                        label="课程性质">
                        </el-table-column>
                        <el-table-column
                        prop="kclx"
                        label="课程类型">
                        </el-table-column>
                        <el-table-column
                        prop="ksxz"
                        label="考试性质">
                        </el-table-column>
                        <el-table-column
                        prop="jsxm"
                        label="教师姓名">
                        </el-table-column>
                        <el-table-column
                        prop="oper"
                        label="操作">
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="综合测评情况">
                    <el-table
                        :data="[]"
                        border
                        style="width: 100%">
                        <el-table-column
                        prop="no"
                        label="学号">
                        </el-table-column>
                        <el-table-column
                        prop="name"
                        label="姓名">
                        </el-table-column>
                        <el-table-column
                        prop="grade"
                        label="年级">
                        </el-table-column>
                        <el-table-column
                        prop="classes_no"
                        label="班级">
                        </el-table-column>
                        <el-table-column
                        prop="dept_name"
                        label="院系">
                        </el-table-column>
                        <el-table-column
                        prop="major_name"
                        label="专业">
                        </el-table-column>
                        <el-table-column
                        prop="turn"
                        label="测评学年">
                        </el-table-column>
                        <el-table-column
                        prop="cj"
                        label="综合测评成绩">
                        </el-table-column>
                        <el-table-column
                        prop="bjpm"
                        label="班级排名">
                        </el-table-column>
                        <el-table-column
                        prop="njpm"
                        label="年级排名">
                        </el-table-column>
                        <el-table-column
                        prop="oper"
                        label="操作">
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="任职信息">
                    <el-table
                        :data="[]"
                        border
                        style="width: 100%">
                        <el-table-column
                        prop="no"
                        label="学号">
                        </el-table-column>
                        <el-table-column
                        prop="name"
                        label="姓名">
                        </el-table-column>
                        <el-table-column
                        prop="gender"
                        label="性别">
                        </el-table-column>
                        <el-table-column
                        prop="politics"
                        label="政治面貌">
                        </el-table-column>
                        <el-table-column
                        prop="begin_date"
                        label="任职开始日期">
                        </el-table-column>
                        <el-table-column
                        prop="end_date"
                        label="任职结束日期">
                        </el-table-column>
                        <el-table-column
                        prop="dept"
                        label="任职部门">
                        </el-table-column>
                        <el-table-column
                        prop="zw"
                        label="职务">
                        </el-table-column>
                        <el-table-column
                        prop="status"
                        label="任职状态">
                        </el-table-column>
                        <el-table-column
                        prop="oper"
                        label="操作">
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="在校获奖情况">
                    <el-table
                        :data="[]"
                        border
                        style="width: 100%">
                        <el-table-column
                        prop="name"
                        label="项目名称">
                        </el-table-column>
                        <el-table-column
                        prop="type"
                        label="项目类型">
                        </el-table-column>
                        <el-table-column
                        prop="turn"
                        label="申请学年">
                        </el-table-column>
                        <el-table-column
                        prop="time"
                        label="申请时间">
                        </el-table-column>
                        <el-table-column
                        prop="jytype"
                        label="奖优类型">
                        </el-table-column>
                        <el-table-column
                        prop="level"
                        label="项目等级">
                        </el-table-column>
                        <el-table-column
                        prop="status"
                        label="项目状态">
                        </el-table-column>
                        <el-table-column
                        prop="jxtype"
                        label="奖项类型">
                        </el-table-column>
                        <el-table-column
                        prop="sqstatus"
                        label="我的申请状态">
                        </el-table-column>
                        <el-table-column
                        prop="oper"
                        label="操作">
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="集体获奖情况">
                    <el-table
                        :data="[]"
                        border
                        style="width: 100%">
                        <el-table-column
                        prop="name"
                        label="项目名称">
                        </el-table-column>
                        <el-table-column
                        prop="type"
                        label="项目类型">
                        </el-table-column>
                        <el-table-column
                        prop="jytype"
                        label="奖优类型">
                        </el-table-column>
                        <el-table-column
                        prop="level"
                        label="项目等级">
                        </el-table-column>
                        <el-table-column
                        prop="status"
                        label="项目状态">
                        </el-table-column>
                        <el-table-column
                        prop="unit"
                        label="获奖单位">
                        </el-table-column>
                        <el-table-column
                        prop="turn"
                        label="申请学年">
                        </el-table-column>
                        <el-table-column
                        prop="sqr"
                        label="申请人">
                        </el-table-column>
                        <el-table-column
                        prop="time"
                        label="申请时间">
                        </el-table-column>
                        <el-table-column
                        prop="oper"
                        label="操作">
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="学期卡">
                    <el-table
                        :data="[]"
                        border
                        style="width: 100%">
                        <el-table-column
                        prop="no"
                        label="学号">
                        </el-table-column>
                        <el-table-column
                        prop="name"
                        label="姓名">
                        </el-table-column>
                        <el-table-column
                        prop="grade"
                        label="年级">
                        </el-table-column>
                        <el-table-column
                        prop="classes_no"
                        label="班级">
                        </el-table-column>
                        <el-table-column
                        prop="dept_name"
                        label="院系">
                        </el-table-column>
                        <el-table-column
                        prop="major_name"
                        label="专业">
                        </el-table-column>
                        <el-table-column
                        prop="oper"
                        label="操作">
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
            </el-tabs>
            <div slot="footer" class="dialog-footer">
                <el-button @click="openDa = false">关 闭</el-button>
            </div>
        </el-dialog>
        <el-dialog
            :title="upload.title"
            :visible.sync="upload.open"
            width="400px"
            append-to-body
        >
            <el-upload
                ref="upload"
                :limit="1"
                accept=".xlsx"
                :headers="upload.headers"
                :action="upload.url + '?update=' + upload.update"
                :disabled="upload.isUploading"
                :on-progress="handleImportProgress"
                :on-success="handleImportSuccess"
                :on-error="handleImportError"
                :auto-upload="false"
                drag
            >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                    将文件拖到此处，或
                    <em>点击上传</em>
                </div>
                <div class="el-upload__tip" slot="tip">
                    <el-checkbox
                        v-model="upload.update"
                    />是否更新已经存在的数据
                    <el-link
                        type="info"
                        style="font-size: 12px"
                        @click="handleImportTemplate"
                        >下载模板</el-link
                    >&nbsp;
                    <el-link
                        type="info"
                        style="font-size: 12px"
                        @click="handleImportReport"
                        >下载报告</el-link
                    >
                </div>
                <div class="el-upload__tip" style="color: red" slot="tip">
                    提示：仅允许导入“xlsx”格式文件！
                </div>
            </el-upload>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitImportForm"
                    >确 定</el-button
                >
                <el-button @click="upload.open = false">取 消</el-button>
            </div>
        </el-dialog>
        <el-dialog
            :title="uploadPhoto.title"
            :visible.sync="uploadPhoto.open"
            width="400px"
            append-to-body
        >
            <el-upload
                ref="uploadPhoto"
                :limit="1"
                accept=".zip"
                :headers="uploadPhoto.headers"
                :action="uploadPhoto.url + '?update=' + uploadPhoto.update"
                :disabled="uploadPhoto.isUploading"
                :on-progress="handleImportPhotoProgress"
                :on-success="handleImportPhotoSuccess"
                :on-error="handleImportPhotoError"
                :auto-upload="false"
                drag
            >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                    将文件拖到此处，或
                    <em>点击上传</em>
                </div>
                <div class="el-upload__tip" slot="tip">
                    <el-checkbox
                        v-model="uploadPhoto.update"
                    />是否更新已经存在的数据
                </div>
                <div class="el-upload__tip" style="color: red" slot="tip">
                    提示：仅允许导入“zip”格式文件！
                </div>
            </el-upload>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitImportPhotoForm"
                    >确 定</el-button
                >
                <el-button @click="uploadPhoto.open = false">取 消</el-button>
            </div>
        </el-dialog>
        <el-dialog
            title="同步账号"
            :visible.sync="syncAccountOpen"
            width="500px"
            append-to-body
        >
            <el-form
                ref="syncAccountForm"
                :model="syncAccountForm"
                :rules="syncAccountRules"
                label-width="80px"
            >
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="密码规则" prop="password_rule">
                            <dict-select
                                v-model="syncAccountForm.password_rule"
                                placeholder="请选密码规则"
                                type="stu_student_password_rule"
                            />
                        </el-form-item>
                    </el-col>
                    <!--<el-col :span="24">
            <el-form-item label="是否需要验证账号" prop="needs_validate">
              <dict-select v-model="syncAccountForm.needs_validate" placeholder="请选择是否需要验证账号" type="stu_yes_no" />
            </el-form-item>
          </el-col>-->
                </el-row>
            </el-form>

            <table
                v-if="syncAccountProgressData.total"
                style="width: 100%"
                cellpadding="5px"
            >
                <tr>
                    <td width="50px">进度</td>
                    <td style="">
                        <el-progress
                            :text-inside="true"
                            :stroke-width="16"
                            :percentage="
                                syncAccountProgressData.total
                                    ? parseInt(
                                          (syncAccountProgressData.count *
                                              100) /
                                              syncAccountProgressData.total
                                      )
                                    : 0
                            "
                        ></el-progress>
                    </td>
                </tr>
                <tr>
                    <td>总数</td>
                    <td>
                        {{ syncAccountProgressData.count }} /
                        {{ syncAccountProgressData.total }}
                    </td>
                </tr>
                <tr>
                    <td>成功</td>
                    <td>{{ syncAccountProgressData.success }}</td>
                </tr>
                <tr>
                    <td>失败</td>
                    <td>
                        {{ syncAccountProgressData.fail }}&nbsp;&nbsp;{{
                            syncAccountProgressData.msg
                        }}
                    </td>
                </tr>
                <tr>
                    <td>耗时</td>
                    <td>
                        {{
                            parseInt(syncAccountProgressData.time / 60 / 60)
                        }}时{{
                            parseInt(syncAccountProgressData.time / 60) % 60
                        }}分{{ syncAccountProgressData.time % 60 }}秒
                    </td>
                </tr>
            </table>

            <div slot="footer" class="dialog-footer">
                <el-button
                    v-if="
                        !syncAccountProgressData.total ||
                        syncAccountProgressData.total ==
                            syncAccountProgressData.count
                    "
                    type="primary"
                    @click="submitSyncAccountForm"
                    >同 步</el-button
                >
                <el-button @click="syncAccountOpen = false">关 闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import Qs from "qs";
import { getAccessToken } from "@/utils/tokenTool";
import {
    listStuStudent,
    getStuStudent,
    addStuStudent,
    editStuStudent,
    delStuStudent,
    syncAccount,
    syncAccountProgress,
} from "@/api/stu/stu_student";
import { listStuStudentExtField } from "@/api/stu/stu_student_ext_field";
import MajorSelect from "../components/major_select/index.vue";
import AssistantSelect from "../components/assistant_select/index.vue";
export default {
    name: "stu-stustudent-list",
    components: {
        MajorSelect,
        AssistantSelect,
    },
    data() {
        return {
            tableToolbar: {
                perfect: false,
                zoom: true,
                custom: false,
                refresh: {
                    query: this.handleQuery,
                },
                slots: {
                    buttons: "toolbar_buttons",
                },
            },
            tableColumn: [
                { type: "checkbox", width: 60, fixed: "left" },
                {
                    field: "id",
                    title: "id",
                    minWidth: 120,
                    fixed: "left",
                    visible: false,
                },
                { field: "no", title: "学号", minWidth: 120, is_query: true, fixed: "left", },
                // { field: "card_no", title: "校园卡号", minWidth: 120 },
                { field: "name", title: "姓名", minWidth: 120, is_query: true, fixed: "left", },
                // { field: "nickname", title: "别名", minWidth: 120 },
                { field: "dept_name", title: "教学学院", minWidth: 120 },
                { field: "major_name", title: "教学专业", minWidth: 120 },
                {
                    field: "classes_no",
                    title: "教学班级",
                    minWidth: 120,
                    is_query: true,
                },
                {
                    field: "grade",
                    title: "年级",
                    minWidth: 120,
                    dictType: "stu_grade",
                    formatter: this.dictFormat,
                    is_query: true,
                },
                {
                    field: "assistant_nos",
                    title: "辅导员工号",
                    minWidth: 120,
                    // is_query: true,
                },
                {
                    field: "assistant_names",
                    title: "辅导员姓名",
                    minWidth: 120,
                },
                //{ field: "candidate_no", title: "考生考号", minWidth: 120 },
                { field: "idcard", title: "身份证号", minWidth: 120 },
                //{ field: "idcard_addr", title: "身份证地址", minWidth: 120 },
                {
                    field: "gender",
                    title: "性别",
                    minWidth: 120,

                    dictType: "stu_gender",
                    formatter: this.dictFormat,
                },
                {
                    field: "ethnicity",
                    title: "民族",
                    minWidth: 120,
                    dictType: "stu_ethnicity",
                    formatter: this.dictFormat,
                },
                {
                    field: "birthday",
                    title: "出生年月",
                    minWidth: 120,
                    formatter: ["formatDate", "yyyy-MM-dd"],
                },
                {
                    field: "politics",
                    title: "政治面貌",
                    minWidth: 120,
                    dictType: "stu_politics",
                    formatter: this.dictFormat,
                },
                {
                    field: "status",
                    title: "学籍状态",
                    minWidth: 120,
                    dictType: "stu_status",
                    formatter: this.dictFormat,
                    is_query: true,
                },
                {
                    field: "school_system",
                    title: "学制",
                    minWidth: 120,

                    dictType: "stu_school_system",
                    formatter: this.dictFormat,
                },
                {
                    field: "stu_level",
                    title: "培养层次",
                    minWidth: 120,
                    formatter: this.dictFormat,
                    dictType: "stu_level",
                },
                {
                    field: "in_date",
                    title: "入学时间",
                    minWidth: 120,
                    formatter: ["formatDate", "yyyy-MM-dd"],
                },
                /*{ field: "province", title: "省份", minWidth: 120, formatter: this.dictFormat, dictType: 'stu_province' },
				{ field: "residence", title: "户口", minWidth: 120, formatter: this.dictFormat, dictType: 'stu_residence' },
				{ field: "senior_school", title: "毕业高中", minWidth: 120 },
				{ field: "mobile", title: "联系电话", minWidth: 120 },
				{ field: "mail", title: "邮箱", minWidth: 120 },
				{ field: "ext", title: "扩展信息", minWidth: 120 },*/
                {
                    field: "sync_account",
                    title: "是否已生成账号",
                    minWidth: 120,
                    formatter: this.dictFormat,
                    dictType: "stu_yes_no",
                },
                {
                    field: "create_by",
                    title: "创建者",
                    minWidth: 120,
                    visible: false,
                },
                {
                    field: "create_time",
                    title: "创建时间",
                    minWidth: 120,
                    formatter: "formatDate",
                    visible: false,
                },
                {
                    field: "update_by",
                    title: "更新者",
                    minWidth: 120,
                    visible: false,
                },
                {
                    field: "update_time",
                    title: "更新时间",
                    minWidth: 120,
                    formatter: "formatDate",
                },
                { field: "remark", title: "备注信息", minWidth: 120 },
                // {
                //     field: "",
                //     title: "操作",
                //     width: 180,
                //     fixed: "right",
                //     align: "center",
                //     slots: { default: "defaultopr" },
                // },
            ],
            loading: true,
            ids: [],
            single: true,
            multiple: true,
            total: 0,
            dataList: [],
            extList: [],
            extListSearch: [],
            title: "",
            open: false,
            openDa: false,
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                id: undefined,
                no: undefined,
                name: undefined,
                dept_no: undefined,
                major_no: undefined,
                classes_no: undefined,
                assistant_nos: undefined,
                ext: undefined,
            },
            form: {},
            extFieldPrefix: "_ext_",
            upload: {
                open: false,
                title: "",
                isUploading: false,
                update: false,
                headers: { Authorization: getAccessToken() },
                url: process.env.VUE_APP_BASE_API + "/stu/stustudent/import",
            },
            uploadPhoto: {
                open: false,
                title: "",
                isUploading: false,
                update: false,
                headers: { Authorization: getAccessToken() },
                url: process.env.VUE_APP_BASE_API + "/stu/stustudent/photoimport",
            },
            rules: {
                no: [
                    {
                        required: true,
                        message: "学号不能为空",
                        trigger: "blur",
                    },
                ],
                name: [
                    {
                        required: true,
                        message: "姓名不能为空",
                        trigger: "blur",
                    },
                ],
                dept_no: [
                    {
                        required: true,
                        message: "院系不能为空",
                        trigger: "blur",
                    },
                ],
                major_no: [
                    {
                        required: true,
                        message: "专业不能为空",
                        trigger: "blur",
                    },
                ],
                classes_no: [
                    {
                        required: true,
                        message: "班级不能为空",
                        trigger: "blur",
                    },
                ],
                grade: [
                    {
                        required: true,
                        message: "年级不能为空",
                        trigger: "blur",
                    },
                ],
                idcard: [
                    {
                        required: true,
                        message: "身份证号不能为空",
                        trigger: "blur",
                    },
                ],
                gender: [
                    {
                        required: true,
                        message: "性别不能为空",
                        trigger: "blur",
                    },
                ],
                ethnicity: [
                    {
                        required: true,
                        message: "民族不能为空",
                        trigger: "blur",
                    },
                ],
                birthday: [
                    {
                        required: true,
                        message: "出生年月不能为空",
                        trigger: "blur",
                    },
                ],
                politics: [
                    {
                        required: true,
                        message: "政治面貌不能为空",
                        trigger: "blur",
                    },
                ],
                status: [
                    {
                        required: true,
                        message: "学籍状态不能为空",
                        trigger: "blur",
                    },
                ],
                school_system: [
                    {
                        required: true,
                        message: "学制不能为空",
                        trigger: "blur",
                    },
                ],
                stu_level: [
                    {
                        required: true,
                        message: "培养层次不能为空",
                        trigger: "blur",
                    },
                ],
                in_date: [
                    {
                        required: true,
                        message: "入学时间不能为空",
                        trigger: "blur",
                    },
                ],
            },
            syncAccountOpen: false,
            syncAccountForm: {},
            syncAccountRules: {
                password_rule: [
                    {
                        required: true,
                        message: "密码规则不能为空",
                        trigger: "blur",
                    },
                ],
                needs_validate: [
                    {
                        required: true,
                        message: "是否需要验证账号不能为空",
                        trigger: "blur",
                    },
                ],
            },
            syncAccountProgressData: {},
            dictsData:{}
        };
    },
    created() {
        this.syncAccountProgress();

        // for (let i = 0; i < this.tableColumn.length; i++) {
        //     const col = this.tableColumn[i];
        //     if (col.dictType) {
        //         this.getDicts(col.dictType).then((response) => {
        //             if (response.code == 0) {
        //                 col.dictData = response.data;
        //                 this.dictsData[col.dictType]=col.dictData
        //                 this.dataList = [].concat(this.dataList);
        //             }
        //         });
        //     }
        // }
        this.getList();
        listStuStudentExtField({ table_name: 'stu_student', pageSize: 100 }).then((response) => {
            if (response.code == 0) {
                let index = this.tableColumn.length;
                for (let i = 0; i < this.tableColumn.length; i++) {
                    if (this.tableColumn[i].field == "sync_account") {
                        index = i;
                        break;
                    }
                }
                const tableColumn = [].concat(this.tableColumn);
                const extListSearch = [];
                for (let i = response.data.list.length - 1; i >= 0; i--) {
                    const d = response.data.list[i];
                    this.$set(
                        this.form,
                        this.extFieldPrefix + d.name,
                        d.default_value
                    );
                    if (d.hidden != "1" && d.searchable == "1") {
                        this.$set(
                            this.queryParams,
                            this.extFieldPrefix + d.name,
                            undefined
                        );
                        extListSearch.splice(0, 0, d);
                    }
                    if (d.hidden != "1" && d.require == "1") {
                        this.rules[this.extFieldPrefix + d.name] = [
                            {
                                required: true,
                                message: d.label + "不能为空",
                                trigger: "blur",
                            },
                        ];
                    }
                    if (d.hidden == "1") {
                        continue
                    }
                    const item = {
                        field: d.name,
                        title: d.label,
                        minWidth: 120,
                        formatter: this.extFormat,
                        extField: d,
                    };
                    if (
                        d.type == "select" ||
                        d.type == "selectm" ||
                        d.type == "radio" ||
                        d.type == "checkbox"
                    ) {
                        item.dictType = d.ext;
                        if (item.dictType) {
                            this.getDicts(item.dictType).then((response) => {
                                if (response.code == 0) {
                                    item.dictData = response.data;
                                    this.dataList = [].concat(this.dataList);
                                }
                            });
                        }
                    }
                    if (d.name == 'xjxy') {
                        for (let j = 0; j < tableColumn.length; j++) {
                            if (tableColumn[j].field == 'dept_name') {
                                tableColumn.splice(j, 0, item);
                                break
                            }
                        }
                    } else if (d.name == 'xjzy') {
                        for (let j = 0; j < tableColumn.length; j++) {
                            if (tableColumn[j].field == 'major_name') {
                                tableColumn.splice(j, 0, item);
                                break
                            }
                        }
                    } else if (d.name == 'xjbj') {
                        for (let j = 0; j < tableColumn.length; j++) {
                            if (tableColumn[j].field == 'classes_no') {
                                tableColumn.splice(j, 0, item);
                                break
                            }
                        }
                    } else {
                        tableColumn.splice(index, 0, item);
                    }
                }
                this.tableColumn = tableColumn;
                this.extListSearch = extListSearch;
                this.extList = response.data.list;
            }
        });
    },
    methods: {
        //列表排序和筛选
        tableChange(e) {},
        getList() {
            this.loading = true;
            this.ids = [];
            this.single = true;
            this.multiple = true;
            const queryParams = this.splitForm(this.queryParams);
            if (Object.keys(queryParams.formExt).length > 0) {
                queryParams.form.ext = JSON.stringify(queryParams.formExt);
            }
            listStuStudent(queryParams.form).then((response) => {
                this.dataList = response.data.list;
                this.total = response.data.total;
                this.loading = false;
            });
        },

        dictFormat(e) {
            const col = this.tableColumn[e.columnIndex];
            if (col.dictData) {
                const values = e.cellValue ? e.cellValue.split(",") : [];
                const labels = [];
                for (let i = 0; i < values.length; i++) {
                    const v = values[i];
                    for (let j = 0; j < col.dictData.values.length; j++) {
                        const item = col.dictData.values[j];
                        if (v == item.key) {
                            labels.push(item.value);
                            break;
                        }
                    }
                }
                return labels.join(",");
            }
            return e.cellValue;
        },

        dictFormatEx(type, value) {
            for (let k = 0; k < this.tableColumn.length; k++) {
                const col = this.tableColumn[k];
                if (col.dictType == type && col.dictData) {
                    const values = value ? value.split(",") : [];
                    const labels = [];
                    for (let i = 0; i < values.length; i++) {
                        const v = values[i];
                        for (let j = 0; j < col.dictData.values.length; j++) {
                            const item = col.dictData.values[j];
                            if (v == item.key) {
                                labels.push(item.value);
                                break;
                            }
                        }
                    }
                    return labels.join(",");
                }
            }
            return value;
        },

        extFormat(e) {
            const d = this.dataList[e.rowIndex];
            if (d.ext && !d._ext_data) {
                d._ext_data = JSON.parse(d.ext);
            }
            if (d._ext_data) {
                const col = this.tableColumn[e.columnIndex];
                e.cellValue = d._ext_data[col.field];
                if (col.dictType) {
                    return this.dictFormat(e);
                } else if (col.extField) {
                    if (col.extField.type == "date" && e.cellValue) {
                        return this.XEUtils.toDateString(
                            e.cellValue * 1000,
                            "yyyy-MM-dd"
                        );
                    } else if (col.extField.type == "datetime" && e.cellValue) {
                        return this.XEUtils.toDateString(
                            e.cellValue * 1000,
                            "yyyy-MM-dd HH:mm:ss"
                        );
                    }
                }
            }
            return e.cellValue;
        },

        cancel() {
            this.open = false;
            this.reset();
        },

        reset() {
            this.form = {
                no: undefined,
                card_no: undefined,
                name: undefined,
                nickname: undefined,
                dept_no: undefined,
                major_no: undefined,
                classes_no: undefined,
                grade: undefined,
                assistant_nos: undefined,
                assistant_names: undefined,
                candidate_no: undefined,
                idcard: undefined,
                idcard_addr: undefined,
                gender: undefined,
                ethnicity: undefined,
                birthday: undefined,
                politics: undefined,
                status: undefined,
                school_system: undefined,
                stu_level: undefined,
                in_date: undefined,
                province: undefined,
                residence: undefined,
                senior_school: undefined,
                mobile: undefined,
                mail: undefined,
                ext: undefined,
                sync_account: undefined,
                remark: undefined,
            };
            for (let i = 0; i < this.extList.length; i++) {
                const d = this.extList[i];
                this.$set(
                    this.form,
                    this.extFieldPrefix + d.name,
                    d.default_value
                );
            }
            this.resetForm("form");
        },

        handleQuery(e) {
            if (e) {
                this.queryParams = this.deepCopy(e);
            }
            this.queryParams.pageNum = 1;
            this.getList();
        },

        resetQuery() {
            for (let i = 0; i < this.extList.length; i++) {
                const d = this.extList[i];
                if (d.hidden != "1" && d.searchable == "1") {
                    this.$set(
                        this.queryParams,
                        this.extFieldPrefix + d.name,
                        undefined
                    );
                }
            }
            this.resetForm("queryForm");
            this.handleQuery();
        },

        handleAdd() {
            this.reset();
            this.open = true;
            this.title = "添加";
        },

        handleUpdate(row) {
            this.reset();
            const id = row.id || (this.ids.length > 0 ? this.ids[0] : "");
            getStuStudent(id).then((response) => {
                this.form = response.data;
                if (this.form.ext) {
                    const formExt = JSON.parse(this.form.ext);
                    for (let key in formExt) {
                        this.$set(
                            this.form,
                            this.extFieldPrefix + key,
                            formExt[key]
                        );
                    }
                }
                this.open = true;
                this.title = "修改";
            });
        },

        handleView(row) {
            this.reset();
            const id = row.id || (this.ids.length > 0 ? this.ids[0] : "");
            getStuStudent(id).then((response) => {
                this.form = response.data;
                if (this.form.ext) {
                    const formExt = JSON.parse(this.form.ext);
                    for (let key in formExt) {
                        this.$set(
                            this.form,
                            this.extFieldPrefix + key,
                            formExt[key]
                        );
                    }
                }
                this.openDa = true;
            });
        },

        splitForm(form) {
            const f = JSON.parse(JSON.stringify(form));
            const sf = {
                form: {},
                formExt: {},
            };
            const len = this.extFieldPrefix.length;
            for (let key in f) {
                const value = f[key];
                if (key.indexOf(this.extFieldPrefix) == 0) {
                    sf.formExt[key.substring(len)] = value;
                } else {
                    sf.form[key] = value;
                }
            }
            return sf;
        },

        submitForm() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    const form = this.splitForm(this.form);
                    if (Object.keys(form.formExt).length > 0) {
                        form.form.ext = JSON.stringify(form.formExt);
                    }
                    if (this.form.id != null) {
                        editStuStudent(form.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("修改成功");
                                this.open = false;
                                setTimeout(() => {
                                    this.getList();
                                }, 300);
                            }
                        });
                    } else {
                        addStuStudent(form.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("新增成功");
                                this.open = false;
                                setTimeout(() => {
                                    this.getList();
                                }, 300);
                            }
                        });
                    }
                }
            });
        },

        handleDelete(row) {
            const ids =
                row.id || (this.ids.length > 0 ? this.ids.join(",") : "");
            this.$confirm('确定删除id为"' + ids + '"的数据项?', "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    return delStuStudent(ids);
                })
                .then(() => {
                    setTimeout(() => {
                        this.getList();
                    }, 300);
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },

        checkboxChangeEvent({ records }) {
            this.ids = records.map((item) => item.id);
            this.single = records.length != 1;
            this.multiple = !records.length;
        },

        handlePageChange({ currentPage, pageSize }) {
            if (this.queryParams.pageSize == pageSize) {
                this.getList();
            } else {
                this.queryParams.pageSize = pageSize;
                this.handleQuery();
            }
        },

        handleAssistantChange(e1, e2) {
            this.form.assistant_names = e2
                ? e2.map((v) => v.name).join(",")
                : undefined;
        },

        handleSyncAccount() {
            this.resetForm("syncAccountForm");
            this.syncAccountOpen = true;
        },

        submitSyncAccountForm() {
            this.$refs["syncAccountForm"].validate((valid) => {
                if (valid) {
                    if (this.syncAccountLoading) {
                        this.syncAccountLoading.close();
                    }
                    this.syncAccountLoading = this.$loading({
                        lock: true,
                        text: "正在同步账号",
                        spinner: "el-icon-loading",
                        background: "rgba(0, 0, 0, 0.7)",
                    });
                    const queryParams = this.splitForm(this.queryParams);
                    if (Object.keys(queryParams.formExt).length > 0) {
                        queryParams.form.ext = JSON.stringify(
                            queryParams.formExt
                        );
                    }
                    queryParams.form.password_rule =
                        this.syncAccountForm.password_rule;
                    queryParams.form.needs_validate =
                        this.syncAccountForm.needs_validate;
                    syncAccount(queryParams.form)
                        .then((response) => {
                            this.syncAccountLoading.close();
                            this.syncAccountLoading = undefined;
                            //this.syncAccountOpen = false
                            if (response.code == 0) {
                                this.msgSuccess(
                                    "同步" + response.data.count + "个账号"
                                );
                            }
                            this.syncAccountForm.password_rule = "";
                            this.syncAccountProgress();
                        })
                        .catch((err) => {
                            this.syncAccountLoading.close();
                            this.syncAccountLoading = undefined;
                        });
                }
            });
        },

        syncAccountProgress() {
            syncAccountProgress().then((res) => {
                this.syncAccountProgressData = res.data;
                if (res.data.total > 0 && res.data.count < res.data.total) {
                    //if (!this.syncAccountOpen) {
                    //    this.syncAccountForm.password_rule = "";
                    //    this.syncAccountOpen = true;
                    //}
                    setTimeout(() => {
                        this.syncAccountProgress();
                    }, 1000);
                }
            });
        },

        handleImport() {
            this.upload.title = "数据导入";
            this.upload.update = false;
            this.upload.open = true;
        },

        handleImportProgress(event, file, fileList) {
            this.upload.isUploading = true;
            if (event.percent == 100) {
                this.upload.open = false;
                this.upload.loading = this.$loading({
                    lock: true,
                    text: "正在导入数据",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
            }
        },

        handleImportSuccess(response, file, fileList) {
            console.log(response);
            if (this.upload.loading) {
                this.upload.loading.close();
            }
            this.upload.open = false;
            this.upload.isUploading = false;
            this.$refs.upload.clearFiles();
            if (response.code == 0) {
                const msg =
                    "导入成功" +
                    response.data.success_count +
                    "条数据，失败" +
                    response.data.fail_count +
                    "条数据" +
                    (response.data.msg ? "，" + response.data.msg : "");
                //this.$alert(msg, "提示", { dangerouslyUseHTMLString: true });
                this.$confirm(msg, "提示", {
                    distinguishCancelAndClose: true,
                    confirmButtonText: "确定",
                    cancelButtonText: "下载导入报告",
                })
                    .then(() => {})
                    .catch((action) => {
                        if (action === "cancel") {
                            // 下载导入报告
                            this.downloadbyurl("/stu/stustudent/importReport");
                        }
                    });
            } else {
                this.$alert(response.msg, "提示", {
                    dangerouslyUseHTMLString: true,
                });
            }
            this.getList();
        },

        handleImportError(err, file, fileList) {
            if (this.upload.loading) {
                this.upload.loading.close();
            }
            this.upload.isUploading = false;
            this.$alert("导入失败", "提示", { dangerouslyUseHTMLString: true });
        },

        submitImportForm() {
            this.$refs.upload.submit();
        },

        handleImportTemplate() {
            this.downloadbyurl("/stu/stustudent/importTemplate");
        },

        handleImportReport() {
            this.downloadbyurl("/stu/stustudent/importReport");
        },

        handleExport() {
            this.$confirm("确定导出数据吗?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                const queryParams = this.splitForm(this.queryParams);
                if (Object.keys(queryParams.formExt).length > 0) {
                    queryParams.form.ext = JSON.stringify(queryParams.formExt);
                }
                this.downloadbyurl(
                    "/stu/stustudent/export?" + Qs.stringify(queryParams.form)
                );
            });
        },

        handleImportPhoto() {
            this.uploadPhoto.title = "照片导入";
            this.uploadPhoto.update = false;
            this.uploadPhoto.open = true;
        },

        handleImportPhotoProgress(event, file, fileList) {
            this.uploadPhoto.isUploading = true;
            if (event.percent == 100) {
                this.uploadPhoto.open = false;
                this.uploadPhoto.loading = this.$loading({
                    lock: true,
                    text: "正在导入照片",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
            }
        },

        handleImportPhotoSuccess(response, file, fileList) {
            console.log(response);
            if (this.uploadPhoto.loading) {
                this.uploadPhoto.loading.close();
            }
            this.uploadPhoto.open = false;
            this.uploadPhoto.isUploading = false;
            this.$refs.uploadPhoto.clearFiles();
            if (response.code == 0) {
                const msg =
                    "导入成功" +
                    response.data.success_count +
                    "条数据，失败" +
                    response.data.fail_count +
                    "条数据" +
                    (response.data.msg ? "，" + response.data.msg : "");
                this.$alert(msg, "提示", { dangerouslyUseHTMLString: true });
            } else {
                this.$alert(response.msg, "提示", {
                    dangerouslyUseHTMLString: true,
                });
            }
            this.getList();
        },

        handleImportPhotoError(err, file, fileList) {
            if (this.uploadPhoto.loading) {
                this.uploadPhoto.loading.close();
            }
            this.uploadPhoto.isUploading = false;
            this.$alert("导入失败", "提示", { dangerouslyUseHTMLString: true });
        },

        submitImportPhotoForm() {
            this.$refs.uploadPhoto.submit();
        },
    },
};
</script>
<style rel="stylesheet/scss" scoped>
::v-deep .scrolldialog {
    max-height: 82vh;
}

::v-deep .el-dialog__body {
    max-height: calc(100vh - 300px);
    overflow-y: auto;
}
.tfirst {
    border-top: solid 1px #eee;
}
.ttitle {
    font-weight: bold;
    text-align: right;
    border-bottom: solid 1px #eee;
    padding: 8px 10px;
}
.tvalue {
    border-bottom: solid 1px #eee;
    padding: 8px 10px;
}
.theader {
    background-color: #f5f5f5;
}
</style>