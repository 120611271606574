<template>
    <div class="app-container">
        <div v-if="dataList.length > 0" class="config-container">
            <div class="top"></div>
            <div class="config">
                <el-row :gutter="20">
                    <el-col v-for="(item, index) in dataList" :key="index" :span="12">
                        <div class="grid-content">{{ item.description }}&nbsp;=&nbsp;<el-input v-model="item.score" size="mini" />&nbsp;分</div>
                    </el-col>
                </el-row>
            </div>
            <div class="tip">线上积分兑换规则企业可选择线下积分兑换礼物，比如一个积分兑换一支牙膏等。</div>
            <div class="bottom">
                <el-button type="primary" @click="onSave">保存</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { listUserScoreConfig, editUserScoreConfigList } from '@/api/core/system/userscore'
export default {
    data() {
        return {
            dataList: []
        }
    },
    created() {
        const loading = this.$loading({
            text: '正在加载...'
        })
        listUserScoreConfig({limit: 100}).then(res => {
            loading.close()
            this.dataList = res.data.list
        }).catch(e => {
            loading.close()
        })
    },
    methods: {
        onSave() {
            for (let i = 0; i < this.dataList.length; i++) {
                const d = this.dataList[i]
                if (d.score === undefined || d.score === "") {
                    this.msgError('请填写积分')
                    return
                }
                if (!/^[0-9]+$/.test(d.score)) {
                    this.msgError('只能填写整数')
                    return
                }
            }

            const loading = this.$loading({
                text: '正在保存...'
            })
            editUserScoreConfigList({
                list: this.dataList
            }).then(res => {
                loading.close()
                this.msgSuccess('保存成功')
            }).catch(e => {
                loading.close()
            })
        }
    }
}
</script>

<style scoped>
.app-container {
    display: flex;
    justify-content: center;
}
.config-container {
    width: 90%;
}
.config {
    padding-right: 25%;
}
.grid-content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
}
.grid-content .el-input {
    width: 100px;
}
.top {
    width: 100%;
    margin-top: 50px;
    padding-top: 30px;
    border-top: solid 1px #ccc;
}
.tip {
    width: 100%;
    margin-top: 50px;
    padding-top: 20px;
    border-top: solid 1px #ccc;
}
.bottom {
    width: 100%;
    margin-top: 20px;
    padding-top: 30px;
    border-top: solid 1px #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>