@import "@/styles/variables.scss"; 






.container {
  width: 100%;
  background-color: rgb(246, 248, 249);
  iframe {
    width: 100%;
    height: calc(100vh - 140px);
    border: none;
  }
}
