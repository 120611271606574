@import "@/styles/variables.scss"; 




























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
  border-radius: 6px;
}
::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 0, 0, 0.05);
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.1);
}
.tree-node {
  font-size: 14px;
}
.tree-node-leaf {
  -webkit-user-drag: element;
  user-select: none;
}
.tree-node-root {
  user-select: none;
}
.custom-tree-node {
  width: 100%;
  display: flex;
  align-items: center;
  height: 26px;
  padding-right: 5px;
}
.custom-tree-node-img {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}
.custom-tree-node-label {
  flex: 1;
  width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.custom-tree-node-button {
  display: none;
}
.custom-tree-node:hover .custom-tree-node-button {
  display: inline-block;
}
.collapse-title {
  margin-left: 10px;
}
.collapse-content {
  padding-left: 10px;
  padding-right: 10px;
}
.mini-button {
  margin-left: 0px;
  padding: 3px;
}
/*.el-input-number {
  width: 100%;
}*/
.left-container {
  padding-right: 20px;
}
.left {
  width: 100%;
  //   height: calc(100vh - 195px);
  border-right: solid 1px #eee;
  padding: 0px 20px 0px 0px;
  display: flex;
  flex-direction: column;
}
.left-top {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.filter {
  margin-bottom: 10px;
}
.left-middle {
  overflow-y: auto;
}
.right-top {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  background-color: rgb(220, 240, 252);
  margin-bottom: 20px;
  .right-top-left {
  }
  .right-top-right {
    display: flex;
    font-size: 13px;
    div {
      margin-left: 10px;
    }
  }
}
.loading {
  text-align: center;
  margin-top: 200px;
  font-size: 30px;
  color: rgb(107, 191, 244);
}
.el-row {
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.grid-content-building {
  border-radius: 4px;
  min-height: 126px;
  margin-bottom: 15px;
  outline: solid 1px #ddd;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .gtop {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: row;
    .gleft {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      margin-left: 10px;
      margin-bottom: 5px;
      font-size: 50px;
      color: rgb(107, 191, 244);
    }
    .gcenter {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      margin-left: 10px;
      margin-bottom: 10px;
    }
    .gright {
      margin-top: 5px;
      margin-right: 5px;
      color: rgb(107, 191, 244);
    }
    .parent-name {
      position: absolute;
      left: 10px;
      top: 10px;
      font-size: 13px;
    }
  }
  .gbottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px 10px;
    background-color: rgb(245, 246, 248);
    font-size: 13px;
  }
}
.grid-content-building:hover {
  outline: solid 2px rgb(25, 133, 252);
}
.grid-content-building-selected {
  outline: solid 2px orange;
  box-shadow: 4px 4px 3px 2px rgba(0, 0, 0, 0.2);
}
.grid-content-building-selected:hover {
  outline: solid 2px orange;
  box-shadow: 4px 4px 3px 2px rgba(0, 0, 0, 0.2);
}
.grid-content-room {
  border-radius: 4px;
  min-height: 126px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  .gtop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 10px;
    .gleft {
      display: flex;
      flex-direction: row;
      font-size: 20px;
    }
    .gcenter {
      width: 10px;
      height: 10px;
      border-radius: 10px;
      margin-top: 5px;
    }
    .gright {
      padding: 5px 10px;
      border-radius: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .dot {
        width: 8px;
        height: 8px;
        border-radius: 8px;
        background-color: white;
      }
      .text {
        color: white;
        font-size: 13px;
        margin-left: 5px;
      }
    }
    .unavailable {
      background-color: rgb(232, 232, 232);
    }
    .gempty {
      background-color: rgb(255, 212, 99);
    }
    .gfull {
      background-color: rgb(0, 219, 174);
    }
    .gunfull {
      background-color: rgb(8, 148, 236);
    }
  }
  .gmiddle {
    font-size: 12px;
    color: #666;
    padding: 10px 10px;
    div {
      margin-top: 3px;
    }
  }
  .gbottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px 10px;
    font-size: 13px;
  }
}
.grid-content-room-male {
  outline: solid 1px rgba(25, 133, 252, 0.6);
  .gtop {
    .gleft {
      color: rgb(8, 148, 236);
    }
    .gcenter {
      background-color: rgb(8, 148, 236);
    }
  }
  .gbottom {
    background-color: rgb(204, 232, 250);
  }
}
.grid-content-room-female {
  outline: solid 1px rgba(255, 135, 142, 0.8);
  .gtop {
    .gleft {
      color: rgb(255, 135, 142);
    }
    .gcenter {
      background-color: rgb(255, 135, 142);
    }
  }
  .gbottom {
    background-color: rgb(253, 229, 231);
  }
}
.grid-content-room-male:hover {
  outline: solid 2px rgb(25, 133, 252);
}
.grid-content-room-male.active {
  outline: solid 2px rgb(25, 133, 252);
}
.grid-content-room-female:hover {
  outline: solid 2px rgb(255, 135, 142);
}
.grid-content-room-female.active {
  outline: solid 2px rgb(255, 135, 142);
}
.grid-content-room-selected {
  outline: solid 2px orange;
  box-shadow: 4px 4px 3px 2px rgba(0, 0, 0, 0.2);
}
.grid-content-room-selected:hover {
  outline: solid 2px orange;
  box-shadow: 4px 4px 3px 2px rgba(0, 0, 0, 0.2);
}
.grid-content-bed {
  min-height: 126px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  .gtop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 10px;
    .gleft {
      width: 80px;
      height: 100px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background-color: #eee;
      img {
        width: 100%;
        height: 100%;
      }
      .gempty {
        color: #333;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .gempty :last-child {
        margin-top: 5px;
      }
    }
    .gcenter {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 10px;
      height: 100px;
      font-size: 13px;
      color: #666;
      .name {
        font-size: 14px;
        color: #000;
      }
    }
    .gright {
    }
  }
  .gmiddle {
    display: flex;
    flex-direction: row;
    padding: 0px 10px;
    justify-content: space-between;
    .name {
      width: 80px;
      text-align: center;
    }
    .watch {
      margin-right: 10px;
      color: red;
    }
  }
  .gbottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px 10px;
    font-size: 13px;
    .button {
      cursor: pointer;
    }
    .disabled {
      cursor: default;
      color: #ccc;
    }
  }
}
.grid-content-bed-male {
  outline: solid 1px rgba(25, 133, 252, 0.6);
  .gtop {
    .gleft {
    }
    .gcenter {
    }
  }
}
.grid-content-bed-female {
  outline: solid 1px rgba(255, 135, 142, 0.8);
  .gtop {
    .gleft {
    }
    .gcenter {
    }
  }
}
.grid-content-bed-male:hover {
  outline: solid 2px rgb(25, 133, 252);
}
.grid-content-bed-female:hover {
  outline: solid 2px rgb(255, 135, 142);
}
.grid-content-bed-selected {
  outline: solid 2px orange;
  box-shadow: 4px 4px 3px 2px rgba(0, 0, 0, 0.2);
}
.grid-content-bed-selected:hover {
  outline: solid 2px orange;
  box-shadow: 4px 4px 3px 2px rgba(0, 0, 0, 0.2);
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.filter {
  .group {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    .label {
      color: #999;
    }
    .button {
      padding: 6px 12px;
      min-width: 55px;
      text-align: center;
      border: solid 1px #ddd;
      border-radius: 3px;
      margin-left: 10px;
      font-size: 12px;
      color: #999;
    }
    .button:hover {
      cursor: pointer;
    }
    .selected {
      border: none;
      background-color: rgb(220, 240, 252);
      color: rgb(8, 148, 236);
    }
  }
}
