<template>
    <div class="app-container">
        <el-form :inline="true" label-width="68px" label-position="left">
            <el-form-item>
                <el-date-picker
                    v-model="echart_selny"
                    type="month"
                    value-format="yyyy-MM"
                    format="yyyy-MM"
                    @change="echart_selnyChange"
                    :loading="btnLoading"
                    placeholder="选择日期"
                >
                </el-date-picker>

                <el-button
                    type="primary"
                    icon="el-icon-search"
                    size="mini"
                    :loading="btnLoading"
                    @click="handleSearch"
                    >查询</el-button
                >
            </el-form-item>
        </el-form>

        <div>
            <el-tabs
                v-model="activeName"
                type="border-card"
                @tab-click="handletabsClick"
            >
                <el-tab-pane label="报表" name="chart">
                    <div class="chart" ref="rj_m_px"></div>
                    <div class="chart" ref="rj_y_px"></div>
                    <div class="chart" ref="rj_m_sk"></div>
                    <div class="chart" ref="rj_y_sk"></div>
                    <div class="chart" ref="js_m"></div>
                    <div class="chart" ref="js_y"></div>
                </el-tab-pane>
                <el-tab-pane label="列表" name="table">
                    <vxe-grid
                        resizable
                        ref="xGrid"
                        :loading="btnLoading"
                        :toolbar-config="tableToolbar"
                        :columns="tableColumn"
                        :data="dataList"
                    ></vxe-grid>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>



<script>
import { pxhyechartstable } from "@/api/form/data";
import echarts from "echarts";

export default {
    data() {
        return {
            tableColumn: [],
            dataList: [],
            tableToolbar: {
                perfect: false,
                zoom: true,
                custom: false,
            },

            echart_y: 0,
            echart_q: 0,
            echart_m: 0,
            echart_selny: null,
            btnLoading: true,
            activeName: "chart",
        };
    },
    created() {
        var _now = new Date();
        var _m = _now.getMonth() + 1;
        this.echart_selny =
            _now.getFullYear() + "-" + (_m < 10 ? "0" + _m : _m);
        this.echart_selnyChange(this.echart_selny);
        setTimeout(() => {
            this.initdata();
        }, 500);

        var onerow = [
            "部门",
            "月部门人均培训小时数",
            "年部门人均培训小时数",
            "月部门人均授课小时数",
            "年部门人均授课小时数",
        ];
        for (var ik in onerow) {
            var tf = onerow[ik];
            this.tableColumn.push({
                field: tf,
                title: tf,
                width: 180,

                visible: true,
            });
        }
    },
    methods: {
        echart_selnyChange(val) {
            //console.log("echart_selnyChange", val);
            var ta = val.split("-");
            this.echart_y = ta[0];
            this.echart_m = ta[1] * 1;
            if (this.echart_m >= 1 && this.echart_m <= 3) {
                this.echart_q = 1;
            } else if (this.echart_m >= 4 && this.echart_m <= 6) {
                this.echart_q = 2;
            } else if (this.echart_m >= 7 && this.echart_m <= 9) {
                this.echart_q = 3;
            } else if (this.echart_m >= 10 && this.echart_m <= 12) {
                this.echart_q = 4;
            }
        },
        myparseInt(v) {
            var val = parseInt(v);
            if (isNaN(val)) {
                return 0;
            } else {
                return val;
            }
        },
        myparseFloat(v) {
            var val = parseFloat(v);
            if (isNaN(val)) {
                return 0;
            } else {
                return val;
            }
        },
        handleSearch() {
            this.initdata();
        },
        handletabsClick() {},
        initdata() {
            this.btnLoading = true;

            var rotate = 10;
            var w1 = 50;

            var toolbox = {
                feature: {
                    saveAsImage: {},
                },
            };

            pxhyechartstable(this.echart_selny).then((res) => {
                var option = {};
                var charttitle = "";
                var barWidth = 30;
                var mdepts = new Array();
                var mdepts2 = new Array();
                var mdepts3 = new Array();
                var mdepts4 = new Array();
                var rj_m_px = [];
                var rj_y_px = [];
                var rj_m_sk = [];
                var rj_y_sk = [];
                var js_m = [];

                var js_y = [];

                // return

                this.dataList = res.data.data["人均培训时长"];

                for (var ik in this.dataList) {
                    var onedata = this.dataList[ik];
                    mdepts.push(onedata["部门"]);

                    rj_m_px.push(
                        this.myparseFloat(onedata["月部门人均培训小时数"])
                    );
                    rj_y_px.push(
                        this.myparseFloat(onedata["年部门人均培训小时数"])
                    );

                    rj_m_sk.push(
                        this.myparseFloat(onedata["月部门人均授课小时数"])
                    );
                    rj_y_sk.push(
                        this.myparseFloat(onedata["年部门人均授课小时数"])
                    );
                }

                var bmdatalist = res.data.data["部门授课小时"];
                for (var ik in bmdatalist) {
                    var onedata = bmdatalist[ik];
                    mdepts2.push(onedata["dept_name"]);

                    rj_m_sk.push(this.myparseFloat(onedata["duration_m"]));
                    rj_y_sk.push(this.myparseFloat(onedata["duration_y"]));
                }

                var jsdatalist = res.data.data["月讲师授课前十"];
                for (var ik in jsdatalist) {
                    var onedata = jsdatalist[ik];
                    mdepts3.push(onedata["nickName"]);

                    js_m.push(this.myparseFloat(onedata["duration_m"]));
                }

                var jsydatalist = res.data.data["年讲师授课前十"];
                for (var ik in jsydatalist) {
                    var onedata = jsydatalist[ik];
                    mdepts4.push(onedata["nickName"]);

                    js_y.push(this.myparseFloat(onedata["duration_y"]));
                }

                var maxbarvalue = Math.max.apply(null, rj_m_px) + 10;

                var myChart = null;

                //月部门人均培训小时数
                charttitle = `${this.echart_y}年${this.echart_m}月部门人均培训小时数`;
                myChart = echarts.init(this.$refs["rj_m_px"]);

                option = {
                    title: {
                        text: charttitle,
                        x: "center",
                    },
                    toolbox: toolbox,
                    color: ["#3398DB"],
                    tooltip: {
                        trigger: "axis",
                        axisPointer: {
                            type: "cross",
                            crossStyle: {
                                color: "#fff",
                            },
                        },
                    },

                    xAxis: [
                        {
                            type: "category",
                            data: mdepts,
                            axisPointer: {
                                type: "shadow",
                            },
                            axisLabel: {
                                interval: 0,
                                rotate: rotate,
                            },
                        },
                    ],
                    yAxis: [
                        {
                            type: "value",
                            min: 0,
                            max: maxbarvalue,
                            axisLabel: {
                                formatter: "{value}小时",
                            },
                        },
                    ],
                    dataZoom: [
                        {
                            type: "slider",
                            height: 10,
                            show: true,
                            start: 0,
                            end: w1,
                            xAxisIndex: [0],
                            backgroundColor: "#C0C0C0",
                            fillerColor: "#eceeee",
                        },
                    ],
                    series: [
                        {
                            name: "人均培训小时数",
                            type: "bar",
                            data: rj_m_px,
                            barWidth: barWidth + "%",
                            label: {
                                normal: {
                                    show: true,
                                    position: "top",
                                    formatter: function (val) {
                                        return val.value;
                                    },
                                },
                            },
                        },
                    ],
                };
                myChart.setOption(option);

                //年部门人均培训小时数
                charttitle = `${this.echart_y}年部门人均培训小时数`;
                myChart = echarts.init(this.$refs["rj_y_px"]);

                option = {
                    title: {
                        text: charttitle,
                        x: "center",
                    },
                    toolbox: toolbox,
                    color: ["#3398DB"],
                    tooltip: {
                        trigger: "axis",
                        axisPointer: {
                            type: "cross",
                            crossStyle: {
                                color: "#fff",
                            },
                        },
                    },

                    xAxis: [
                        {
                            type: "category",
                            data: mdepts,
                            axisPointer: {
                                type: "shadow",
                            },
                            axisLabel: {
                                interval: 0,
                                rotate: rotate,
                            },
                        },
                    ],
                    yAxis: [
                        {
                            type: "value",
                            min: 0,
                            max: maxbarvalue,
                            axisLabel: {
                                formatter: "{value}小时",
                            },
                        },
                    ],
                    dataZoom: [
                        {
                            type: "slider",
                            height: 10,
                            show: true,
                            start: 0,
                            end: w1,
                            xAxisIndex: [0],
                            backgroundColor: "#C0C0C0",
                            fillerColor: "#eceeee",
                        },
                    ],
                    series: [
                        {
                            name: "人均培训小时数",
                            type: "bar",
                            data: rj_y_px,
                            barWidth: barWidth + "%",
                            label: {
                                normal: {
                                    show: true,
                                    position: "top",
                                    formatter: function (val) {
                                        return val.value;
                                    },
                                },
                            },
                        },
                    ],
                };

                myChart.setOption(option);

                //月部门人均授课小时数
                charttitle = `${this.echart_y}年${this.echart_m}月部门授课小时数`;
                myChart = echarts.init(this.$refs["rj_m_sk"]);

                option = {
                    title: {
                        text: charttitle,
                        x: "center",
                    },
                    toolbox: toolbox,
                    color: ["#3398DB"],
                    tooltip: {
                        trigger: "axis",
                        axisPointer: {
                            type: "cross",
                            crossStyle: {
                                color: "#fff",
                            },
                        },
                    },

                    xAxis: [
                        {
                            type: "category",
                            data: mdepts2,
                            axisPointer: {
                                type: "shadow",
                            },
                            axisLabel: {
                                interval: 0,
                                rotate: rotate,
                            },
                        },
                    ],
                    yAxis: [
                        {
                            type: "value",
                            min: 0,
                            max: maxbarvalue,
                            axisLabel: {
                                formatter: "{value}小时",
                            },
                        },
                    ],
                    dataZoom: [
                        {
                            type: "slider",
                            height: 10,
                            show: true,
                            start: 0,
                            end: w1,
                            xAxisIndex: [0],
                            backgroundColor: "#C0C0C0",
                            fillerColor: "#eceeee",
                        },
                    ],
                    series: [
                        {
                            name: "授课小时数",
                            type: "bar",
                            data: rj_m_sk,
                            barWidth: barWidth + "%",
                            label: {
                                normal: {
                                    show: true,
                                    position: "top",
                                    formatter: function (val) {
                                        return val.value;
                                    },
                                },
                            },
                        },
                    ],
                };
                myChart.setOption(option);

                //部门授课小时数
                charttitle = `${this.echart_y}年部门授课小时数`;
                myChart = echarts.init(this.$refs["rj_y_sk"]);

                option = {
                    title: {
                        text: charttitle,
                        x: "center",
                    },
                    toolbox: toolbox,
                    color: ["#3398DB"],
                    tooltip: {
                        trigger: "axis",
                        axisPointer: {
                            type: "cross",
                            crossStyle: {
                                color: "#fff",
                            },
                        },
                    },

                    xAxis: [
                        {
                            type: "category",
                            data: mdepts2,
                            axisPointer: {
                                type: "shadow",
                            },
                            axisLabel: {
                                interval: 0,
                                rotate: rotate,
                            },
                        },
                    ],
                    yAxis: [
                        {
                            type: "value",
                            min: 0,
                            max: maxbarvalue,
                            axisLabel: {
                                formatter: "{value}小时",
                            },
                        },
                    ],
                    dataZoom: [
                        {
                            type: "slider",
                            height: 10,
                            show: true,
                            start: 0,
                            end: w1,
                            xAxisIndex: [0],
                            backgroundColor: "#C0C0C0",
                            fillerColor: "#eceeee",
                        },
                    ],
                    series: [
                        {
                            name: "授课小时数",
                            type: "bar",
                            data: rj_y_sk,
                            barWidth: barWidth + "%",
                            label: {
                                normal: {
                                    show: true,
                                    position: "top",
                                    formatter: function (val) {
                                        return val.value;
                                    },
                                },
                            },
                        },
                    ],
                };
                myChart.setOption(option);

                charttitle = `${this.echart_y}年${this.echart_m}月前10名个人授课小时数`;
                myChart = echarts.init(this.$refs["js_m"]);

                option = {
                    title: {
                        text: charttitle,
                        x: "center",
                    },
                    toolbox: toolbox,
                    color: ["#3398DB"],
                    tooltip: {
                        trigger: "axis",
                        axisPointer: {
                            type: "cross",
                            crossStyle: {
                                color: "#fff",
                            },
                        },
                    },

                    xAxis: [
                        {
                            type: "category",
                            data: mdepts3,
                            axisPointer: {
                                type: "shadow",
                            },
                            axisLabel: {
                                interval: 0,
                                rotate: rotate,
                            },
                        },
                    ],
                    yAxis: [
                        {
                            type: "value",
                            min: 0,
                            max: maxbarvalue,
                            axisLabel: {
                                formatter: "{value}小时",
                            },
                        },
                    ],
                    dataZoom: [
                        {
                            type: "slider",
                            height: 10,
                            show: true,
                            start: 0,
                            end: w1,
                            xAxisIndex: [0],
                            backgroundColor: "#C0C0C0",
                            fillerColor: "#eceeee",
                        },
                    ],
                    series: [
                        {
                            name: "授课小时数",
                            type: "bar",
                            data: js_m,
                            barWidth: barWidth + "%",
                            label: {
                                normal: {
                                    show: true,
                                    position: "top",
                                    formatter: function (val) {
                                        return val.value;
                                    },
                                },
                            },
                        },
                    ],
                };
                myChart.setOption(option);

                charttitle = `${this.echart_y}年前10名个人授课小时数`;
                myChart = echarts.init(this.$refs["js_y"]);

                option = {
                    title: {
                        text: charttitle,
                        x: "center",
                    },
                    toolbox: toolbox,
                    color: ["#3398DB"],
                    tooltip: {
                        trigger: "axis",
                        axisPointer: {
                            type: "cross",
                            crossStyle: {
                                color: "#fff",
                            },
                        },
                    },

                    xAxis: [
                        {
                            type: "category",
                            data: mdepts4,
                            axisPointer: {
                                type: "shadow",
                            },
                            axisLabel: {
                                interval: 0,
                                rotate: rotate,
                            },
                        },
                    ],
                    yAxis: [
                        {
                            type: "value",
                            min: 0,
                            max: maxbarvalue,
                            axisLabel: {
                                formatter: "{value}小时",
                            },
                        },
                    ],
                    dataZoom: [
                        {
                            type: "slider",
                            height: 10,
                            show: true,
                            start: 0,
                            end: w1,
                            xAxisIndex: [0],
                            backgroundColor: "#C0C0C0",
                            fillerColor: "#eceeee",
                        },
                    ],
                    series: [
                        {
                            name: "授课小时数",
                            type: "bar",
                            data: js_y,
                            barWidth: barWidth + "%",
                            label: {
                                normal: {
                                    show: true,
                                    position: "top",
                                    formatter: function (val) {
                                        return val.value;
                                    },
                                },
                            },
                        },
                    ],
                };
                myChart.setOption(option);
                this.btnLoading = false;
            });
        },
    },
};
</script>

<style rel="stylesheet/scss" scoped>
.chart {
    min-height: 500px;
    margin-bottom: 50px;
}
.__fxdj {
    display: inline-block;
    text-align: center;
    width: 21px;
    margin-right: 5px;
}
.sisetutil {
    text-align: center;
    font-size: 18px;
    line-height: 1.5;
}
.sisetudiv {
    margin: 50px;
}
.sisetudiv img {
    max-width: 100%;
}
</style>