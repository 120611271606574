<template>
	<div class="app-container">
		<el-row>
			<el-col :span="4">
				<div class="left-container">
					<div class="left">
						<div class="left-top">
							<div>校区</div>
							<el-button type="text" icon="el-icon-plus" @click="handleAdd('1', undefined)">校区</el-button>
						</div>
						<div class="left-middle">
							<el-input class="filter"
								clearable
								size="small"
								placeholder="输入关键字进行过滤"
								v-model="filterText">
							</el-input>
							<el-tree
								ref="tree"
								:data="dataTree"
								:props="defaultProps"
								node-key="id"
								:default-expanded-keys="defaultExpandedKeys"
								:highlight-current="true"
								:expand-on-click-node="false"
								:filter-node-method="filterNode"
								@node-expand="nodeExpand"
								@node-collapse="nodeCollapse"
								>
								<div
									slot-scope="{ node, data }"
									@click="handleClickItem(node, data)"
									:class="
									data.children
										? 'custom-tree-node tree-node-root'
										: 'custom-tree-node tree-node-leaf'
									"
								>
									<div
									class="el-tree-node__label custom-tree-node-label"
									:title="node.label"
									>
									{{ node.label }}
									</div>
									<div
									v-if="
										data.type == '1' || data.type == '2'
									"
									class="custom-tree-node-button"
									>
									<el-dropdown-ex
										trigger="click"
										:stopPropagationOnClick="true"
										@command="handleCommand"
										@visible-change="
										(e) => handleDropdownVisibleChange(e, node, data)
										"
									>
										<span class="el-dropdown-link">
										<i class="el-icon-more"></i>&nbsp;
										</span>
										<el-dropdown-menu slot="dropdown">
										<el-dropdown-item v-if="data.type == '1'"
											icon="el-icon-plus"
											:command="makeCommand('add', data, data.type == '1' ? '2' : '3')"
											>{{data.type == '1' ? '宿舍区' : '宿舍楼'}}</el-dropdown-item
										>
										<el-dropdown-item
											icon="el-icon-edit"
											:command="makeCommand('edit', data)"
											>编辑</el-dropdown-item
										>
										<el-dropdown-item
											icon="el-icon-delete"
											:command="makeCommand('delete', data)"
											>删除</el-dropdown-item
										>
										</el-dropdown-menu>
									</el-dropdown-ex>
									</div>
								</div>
							</el-tree>
						</div>
					</div>
				</div>
			</el-col>
			<el-col :span="20">
				<div class="right-top">
					<div v-if="selectedItem">{{ selectedName }}</div>
				</div>
				
				<jf-table :loading="loading" :total="total" pageNumField="page" pageSizeField="limit" :columns="tableColumn" :data="dataList"
					:toolbar-config="tableToolbar" :query-params="queryParams" @checkbox-change="checkboxChangeEvent"
					@page-change="handlePageChange" @handleQuery="handleQuery">
					<template slot="query_qarams_item">
						<!--<el-form-item label="父id" prop="pid">
							<el-input v-model="queryParams.pid" placeholder="请输入父id" clearable size="small"
								@keyup.enter.native="handleQuery" />
						</el-form-item>
						<el-form-item label="类型" prop="type">
							<el-input v-model="queryParams.type" placeholder="请输入类型" clearable size="small"
								@keyup.enter.native="handleQuery" />
						</el-form-item>
						<el-form-item label="性别" prop="gender">
							<el-input v-model="queryParams.gender" placeholder="请输入性别" clearable size="small"
								@keyup.enter.native="handleQuery" />
						</el-form-item>-->
						<el-form-item label="名称" prop="name">
							<el-input v-model="queryParams.name" placeholder="请输入名称" clearable size="small"
								@keyup.enter.native="handleQuery" />
						</el-form-item>
						<el-form-item label="编号" prop="no">
							<el-input v-model="queryParams.no" placeholder="请输入编号" clearable size="small"
								@keyup.enter.native="handleQuery" />
						</el-form-item>
						<!--<el-form-item label="简称" prop="sname">
							<el-input v-model="queryParams.sname" placeholder="请输入简称" clearable size="small"
								@keyup.enter.native="handleQuery" />
						</el-form-item>-->
						<el-form-item label="地址" prop="address">
							<el-input v-model="queryParams.address" placeholder="请输入地址" clearable size="small"
								@keyup.enter.native="handleQuery" />
						</el-form-item>
						<el-form-item label="开放状态" prop="status">
							<dict-select v-model="queryParams.status" type="dorm_status" placeholder="请选择开放状态" />
						</el-form-item>
						<el-form-item label="入住性别" prop="gender">
							<dict-select v-model="queryParams.gender" type="dorm_gender" placeholder="请选择入住性别" />
						</el-form-item>
					</template>

					<template slot="toolbar_btn_left">
						<el-button style="margin-left: 10px" type="primary" icon="el-icon-plus" size="mini" :disabled="!selectedItem || selectedHasChildren"
							@click="handleAdd('3', selectedItem)" v-hasPermi="[
				'/dorm/dormbuilding/add',
			]">宿舍楼</el-button>

						<el-button type="success" icon="el-icon-edit" size="mini" :disabled="single"
							@click="handleUpdate" v-hasPermi="[
				'/dorm/dormbuilding/get',
				'/dorm/dormbuilding/edit',
			]">修改</el-button>

						<el-button type="danger" icon="el-icon-delete" size="mini" :disabled="multiple"
							@click="handleDelete" v-hasPermi="[
				'/dorm/dormbuilding/del',
			]">删除</el-button>

						<el-button type="warning" icon="el-icon-upload" size="mini" @click="handleImport"
							v-hasPermi="['/dorm/dormbuilding/import']">导入宿舍楼</el-button>

						<!--<el-button type="warning" icon="el-icon-download" size="mini" @click="handleExport"
							v-hasPermi="['/dorm/dormbuilding/export']">导出</el-button>-->

						<el-button type="warning" icon="el-icon-upload" size="mini" @click="handleImportBed"
							v-hasPermi="['/dorm/dormbed/import']">导入床位</el-button>

						<el-button type="success" icon="el-icon-download" size="mini" @click="handleExportBed"
							v-hasPermi="['/dorm/dormbed/export']">导出床位</el-button>
					</template>

					<template slot="toolbar_btn_row" slot-scope="{ row }">
						<el-button size="mini" type="text" icon="el-icon-office-building" @click.stop="handleFloor(row)">楼层管理
						</el-button>
						<el-button size="mini" type="text" icon="el-icon-view" @click.stop="handleView(row)">查看
						</el-button>
						<el-button size="mini" type="text" icon="el-icon-edit" @click.stop="handleUpdate(row)">修改
						</el-button>
						<el-button size="mini" type="text" icon="el-icon-delete" @click.stop="handleDelete(row)">删除
						</el-button>
					</template>

				</jf-table>

			</el-col>
		</el-row>

		<el-dialog :close-on-click-modal="false" :title="title" :visible.sync="open" width="800px" append-to-body>
			<el-form ref="form" :model="form" :rules="rules" label-width="120px">
				<el-row>
					<!--<el-col :span="12">
						<el-form-item label="父id" prop="pid">
							<el-input-number v-model="form.pid" placeholder="请输入父id" controls-position="right" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="类型(1校区,2宿舍区,3楼栋)" prop="type">
							<el-input v-model="form.type" placeholder="请输入类型(1校区,2宿舍区,3楼栋)" clearable />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="性别" prop="gender">
							<el-input v-model="form.gender" placeholder="请输入性别" clearable />
						</el-form-item>
					</el-col>-->
					<el-col :span="12">
						<el-form-item label="名称" prop="name">
							<el-input v-model="form.name" placeholder="请输入名称" clearable />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="编号" prop="no">
							<el-input v-model="form.no" placeholder="请输入编号" clearable />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="简称" prop="sname">
							<el-input v-model="form.sname" placeholder="请输入简称" clearable />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="地址" prop="address">
							<el-input v-model="form.address" placeholder="请输入地址" clearable />
						</el-form-item>
					</el-col>
					<template v-if="form.type == '3'">
					<el-col :span="12">
						<el-form-item label="开放状态" prop="status">
							<dict-select v-model="form.status" type="dorm_status" placeholder="请选择开放状态" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="入住性别" prop="gender">
							<dict-select v-model="form.gender" type="dorm_gender" placeholder="请选择入住性别" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="楼层数" prop="floors">
							<el-input-number v-model="form.floors" placeholder="请输入楼层数" :min="0" clearable controls-position="right" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="每楼层房间数" prop="floor_rooms">
							<el-input-number v-model="form.floor_rooms" placeholder="请输入每楼层房间数" :min="0" clearable controls-position="right" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="每房间床位数" prop="room_beds">
							<el-input-number v-model="form.room_beds" placeholder="请输入每房间床位数" :min="0" clearable controls-position="right" />
						</el-form-item>
					</el-col>
					<!--<el-col :span="12">
						<el-form-item label="纬度" prop="lat">
							<el-input-number v-model="form.lat" placeholder="请输入纬度" controls-position="right" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="经度" prop="lng">
							<el-input-number v-model="form.lng" placeholder="请输入经度" controls-position="right" />
						</el-form-item>
					</el-col>-->
					</template>
					<el-col :span="12">
						<el-form-item label="管理员" prop="manager_ids">
							<admin-selectex size="small" placeholder="请选择管理员" title="管理员" idField="id"
								:value="form.manager_ids" :label="form.manager_names" :readonly="true"
								@getValue='(ids, names) => { form.manager_ids = ids; form.manager_names = names; }' />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="备注" prop="remark">
							<el-input v-model="form.remark" placeholder="请输入备注" clearable type="textarea" />
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>

			<div slot="footer" class="dialog-footer">
				<template v-if="!readonly">
					<el-button type="primary" @click="submitForm">确 定</el-button>
					<el-button @click="cancel">取 消</el-button>
				</template>
				<el-button v-else @click="cancel">关 闭</el-button>
			</div>
		</el-dialog>
		<el-dialog :close-on-click-modal="false" :title="titleFloor" :visible.sync="openFloor" width="1080px" append-to-body>
			<dormfloor v-if="openFloor" 
				:xq="openFloorXq" 
				:ssq="openFloorSsq" 
				:building="openFloorBuilding"
				:building_full_name="openFloorBuildingFullname"
				@close="openFloor = false" />

			<div slot="footer" class="dialog-footer">
				<el-button @click="openFloor = false">关 闭</el-button>
			</div>
		</el-dialog>
		<el-dialog :close-on-click-modal="false" :title="upload.title" :visible.sync="upload.open" width="400px"
			append-to-body>
			<el-upload ref="upload" :limit="1" accept=".xlsx" :headers="upload.headers"
				:action="upload.url + '?update=' + upload.update" :disabled="upload.isUploading"
				:on-progress="handleImportProgress" :on-success="handleImportSuccess" :on-error="handleImportError"
				:auto-upload="false" drag>
				<i class="el-icon-upload"></i>
				<div class="el-upload__text">
					将文件拖到此处，或
					<em>点击上传</em>
				</div>
				<div class="el-upload__tip" slot="tip">
					<el-checkbox v-model="upload.update">更新已经存在的数据</el-checkbox>&nbsp;&nbsp;
					<el-link type="info" style="font-size:12px" @click="handleImportTemplate">下载模板</el-link>&nbsp;&nbsp;
					<el-link type="info" style="font-size: 12px" @click="handleImportReport">下载报告</el-link>
				</div>
				<div class="el-upload__tip" style="color:red" slot="tip">提示：仅允许导入“xlsx”格式文件！</div>
			</el-upload>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitImportForm">确 定</el-button>
				<el-button @click="upload.open = false">取 消</el-button>
			</div>
		</el-dialog>
		<el-dialog :close-on-click-modal="false" :title="uploadBed.title" :visible.sync="uploadBed.open" width="400px"
			append-to-body>
			<el-upload ref="uploadBed" :limit="1" accept=".xlsx" :headers="uploadBed.headers"
				:action="uploadBed.url + '?update=' + uploadBed.update" :disabled="uploadBed.isUploading"
				:on-progress="handleImportBedProgress" :on-success="handleImportBedSuccess" :on-error="handleImportBedError"
				:auto-upload="false" drag>
				<i class="el-icon-upload"></i>
				<div class="el-upload__text">
					将文件拖到此处，或
					<em>点击上传</em>
				</div>
				<div class="el-upload__tip" slot="tip">
					<el-checkbox v-model="uploadBed.update">更新已经存在的数据</el-checkbox>&nbsp;&nbsp;
					<el-link type="info" style="font-size:12px" @click="handleImportBedTemplate">下载模板</el-link>&nbsp;&nbsp;
					<el-link type="info" style="font-size: 12px" @click="handleImportBedReport">下载报告</el-link>
				</div>
				<div class="el-upload__tip" style="color:red" slot="tip">提示：仅允许导入“xlsx”格式文件！</div>
			</el-upload>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitImportBedForm">确 定</el-button>
				<el-button @click="uploadBed.open = false">取 消</el-button>
			</div>
		</el-dialog>
		<el-dialog :close-on-click-modal="false" title="导出床位" :visible.sync="exportBedOpen" width="500px"
			append-to-body>
			<el-form v-if="exportBedOpen" label-width="120px">
				<el-row>
					<el-col :span="24">
						<el-form-item label="导出类型">
							<el-select v-model="exportBedForm.type" placeholder="请选择">
								<el-option label="全部床位" value=""></el-option>
								<el-option label="开放床位" value="1"></el-option>
								<el-option label="未开放床位" value="2"></el-option>
								<el-option label="空床位" value="3"></el-option>
								<el-option label="已锁定空床位" value="4"></el-option>
								<el-option label="未锁定空床位" value="5"></el-option>
								<el-option label="已分配床位" value="6"></el-option>
								<el-option label="已分配已入住床位" value="7"></el-option>
								<el-option label="已分配未入住床位" value="8"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="校区/楼栋">
							<treeselect v-model="exportBedForm.xsb_id" :options="buildingTree" placeholder="请选择校区/楼栋"
								:normalizer="normalizerXqTreeNode" @select="onExportXqChange" />
						</el-form-item>
					</el-col>
					<el-col :span="24">
                        <el-form-item label="院系" prop="dept_no">
                            <dept-select
                                v-model="exportBedForm.dept_no"
                                :defaultLabel="exportBedForm.dept_name"
                                placeholder="请选择院系"
                                valueField="no"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="专业" prop="major_no">
                            <major-select
                                v-model="exportBedForm.major_no"
                                :defaultLabel="exportBedForm.major_name"
                                placeholder="请选择专业"
                                :dept_no="exportBedForm.dept_no"
                                valueField="no"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="班级" prop="classes_no">
                            <classes-select
                                v-model="exportBedForm.class_no"
                                placeholder="请选择班级"
                                valueField="no"
                                :dept_no="exportBedForm.dept_no"
                                :major_no="exportBedForm.major_no"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="年级" prop="grade">
                            <dict-select
                                v-model="exportBedForm.grade"
                                placeholder="请选择年级"
                                type="stu_grade"
								clearable
                            />
                        </el-form-item>
                    </el-col>
					<el-col :span="24">
						<el-form-item label="性别">
							<el-select v-model="exportBedForm.gender" placeholder="请选择" clearable>
								<el-option label="男" value="1"></el-option>
								<el-option label="女" value="2"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitExportBedForm">确 定</el-button>
				<el-button @click="exportBedOpen = false">取 消</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
import Qs from 'qs';
import { getToken } from "@/utils/auth";
import {
	listDormBuilding,
	getDormBuilding,
	addDormBuilding,
	editDormBuilding,
	delDormBuilding,
} from "@/api/dorm/dorm_building";
import dormfloor from '../dorm_floor'
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
	name: "dorm-building",
	components: {
		dormfloor: dormfloor,
		Treeselect: Treeselect
	},
	data() {
		return {
			tableToolbar: {
				perfect: true,
				zoom: true,
				custom: true,
				refresh: {
					query: this.handleQuery,
				},
				slots: {
					buttons: "toolbar_buttons",
				},
			},
			tableColumn: [
				{ type: "checkbox", width: 60, fixed: "left" },
				//{ field: "id", title: "id", minWidth: 120, fixed: "left", visible: false },
				{ field: "pid", title: "校区", minWidth: 120, formatter: this.formatParent },
				//{ field: "type", title: "类型(1校区,2宿舍区,3楼栋)", minWidth: 120 },
				{ field: "name", title: "宿舍楼名称", minWidth: 120 },
				{ field: "no", title: "宿舍楼编号", minWidth: 120 },
				{ field: "sname", title: "简称", minWidth: 120, visible: false },
				{ field: "address", title: "地址", minWidth: 120 },
				{ field: "status", title: "开放状态", minWidth: 120, formatter: this.dictFormat, dictType: 'dorm_status' },
				{ field: "gender", title: "入住性别", minWidth: 120, formatter: this.dictFormat, dictType: 'dorm_gender' },
				//{ field: "lat", title: "纬度", minWidth: 120 },
				//{ field: "lng", title: "经度", minWidth: 120 },
				{ field: "remark", title: "备注", minWidth: 120 },
				{ field: "create_by", title: "创建者", minWidth: 120, visible: false },
				{ field: "create_time", title: "创建时间", minWidth: 120, formatter: "formatDate", visible: false },
				{ field: "update_by", title: "更新者", minWidth: 120, visible: false },
				{ field: "update_time", title: "更新时间", minWidth: 120, formatter: "formatDate" },
				{
					field: "",
					title: "操作",
					width: 280,
					fixed: "right",
					align: "center",
					slots: { default: "defaultopr" },
				},
			],
			loading: true,
			ids: [],
			single: true,
			multiple: true,
			total: 0,
			dataList: [],
			allDataList: [],
			dataTree: [],
			buildingTree: [],
			title: "",
			readonly: false,
			open: false,
			queryParams: {
				page: 1,
				limit: 10,
				pid: undefined,
				type: undefined,
				gender: undefined,
				no: undefined,
				name: undefined,
				sname: undefined,
				address: undefined,
			},
			form: {},
			upload: {
				open: false,
				title: "",
				isUploading: false,
				update: false,
				headers: { Authorization: getToken() },
				url: process.env.VUE_APP_BASE_API + "/dorm/dormbuilding/import"
			},
			uploadBed: {
				open: false,
				title: "",
				isUploading: false,
				update: false,
				headers: { Authorization: getToken() },
				url: process.env.VUE_APP_BASE_API + "/dorm/dormbed/import"
			},
			rules: {
				pid: [{ required: true, message: "父id不能为空", trigger: "blur" }],
				type: [{ required: true, message: "类型(1校区,2宿舍区,3楼栋)不能为空", trigger: "blur" }],
				no: [{ required: true, message: "编号不能为空", trigger: "blur" }],
				name: [{ required: true, message: "名称不能为空", trigger: "blur" }],
				//sname: [{ required: true, message: "简称不能为空", trigger: "blur" }],
				//address: [{ required: true, message: "地址不能为空", trigger: "blur" }],
				status: [{ required: true, message: "开放状态不能为空", trigger: "blur" }],
				gender: [{ required: true, message: "入住性别不能为空", trigger: "blur" }],
				floors: [{ required: true, message: "楼层数不能为空", trigger: "blur" }],
				floor_rooms: [{ required: true, message: "每楼层房间数不能为空", trigger: "blur" }],
				room_beds: [{ required: true, message: "每房间床位数不能为空", trigger: "blur" }],
			},
			defaultProps: {
				children: "children",
				label: "name",
			},
			defaultExpandedKeys: [],
			selectedItem: undefined,
			selectedName: '',
			selectedHasChildren: false,
			filterText: '',
			titleFloor: '楼层管理',
			openFloor: false,
			openFloorXq: undefined,
			openFloorSsq: undefined,
			openFloorBuilding: undefined,
			openFloorBuildingFullname: '',
			exportBedOpen: false,
			exportBedForm: {},
		};
	},
	created() {
		for (let i = 0; i < this.tableColumn.length; i++) {
			const col = this.tableColumn[i]
			if (col.dictType) {
				this.getDicts(col.dictType).then(response => {
					if (response.code == 0) {
						col.dictData = response.data
						this.dataList = [].concat(this.dataList)
					}
				})
			}
		}
	},
	mounted() {
		this.getTree();
		this.getList();
	},
	methods: {
		getTree() {
			listDormBuilding({
				limit: 10000,
				qstr: JSON.stringify({
					filter: 'type=? or type=?',
					args: ['1', '2']
				})
			}).then((response) => {
				const firstInit = this.allDataList.length == 0
				this.allDataList = response.data.list;
				if (firstInit && this.defaultExpandedKeys.length == 0) {
					for (let i = 0; i < this.allDataList.length; i++) {
						const d = this.allDataList[i]
						if (d.type == '1') {
							this.defaultExpandedKeys.push(d.id)
						}
					}
				}
				this.dataTree = this.handleTree(
					this.allDataList,
					"id",
					"pid"
				);
				this.dataList = [].concat(this.dataList)
				if (this.selectedItem) {
					for (let i = 0; i < this.allDataList.length; i++) {
						const d = this.allDataList[i]
						if (d.id == this.selectedItem.id) {
							this.selectedItem = d
							this.selectedName = this.getItemFullName(this.selectedItem)
							this.$nextTick(() => {
								this.$refs.tree.setCurrentNode(d);
							})
							break
						}
					}
				}
			});

			listDormBuilding({
				limit: 10000
			}).then((response) => {
				this.buildingTree = this.handleTree(
					response.data.list,
					"id",
					"pid"
				);
			})
		},

		getList() {
			this.loading = true;
			this.ids = [];
			this.single = true;
			this.multiple = true;
			this.queryParams.type = '3'
			if (this.selectedItem) {
				//this.queryParams.pid = this.selectedItem.id
				this.queryParams.qstr = JSON.stringify({
					filter: 'concat(?, pids, ?) like ?',
					args: [',', ',', '%,'+this.selectedItem.id+',%']
				})
			}
			listDormBuilding(this.queryParams).then((response) => {
				this.dataList = response.data.list;
				this.total = response.data.total;
				this.loading = false;
			});
		},

		dictFormat(e) {
			const col = this.tableColumn[e.columnIndex]
			if (col.dictData) {
				const values = e.cellValue ? e.cellValue.split(',') : []
				const labels = []
				for (let i = 0; i < values.length; i++) {
					const v = values[i]
					for (let j = 0; j < col.dictData.values.length; j++) {
						const item = col.dictData.values[j]
						if (v == item.key) {
							labels.push(item.value)
							break
						}
					}
				}
				return labels.join(',')
			}
			return e.cellValue
		},

		cancel() {
			this.open = false;
			this.reset();
		},

		reset() {
			this.form = {
				id: undefined,
				pid: undefined,
				type: undefined,
				status: '1',
				gender: undefined,
				no: undefined,
				name: undefined,
				sname: undefined,
				address: undefined,
				lat: undefined,
				lng: undefined,
				manager: undefined,
				manager_ids: undefined,
				manager_names: undefined,
				remark: undefined,
				delete_time: undefined,
				create_by: undefined,
				create_time: undefined,
				update_by: undefined,
				update_time: undefined,
			};
			this.resetForm("form");
		},

		handleQuery() {
			this.queryParams.page = 1;
			this.getList();
		},

		resetQuery() {
			this.resetForm("queryForm");
			this.handleQuery();
		},

		handleAdd(type, parentItem) {
			if (type != '1' && type != '2' && type != '3') {
				return
			}
			this.reset();
			this.form.type = type;
			this.form.pid = parentItem && parentItem.id || 0;
			if (type == '1') {
				this.title = "添加校区";
			} else if (type == '2') {
				this.title = "添加宿舍区(" + this.getItemFullName(parentItem) + ")";
			} else {
				this.title = "添加宿舍楼(" + this.getItemFullName(parentItem) + ")";
			}
			this.readonly = false;
			this.open = true;
		},

		handleUpdate(row) {
			this.reset();
			const id = row.id || (this.ids.length > 0 ? this.ids[0] : '');
			const loading = this.$loading({
				text: '正在加载...'
			})
			getDormBuilding(id).then((response) => {
				loading.close()
				this.form = response.data;
				if (this.form.type == '1') {
					this.title = "修改校区";
				} else if (this.form.type == '2') {
					this.title = "修改宿舍区";
				} else {
					this.title = "修改宿舍楼";
				}
				this.readonly = false;
				this.open = true;
			}).catch(e => {
				loading.close()
			});
		},

		handleFloor(row) {
			this.openFloorXq = undefined
			this.openFloorSsq = undefined
			this.openFloorBuilding = row
			if (row.pid) {
				for (let i = 0; i < this.allDataList.length; i++) {
					const d = this.allDataList[i]
					if (d.id == row.pid) {
						if (d.type == '1') {
							this.openFloorXq = d
						} else if (d.type == '2') {
							this.openFloorSsq = d
						}
						break
					}
				}
			}
			if (this.openFloorSsq && !this.openFloorXq) {
				for (let i = 0; i < this.allDataList.length; i++) {
					const d = this.allDataList[i]
					if (d.id == this.openFloorSsq.pid) {
						if (d.type == '1') {
							this.openFloorXq = d
						}
						break
					}
				}
			}
			this.openFloorBuildingFullname = this.getItemFullName(row)
			this.titleFloor = "楼层管理("+this.openFloorBuildingFullname+")"
			this.openFloor = true
		},

		handleView(row) {
			const id = row.id || (this.ids.length > 0 ? this.ids[0] : '');
			const loading = this.$loading({
				text: '正在加载...'
			})
			getDormBuilding(id).then((response) => {
				loading.close()
				this.form = response.data;
				this.title = "查看";
				this.readonly = true;
				this.open = true;
			}).catch(e => {
				loading.close()
			});
		},

		submitForm() {
			this.$refs["form"].validate((valid) => {
				if (valid) {
					if (this.form.id) {
						const loading = this.$loading({
							text: '正在提交...'
						})
						editDormBuilding(this.form).then((response) => {
							loading.close()
							if (response.code === 0) {
								this.msgSuccess("修改成功");
								this.open = false;
								setTimeout(() => {
									this.getTree();
									if (this.form.type == '3') {
										this.getList();
									}
								}, 300);
							}
						}).catch(e => {
							loading.close()
						});
					} else {
						const loading = this.$loading({
							text: '正在提交...'
						})
						addDormBuilding(this.form).then((response) => {
							loading.close()
							if (response.code === 0) {
								this.msgSuccess("新增成功");
								this.open = false;
								setTimeout(() => {
									this.getTree();
									this.getList();
								}, 300);
							}
						}).catch(e => {
							loading.close()
						});
					}
				}
			});
		},

		handleDelete(row) {
			const ids = row.id || (this.ids.length > 0 ? this.ids.join(',') : '');
			let title = ''
			if (row.id) {
				title = '确定删除' + row.name + '吗？'
			} else {
				title = '确定删除选中的数据吗？'
			}
			this.$confirm(title, "警告", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					return delDormBuilding(ids);
				})
				.then(() => {
					if (this.selectedItem && this.selectedItem.id == row.id) {
						this.selectedItem = undefined
						this.selectedName = ''
					}
					setTimeout(() => {
						this.getTree();
						if (ids || (row.id && row.type == '3')) {
							this.getList();
						}
					}, 300);
					this.msgSuccess("删除成功");
				})
				.catch(() => { });
		},

		checkboxChangeEvent({ records }) {
			this.ids = records.map((item) => item.id);
			this.single = records.length != 1;
			this.multiple = !records.length;
		},

		handlePageChange({ currentPage, pageSize }) {
			if (this.queryParams.limit == pageSize) {
				this.getList();
			} else {
				this.queryParams.limit = pageSize;
				this.handleQuery();
			}
		},

		handleImport() {
			this.upload.title = "数据导入"
			this.upload.update = false
			this.upload.open = true
		},

		handleImportProgress(event, file, fileList) {
			this.upload.isUploading = true;
			if (event.percent == 100) {
				this.upload.open = false;
				this.upload.loading = this.$loading({
					lock: true,
					text: '正在导入数据',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
			}
		},

		handleImportSuccess(response, file, fileList) {
			if (this.upload.loading) {
				this.upload.loading.close();
			}
			this.upload.open = false;
			this.upload.isUploading = false;
			this.$refs.upload.clearFiles();
			if (response.code == 0) {
				//this.$alert('导入成功', "提示", { dangerouslyUseHTMLString: true });
				const msg = "导入成功" + response.data.success_count + "条数据，失败" + response.data.fail_count + "条数据" + (response.data.msg ? "，" + response.data.msg : "");
				this.$confirm(msg, "提示", {
					distinguishCancelAndClose: true,
					confirmButtonText: "确定",
					cancelButtonText: "下载导入报告",
				})
					.then(() => { })
					.catch((action) => {
						if (action === "cancel") {
							// 下载导入报告
							this.downloadbyurl('/dorm/dormbuilding/importreport');
						}
					});
			} else {
				this.$alert(response.msg || '导入失败', "提示", { dangerouslyUseHTMLString: true });
			}
			this.getList();
		},

		handleImportError(err, file, fileList) {
			if (this.upload.loading) {
				this.upload.loading.close();
			}
			this.upload.isUploading = false;
			this.$alert('导入失败', "提示", { dangerouslyUseHTMLString: true });
		},

		submitImportForm() {
			this.$refs.upload.submit();
		},

		handleImportTemplate() {
			this.downloadbyurl('/dorm/dormbuilding/importtemplate')
		},

		handleImportReport() {
			this.downloadbyurl('/dorm/dormbuilding/importreport');
		},

		handleExport() {
			this.$confirm('确定导出数据吗?', "警告", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					this.downloadbyurl('/dorm/dormbuilding/export?' + Qs.stringify(this.queryParams))
				})
		},

		handleImportBed() {
			this.uploadBed.title = "数据导入"
			this.uploadBed.update = false
			this.uploadBed.open = true
		},

		handleImportBedProgress(event, file, fileList) {
			this.uploadBed.isUploading = true;
			if (event.percent == 100) {
				this.uploadBed.open = false;
				this.uploadBed.loading = this.$loading({
					lock: true,
					text: '正在导入数据',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
			}
		},

		handleImportBedSuccess(response, file, fileList) {
			if (this.uploadBed.loading) {
				this.uploadBed.loading.close();
			}
			this.uploadBed.open = false;
			this.uploadBed.isUploading = false;
			this.$refs.uploadBed.clearFiles();
			if (response.code == 0) {
				//this.$alert('导入成功', "提示", { dangerouslyUseHTMLString: true });
				const msg = "导入成功" + response.data.success_count + "条数据，失败" + response.data.fail_count + "条数据" + (response.data.msg ? "，" + response.data.msg : "");
				this.$confirm(msg, "提示", {
					distinguishCancelAndClose: true,
					confirmButtonText: "确定",
					cancelButtonText: "下载导入报告",
				})
					.then(() => { })
					.catch((action) => {
						if (action === "cancel") {
							// 下载导入报告
							this.downloadbyurl('/dorm/dormbed/importreport');
						}
					});
			} else {
				this.$alert(response.msg || '导入失败', "提示", { dangerouslyUseHTMLString: true });
			}
			this.getList();
		},

		handleImportBedError(err, file, fileList) {
			if (this.uploadBed.loading) {
				this.uploadBed.loading.close();
			}
			this.uploadBed.isUploading = false;
			this.$alert('导入失败', "提示", { dangerouslyUseHTMLString: true });
		},

		submitImportBedForm() {
			this.$refs.uploadBed.submit();
		},

		handleImportBedTemplate() {
			this.downloadbyurl('/dorm/dormbed/importtemplate')
		},

		handleImportBedReport() {
			this.downloadbyurl('/dorm/dormbed/importreport');
		},

		handleExportBed() {
			this.exportBedForm = {}
			this.exportBedOpen = true
		},

		submitExportBedForm() {
			this.$confirm('确定导出数据吗?', "警告", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
			.then(() => {
				this.exportBedOpen = false
				this.downloadbyurl('/dorm/dormbed/export?export_type=' + (this.exportBedForm.type || '') + '&xq_id=' + (this.exportBedForm.xq_id || '') + '&ssq_id=' + (this.exportBedForm.ssq_id || '') + '&building_id=' + (this.exportBedForm.building_id || '') + '&dept_no=' + (this.exportBedForm.dept_no || '') + '&major_no=' + (this.exportBedForm.major_no || '') + '&class_no=' + (this.exportBedForm.class_no || '') + '&grade=' + (this.exportBedForm.grade || '') + '&gender=' + (this.exportBedForm.gender || ''))
			})
		},

		getItemFullName(item) {
			if (item.pid) {
				for (let i = 0; i < this.allDataList.length; i++) {
					const d = this.allDataList[i]
					if (d.id == item.pid) {
						return this.getItemFullName(d) + ' - ' + item.name
					}
				}
			}

			return item.name
		},

		handleDropdownVisibleChange(e, node, data) {
			//this.$refs.tree.setCurrentNode(data);
			//this.selectedItem = data;
			//this.selectedName = this.getItemFullName(this.selectedItem);
			//this.selectedHasChildren = node.childNodes && node.childNodes.length > 0
			//this.handleQuery();
		},
		handleCommand(cmd) {
			if (cmd.cmd === "add") {
				this.handleAdd(cmd.type, cmd.item)
			} else if (cmd.cmd === "edit") {
				this.handleUpdate(cmd.item)
			} else if (cmd.cmd === "delete") {
				this.handleDelete(cmd.item)
			}
		},
		makeCommand(cmd, item, type) {
			return {
				cmd: cmd,
				type: type,
				item: item,
			};
		},
		
		handleClickItem(node, data) {
			console.log(node, data)
			this.selectedItem = data
			this.selectedName = this.getItemFullName(this.selectedItem);
			this.selectedHasChildren = node.childNodes && node.childNodes.length > 0
			this.handleQuery();
		},

		nodeExpand(e1, e2) {
			let index = -1
			for (let i = 0; i < this.defaultExpandedKeys.length; i++) {
				if (this.defaultExpandedKeys[i] == e1.id) {
					index = i
					break
				}
			}
			if (index < 0) {
				this.defaultExpandedKeys.push(e1.id)
			}
		},

		nodeCollapse(e1, e2) {
			let index = -1
			for (let i = 0; i < this.defaultExpandedKeys.length; i++) {
				if (this.defaultExpandedKeys[i] == e1.id) {
					index = i
					break
				}
			}
			if (index >= 0) {
				this.defaultExpandedKeys.splice(index, 1)
			}
		},
		
		filterNode(value, data) {
			if (!value) return true;
			return data.name.indexOf(value) !== -1;
		},

		formatParent(e) {
			if (e.row.pid) {
				for (let i = 0; i < this.allDataList.length; i++) {
					const d = this.allDataList[i]
					if (d.id == e.row.pid) {
						return this.getItemFullName(d)
					}
				}
			}
			return ''
		},

		normalizerXqTreeNode(e) {
			e.label = e.name
			return e
		},

		onExportXqChange(e) {
			if (e.type == '1') {
				this.exportBedForm.xq_id = e.id
				this.exportBedForm.ssq_id = undefined
				this.exportBedForm.building_id = undefined
			} else if (e.type == '2') {
				this.exportBedForm.xq_id = undefined
				this.exportBedForm.ssq_id = e.id
				this.exportBedForm.building_id = undefined
			} else if (e.type == '3') {
				this.exportBedForm.xq_id = undefined
				this.exportBedForm.ssq_id = undefined
				this.exportBedForm.building_id = e.id
			}
		}
	},
	watch: {
      filterText(val) {
        this.$refs.tree.filter(val);
      }
    }
};
</script>
<style scoped>
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
  border-radius: 6px;
}
::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 0, 0, 0.05);
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.1);
}
.tree-node {
  font-size: 14px;
}
.tree-node-leaf {
  -webkit-user-drag: element;
  user-select: none;
}
.tree-node-root {
  user-select: none;
}
.custom-tree-node {
  width: 100%;
  display: flex;
  align-items: center;
  height: 26px;
  padding-right: 5px;
}
.custom-tree-node-img {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}
.custom-tree-node-label {
  flex: 1;
  width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.custom-tree-node-button {
  display: none;
}
.custom-tree-node:hover .custom-tree-node-button {
  display: inline-block;
}
.collapse-title {
  margin-left: 10px;
}
.collapse-content {
  padding-left: 10px;
  padding-right: 10px;
}
.mini-button {
  margin-left: 0px;
  padding: 3px;
}
/*.el-input-number {
  width: 100%;
}*/
.left-container {
	padding-right: 20px;
}
.left {
	width: 100%;
	height: calc(100vh - 195px);
	border-right: solid 1px #eee;
	padding: 0px 20px 0px 0px;
	display: flex;
	flex-direction: column;
}
.left-top {
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.filter {
	margin-bottom: 10px;
}
.left-middle {
	overflow-y: auto;
}
.right-top {
	height: 50px;
}
</style>