<template>
    <div class="app-container">
        <el-row :gutter="20">
            <!--机构数据-->
            <el-col :span="5" :xs="24">
                <div class="head-container">
                    <el-input
                        v-model="name"
                        placeholder="请输入指标名称"
                        clearable
                        size="small"
                        prefix-icon="el-icon-search"
                        style="margin-bottom: 20px"
                    />
                </div>
                <div class="head-container">
                    <el-tree
                        :data="deptOptions"
                        :props="defaultProps"
                        :highlight-current="true"
                        :indent="10"
                        :expand-on-click-node="false"
                        :filter-node-method="filterNode"
                        ref="tree"
                        default-expand-all
                        @node-click="handleNodeClick"
                    />
                </div>
            </el-col>
            <!--数据-->
            <el-col v-if="index_id != 0" :span="19" :xs="24">
                <el-form
                    :model="queryParams"
                    ref="queryForm"
                    :inline="true"
                    label-width="68px"
                >
                    <el-form-item
                        v-for="(item, index) in queryList"
                        :label="item.column_comment"
                        :prop="item.column_name"
                        :key="index"
                    >
                        <el-input
                            v-model="queryParams[item.column_name]"
                            :placeholder="item.column_comment"
                            clearable
                            size="small"
                            style="width: 250px"
                            @keyup.enter.native="handleQuery"
                        />
                    </el-form-item>

                    <el-form-item>
                        <el-button
                            type="primary"
                            icon="el-icon-search"
                            size="mini"
                            @click="handleQuery"
                            >搜索
                        </el-button>
                        <el-button
                            icon="el-icon-refresh"
                            size="mini"
                            @click="resetQuery"
                            >重置</el-button
                        >
                    </el-form-item>
                </el-form>

                <el-form :inline="true" label-width="68px">
                    <el-form-item label="当前学年">
                        <el-input
                            :disabled="true"
                            placeholder=""
                            v-model="activ_ptur.name"
                        />
                    </el-form-item>

                    <el-form-item label="当前学期">
                        <el-input
                            :disabled="true"
                            placeholder=""
                            v-model="activ_tur.name"
                        />
                    </el-form-item>
                </el-form>

                <vxe-grid
                    resizable
                    ref="xGrid"
                    stripe
                    show-overflow
                    highlight-hover-row
                    :loading="loading"
                    :toolbar-config="tableToolbar"
                    :columns="tableColumn"
                    :data="dataList"
                    @checkbox-change="checkboxChangeEvent"
                    @checkbox-all="checkboxChangeEvent"
                    @cell-dblclick="Oncelldblclick"
                >
                    <template #toolbar_buttons>
                        <el-button
                            style="margin-left: 10px"
                            type="primary"
                            icon="el-icon-plus"
                            size="mini"
                            @click="handleAdd"
                            v-hasPermi="[
                                '/growthindicators/gyxy/indexform/add',
                            ]"
                            >新增</el-button
                        >
                        <el-button
                            type="success"
                            icon="el-icon-edit"
                            size="mini"
                            :disabled="single"
                            @click="handleUpdate"
                            v-hasPermi="[
                                '/growthindicators/gyxy/indexform/get',
                                '/growthindicators/gyxy/indexform/edit',
                            ]"
                            >修改</el-button
                        >
                        <el-button
                            type="danger"
                            icon="el-icon-delete"
                            size="mini"
                            :disabled="multiple"
                            @click="handleDelete"
                            v-hasPermi="[
                                '/growthindicators/gyxy/indexform/del',
                            ]"
                            >删除</el-button
                        >
                        <el-button
                            type="warning"
                            icon="el-icon-file"
                            size="mini"
                            v-if="canupload"
                            @click="handleImport"
                            v-hasPermi="[
                                '/growthindicators/gyxy/indexform/import',
                            ]"
                            >导入</el-button
                        >
                        <el-button
                            type="warning"
                            icon="el-icon-download"
                            size="mini"
                            @click="handleExport"
                            v-hasPermi="[
                                '/growthindicators/gyxy/indexform/exportdata',
                            ]"
                            >导出</el-button
                        >

                        <!--<el-button-->
                        <!--type="warning"-->
                        <!--icon="el-icon-file"-->
                        <!--size="mini"-->
                        <!--@click="handleExport"-->
                        <!--v-hasPermi="['system/auth/addUser']"-->
                        <!--&gt;导出</el-button>-->
                    </template>
                    <template #status="{ row }">
                        <el-switch
                            v-model="row.status"
                            active-value="1"
                            inactive-value="0"
                            @change="handleStatusChange(row)"
                        ></el-switch>
                    </template>

                    <template #roles="{ row }">
                        <span v-for="(item, index) of row.roles" :key="index">
                            {{ item.name }}
                        </span>
                    </template>

                    <!--默认操作按钮-->
                    <template #defaultopr="{ row }">
                        <el-button
                            size="mini"
                            type="text"
                            icon="el-icon-edit"
                            v-hasPermi="[
                                '/growthindicators/gyxy/indexform/get',
                            ]"
                            @click="Oncelldblclick({ row })"
                            >查看
                        </el-button>

                        <el-button
                            size="mini"
                            type="text"
                            icon="el-icon-edit"
                            @click="handleUpdate(row)"
                            v-hasPermi="[
                                '/growthindicators/gyxy/indexform/get',
                                '/growthindicators/gyxy/indexform/edit',
                            ]"
                            >修改
                        </el-button>
                        <el-button
                            v-if="row.id !== 1"
                            size="mini"
                            type="text"
                            icon="el-icon-delete"
                            v-hasPermi="[
                                '/growthindicators/gyxy/indexform/del',
                            ]"
                            @click="handleDelete(row)"
                            >删除
                        </el-button>
                    </template>

                    <!--自定义空数据模板-->
                    <template #empty>
                        <span>
                            <p>暂无数据</p>
                        </span>
                    </template>
                </vxe-grid>
                <vxe-pager
                    background
                    size="small"
                    :loading="loading"
                    :current-page="queryParams.pageNum"
                    :page-size="queryParams.pageSize"
                    :total="total"
                    @page-change="handleSizeChange"
                >
                </vxe-pager>
            </el-col>
        </el-row>

        <!-- 添加或修改对话框 -->
        <el-dialog
            :title="title"
            :visible.sync="open"
            width="800px"
            :close-on-click-modal="false"
            append-to-body
            custom-class="scrolldialog"
        >
            <el-form
                ref="form"
                :disabled="isview"
                :model="form"
                :rules="rules"
                label-width="80px"
            >
                <el-row>
                    <el-col
                        v-for="(item, index) in inserteditList"
                        :span="item.column_name == 'stdname' ? 24 : 10"
                        :key="index"
                    >
                        <el-form-item
                            :label="item.column_comment"
                            :prop="item.column_name"
                        >
                            <el-input
                                v-if="item.html_type == 'input'"
                                :disabled="
                                    item.column_name == 'stdname' ||
                                    item.column_name == 'major_name' ||
                                    item.column_name == 'college_name' ||
                                    item.column_name == 'classes_no' ||
                                    item.column_name == 'student_no' ||
                                    item.column_name == 'grade'
                                        ? true
                                        : false
                                "
                                v-model="form[item.column_name]"
                                :placeholder="item.column_comment"
                            >
                                <el-button
                                    v-if="item.column_name == 'stdname'"
                                    slot="append"
                                    icon="el-icon-search"
                                    @click="selstu"
                                ></el-button>
                            </el-input>

                            <el-date-picker
                                v-if="item.html_type == 'datetime'"
                                clearable
                                size="small"
                                style="width: 200px"
                                v-model="form[item.column_name]"
                                type="date"
                                value-format="yyyy-MM-dd"
                                :placeholder="'选择' + item.column_comment"
                            >
                            </el-date-picker>

                            <el-input
                                v-if="item.html_type == 'textarea'"
                                v-model="form[item.column_name]"
                                :placeholder="'请输入' + item.column_comment"
                                type="textarea"
                                :rows="5"
                            />

                            <el-select
                                v-if="
                                    item.html_type == 'select' &&
                                    item.dict_type == 'gyxy_actrecord_list'
                                "
                                v-model="form[item.column_name]"
                                placeholder="请选择"
                                clearable
                                size="small"
                            >
                                <el-option
                                    v-for="dict in actrecordListData"
                                    :key="dict.actname"
                                    :label="dict.actname"
                                    :value="dict.actname"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button
                    v-if="isview == false"
                    type="primary"
                    @click="submitForm"
                    >确 定</el-button
                >
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>

        <!-- 选择学生 -->
        <el-dialog
            title="选择学生"
            :visible.sync="openselstu"
            width="1100px"
            :close-on-click-modal="false"
            append-to-body
        >
            <el-form
                :model="stuqueryParams"
                ref="stuqueryForm"
                :inline="true"
                label-width="68px"
            >
                <el-form-item label="学号" prop="no">
                    <el-input
                        v-model="stuqueryParams.no"
                        placeholder="请输入学号"
                        clearable
                        size="small"
                        @keyup.enter.native="handleQuery"
                    />
                </el-form-item>
                <el-form-item label="姓名" prop="name">
                    <el-input
                        v-model="stuqueryParams.name"
                        placeholder="请输入姓名"
                        clearable
                        size="small"
                        @keyup.enter.native="handleQuery"
                    />
                </el-form-item>
                <el-form-item label="所属院系" prop="dept_no">
                    <dept-select
                        v-model="stuqueryParams.dept_no"
                        placeholder="请选择所属院系"
                        valueField="no"
                        size="small"
                    />
                </el-form-item>
                <el-form-item label="所属专业" prop="major_no">
                    <major-select
                        v-model="stuqueryParams.major_no"
                        placeholder="请选择所属专业"
                        :dept_no="queryParams.dept_no"
                        valueField="no"
                        size="small"
                    />
                </el-form-item>
                <el-form-item label="所属班级" prop="classes_no">
                    <el-input
                        v-model="stuqueryParams.classes_no"
                        placeholder="请输入所属班级"
                        clearable
                        size="small"
                    />
                </el-form-item>

                <el-form-item>
                    <el-button
                        type="cyan"
                        icon="el-icon-search"
                        size="mini"
                        @click="handleQuerystu"
                        >搜索</el-button
                    >
                    <el-button
                        icon="el-icon-refresh"
                        size="mini"
                        @click="resetQuery"
                        >重置</el-button
                    >
                </el-form-item>
            </el-form>

            <vxe-grid
                resizable
                ref="xGrid_stu"
                row-id="id"
                stripe
                show-overflow
                highlight-hover-row
                :loading="loadingstu"
                :columns="selstuColumn"
                :data="studataList"
            >
                <template #defaultopr="{ row }">
                    <el-button
                        size="mini"
                        type="text"
                        icon="el-icon-edit"
                        @click.stop="handleselstu(row)"
                        >选择
                    </el-button>
                </template>

                <template #empty>
                    <span>
                        <p>暂无数据</p>
                    </span>
                </template>
            </vxe-grid>

            <vxe-pager
                background
                size="small"
                :loading="loadingstu"
                :current-page.sync="stuqueryParams.pageNum"
                :page-size.sync="stuqueryParams.pageSize"
                :total="totalstu"
                @page-change="handlePageChangestu"
            >
            </vxe-pager>
        </el-dialog>
        <!-- 导入对话框 -->
        <el-dialog
            :close-on-click-modal="false"
            :title="upload.title"
            :visible.sync="upload.open"
            width="400px"
            append-to-body
        >
            <el-upload
                ref="upload"
                :limit="1"
                accept=".xlsx"
                :headers="upload.headers"
                :action="
                    upload.url +
                    '?updateSupport=' +
                    upload.updateSupport +
                    '&index_id=' +
                    index_id +
                    '&lecturetitle=' +
                    lecturetitle
                "
                :disabled="upload.isUploading"
                :on-progress="handleFileUploadProgress"
                :on-success="handleFileSuccess"
                :auto-upload="false"
                drag
            >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                    将文件拖到此处，或
                    <em>点击上传</em>
                </div>
                <div class="el-upload__tip" slot="tip">
                    <el-checkbox
                        true-label="1"
                        false-label="0"
                        v-model="upload.updateSupport"
                    />是否更新已经存在的数据
                    <el-link
                        type="info"
                        style="font-size: 12px"
                        @click="importTemplate"
                        >下载模板</el-link
                    >
                </div>

                <div class="el-upload__tip" style="color: red" slot="tip">
                    提示：仅允许导入“xlsx”格式文件！
                </div>

                <div
                    v-if="actrecordListData.length > 0"
                    class="el-upload__tip"
                    slot="tip"
                >
                    选择活动:
                    <el-select
                        placeholder="请选择"
                        v-model="lecturetitle"
                        clearable
                        size="small"
                    >
                        <el-option
                            v-for="dict in actrecordListData"
                            :key="dict.actname"
                            :label="dict.actname"
                            :value="dict.actname"
                        />
                    </el-select>
                </div>
            </el-upload>
            <div slot="footer" class="dialog-footer">
                <el-button
                    type="primary"
                    :loading="upload.isUploading"
                    @click="submitFileForm"
                    >{{
                        upload.isUploading ? "上 传 中..." : "上 传"
                    }}</el-button
                >
                <el-button
                    :disabled="upload.isUploading"
                    @click="upload.open = false"
                    >返 回</el-button
                >
            </div>
        </el-dialog>

        <el-dialog
            :title="showerr_til"
            :close-on-click-modal="false"
            :visible.sync="showerrinfo"
            width="1000px"
            append-to-body
            custom-class="scrolldialog"
        >
            <p v-for="(item, idx) in upload_errinfo" :key="idx">{{ item }}</p>
        </el-dialog>
    </div>
</template>

<script>
import {
    listUser,
    getUser,
    getEditUser,
    delUser,
    addUser,
    updateUser,
    exportUser,
    resetUserPwd,
    changeUserStatus,
} from "@/api/core/system/user";
import Qs from 'qs';
import { listActrecord } from "@/api/growthindicators/gyxy/actrecord";

import { listStuTurn } from "@/api/stu/stu_turn";

import { getToken } from "@/utils/auth";
import {
    dataList,
    datacolList,
    dataAdd,
    dataGet,
    dataEdit,
    dataDel,
    importTemplate,
    Indexlist,
} from "@/api/growthindicators/gyxy/indexform";
import { treeselect } from "@/api/core/system/dept";
import Treeselect from "@riophae/vue-treeselect";
import { mapGetters } from "vuex";
import { listIndexs } from "@/api/growthindicators/gyxy/indexs";

import "@riophae/vue-treeselect/dist/vue-treeselect.css";

import { listStuStudent } from "@/api/stu/stu_student";

export default {
    name: "Indexform",
    components: { Treeselect },
    data() {
        return {
            upload_errinfo: [],
            showerrinfo: false,
            showerr_til: "导入结果",
            isview: false,
            canupload: false,
            lecturetitle: "",
            selact_upload: "",
            actrecordListData: [],
            activ_ptur: {},
            activ_tur: {},
            loadingstu: false,
            openselstu: false,
            // 工具栏
            tableToolbar: {
                perfect: true,
                zoom: true,
                custom: true,
                refresh: {
                    query: this.handleQuery,
                },
                slots: {
                    buttons: "toolbar_buttons",
                },
            },
            // 列
            tableColumn: [
                { type: "checkbox", width: 60, fixed: "left" },
                { field: "id", title: "ID", width: 100, fixed: "left" },

                {
                    field: "",
                    title: "操作",
                    resizable: false,
                    width: 180,
                    fixed: "right",
                    align: "center",
                    slots: { default: "defaultopr" },
                },
            ],
            selstuColumn: [
                {
                    field: "id",
                    title: "id",
                    minWidth: 120,
                    fixed: "left",
                    visible: false,
                },
                { field: "no", title: "学号", minWidth: 120 },
                { field: "name", title: "姓名", minWidth: 120 },
                { field: "dept_name", title: "所属院系", minWidth: 120 },
                { field: "major_name", title: "所属专业", minWidth: 120 },
                { field: "classes_no", title: "所属班级", minWidth: 120 },
                {
                    field: "grade",
                    title: "所属年级",
                    minWidth: 120,
                    formatter: this.dictFormat,
                    dictType: "stu_grade",
                },
                {
                    field: "",
                    title: "操作",
                    width: 180,
                    fixed: "right",
                    align: "center",
                    slots: { default: "defaultopr" },
                },
            ],
            stuqueryParams: {
                pageNum: 1,
                pageSize: 10,
                id: undefined,
                no: undefined,
                name: undefined,
                dept_no: undefined,
                major_no: undefined,
                classes_no: undefined,
            },
            studataList: [],

            orgcolumnList: [],
            // 遮罩层
            loading: false,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 总条数
            total: 0,
            totalstu: 0,
            // 表格数据
            dataList: [],
            // 弹出层标题
            title: "",
            // 机构树选项
            deptOptions: undefined,
            // 是否显示弹出层
            open: false,
            // 机构名称
            name: undefined,
            // 默认密码
            initPassword: undefined,
            // 日期范围
            dateRange: [],
            // 状态数据字典
            statusOptions: [],
            // 性别状态字典
            genderOptions: [],
            typeOptions: [],
            // 岗位选项
            postOptions: [],
            // 角色选项
            roleOptions: [],
            // 表单参数
            form: {},
            defaultProps: {
                children: "children",
                label: "name",
            },
            // 用户导入参数
            upload: {
                // 是否显示弹出层（用户导入）
                open: false,
                // 弹出层标题（用户导入）
                title: "",
                // 是否禁用上传
                isUploading: false,
                // 是否更新已经存在的用户数据
                updateSupport: 0,
                // 设置上传的请求头部
                headers: { Authorization: getToken() },
                // 上传的地址
                url:
                    process.env.VUE_APP_BASE_API +
                    "/growthindicators/gyxy/indexform/import",
            },
            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                opstr: {},
            },
            index_id: 0,
            // 表单校验
            rules: {},
        };
    },

    computed: {
        inserteditList: function () {
            return this.orgcolumnList.filter((item) => {
                return item.is_insert == "1" || item.is_edit == "1";
            });
        },
        ...mapGetters(["uid", "roles_id"]),
        queryList: function () {
            return this.orgcolumnList.filter((item) => {
                return item.is_query == "1"||item.column_name=="college_name";
            });
        },
    },
    watch: {
        // 根据名称筛选机构树
        name(val) {
            this.$refs.tree.filter(val);
        },
    },
    created() {
        //console.log("roles_id", this.roles_id);

        this.getDicts("stu_gender").then((response) => {
            if (response.code == 0) {
                //console.log("stu_gender", response.data);
                this.genderOptions = response.data;
            }
        });

        for (let i = 0; i < this.selstuColumn.length; i++) {
            const col = this.selstuColumn[i];
            if (col.dictType) {
                this.getDicts(col.dictType).then((response) => {
                    if (response.code == 0) {
                        col.dictData = response.data;
                        this.dataList = [].concat(this.dataList);
                    }
                });
            }
        }

        // this.getList();
        this.getTreeselect();
        var tparam = {
            pageNum: 1,
            pageSize: 10,
        };

        listStuTurn(tparam).then((response) => {
            this.turdata = response.data.list;
            var tur_pid = {};
            var tur_id = [];

            var hasfound = false;
            for (var i in response.data.list) {
                var item = response.data.list[i];

                if (item.pid == 0 && item.status == "1" && hasfound == false) {
                    tur_pid = item;
                    hasfound = true;
                }
                if (hasfound && item.pid == tur_pid.id) {
                    tur_id.push(item);
                    if (item.status == "1") {
                        this.activ_tur = item;
                        break;
                    }
                }
            }
            this.activ_ptur = tur_pid;
        });
    },
    methods: {
        getrolefilter(ty) {
            if (ty == 1) {
                return this.uid + "";
            }
            var adminrole = [1, 2, 5, 12, 13];
            for (var i in this.roles_id) {
                var r = this.roles_id[i];
                //console.log("r",r,adminrole.indexOf(r.id))
                if (adminrole.indexOf(r.id) != -1) {
                    return undefined;
                }
            }
            return this.uid + "";
        },
        //查看
        Oncelldblclick(row) {
            this.handleUpdate(row.row, "查看");
            this.isview = true;
        },

        //获取活动
        loadactlist(indexid) {
            var tparamact = {
                pageNum: 1,
                pageSize: 1000,
                flow_inst_status: "2",
                indexid: indexid + "",
                tur_no: this.activ_tur.no,
                create_by: this.getrolefilter(1),
            };
            //console.log("tparamact", tparamact);

            listActrecord(tparamact).then((response) => {
                this.actrecordListData = response.data.list;
            });
        },
        genderFormat({ cellValue }) {
            return this.selectDictLabel(
                this.genderOptions.values,
                "" + cellValue
            );
        },

        handlePageChangestu({ currentPage, pageSize }) {
            if (this.stuqueryParams.pageSize == pageSize) {
                this.getstuList();
            } else {
                this.stuqueryParams.pageSize = pageSize;
                this.handleQuerystu();
            }
        },
        selstu() {
            this.getstuList();
            this.openselstu = true;
        },
        handleQuerystu() {
            this.stuqueryParams.pageNum = 1;
            this.getstuList();
        },
        handleselstu(row) {
            this.openselstu = false;
            this.form["college_id"] = row.dept_no;
            this.form["college_name"] = row.dept_name;
            this.form["major_id"] = row.major_no;
            this.form["major_name"] = row.major_name;
            this.form["grade"] = row.grade;
            // this.form["gender"] = this.selectDictLabel(
            //   this.genderOptions.values,
            //   "" + row.gender
            // );
            this.form["classes_no"] = row.classes_no;
            this.form["student_no"] = row.no;
            this.form["stdname"] = row.name;
            //console.log("row", row);
        },
        getstuList() {
            this.loadingstu = true;
            this.ids = [];
            this.single = true;
            this.multiple = false;
            const queryParams = this.stuqueryParams;
            //      if (Object.keys(queryParams.formExt).length > 0) {
            //        queryParams.form.ext = JSON.stringify(queryParams.formExt)
            //      }
            listStuStudent(queryParams).then((response) => {
                this.studataList = response.data.list;
                this.totalstu = response.data.total;
                this.loadingstu = false;
            });
        },

        dictFormat(e) {
            const col = this.selstuColumn[e.columnIndex];
            if (col.dictData) {
                const values = e.cellValue ? e.cellValue.split(",") : [];
                const labels = [];
                for (let i = 0; i < values.length; i++) {
                    const v = values[i];
                    for (let j = 0; j < col.dictData.values.length; j++) {
                        const item = col.dictData.values[j];
                        if (v == item.key) {
                            labels.push(item.value);
                            break;
                        }
                    }
                }
                return labels.join(",");
            }
            return e.cellValue;
        },

        handleSizeChange({ currentPage, pageSize }) {
            this.queryParams.pageNum = currentPage;
            if (this.queryParams.pageSize == pageSize) {
                this.getList(this.index_id);
            } else {
                this.queryParams.pageSize = pageSize;
                this.handleQuery();
            }
        },
        checkboxChangeEvent({ records }) {
            this.ids = records.map((item) => item.id);
            this.single = records.length != 1;
            this.multiple = !records.length;
        },
        handleExport() {
            this.$confirm("确定导出数据吗?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.queryParams["create_by"] = this.getrolefilter(0);
                this.queryParams["extfilter"] = "admin";
                this.queryParams["turid"] = this.activ_tur.id+""
                this.queryParams["extinfo"] = { data_scope: "1" };
                var downloadurl = dataList(this.index_id, this.queryParams,"/growthindicators/gyxy/indexform/exportdata")
                console.log("downloadurl", downloadurl);
                this.downloadbyurl(downloadurl);
            });
        },

        /** 查询用户列表 */
        getList(index_id) {
            //console.log("this.$router)", this.$router);
            this.loading = true;
            this.queryParams["create_by"] = this.getrolefilter(0);
            this.queryParams["turid"] = this.activ_tur.id+""
            this.queryParams["extfilter"] = "admin";
            this.queryParams["extinfo"] = { data_scope: "1" };
            dataList(index_id, this.queryParams)
                .then((response) => {
                    var data = JSON.parse(response.data.list);
                    this.total = response.data.total;
                    var rldata = [];
                    for (var i in data) {
                        var item = data[i];
                        item["tur_name"] = this.activ_tur.name;
                        rldata.push(item);
                    }
                    this.dataList = rldata;
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                    this.msgError("加载数据出错,请先检查是否配置了表单");
                });

            //      listUser(this.addDateRange(this.queryParams, this.dateRange)).then(response => {
            //        let list = []
            //        if(response.data.list) {
            //          response.data.list.forEach(item=>{
            //            item.status = ''+item.status
            //            list.push(item)
            //          })
            //        }
            //        this.single = true;
            //        this.multiple = true;
            //        this.userList = list;
            //        this.total = response.data.total;
            //        this.statusOptions = response.data.statusOptions;
            //        this.genderOptions = response.data.genderOptions;
            //        this.typeOptions = response.data.typeOptions;
            //        this.loading = false;
            //      });
        },
        /** 查询指标下拉树结构 */
        getTreeselect() {
            Indexlist({}).then((response) => {
                var tlist = response.data.list;
                const filterlist = tlist.filter((one) => {
                    return !this.checkisper(one.name);
                });

                this.deptOptions = this.handleTree(filterlist, "id", "pid");
            });
        },
        // 筛选节点
        filterNode(value, data) {
            // console.log(value, data);
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        checkisper(indexname) {
            return (
                indexname.indexOf("出勤率") != -1 ||
                indexname.indexOf("学习率") != -1 ||
                indexname.indexOf("达标率") != -1
            );
        },
        // 节点单击事件
        handleNodeClick(data) {
            if (!data.children || data.children.length == 0) {
                this.getcolinfo(data.id);
                if (this.checkisper(data.name)) {
                    this.canupload = false;
                } else {
                    this.canupload = true;
                }
            }
        },
        getcolinfo(index_id) {
            this.dataList = [];
            this.index_id = index_id;
            this.loadactlist(index_id);
            datacolList(index_id)
                .then((rs) => {
                    var collist = rs.data.list;

                    this.orgcolumnList = collist;
                    var tableColumn = [];
                    var oprstrar = {};
                    var rules = {};

                    tableColumn.push({
                        type: "checkbox",
                        width: 60,
                        fixed: "left",
                    });
                    // tableColumn.push({field: 'id', title: 'ID', width: 60, fixed: "left"});
                    for (var i in collist) {
                        var col = collist[i];
                        var colobj = {};
                        if (col.is_list == "1") {
                            colobj = {
                                field: col.column_name,
                                title: col.column_comment,
                                minWidth: 100,
                            };
                            if (col.html_type == "datetime") {
                                if (col.column_name.indexOf("time") != -1) {
                                    colobj["formatter"] = "formatDate";
                                } else {
                                    colobj["formatter"] = [
                                        "formatDate",
                                        "yyyy-MM-dd",
                                    ];
                                }
                            }
                            // if (col.column_name == "gender") {
                            //   colobj["formatter"] = this.genderFormat;
                            // }
                            tableColumn.push(colobj);
                        }

                        if (col.is_query == "1") {
                            oprstrar[col.column_name] = col.query_type;
                        }

                        if (col.is_required == "1") {
                            rules[col.column_name] = [
                                {
                                    required: true,
                                    message: col.column_comment + "不能为空",
                                    trigger: "blur",
                                },
                            ];
                        }
                    }
                    this.queryParams.opstr = oprstrar;
                    this.rules = rules;

                    tableColumn.push({
                        field: "",
                        title: "操作",
                        resizable: false,
                        width: 180,
                        fixed: "right",
                        align: "center",
                        slots: { default: "defaultopr" },
                    });

                    this.tableColumn = tableColumn;
                    //console.log("collist", collist);
                })
                .then(() => {
                    this.queryParams.pageNum = 1;
                    //        this.queryParams.dept_id = data.id;
                    this.getList(index_id);
                });
        },
        // 用户状态修改
        handleStatusChange(row) {
            let text = row.status === "1" ? "启用" : "停用";
            this.$confirm(
                '确认要"' + text + '""' + row.username + '"用户吗?',
                "警告",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            )
                .then(function () {
                    return changeUserStatus(row.id, row.status);
                })
                .then(() => {
                    this.msgSuccess(text + "成功");
                })
                .catch(function () {
                    row.status = row.status === "0" ? "1" : "0";
                });
        },
        // 取消按钮
        cancel() {
            this.open = false;
            this.reset();
        },
        // 表单重置
        reset() {
            this.form = {};
            this.resetForm("form");
            this.isview = false;
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList(this.index_id);
        },
        /** 重置按钮操作 */
        resetQuery() {
            // this.queryParams.dept_id = 0;
            this.dateRange = [];
            this.resetForm("queryForm");
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map((item) => item.id);
            this.single = selection.length != 1;
            this.multiple = !selection.length;
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.reset();
            this.open = true;
            this.title = "添加数据";
        },
        /** 修改按钮操作 */
        handleUpdate(row, til) {
            this.reset();
            //this.getTreeselect();
            const id = row.id || this.ids[0];
            dataGet(this.index_id, id).then((response) => {
                var data = JSON.parse(response.data.list);
                // console.log(data);
                var tardata = data["0"] || {};
                for (var ti in this.orgcolumnList) {
                    var titem = this.orgcolumnList[ti];
                    if (titem.is_insert == "1" || titem.is_edit == "1") {
                        if (
                            titem.html_type == "datetime" &&
                            tardata[titem.column_name]
                        ) {
                            tardata[titem.column_name] =
                                this.unixTimestamptoDate(
                                    tardata[titem.column_name],
                                    "yyyy-MM-dd"
                                );
                        }
                    }
                }

                this.form = tardata;
                // this.form["gender"] = this.selectDictLabel(
                //   this.genderOptions.values,
                //   "" + row.gender
                // );

                this.open = true;
                this.title = til || "修改";
            });
        },

        /** 提交按钮 */
        submitForm: function () {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    for (var ti in this.orgcolumnList) {
                        var titem = this.orgcolumnList[ti];
                        if (titem.is_insert == "1" || titem.is_edit == "1") {
                            if (
                                titem.html_type == "datetime" &&
                                this.form[titem.column_name]
                            ) {
                                this.form[titem.column_name] =
                                    this.DatetounixTimestamp(
                                        this.form[titem.column_name]
                                    );
                            }
                        }
                    }
                    //          this.form.post_ids = this.form.postIds.join(',')
                    //          this.form.role_ids = this.form.roleIds.join(',')
                    if (this.form.id != undefined) {
                        dataEdit(this.index_id, this.form.id, this.form).then(
                            (response) => {
                                if (response.code === 0) {
                                    this.msgSuccess("修改成功");
                                    this.open = false;
                                    this.handleQuery();
                                } else {
                                    this.msgError(response.msg);
                                }
                            }
                        );
                    } else {
                        dataAdd(this.index_id, this.form).then((response) => {
                            if (response.code === 0) {
                                this.msgSuccess("新增成功");
                                this.open = false;
                                this.handleQuery();
                            } else {
                                this.msgError(response.msg);
                            }
                        });
                    }
                }
            });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            const did = row.id || (this.ids ? this.ids.join(",") : "");
            this.$confirm('是否确认删除编号为"' + did + '"的数据项?', "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    return dataDel(this.index_id, did);
                })
                .then(() => {
                    this.handleQuery();
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },

        /** 导入按钮操作 */
        handleImport() {
            //      this.msgSuccess("敬请期待...");
            //      return false;

            this.upload.title = "数据导入";
            this.upload.open = true;
        },
        /** 下载模板操作 */
        importTemplate() {
            importTemplate(this.index_id).then((response) => {
                this.download(response.data.file);
            });
        },
        // 文件上传中处理
        handleFileUploadProgress(event, file, fileList) {
            this.upload.isUploading = true;
            this.upload_errinfo = [];
        },
        // 文件上传成功处理
        handleFileSuccess(response, file, fileList) {
            this.upload.open = false;
            this.upload.isUploading = false;
            this.$refs.upload.clearFiles();

            if (
                response.code == 0 &&
                response.data.Ttc > 0 &&
                response.data.Ttc == response.data.Susscount
            ) {
                this.$alert(
                    response.msg == "success" ? "操作成功" : response.msg,
                    "导入结果",
                    { dangerouslyUseHTMLString: true }
                );
            } else {
                this.showerrinfo = true;
                this.showerr_til = `导入结果返回(总共:${
                    response.data.Ttc
                },导入成功:${response.data.Susscount},导入失败:${
                    response.data.Ttc - response.data.Susscount
                })`;
                this.upload_errinfo = response.data.Errinfo.split("|");
            }

            this.handleQuery();
        },
        // 提交上传文件
        submitFileForm() {
            if (this.lecturetitle == "") {
                this.msgError("请选择活动!");
                return false;
            }
            if (this.upload.isUploading) {
                this.msgError("正在上传,请稍候!");
                return false;
            }
            this.$refs.upload.submit();
        },
        normalizerDeptTreeNode(e) {
            //      e.id = e.id
            e.label = e.name;
            return e;
        },
    },
};
</script>
<style rel="stylesheet/scss"  scoped >
.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
    background-color: #2196f3;
    color: #fff;
}
::v-deep .scrolldialog {
    max-height: 80vh;
}
/* .el-dialog__body {
    max-height: calc(100vh - 300px);
    overflow-y: auto;
} */
</style>