@import "@/styles/variables.scss"; 
















































































































































































































































































































































































































































.option-items-pane ul {
    padding-inline-start: 6px;
    padding-left: 6px; /* 重置IE11默认样式 */
}

li.ghost {
    background: #fff;
    border: 2px dotted $--color-primary;
}

.drag-option {
    cursor: move;
}

.small-padding-dialog ::v-deep .el-dialog__body {
    padding: 10px 15px;
}

.dialog-footer .el-button {
    width: 100px;
}
