@import "@/styles/variables.scss"; 

































































































.qz-svg-container {
  position: absolute;
  top: 14px;
  left: 5px;
  z-index: $base-z-index;
  font-size: 16px;
  color: #808080;
  cursor: pointer;
  user-select: none;
}

.qz-show-password {
  position: absolute;
  top: 14px;
  right: 25px;
  font-size: 16px;
  color: #808080;
  cursor: pointer;
  user-select: none;
}

.qz-code-img {
  position: absolute;
  right: 0;
  top: 14px;
  width: 120px;
  height: 40px;
  cursor: pointer;
}

.qz-remember-password {
  margin-bottom: 20px;
  display: flex;
  margin-left: 10px;
}
