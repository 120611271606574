<template>
    <div class="app-container">
        权限
    </div>
</template>

<script>
import { listUserScoreConfig, editUserScoreConfigList } from '@/api/core/system/userscore'
export default {
    data() {
        return {
        }
    },
    created() {
    },
    methods: {
        
    }
}
</script>

<style scoped>
.app-container {
    display: flex;
    justify-content: center;
}
</style>