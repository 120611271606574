@import "@/styles/variables.scss"; 























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.text-primary {
    color: #1890ff;
}
