@import "@/styles/variables.scss"; 






















.radio-group-custom {
    ::v-deep .el-radio-button__inner {
        padding-left: 12px;
        padding-right: 12px;
    }
}
