@import "@/styles/variables.scss"; 



























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.previewName {
  text-align: center;
  border-bottom: 1px solid #bbbbbb;
  margin-left: 100px;
  width: 150px;
}
