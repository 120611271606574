<template>
  <div class="app-container student-container">
    <!-- <el-form
            :model="queryParams"
            ref="queryForm"
            :inline="true"
            label-width="68px"
        > -->
    <!--<el-form-item label="id" prop="id">
			<el-input
				v-model="queryParams.id"
				placeholder="请输入id"
				clearable
				size="small"
				@keyup.enter.native="handleQuery"
			/>
		</el-form-item>-->
    <!-- <el-form-item label="学号" prop="no">
                <el-input
                    v-model="queryParams.no"
                    placeholder="请输入学号"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="姓名" prop="name">
                <el-input
                    v-model="queryParams.name"
                    placeholder="请输入姓名"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="院系" prop="dept_no">
                <dept-select
                    v-model="queryParams.dept_no"
                    placeholder="请选择院系"
                    valueField="no"
                    size="small"
                />
            </el-form-item>
            <el-form-item label="专业" prop="major_no">
                <major-select
                    v-model="queryParams.major_no"
                    placeholder="请选择专业"
                    :dept_no="queryParams.dept_no"
                    valueField="no"
                    size="small"
                />
            </el-form-item>
            <el-form-item label="班级" prop="classes_no">
                <el-input
                    v-model="queryParams.classes_no"
                    placeholder="请输入班级"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item label="辅导员" prop="assistant_nos">
                <el-input
                    v-model="queryParams.assistant_nos"
                    placeholder="请输入辅导员姓名或工号"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>

            <el-form-item
                v-for="item in extListSearch"
                :key="item.name"
                :label="item.label"
                :prop="extFieldPrefix + item.name"
            >
                <dict-select
                    v-if="
                        item.type == 'select' ||
                        item.type == 'selectm' ||
                        item.type == 'radio' ||
                        item.type == 'checkbox'
                    "
                    v-model="queryParams[extFieldPrefix + item.name]"
                    :placeholder="'请选择' + item.label"
                    :type="item.ext"
                    :multiple="
                        item.type == 'selectm' || item.type == 'checkbox'
                    "
                    size="small"
                /> -->
    <!--<dict-radio v-else-if="item.type=='radio'"
        v-model="queryParams[extFieldPrefix+item.name]"
        :placeholder="'请选择'+item.label"
        :type="item.ext"
        size="small"
      />
      <dict-checkbox v-else-if="item.type=='checkbox'"
        v-model="queryParams[extFieldPrefix+item.name]"
        :placeholder="'请选择'+item.label"
        :type="item.ext"
        size="small"
      />-->
    <!-- <datetime-select
                    v-else-if="item.type == 'date'"
                    v-model="queryParams[extFieldPrefix + item.name]"
                    type="date"
                    :placeholder="'请选择' + item.label"
                    size="small"
                />
                <datetime-select
                    v-else-if="item.type == 'datetime'"
                    v-model="queryParams[extFieldPrefix + item.name]"
                    type="datetime"
                    :placeholder="'请选择' + item.label"
                    size="small"
                />
                <el-input-number
                    v-else-if="item.type == 'number'"
                    v-model="queryParams[extFieldPrefix + item.name]"
                    :placeholder="'请输入' + item.label"
                    clearable
                    controls-position="right"
                    size="small"
                />
                <el-input
                    v-else
                    v-model="queryParams[extFieldPrefix + item.name]"
                    :placeholder="'请输入' + item.label"
                    clearable
                />
            </el-form-item>

            <el-form-item>
                <el-button
                    type="cyan"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleQuery"
                    >搜索</el-button
                >
                <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                >
            </el-form-item>
        </el-form> -->
    <!-- 
        <vxe-grid
            resizable
            ref="xGrid"
            row-id="id"
            stripe
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="dataList"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent"
        >
            <template #toolbar_buttons>
                <el-button
                    style="margin-left: 10px"
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    v-hasPermi="['/stu/stustudent/add']"
                    >新增</el-button
                >

                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    v-hasPermi="['/stu/stustudent/get', '/stu/stustudent/edit']"
                    >修改</el-button
                >

                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    v-hasPermi="['/stu/stustudent/del']"
                    >删除</el-button
                >

                <el-button
                    type="warning"
                    icon="el-icon-refresh-left"
                    size="mini"
                    @click="handleSyncAccount"
                    v-hasPermi="['/stu/stustudent/syncAccount']"
                    >同步账号</el-button
                >

                <el-button
                    type="warning"
                    icon="el-icon-upload"
                    size="mini"
                    @click="handleImport"
                    v-hasPermi="['/stu/stustudent/import']"
                    >导入</el-button
                >

                <el-button
                    type="warning"
                    icon="el-icon-download"
                    size="mini"
                    @click="handleExport"
                    v-hasPermi="['/stu/stustudent/export']"
                    >导出</el-button
                >
            </template>

            <template #defaultopr="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click.stop="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click.stop="handleDelete(row)"
                    >删除
                </el-button>
            </template>

            <template #empty>
                <span>
                    <p>暂无数据</p>
                </span>
            </template>
        </vxe-grid>

        <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page.sync="queryParams.pageNum"
            :page-size.sync="queryParams.pageSize"
            :total="total"
            @page-change="handlePageChange"
        >
        </vxe-pager> -->
    <jf-table
      :loading="loading"
      :columns="tableColumn"
      :data="dataList"
      :toolbar-config="tableToolbar"
      :query-params="queryParams"
      @checkbox-change="checkboxChangeEvent"
      @page-change="handlePageChange"
      :total="total"
      :dicts-data="dictsData"
      @change="tableChange"
      @handleQuery="handleQuery"
    >
      <template slot="query_qarams_item">
        <el-form-item label="辅导员" prop="assistant_nos">
          <el-input
            v-model="queryParams.assistant_nos"
            placeholder="请输入辅导员姓名或工号"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
          />
        </el-form-item>
        <el-form-item label="院系" prop="dept_no">
          <dept-select
            v-model="queryParams.dept_no"
            placeholder="请选择院系"
            valueField="no"
            size="small"
          />
        </el-form-item>
        <el-form-item label="专业" prop="major_no">
          <major-select
            v-model="queryParams.major_no"
            placeholder="请选择专业"
            :dept_no="queryParams.dept_no"
            valueField="no"
            size="small"
          />
        </el-form-item>
        <el-form-item
          v-for="item in extListSearch"
          :key="item.name"
          :label="item.label"
          :prop="extFieldPrefix + item.name"
        >
          <dict-select
            v-if="
              item.type == 'select' ||
              item.type == 'selectm' ||
              item.type == 'radio' ||
              item.type == 'checkbox'
            "
            v-model="queryParams[extFieldPrefix + item.name]"
            :placeholder="'请选择' + item.label"
            :type="item.ext"
            :multiple="item.type == 'selectm' || item.type == 'checkbox'"
            size="small"
          />
          <!--<dict-radio v-else-if="item.type=='radio'"
        v-model="queryParams[extFieldPrefix+item.name]"
        :placeholder="'请选择'+item.label"
        :type="item.ext"
        size="small"
      />
      <dict-checkbox v-else-if="item.type=='checkbox'"
        v-model="queryParams[extFieldPrefix+item.name]"
        :placeholder="'请选择'+item.label"
        :type="item.ext"
        size="small"
      />-->
          <datetime-select
            v-else-if="item.type == 'date'"
            v-model="queryParams[extFieldPrefix + item.name]"
            type="date"
            :placeholder="'请选择' + item.label"
            size="small"
          />
          <datetime-select
            v-else-if="item.type == 'datetime'"
            v-model="queryParams[extFieldPrefix + item.name]"
            type="datetime"
            :placeholder="'请选择' + item.label"
            size="small"
          />
          <el-input-number
            v-else-if="item.type == 'number'"
            v-model="queryParams[extFieldPrefix + item.name]"
            :placeholder="'请输入' + item.label"
            clearable
            controls-position="right"
            size="small"
          />
          <el-input
            v-else
            v-model="queryParams[extFieldPrefix + item.name]"
            :placeholder="'请输入' + item.label"
            clearable
          />
        </el-form-item>
      </template>
      <template slot="toolbar_btn_left">
        <el-button
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
          v-hasPermi="['/stu/stustudent/add']"
          >新增</el-button
        >

        <el-button
          type="success"
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
          v-hasPermi="['/stu/stustudent/get', '/stu/stustudent/edit']"
          >修改</el-button
        >

        <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
          v-hasPermi="['/stu/stustudent/del']"
          >删除</el-button
        >

        <el-button
          type="warning"
          icon="el-icon-refresh-left"
          size="mini"
          @click="handleSyncAccount"
          v-hasPermi="['/stu/stustudent/syncAccount']"
          >同步账号</el-button
        >

        <el-button
          type="warning"
          size="mini"
          @click="handleImport"
          v-hasPermi="['/stu/stustudent/import']"
        >
          <i class="iconfont icon-daoru_o"></i>
          导入
        </el-button>

        <el-button
          class="btn-orange"
          size="mini"
          @click="handleExport"
          v-hasPermi="['/stu/stustudent/import']"
        >
          <i class="iconfont icon-daochu_o"></i>
          导出
        </el-button>

        <el-button class="btn-orange" size="mini" @click="handlePrint">
          <!-- v-hasPermi="['/stu/stustudent/import']" -->
          <i class="el-icon-printer"></i>
          打印
        </el-button>
      </template>
      <template slot="toolbar_btn_row" slot-scope="{ row }">
        <el-button
          size="mini"
          type="text"
          icon="el-icon-edit"
          @click.stop="handleUpdate(row)"
          >修改
        </el-button>
        <el-button
          size="mini"
          type="text"
          icon="el-icon-delete"
          @click.stop="handleDelete(row)"
          >删除
        </el-button>
      </template>
    </jf-table>

    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="open"
      width="960px"
      append-to-body
      custom-class="scrolldialog"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="150px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="学号" prop="no">
              <el-input v-model="form.no" placeholder="请输入学号" clearable />
            </el-form-item>
          </el-col>
          <!--<el-col :span="12">
					<el-form-item label="校园卡号" prop="card_no">
						<el-input
							v-model="form.card_no"
							placeholder="请输入校园卡号"
							clearable
						/>
					</el-form-item> 
				</el-col>-->
          <el-col :span="12">
            <el-form-item label="姓名" prop="name">
              <el-input
                v-model="form.name"
                placeholder="请输入姓名"
                clearable
              />
            </el-form-item>
          </el-col>
          <!--<el-col :span="12">
					<el-form-item label="别名" prop="nickname">
						<el-input
							v-model="form.nickname"
							placeholder="请输入别名"
							clearable
						/>
					</el-form-item> 
				</el-col>-->
          <el-col :span="12">
            <el-form-item label="院系" prop="dept_no">
              <dept-select
                v-model="form.dept_no"
                :defaultLabel="form.dept_name"
                placeholder="请选择院系"
                valueField="no"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="专业" prop="major_no">
              <major-select
                v-model="form.major_no"
                :defaultLabel="form.major_name"
                placeholder="请选择专业"
                :dept_no="form.dept_no"
                valueField="no"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="班级" prop="classes_no">
              <el-input
                v-model="form.classes_no"
                placeholder="请输入班级"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="年级" prop="grade">
              <dict-select
                v-model="form.grade"
                placeholder="请选择年级"
                type="stu_grade"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="辅导员" prop="assistant_nos">
              <assistant-select
                v-model="form.assistant_nos"
                :defaultLabel="form.assistant_names"
                placeholder="请选择辅导员"
                @change="handleAssistantChange"
              />
            </el-form-item>
          </el-col>
          <!--<el-col :span="12">
					<el-form-item label="考生考号" prop="candidate_no">
						<el-input
							v-model="form.candidate_no"
							placeholder="请输入考生考号"
							clearable
						/>
					</el-form-item> 
				</el-col>-->
          <el-col :span="12">
            <el-form-item label="身份证号" prop="idcard">
              <el-input
                v-model="form.idcard"
                placeholder="请输入身份证号"
                clearable
              />
            </el-form-item>
          </el-col>
          <!--<el-col :span="12">
					<el-form-item label="身份证地址" prop="idcard_addr">
						<el-input
							v-model="form.idcard_addr"
							placeholder="请输入身份证地址"
							clearable
						/>
					</el-form-item> 
				</el-col>-->
          <el-col :span="12">
            <el-form-item label="性别" prop="gender">
              <dict-select
                v-model="form.gender"
                placeholder="请选择性别"
                type="stu_gender"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="民族" prop="ethnicity">
              <dict-select
                v-model="form.ethnicity"
                placeholder="请选择民族"
                type="stu_ethnicity"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="出生年月" prop="birthday">
              <datetime-select
                v-model="form.birthday"
                type="date"
                placeholder="请选择出生年月"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="政治面貌" prop="politics">
              <dict-select
                v-model="form.politics"
                placeholder="请选择政治面貌"
                type="stu_politics"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="学籍状态" prop="status">
              <dict-select
                v-model="form.status"
                placeholder="请选择学籍状态"
                type="stu_status"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="学制" prop="school_system">
              <dict-select
                v-model="form.school_system"
                placeholder="请选择学制"
                type="stu_school_system"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="培养层次" prop="stu_level">
              <dict-select
                v-model="form.stu_level"
                placeholder="请选择培养层次"
                type="stu_level"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="入学时间" prop="in_date">
              <datetime-select
                v-model="form.in_date"
                type="date"
                placeholder="请选择入学时间"
              />
            </el-form-item>
          </el-col>
          <!--<el-col :span="12">
					<el-form-item label="省份" prop="province">
						<dict-select
							v-model="form.province"
							placeholder="请选择省份"
							type="stu_province"
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="12">
					<el-form-item label="户口" prop="residence">
						<dict-select
							v-model="form.residence"
							placeholder="请选择户口"
							type="stu_residence"
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="12">
					<el-form-item label="毕业高中" prop="senior_school">
						<el-input
							v-model="form.senior_school"
							placeholder="请输入毕业高中"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="12">
					<el-form-item label="联系电话" prop="mobile">
						<el-input
							v-model="form.mobile"
							placeholder="请输入联系电话"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="12">
					<el-form-item label="邮箱" prop="mail">
						<el-input
							v-model="form.mail"
							placeholder="请输入邮箱"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="12">
					<el-form-item label="扩展信息" prop="ext">
						<el-input
							v-model="form.ext"
							placeholder="请输入扩展信息"
							clearable
						/>
					</el-form-item> 
				</el-col>-->
          <el-col :span="12" v-for="item in extList" :key="item.name">
            <el-form-item
              v-if="item.hidden != '1'"
              :label="item.label"
              :prop="extFieldPrefix + item.name"
            >
              <dict-select
                v-if="item.type == 'select' || item.type == 'selectm'"
                v-model="form[extFieldPrefix + item.name]"
                :placeholder="'请选择' + item.label"
                :type="item.ext"
                :multiple="item.type == 'selectm'"
              />
              <dict-radio
                v-else-if="item.type == 'radio'"
                v-model="form[extFieldPrefix + item.name]"
                :placeholder="'请选择' + item.label"
                :type="item.ext"
              />
              <dict-checkbox
                v-else-if="item.type == 'checkbox'"
                v-model="form[extFieldPrefix + item.name]"
                :placeholder="'请选择' + item.label"
                :type="item.ext"
              />
              <datetime-select
                v-else-if="item.type == 'date'"
                v-model="form[extFieldPrefix + item.name]"
                type="date"
                :placeholder="'请选择' + item.label"
              />
              <datetime-select
                v-else-if="item.type == 'datetime'"
                v-model="form[extFieldPrefix + item.name]"
                type="datetime"
                :placeholder="'请选择' + item.label"
              />
              <el-input-number
                v-else-if="item.type == 'number'"
                v-model="form[extFieldPrefix + item.name]"
                :placeholder="'请输入' + item.label"
                clearable
                controls-position="right"
              />
              <el-input
                v-else-if="item.type == 'textarea'"
                v-model="form[extFieldPrefix + item.name]"
                :placeholder="'请输入' + item.label"
                clearable
                :rows="item.ext ? parseInt(item.ext) : 2"
                type="textarea"
              />
              <el-input
                v-else
                v-model="form[extFieldPrefix + item.name]"
                :placeholder="'请输入' + item.label"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否已生成账号" prop="sync_account">
              <dict-select
                v-model="form.sync_account"
                placeholder="请选择是否已生成账号"
                type="stu_yes_no"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注信息" prop="remark">
              <el-input
                v-model="form.remark"
                placeholder="请输入备注信息"
                clearable
                type="textarea"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">返 回</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :title="upload.title"
      :visible.sync="upload.open"
      width="400px"
      append-to-body
    >
      <el-upload
        ref="upload"
        :limit="1"
        accept=".xlsx"
        :headers="upload.headers"
        :action="upload.url + '?update=' + upload.update"
        :disabled="upload.isUploading"
        :on-progress="handleImportProgress"
        :on-success="handleImportSuccess"
        :on-error="handleImportError"
        :auto-upload="false"
        drag
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          将文件拖到此处，或
          <em>点击上传</em>
        </div>
        <div class="el-upload__tip" slot="tip">
          <el-checkbox v-model="upload.update" />是否更新已经存在的数据
          <el-link
            type="info"
            style="font-size: 12px"
            @click="handleImportTemplate"
            >下载模板</el-link
          >&nbsp;
          <el-link
            type="info"
            style="font-size: 12px"
            @click="handleImportReport"
            >下载报告</el-link
          >
        </div>
        <div class="el-upload__tip" style="color: red" slot="tip">
          提示：仅允许导入“xlsx”格式文件！
        </div>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitImportForm">确 定</el-button>
        <el-button @click="upload.open = false">取 消</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="同步账号"
      :visible.sync="syncAccountOpen"
      width="500px"
      append-to-body
    >
      <el-form
        ref="syncAccountForm"
        :model="syncAccountForm"
        :rules="syncAccountRules"
        label-width="80px"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="密码规则" prop="password_rule">
              <dict-select
                v-model="syncAccountForm.password_rule"
                placeholder="请选密码规则"
                type="stu_student_password_rule"
              />
            </el-form-item>
          </el-col>
          <!--<el-col :span="24">
            <el-form-item label="是否需要验证账号" prop="needs_validate">
              <dict-select v-model="syncAccountForm.needs_validate" placeholder="请选择是否需要验证账号" type="stu_yes_no" />
            </el-form-item>
          </el-col>-->
        </el-row>
      </el-form>

      <table
        v-if="syncAccountProgressData.total"
        style="width: 100%"
        cellpadding="5px"
      >
        <tr>
          <td width="50px">进度</td>
          <td style="">
            <el-progress
              :text-inside="true"
              :stroke-width="16"
              :percentage="
                syncAccountProgressData.total
                  ? parseInt(
                      (syncAccountProgressData.count * 100) /
                        syncAccountProgressData.total
                    )
                  : 0
              "
            ></el-progress>
          </td>
        </tr>
        <tr>
          <td>总数</td>
          <td>
            {{ syncAccountProgressData.count }} /
            {{ syncAccountProgressData.total }}
          </td>
        </tr>
        <tr>
          <td>成功</td>
          <td>{{ syncAccountProgressData.success }}</td>
        </tr>
        <tr>
          <td>失败</td>
          <td>
            {{ syncAccountProgressData.fail }}&nbsp;&nbsp;{{
              syncAccountProgressData.msg
            }}
          </td>
        </tr>
        <tr>
          <td>耗时</td>
          <td>
            {{ parseInt(syncAccountProgressData.time / 60 / 60) }}时{{
              parseInt(syncAccountProgressData.time / 60) % 60
            }}分{{ syncAccountProgressData.time % 60 }}秒
          </td>
        </tr>
      </table>

      <div slot="footer" class="dialog-footer">
        <el-button
          v-if="
            !syncAccountProgressData.total ||
            syncAccountProgressData.total == syncAccountProgressData.count
          "
          type="primary"
          @click="submitSyncAccountForm"
          >同 步</el-button
        >
        <el-button @click="syncAccountOpen = false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Qs from "qs";
import { getAccessToken } from "@/utils/tokenTool";
import {
  listStuStudent,
  getStuStudent,
  addStuStudent,
  editStuStudent,
  delStuStudent,
  syncAccount,
  syncAccountProgress,
  addStudent,
} from "@/api/stu/stu_student";
import { listStuStudentExtField } from "@/api/stu/stu_student_ext_field";
import MajorSelect from "../components/major_select/index.vue";
import AssistantSelect from "../components/assistant_select/index.vue";
import {
  getTokens,
  postPrint,
  getUser,
  stustudentEdit,
  getArchives,
  changeArchives,
} from "@/api/stu/stu_student.js";
export default {
  name: "stu-stustudent-list",
  components: {
    MajorSelect,
    AssistantSelect,
  },
  data() {
    return {
      recordsList: [],
      tokens: "",
      tableToolbar: {
        perfect: false,
        zoom: true,
        custom: false,
        refresh: {
          query: this.handleQuery,
        },
        slots: {
          buttons: "toolbar_buttons",
        },
      },
      tableColumn: [
        { type: "checkbox", width: 60, fixed: "left" },
        {
          field: "id",
          title: "id",
          minWidth: 120,
          fixed: "left",
          visible: false,
        },
        {
          field: "no",
          title: "学号",
          minWidth: 120,
          is_query: true,
          fixed: "left",
        },
        // { field: "card_no", title: "校园卡号", minWidth: 120 },
        {
          field: "name",
          title: "姓名",
          minWidth: 120,
          is_query: true,
          fixed: "left",
        },
        // { field: "nickname", title: "别名", minWidth: 120 },
        { field: "dept_name", title: "院系", minWidth: 120 },
        { field: "major_name", title: "专业", minWidth: 120 },
        {
          field: "classes_no",
          title: "班级",
          minWidth: 120,
          is_query: true,
        },
        {
          field: "grade",
          title: "年级",
          minWidth: 120,
          dictType: "stu_grade",
          formatter: this.dictFormat,
        },
        {
          field: "assistant_nos",
          title: "辅导员工号",
          minWidth: 120,
          // is_query: true,
        },
        {
          field: "assistant_names",
          title: "辅导员姓名",
          minWidth: 120,
        },
        //{ field: "candidate_no", title: "考生考号", minWidth: 120 },
        { field: "idcard", title: "身份证号", minWidth: 120 },
        //{ field: "idcard_addr", title: "身份证地址", minWidth: 120 },
        {
          field: "gender",
          title: "性别",
          minWidth: 120,

          dictType: "stu_gender",
          formatter: this.dictFormat,
        },
        {
          field: "ethnicity",
          title: "民族",
          minWidth: 120,
          dictType: "stu_ethnicity",
          formatter: this.dictFormat,
        },
        {
          field: "birthday",
          title: "出生年月",
          minWidth: 120,
          formatter: ["formatDate", "yyyy-MM-dd"],
        },
        {
          field: "politics",
          title: "政治面貌",
          minWidth: 120,
          dictType: "stu_politics",
          formatter: this.dictFormat,
        },
        {
          field: "status",
          title: "学籍状态",
          minWidth: 120,
          dictType: "stu_status",
          formatter: this.dictFormat,
        },
        {
          field: "school_system",
          title: "学制",
          minWidth: 120,

          dictType: "stu_school_system",
          formatter: this.dictFormat,
        },
        {
          field: "stu_level",
          title: "培养层次",
          minWidth: 120,
          formatter: this.dictFormat,
          dictType: "stu_level",
        },
        {
          field: "in_date",
          title: "入学时间",
          minWidth: 120,
          formatter: ["formatDate", "yyyy-MM-dd"],
        },
        /*{ field: "province", title: "省份", minWidth: 120, formatter: this.dictFormat, dictType: 'stu_province' },
				{ field: "residence", title: "户口", minWidth: 120, formatter: this.dictFormat, dictType: 'stu_residence' },
				{ field: "senior_school", title: "毕业高中", minWidth: 120 },
				{ field: "mobile", title: "联系电话", minWidth: 120 },
				{ field: "mail", title: "邮箱", minWidth: 120 },
				{ field: "ext", title: "扩展信息", minWidth: 120 },*/
        {
          field: "sync_account",
          title: "是否已生成账号",
          minWidth: 120,
          formatter: this.dictFormat,
          dictType: "stu_yes_no",
        },
        {
          field: "create_by",
          title: "创建者",
          minWidth: 120,
          visible: false,
        },
        {
          field: "create_time",
          title: "创建时间",
          minWidth: 120,
          formatter: "formatDate",
          visible: false,
        },
        {
          field: "update_by",
          title: "更新者",
          minWidth: 120,
          visible: false,
        },
        {
          field: "update_time",
          title: "更新时间",
          minWidth: 120,
          formatter: "formatDate",
        },
        { field: "remark", title: "备注信息", minWidth: 120 },
        // {
        //     field: "",
        //     title: "操作",
        //     width: 180,
        //     fixed: "right",
        //     align: "center",
        //     slots: { default: "defaultopr" },
        // },
      ],
      loading: true,
      ids: [],
      single: true,
      multiple: true,
      total: 0,
      dataList: [],
      extList: [],
      extListSearch: [],
      title: "",
      open: false,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        id: undefined,
        no: undefined,
        name: undefined,
        dept_no: undefined,
        major_no: undefined,
        classes_no: undefined,
        assistant_nos: undefined,
        ext: undefined,
      },
      form: {},
      extFieldPrefix: "_ext_",
      upload: {
        open: false,
        title: "",
        isUploading: false,
        update: false,
        headers: { Authorization: getAccessToken() },
        url: process.env.VUE_APP_BASE_API + "/stu/stustudent/import",
      },
      rules: {
        no: [
          {
            required: true,
            message: "学号不能为空",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "姓名不能为空",
            trigger: "blur",
          },
        ],
        dept_no: [
          {
            required: true,
            message: "院系不能为空",
            trigger: "blur",
          },
        ],
        major_no: [
          {
            required: true,
            message: "专业不能为空",
            trigger: "blur",
          },
        ],
        classes_no: [
          {
            required: true,
            message: "班级不能为空",
            trigger: "blur",
          },
        ],
        grade: [
          {
            required: true,
            message: "年级不能为空",
            trigger: "blur",
          },
        ],
        idcard: [
          {
            required: true,
            message: "身份证号不能为空",
            trigger: "blur",
          },
        ],
        gender: [
          {
            required: true,
            message: "性别不能为空",
            trigger: "blur",
          },
        ],
        ethnicity: [
          {
            required: true,
            message: "民族不能为空",
            trigger: "blur",
          },
        ],
        birthday: [
          {
            required: true,
            message: "出生年月不能为空",
            trigger: "blur",
          },
        ],
        politics: [
          {
            required: true,
            message: "政治面貌不能为空",
            trigger: "blur",
          },
        ],
        status: [
          {
            required: true,
            message: "学籍状态不能为空",
            trigger: "blur",
          },
        ],
        school_system: [
          {
            required: true,
            message: "学制不能为空",
            trigger: "blur",
          },
        ],
        stu_level: [
          {
            required: true,
            message: "培养层次不能为空",
            trigger: "blur",
          },
        ],
        in_date: [
          {
            required: true,
            message: "入学时间不能为空",
            trigger: "blur",
          },
        ],
      },
      syncAccountOpen: false,
      syncAccountForm: {},
      syncAccountRules: {
        password_rule: [
          {
            required: true,
            message: "密码规则不能为空",
            trigger: "blur",
          },
        ],
        needs_validate: [
          {
            required: true,
            message: "是否需要验证账号不能为空",
            trigger: "blur",
          },
        ],
      },
      syncAccountProgressData: {},
      dictsData: {},
    };
  },
  created() {
    this.syncAccountProgress();

    // for (let i = 0; i < this.tableColumn.length; i++) {
    //     const col = this.tableColumn[i];
    //     if (col.dictType) {
    //         this.getDicts(col.dictType).then((response) => {
    //             if (response.code == 0) {
    //                 col.dictData = response.data;
    //                 this.dictsData[col.dictType]=col.dictData
    //                 this.dataList = [].concat(this.dataList);
    //             }
    //         });
    //     }
    // }
    this.getList();
    listStuStudentExtField({ table_name: "stu_student", pageSize: 100 }).then(
      (response) => {
        if (response.code == 0) {
          let index = this.tableColumn.length;
          for (let i = 0; i < this.tableColumn.length; i++) {
            if (this.tableColumn[i].field == "sync_account") {
              index = i;
              break;
            }
          }
          const tableColumn = [].concat(this.tableColumn);
          const extListSearch = [];
          for (let i = response.data.list.length - 1; i >= 0; i--) {
            const d = response.data.list[i];
            this.$set(this.form, this.extFieldPrefix + d.name, d.default_value);
            if (d.hidden != "1" && d.searchable == "1") {
              this.$set(
                this.queryParams,
                this.extFieldPrefix + d.name,
                undefined
              );
              extListSearch.splice(0, 0, d);
            }
            if (d.hidden != "1" && d.require == "1") {
              this.rules[this.extFieldPrefix + d.name] = [
                {
                  required: true,
                  message: d.label + "不能为空",
                  trigger: "blur",
                },
              ];
            }
            if (d.hidden == "1") {
              continue;
            }
            const item = {
              field: d.name,
              title: d.label,
              minWidth: 120,
              formatter: this.extFormat,
              extField: d,
            };
            if (
              d.type == "select" ||
              d.type == "selectm" ||
              d.type == "radio" ||
              d.type == "checkbox"
            ) {
              item.dictType = d.ext;
              if (item.dictType) {
                this.getDicts(item.dictType).then((response) => {
                  if (response.code == 0) {
                    item.dictData = response.data;
                    this.dataList = [].concat(this.dataList);
                  }
                });
              }
            }
            tableColumn.splice(index, 0, item);
          }
          this.tableColumn = tableColumn;
          this.extListSearch = extListSearch;
          this.extList = response.data.list;
        }
      }
    );

    getTokens().then((res) => {
      if (res.code == 0) {
        let userNamess = localStorage.getItem("userNamess");
        res.data.list.map((item) => {
          if (item.label === userNamess) {
            this.tokens = item.value;
          }
        });
      }
    });
  },
  methods: {
    handlePrint() {
      this.$confirm("确定打印数据吗?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        if (!this.recordsList.length) return;

        let data = [];
        this.recordsList.map((item) => {
          let obj = {};
          obj["name"] = item.name;
          obj["no"] = item.no;
          obj["dept"] = item.dept_name;
          obj["major"] = item.major_name;
          data.push(obj);
        });

        this.sendPrintRequest(data);
        // this.addUserMessage();
        // this.stustudentEdits();
        // this.addStudents();
      });
    },

    sendPrintRequest(data) {
      // 假设这些参数是从用户输入或其他方式获取的
      if (!this.tokens) {
        this.$message({
          message: "当前账号没有绑定打印机，请联系管理员",
          type: "warning",
        });
        return;
      }

      // const accessToken = '<根据用户名从字典类型tsc_print中获取对应的token>'; // 从接口5.1获取的accessToken
      const accessToken = this.tokens; // 从接口5.1获取的accessToken
      const equip = 1; // 假设是电脑端打印
      const doc_no = "1719558985581"; // 模板编号
      //   const print_data = '[{"name":"张三","no":20080352415,"dept":"机电工程学院","major":""}]'; // 假设的打印数据
      const print_data = JSON.stringify(data); // 假设的打印数据
      const copies_number = 1; // 打印份数
      const brand_par_id = 5300; // 打印机品牌参数编号

      // 创建请求对象
      const xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        "https://dlabelplugga.ctaiot.com:9443/api/print/send_data_print.json",
        true
      );

      // 设置请求头
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
      xhr.setRequestHeader("accessToken", accessToken);

      // 发送请求体
      const params = new URLSearchParams();
      params.append("equip", equip);
      params.append("doc_no", doc_no);
      params.append("print_data", print_data);
      params.append("copies_number", copies_number);
      params.append("brand_par_id", brand_par_id);
      xhr.send(params.toString());

      let than = this;
      // 处理响应
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
          console.log(xhr.responseText); //
          let resValue = JSON.parse(xhr.responseText);
          console.log("resValue =>", resValue);
          if (resValue.code == 100) {
            than.$message({
              message: "打印成功",
              type: "success",
            });
            // than.addUserMessage();
            than.stustudentEdits();
            than.addStudents();

            than.recordsList = [];
          } else {
            than.$message({
              message: resValue.message,
              type: "error",
            });
          }
        } else if (xhr.status !== 200) {
          console.error("Error: " + xhr.status);
        }
      };

      // 处理错误
      xhr.onerror = function () {
        console.error("Request failed");
      };
    },

    // 添加打印
    addUserMessage() {
      this.recordsList.map((item) => {
        // 根据学号查询
        let obj = {
          input26911: item.no,
          id: {
            orderby: "desc",
          },
        };
        let dataArchives = {
          mid: "43829154416645",
          page: 1,
          psize: 20,
          qstr: JSON.stringify(obj),
        };

        setTimeout(() => {
          getArchives(dataArchives).then((res) => {
            if (res.code == 0 && res.data.list.length) {
              let newArr = res.data.list[0];
              let newObj = {
                input26911: "",
                input37215: "",
                input88473: "",
                input102117: "",
                input68513: "",
                input68718: "",
                select61626: "1", // "1"  是否打印
                date43805: "",
                select87884: "",
                date105697: null,
                select87639: "",
                date91689: null,
                select54988: "",
                date34746: null,
                input105544: "",
                select54799: "",
                date60684: null,
                select72801: "",
                date54352: null,
                select58804: "",
                date86050: null,
                input78472: "",
                input68052: "",
                input108473: "",
                input50094: "",
                input72599: "",
                input106371: "",
              };

              for (let k in newObj) {
                newObj[k] = newArr[k];
              }
              newObj["select61626"] = "1"; //修改打印
              let newValues = {
                dataid: newArr.id,
                formdata: JSON.stringify(newObj),
                mid: "43829154416645",
              };
              changeArchives(newValues).then((res) => {
                console.log("修改之后的信息 =>", res);
              });
            } else {
              let message = {
                input26911: item.no, // 学号
                input37215: item.name, // 姓名
                input88473: item.gender == "2" ? "女" : "男", // 性别
                input102117: item.dept_name, // 学院
                input68513: item.major_name, // 专业
                input68718: item.idcard, // 身份证
                select61626: "1", // 是
                date43805: this.formatDateTime(new Date()),
                select87884: "",
                date105697: null,
                select87639: "",
                date91689: null,
                select54988: "",
                date34746: null,
                input105544: "",
                select54799: "",
                date60684: null,
                select72801: "",
                date54352: null,
                select58804: "",
                date86050: null,
                input78472: "",
                input68052: "",
                input72599: "",
                input106371: "",
              };
              let data = {
                dataid: null,
                formdata: JSON.stringify(message),
                mid: "43829154416645",
              };
              // 添加数据
              postPrint(data).then((res) => {
                console.log("添加数据 ==>", res);
              });
            }
          });
        }, 500);
      });
    },

    // 修改数据
    stustudentEdits() {
      this.recordsList.map((item) => {
        let obj = {};
        if (item.ext == "") {
          obj = {};
        } else {
          obj = JSON.parse(item.ext);
        }
        obj["sfdy"] = "1";
        item.ext = JSON.stringify(obj);
        stustudentEdit(item).then((res) => {
          console.log("修改数据 ==>", res);
        });
      });

      setTimeout(() => {
        this.getList();
      }, 300);
    },

    // 发送学号
    addStudents() {
      let str = "";
      this.recordsList.map((item) => {
        str += item.no + ",";
      });
      str = str.slice(0, -1);
      console.log("str", str);
      let data = {
        stunolist: str,
      };
      addStudent(data).then((res) => {
        console.log("发送学号 ==>", res);
      });
    },

    formatDateTime(date) {
      let year = ("0000" + date.getFullYear()).slice(-4);
      let month = ("0" + (date.getMonth() + 1)).slice(-2);
      let day = ("0" + date.getDate()).slice(-2);
      let hours = ("0" + date.getHours()).slice(-2);
      let minutes = ("0" + date.getMinutes()).slice(-2);
      let seconds = ("0" + date.getSeconds()).slice(-2);
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },

    //列表排序和筛选
    tableChange(e) {},
    getList() {
      this.loading = true;
      this.ids = [];
      this.single = true;
      this.multiple = true;
      const queryParams = this.splitForm(this.queryParams);
      if (Object.keys(queryParams.formExt).length > 0) {
        queryParams.form.ext = JSON.stringify(queryParams.formExt);
      }
      listStuStudent(queryParams.form).then((response) => {
        this.dataList = response.data.list;
        this.total = response.data.total;
        this.loading = false;
      });
    },

    dictFormat(e) {
      const col = this.tableColumn[e.columnIndex];
      if (col.dictData) {
        const values = e.cellValue ? e.cellValue.split(",") : [];
        const labels = [];
        for (let i = 0; i < values.length; i++) {
          const v = values[i];
          for (let j = 0; j < col.dictData.values.length; j++) {
            const item = col.dictData.values[j];
            if (v == item.key) {
              labels.push(item.value);
              break;
            }
          }
        }
        return labels.join(",");
      }
      return e.cellValue;
    },

    extFormat(e) {
      const d = this.dataList[e.rowIndex];
      if (d.ext && !d._ext_data) {
        d._ext_data = JSON.parse(d.ext);
      }
      if (d._ext_data) {
        const col = this.tableColumn[e.columnIndex];
        e.cellValue = d._ext_data[col.field];
        if (col.dictType) {
          return this.dictFormat(e);
        } else if (col.extField) {
          if (col.extField.type == "date" && e.cellValue) {
            return this.XEUtils.toDateString(e.cellValue * 1000, "yyyy-MM-dd");
          } else if (col.extField.type == "datetime" && e.cellValue) {
            return this.XEUtils.toDateString(
              e.cellValue * 1000,
              "yyyy-MM-dd HH:mm:ss"
            );
          }
        }
      }
      return e.cellValue;
    },

    cancel() {
      this.open = false;
      this.reset();
    },

    reset() {
      this.form = {
        no: undefined,
        card_no: undefined,
        name: undefined,
        nickname: undefined,
        dept_no: undefined,
        major_no: undefined,
        classes_no: undefined,
        grade: undefined,
        assistant_nos: undefined,
        assistant_names: undefined,
        candidate_no: undefined,
        idcard: undefined,
        idcard_addr: undefined,
        gender: undefined,
        ethnicity: undefined,
        birthday: undefined,
        politics: undefined,
        status: undefined,
        school_system: undefined,
        stu_level: undefined,
        in_date: undefined,
        province: undefined,
        residence: undefined,
        senior_school: undefined,
        mobile: undefined,
        mail: undefined,
        ext: undefined,
        sync_account: undefined,
        remark: undefined,
      };
      for (let i = 0; i < this.extList.length; i++) {
        const d = this.extList[i];
        this.$set(this.form, this.extFieldPrefix + d.name, d.default_value);
      }
      this.resetForm("form");
    },

    handleQuery(e) {
      if (e) {
        this.queryParams = this.deepCopy(e);
      }
      this.queryParams.pageNum = 1;
      this.getList();
    },

    resetQuery() {
      for (let i = 0; i < this.extList.length; i++) {
        const d = this.extList[i];
        if (d.hidden != "1" && d.searchable == "1") {
          this.$set(this.queryParams, this.extFieldPrefix + d.name, undefined);
        }
      }
      this.resetForm("queryForm");
      this.handleQuery();
    },

    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加";
    },

    handleUpdate(row) {
      this.reset();
      const id = row.id || (this.ids.length > 0 ? this.ids[0] : "");
      getStuStudent(id).then((response) => {
        this.form = response.data;
        if (this.form.ext) {
          const formExt = JSON.parse(this.form.ext);
          for (let key in formExt) {
            this.$set(this.form, this.extFieldPrefix + key, formExt[key]);
          }
        }
        this.open = true;
        this.title = "修改";
      });
    },

    splitForm(form) {
      const f = JSON.parse(JSON.stringify(form));
      const sf = {
        form: {},
        formExt: {},
      };
      const len = this.extFieldPrefix.length;
      for (let key in f) {
        const value = f[key];
        if (key.indexOf(this.extFieldPrefix) == 0) {
          sf.formExt[key.substring(len)] = value;
        } else {
          sf.form[key] = value;
        }
      }
      return sf;
    },

    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          const form = this.splitForm(this.form);
          if (Object.keys(form.formExt).length > 0) {
            form.form.ext = JSON.stringify(form.formExt);
          }
          if (this.form.id != null) {
            editStuStudent(form.form).then((response) => {
              if (response.code === 0) {
                this.msgSuccess("修改成功");
                this.open = false;
                setTimeout(() => {
                  this.getList();
                }, 300);
              }
            });
          } else {
            addStuStudent(form.form).then((response) => {
              if (response.code === 0) {
                this.msgSuccess("新增成功");
                this.open = false;
                setTimeout(() => {
                  this.getList();
                }, 300);
              }
            });
          }
        }
      });
    },

    handleDelete(row) {
      const ids = row.id || (this.ids.length > 0 ? this.ids.join(",") : "");
      this.$confirm('确定删除id为"' + ids + '"的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(function () {
          return delStuStudent(ids);
        })
        .then(() => {
          setTimeout(() => {
            this.getList();
          }, 300);
          this.msgSuccess("删除成功");
        })
        .catch(function () {});
    },

    checkboxChangeEvent({ records }) {
      this.recordsList = records;

      this.ids = records.map((item) => item.id);
      this.single = records.length != 1;
      this.multiple = !records.length;
    },

    handlePageChange({ currentPage, pageSize }) {
      if (this.queryParams.pageSize == pageSize) {
        this.getList();
      } else {
        this.queryParams.pageSize = pageSize;
        this.handleQuery();
      }
    },

    handleAssistantChange(e1, e2) {
      this.form.assistant_names = e2
        ? e2.map((v) => v.name).join(",")
        : undefined;
    },

    handleSyncAccount() {
      this.resetForm("syncAccountForm");
      this.syncAccountOpen = true;
    },

    submitSyncAccountForm() {
      this.$refs["syncAccountForm"].validate((valid) => {
        if (valid) {
          if (this.syncAccountLoading) {
            this.syncAccountLoading.close();
          }
          this.syncAccountLoading = this.$loading({
            lock: true,
            text: "正在同步账号",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          const queryParams = this.splitForm(this.queryParams);
          if (Object.keys(queryParams.formExt).length > 0) {
            queryParams.form.ext = JSON.stringify(queryParams.formExt);
          }
          queryParams.form.password_rule = this.syncAccountForm.password_rule;
          queryParams.form.needs_validate = this.syncAccountForm.needs_validate;
          syncAccount(queryParams.form)
            .then((response) => {
              this.syncAccountLoading.close();
              this.syncAccountLoading = undefined;
              //this.syncAccountOpen = false
              if (response.code == 0) {
                this.msgSuccess("同步" + response.data.count + "个账号");
              }
              this.syncAccountForm.password_rule = "";
              this.syncAccountProgress();
            })
            .catch((err) => {
              this.syncAccountLoading.close();
              this.syncAccountLoading = undefined;
            });
        }
      });
    },

    syncAccountProgress() {
      syncAccountProgress().then((res) => {
        this.syncAccountProgressData = res.data;
        if (res.data.total > 0 && res.data.count < res.data.total) {
          if (!this.syncAccountOpen) {
            this.syncAccountForm.password_rule = "";
            this.syncAccountOpen = true;
          }
          setTimeout(() => {
            this.syncAccountProgress();
          }, 1000);
        }
      });
    },

    handleImport() {
      this.upload.title = "数据导入";
      this.upload.update = false;
      this.upload.open = true;
    },

    handleImportProgress(event, file, fileList) {
      this.upload.isUploading = true;
      if (event.percent == 100) {
        this.upload.open = false;
        this.upload.loading = this.$loading({
          lock: true,
          text: "正在导入数据",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
      }
    },

    handleImportSuccess(response, file, fileList) {
      console.log(response);
      if (this.upload.loading) {
        this.upload.loading.close();
      }
      this.upload.open = false;
      this.upload.isUploading = false;
      this.$refs.upload.clearFiles();
      if (response.code == 0) {
        const msg =
          "导入成功" +
          response.data.success_count +
          "条数据，失败" +
          response.data.fail_count +
          "条数据" +
          (response.data.msg ? "，" + response.data.msg : "");
        //this.$alert(msg, "提示", { dangerouslyUseHTMLString: true });
        this.$confirm(msg, "提示", {
          distinguishCancelAndClose: true,
          confirmButtonText: "确定",
          cancelButtonText: "下载导入报告",
        })
          .then(() => {})
          .catch((action) => {
            if (action === "cancel") {
              // 下载导入报告
              this.downloadbyurl("/stu/stustudent/importReport");
            }
          });
      } else {
        this.$alert(response.msg, "提示", {
          dangerouslyUseHTMLString: true,
        });
      }
      this.getList();
    },

    handleImportError(err, file, fileList) {
      if (this.upload.loading) {
        this.upload.loading.close();
      }
      this.upload.isUploading = false;
      this.$alert("导入失败", "提示", { dangerouslyUseHTMLString: true });
    },

    submitImportForm() {
      this.$refs.upload.submit();
    },

    handleImportTemplate() {
      this.downloadbyurl("/stu/stustudent/importTemplate");
    },

    handleImportReport() {
      this.downloadbyurl("/stu/stustudent/importReport");
    },

    handleExport() {
      this.$confirm("确定导出数据吗?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const queryParams = this.splitForm(this.queryParams);
        if (Object.keys(queryParams.formExt).length > 0) {
          queryParams.form.ext = JSON.stringify(queryParams.formExt);
        }
        this.downloadbyurl(
          "/stu/stustudent/export?" + Qs.stringify(queryParams.form)
        );
      });
    },
  },
};
</script>
<style rel="stylesheet/scss" scoped>
::v-deep .scrolldialog {
  max-height: 82vh;
}

::v-deep .el-dialog__body {
  max-height: calc(100vh - 300px);
  overflow-y: auto;
}
</style>
