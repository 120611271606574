<!-- 生成时间:2024-03-21 14:30:59 -->
<template>
  <div class="app-container">
    <!-- 渲染查询表单 -->
    <VFormRender v-if="queryformData" class="query-form-data" ref="queryform" :formJson="queryformData"></VFormRender>

    <el-form>
      <!-- label-width="68px" -->
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <jf-table :tree-config="treeConfig" :loading="loading" :query-params="queryParams" :toolbar-config="tableToolbar"
      :columns="tableColumn" :data="dataList" @sort-change="sortChange" @checkbox-change="checkboxChangeEvent"
      @checkbox-all="checkboxChangeEvent" @toggle-tree-expand="toggleExpandChangeEvent" @cell-dblclick="Oncelldblclick"
      @page-change="handleSizeChange" :total="total" :filterBtn="switchshowbtn('filter')"
      :sortBtn="switchshowbtn('sort')" @change="tableChange" :defaultoprWidth="280">
      <template slot="toolbar_btn_left">
        <el-button v-if="switchshowbtn('add')" type="primary" icon="el-icon-plus" size="mini" @click="handleAdd"
          v-hasPermiZc="['/digital/bookedit/add']">新增</el-button>

        <el-button type="success" icon="el-icon-refresh" :loading="upLoading" size="mini" @click="handleApiList"
          v-hasPermiZc.all="['/digital/bookedit/get', '/digital/bookedit/edit']">更新数据</el-button>
        <el-button v-if="switchshowbtn('edit')" type="success" icon="el-icon-edit" size="mini" :disabled="single"
          @click="handleUpdate" v-hasPermiZc.all="['/digital/bookedit/get', '/digital/bookedit/edit']">修改</el-button>
        <el-button type="danger" icon="el-icon-delete" size="mini" :disabled="multiple" @click="handleDelete"
          v-hasPermiZc="['/digital/bookedit/del']" v-if="switchshowbtn('delete')">删除</el-button>
        <el-button v-if="switchshowbtn('import')" type="warning" size="mini" @click="handleImport(1)"
          v-hasPermiZc="['/digital/bookedit/import']">
          <i class="iconfont icon-daoru_o"></i>
          导入
        </el-button>

        <el-button v-if="switchshowbtn('export')" class="btn-orange" size="mini" @click="handleImport(2)"
          v-hasPermiZc="['/digital/bookedit/export']">
          <i class="iconfont icon-daochu_o"></i>
          导出
        </el-button>

        <el-dropdown @command="handleCommand">
          <el-button size="mini" type="text" style="margin-left: 10px">
            更多<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="operationLogOpen">操作记录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
      <!-- 	<template slot="toolbar_btn_right">

					</template> -->
      <template slot="toolbar_btn_row" slot-scope="{ row }">
        <el-button size="mini" type="text" icon="el-icon-document-add" @click="handleAddFile({ row })"
          v-hasPermiZc="['/digital/bookedit/get']">PDF处理
        </el-button>
        <el-button size="mini" v-if="switchshowbtn('get')" type="text" icon="el-icon-view"
          @click="Oncelldblclick({ row })" v-hasPermiZc="['/digital/bookedit/get']">查看
        </el-button>

        <el-button v-if="switchshowbtn('edit')" size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(row)"
          v-hasPermiZc="['/digital/bookedit/edit']">修改
        </el-button>

        <el-popconfirm title="确定操作吗" @confirm="erp_send_erp(row)">
          <el-button style="margin-left: 10px;" slot="reference" v-if="switchshowbtn('edit')" size="mini" type="text"
            icon="el-icon-position" v-hasPermiZc="['/digital/bookedit/edit']">{{ row.erp_send_status == '已缴送' ? '取消缴送' :
      '缴送' }}
          </el-button>
        </el-popconfirm>

        <template> </template>

        <!-- <el-button
                            size="mini"
                            type="text"
                            icon="el-icon-delete"
                            v-hasPermiZc="['/digital/bookedit/del']"
                            @click="handleDelete(row)"
                            v-if="switchshowbtn('delete')"
                            >删除
                        </el-button> -->
      </template>

      <template slot="roles" slot-scope="{ row }">
        <span v-for="(item, index) of row.roles" :key="index">
          {{ item.name }}
        </span>
      </template>
      <template :slot="item.slots.default" slot-scope="{ row }" v-for="item in columnSlots">
        <div v-html="getFun(item, row)"></div>
      </template>
    </jf-table>

    <!-- 添加或修改对话框  数据 -->
    <el-dialog :title="title" :visible.sync="open" :width="formData && formData.formConfig && formData.formConfig.dialogWidth
      ? formData.formConfig.dialogWidth
      : '1000px'
      " :close-on-click-modal="false" append-to-body :before-close="cancel" custom-class="scrolldialog">
      <VFormRender v-if="formData != null" ref="form" :disabledMode="isview" :isReadOnly="isview" :formJson="formData"
        :userInfo="userInfo"></VFormRender>

      <div slot="footer" class="dialog-footer">
        <el-button v-if="!isview" type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">返 回</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>

    <!-- 导入导出 -->
    <jf-import :show.sync="importOpen" v-if="importOpen" :type="importType" :columns="tableColumn"
      :isfinish="isupladfinish" :downloadtplurl="downloadtplurl" @importsubmit="importsubmit" :importError="importError"
      :exportTotal="total" @exportSubmit="exportSubmit" @saveExportTemplate="saveExportTemplate"
      :exportTemplateList="exportTemplateList"></jf-import>
    <!-- 操作记录 -->
    <jf-operation-log :show.sync="operationLogOpen" v-if="operationLogOpen"></jf-operation-log>

    <!-- 导入提示 -->
    <el-dialog title="导入提示" :close-on-click-modal="false" :visible.sync="showerrinfo" width="750px" append-to-body
      custom-class="scrolldialog">
      <p class="erinfo" v-for="(item, idx) in upload_errinfo" :key="idx">
        {{ item }}
      </p>
    </el-dialog>

    <!-- 文件上传列表 -->
    <el-dialog :title="pdfForm.title" :visible.sync="pdfForm.visible" :fullscreen="pdfForm.fullscreen"
      :close-on-press-escape="pdfForm.closeOnPressEscape" append-to-body
      :close-on-click-modal="pdfForm.closeOnClickModal" border width="70%" id="uptishi" @close="fileDiaClose">
      <div>
        <div style="margin-bottom: 10px">
          <el-upload class="upload-demo" ref="upload" action="" :on-preview="handlePreview" :on-remove="handleRemove"
            :file-list="fileData.fileList" :on-change="handelChange" name="cover" :multiple="true" :auto-upload="false"
            :show-file-list="false">
            <el-button :disabled="mergeLoading" slot="trigger" size="small" type="primary">选取文件</el-button>
          </el-upload>
        </div>
      </div>

      <el-table id="uptable" :data="pdfForm.list" ref="curtable" :row-class-name="tableRowClassName">
        <el-table-column type="index" label="序号" width="80"></el-table-column>
        <el-table-column prop="name" align="cneter" label="名称" width="300">
        </el-table-column>
        <el-table-column align="cneter" label="书签名称" width="300">
          <template slot-scope="scope">
            <el-input v-if="!scope.row.isContent" v-model="scope.row.bookmark"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="排序" min-width="150">
          <template slot-scope="scope">
            <el-button type="text" style="padding: 0" :disabled="scope.$index == 0"
              @click="moveUpward(scope.row, scope.$index)">上移</el-button>
            <el-button type="text" style="padding: 0" :disabled="scope.$index + 1 == pdfForm.list.length"
              @click="moveDown(scope.row, scope.$index)">下移</el-button>

            <el-button size="mini" type="text" v-hasPermiZc="['/digital/bookedit/del']"
              @click="deleteFile(scope.row, scope.$index)" v-if="switchshowbtn('delete')">删除
            </el-button>
            <el-button size="mini" type="text" v-if="scope.row.fileId"
              @click="selectContent(scope.row, scope.$index)">{{ scope.row.isContent ? "书签编辑" : "选取正文" }}
            </el-button>
            <!-- <el-button
              v-show="scope.row.isContent"
              size="mini"
              :loading="contentUploadLoading"
              @click="handBookMark(scope.row, scope.$index)"
              >{{
                contentUploadLoading
                  ? "正在处理..."
                  : scope.row.isFinish
                  ? "书签编辑"
                  : "正文处理"
              }}</el-button
            > -->
          </template>
        </el-table-column>

        <el-table-column align="cneter" label="上传情况">
          <template slot-scope="scope">
            <el-button size="mini" :disabled="scope.row.fileId" @click="handUpload(scope.row, scope.$index)">{{
      scope.row.fileId ? "已上传" : "点击上传" }}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="handelSend">上传</el-button> -->
        <el-button :loading="mergeLoading" type="primary" @click="handelSend">{{
      mergeLoading ? "合并中..." : "文档合并"
    }}</el-button>
      </span>
    </el-dialog>

    <!-- 书签编辑组件 -->
    <el-dialog :title="bookMarkForm.title" :visible.sync="bookMarkForm.visible" :fullscreen="bookMarkForm.fullscreen"
      :close-on-press-escape="bookMarkForm.closeOnPressEscape" append-to-body
      :close-on-click-modal="bookMarkForm.closeOnClickModal" width="96%" border class="bookMarkForm">
      <div slot="title">
        <div style="
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 96%;
          ">
          <div class="el-dialog__title">书签编辑</div>
          <div>
            <el-tooltip class="item" effect="dark" :content="bookMarkForm.fullscreen ? '退出全屏' : '开启全屏'" placement="top">
              <i class="el-icon-full-screen" @click="bookMarkForm.fullscreen = !bookMarkForm.fullscreen"></i>
            </el-tooltip>
          </div>
        </div>
      </div>
      <BookMarkView v-if="bookMarkForm.visible" @getBookInfo="getBookInfo" @getContent="getContent" :formId="mid"
        :recordId="rid" :fileId="contentPdf.id" :filePath="contentPdf.url" :action="bookMarkForm.action"
        :textMark="bookMarkForm.textMark"></BookMarkView>

      <!-- <MarkView
        v-if="bookMarkForm.visible"
        @getBookInfo="getBookInfo"
        @getContent="getContent"
        :formId="mid"
        :recordId="rid"
        :fileId="contentPdf.id"
        :filePath="contentPdf.url"
        :action="bookMarkForm.action"
        :textMark="bookMarkForm.textMark"
      ></MarkView> -->
    </el-dialog>
  </div>
</template>

<script>
import { uploadServer, getDssInfo } from "@/api/dss/fastdfsServer.js";
import bookeditService from "@/api/digital/bookedit";
import { form } from "@/mixins/form.js";

import { execModel } from "@/api/core/data/datasource/model";
import { getToken } from "@/utils/auth";

import Treeselect from "@riophae/vue-treeselect";

import "@riophae/vue-treeselect/dist/vue-treeselect.css";

import jfImport from "@/components/jfimport/jfimport";
import JfOperationLog from "@/components/jfoperationlog/jfoperationlog";

import { getFieldWidgetByName, deepClone } from "@/vform/utils/util";
import { loadExtension } from "@/vform/extension/extension-loader";
import VFormRender from "@/vform/components/form-render/index.vue";
import { getUserProfile } from "@/api/core/system/user";
import BookMarkView from "../components/bookmarkview.vue";
import MarkView from "../components/markview.vue";
loadExtension();

export default {
  mixins: [form],
  name: "digital-bookedit-index",
  components: {
    BookMarkView,
    MarkView,
    Treeselect,
    VFormRender,
    jfImport,
    JfOperationLog,
  },

  data() {
    return {
      upLoading: false,
      org_optionItems: {},
      istree: false,
      hidequeryparamform: false,
      exportTemplateList: [],
      listconf: null,

      importError: false,
      downloadtplurl: "",
      upload_errinfo: null,
      showerrinfo: false,
      isupladfinish: false,
      //导入导出弹窗
      importOpen: false,
      //导入导出类型
      imporType: 1,
      //操作记录
      operationLogOpen: false,
      //功能id
      mid: "17800036357893", //不可修改
      queryformData: null, //查询表单数据
      queryformDataorgstr: "",

      formData: null, //表单数据
      isview: false,
      model_info: {},
      child_model_info: {},
      childcollist: [],
      collist: [],
      LeftTreeData: [],
      LeftTreeDataProc: {},
      treeOptions: [],
      treeselectprops: {},
      treeConfig: {
        trigger: "row",
        expandRowKeys: [],
      },
      TreeAttr: {},

      //左侧分栏比例
      leftrolspan: 0,
      //checkbox 的数据
      CheckBoxData: {},
      //字典库数据
      DictsData: {},
      DsData: {},

      area_dist: {},
      province_dist: {},
      city_dist: {},

      //上传的文件列表
      upload_fileList: {},

      //上传文件参数
      upload: {
        headers: {
          Authorization: getToken(),
        },
        url: process.env.VUE_APP_BASE_API + "/file/upload?public=1",
      },

      //编辑器配置
      ueConfig: {
        // 初始容器高度
        initialFrameHeight: 300,
        // 初始容器宽度
        initialFrameWidth: "100%",
        // 上传文件接口
        serverUrl: process.env.VUE_APP_BASE_API + "/file/ueditorupload",
        enableAutoSave: true,
        elementPathEnable: false,
        wordCount: false,
        UEDITOR_HOME_URL: "/ueditor/",
      },

      //图片预览地址
      dialogImageUrl: "",
      //图片预览对话框
      dialogVisible: false,

      loadingstu: false,
      openselstu: false,

      // 工具栏
      tableToolbar: {
        perfect: false,
        zoom: true,
        custom: false,
        refresh: {
          query: this.handleQuery,
        },
        slots: {
          buttons: "toolbar_buttons",
        },
      },
      // 数据列
      tableColumn: [
        // {
        // 	type: "checkbox",
        // 	width: 60,
        // 	fixed: "left"
        // },
        {
          field: "id",
          title: "ID",
          width: 100,
          fixed: "left",
        },

        // {
        // 	field: "",
        // 	title: "操作",
        // 	resizable: false,
        // 	width: 180,
        // 	fixed: "right",
        // 	align: "center",
        // 	slots: {
        // 		default: "defaultopr"
        // 	},
        // },
      ],

      orgcolumnList: [],
      // 遮罩层
      loading: false,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      totalstu: 0,
      // 表格数据
      dataList: [],
      // 弹出层标题
      title: "",
      // 快速检索数据
      Quitfilter_data: {},
      // 快速检索数据 字典类型
      Quitfilter_keys: [],
      // 是否显示弹出层
      open: false,
      // 机构名称
      deptname: undefined,

      //表单
      form: {},
      defaultProps: {
        children: "children",
        label: "name",
      },

      // 表单校验
      rules: {},
      userInfo: {},
      //PDF文件处理弹出框
      pdfForm: {
        visible: false,
        title: "PDF文件处理",
        fullscreen: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        list: [],
      },
      contentPdf: {},
      bookMarkForm: {
        visible: false,
        title: "PDF书签处理",
        fullscreen: true,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        width: "100%",
        action: "",
        textMark: "",
        list: [],
      },
      uploadLoading: false,
      mergeLoading: false,
      contentUploadLoading: false,
      fileData: {
        fileList: [],
        isUpload: false,
        public: 1,
        inputPdfUrl_shuqian: "",
        inputPdfUrl_shuqian_path: "",
      },
      dialogKey: 0,
      isSelectContent: false,
    };
  },

  computed: {
    columnSlots: function () {
      return this.tableColumn.filter((item) => {
        return item.slots;
      });
      // console.log(this.tableColumn.filter((item) => {return item.slots}))
    },
  },
  watch: {
    // 根据名称筛选机构树
    deptname(val) {
      this.$refs.tree.filter(val);
    },
  },
  beforeCreate() { },
  created() {
    // this.bookMarkForm.visible = true;
    this.downloadtplurl = bookeditService.bookeditimportTemplate(); //下载模版地址
    this.queryParams.sortinfo = this.defsort;
    this.getforminfo(this.mid);
    this.getUser();
  },
  methods: {
    getUser() {
      getUserProfile().then((response) => {
        this.userInfo = response.data.user;
      });
    },
    getName(row) {
      console.log(row, "测试");
    },

    exportSubmit(fieldlist) {
      var that = this;
      var tfa = [];
      for (var i in fieldlist) {
        var byname = "";
        if (fieldlist[i].byname != null) {
          byname = fieldlist[i].byname;
        }
        tfa.push(fieldlist[i].field + "=" + byname);
      }
      var durl = bookeditService.bookeditExportUrl(
        this.mid,
        this.queryParams,
        tfa
      );
      try {
        this.downloadbyurl(durl, null, function (ers) {
          if (ers.currentTarget.status != 200) {
            that.msgError("导出错误");
          }
        });
      } catch (error) {
        console.log(chalk.red(error));
      }
    },
    importsubmit(rows) {
      //开始导入
      bookeditService
        .bookeditImport(this.mid, rows.datalist, rows.importoption)
        .then((rs) => {
          if (rs.data.row.Failmsg != null && rs.data.row.Failmsg != "") {
            this.upload_errinfo = rs.data.row.Failmsg.split("|");
            this.showerrinfo = true;
            this.importError = true;
          } else {
            this.isupladfinish = true;
            this.msgSuccess("导入成功");
            this.getList(this.mid);
          }
        });
    },
    //导入导出
    handleImport(type) {
      this.importError = false;
      this.isupladfinish = false;
      this.importType = type;
      this.importOpen = true;
    },
    handleCommand(e) {
      //  console.log(e);
      this[e] = true;
    },

    getFun(item, row) {
      return this[item.slotsFun.funName](
        item.slotsFun.dt,
        row[item.slotsFun.key],
        item.slotsFun.key
      );
    },

    //查看
    Oncelldblclick(row) {
      this.handleUpdate(row.row, "查看");
      this.isview = true;
    },

    getTreeselect() {
      if (this.model_info.Category != "4" && this.model_info.Category != "5") {
        return false;
      }
      this.treeOptions = [];
      let opt = {
        id: 0,
        children: [],
      };
      opt[this.TreeAttr.tree_name] = "(无上级)";

      if (this.model_info.Category == "5") {
        opt.children = this.LeftTreeData;
      } else {
        opt.children = this.dataList;
      }

      this.treeOptions.push(opt);
      // console.log(this.treeOptions);
    },

    /** 查询数据 */
    getList(mid) {
      this.loading = true;

      bookeditService
        .listBookedit(this.queryParams)
        .then((response) => {
          this.loading = false;
          if (this.treeConfig.parentField != null) {
            var tlist = [];
            for (let k in response.data.list) {
              let tonef = response.data.list[k];
              tonef["label"] = tonef[this.treeConfig.treeNode];

              tlist.push(JSON.parse(JSON.stringify(tonef)));
            }
            try {
              this.dataList = this.handleTree(
                tlist,
                "id",
                this.treeConfig.parentField
              );
            } catch (error) {
              console.log(" this.dataList error", error);
            }
          } else {
            this.dataList = response.data.list;
          }

          this.total = response.data.total;
        })
        .catch(() => {
          this.loading = false;
          // this.msgError("加载数据出错,请先检查表单配置!");
        });
    },

    // 筛选节点
    filterNode(value, data) {
      console.log(value, data);
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },

    // 取消按钮
    cancel() {
      // this.reset();
      this.open = false;
      this.isview = false;
    },
    // 表单重置
    reset() {
      this.form = {};
      this.formData = null;
      // this.resetQuery();
    },

    /** 新增按钮操作 */
    handleAdd() {
      //this.reset();
      // this.getTreeselect();
      this.hidequeryparamform = true;
      this.formData = null;
      let tformdata = JSON.parse(this.model_info.Formresource);
      tformdata.formstatus = "add";

      this.$nextTick((_) => {
        this.formData = this.binddicttotablecolumn(tformdata);
      });

      this.open = true;
      this.title = "添加数据";
    },

    /** 修改按钮操作 */
    handleUpdate(row, til) {
      this.reset();
      // this.getTreeselect();
      //this.getTreeselect();
      const id = row.id || this.ids[0];
      bookeditService.getBookedit(id).then((response) => {
        this.form.id = response.data.row.id;

        //填充表单值
        let formvalue = response.data.row; //表单填写内容

        let array = Object.keys(formvalue);
        this.reformatformdata(formvalue);
        let formData = JSON.parse(this.model_info.Formresource); //表单数据(字段,布局等)
        formData.formstatus = "edit";

        this.formData = formData;
        this.$nextTick((_) => {
          //    formData;
          this.formData = this.binddicttotablecolumn(formData);
          this.$refs.form.setFormData(formvalue);
        });

        this.open = true;
        this.title = til || "修改";
      });
    },

    /** 修改按钮操作 */
    erp_send_erp(row) {
      this.reset();
      // this.getTreeselect();
      //this.getTreeselect();
      const id = row.id || this.ids[0];
      bookeditService.getBookedit(id).then((response) => {
        this.form.id = response.data.row.id;

        //填充表单值
        let formvalue = response.data.row; //表单填写内容
        let staaa = formvalue.erp_send_status == '已缴送' ? '待缴送' : '已缴送';
        bookeditService
          .editBookedit(this.mid, {
            erp_send_status: staaa,
            id: formvalue.id,
          })
          .then((rs) => {
            this.msgSuccess("操作成功");
            this.cancel();
            this.handleQuery();
          })
          .catch(() => {
            // this.msgError("提交失败!");
          });
      });
    },

    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form
        .getFormData()
        .then((data) => {
          if (this.form.id != null) {
            //修改
            data.id = this.form.id;
            bookeditService
              .editBookedit(this.mid, data)
              .then((rs) => {
                this.msgSuccess("操作成功");
                this.cancel();
                this.handleQuery();
              })
              .catch(() => {
                // this.msgError("提交失败!");
              });
          } else {
            //新增
            bookeditService
              .addBookedit(this.mid, data)
              .then((rs) => {
                this.msgSuccess("操作成功");
                this.cancel();
                this.handleQuery();
              })
              .catch(() => {
                // this.msgError("提交失败!");
              });
          }
        })
        .catch((e) => {
          this.msgError("表单验证不通过,请检查输入");
        });

      return true;
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const did = row.id || (this.ids ? this.ids.join(",") : "");
      this.$confirm("是否确认删除所选数据项?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return bookeditService.delBookedit(did);
        })
        .then(() => {
          this.handleQuery();
          this.msgSuccess("删除成功");
        })
        .catch(function () { });
    },

    normalizerDeptTreeNode(e) {
      //      e.id = e.id
      e.label = e.name;
      return e;
    },

    //更新书单
    handleApiList() {
      let postData = {
        action: "getBookList",
      };
      this.upLoading = true;

      bookeditService
        .editBookedit(this.mid, postData)
        .then((rs) => {
          this.msgSuccess("获取成功");
          this.cancel();
          this.handleQuery();
          this.upLoading = false;
        })
        .catch(() => {
          // this.msgError("提交失败!");
          this.upLoading = false;
        });
    },

    handleAddFile(data) {
      this.rid = data.row.id;
      const id = data.row.id || this.ids[0];
      bookeditService.getBookedit(id).then((response) => {
        let resp = response.data.row;
        this.contentPdf.id = resp.file_id;

        this.pdfForm.list = [];
        this.bookMarkForm.textMark = "";
        if (resp.file_forhead != "") {
          this.pdfForm.list = JSON.parse(resp.file_forhead);
        }
        if (resp.edit_txt != "") {
          this.bookMarkForm.textMark = JSON.parse(resp.edit_txt).url;
          this.$set(
            this.bookMarkForm,
            "textMark",
            JSON.parse(resp.edit_txt).url
          );

          console.log(this.bookMarkForm.textMark + "--------------");
        }
        if (resp.edit_txt == "" && resp.origin_txt != "") {
          this.bookMarkForm.textMark = JSON.parse(resp.origin_txt).url;
        }
      });

      this.pdfForm.visible = true;


    },

    // 文件上传
    handelChange(file, fileList) {
      this.fileData.fileList = fileList;

      this.pdfForm.list = fileList;
      for (let i = 0; i < this.pdfForm.list.length; i++) {
        if (this.pdfForm.list[i].isContent) {
          continue;
        }

        this.$set(this.pdfForm.list[i], "isContent", false);
      }

      // console.log("文件修改执行的函数", file, fileList);
    },
    handleRemove(file, fileList) {
      // console.log("移除文件执行的函数", file, fileList);
      this.fileList = fileList;
    },
    handlePreview(file) {
      console.log("点击已经上传的文件", file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      console.log("移除之前执行的函数", fileList);
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handelSend() {
      let fileArr = new Array();
      let markArr = new Array();
      let isAllFinish = true;
      let isAllMark = true;

      for (let index = 0; index < this.pdfForm.list.length; index++) {
        let obj = this.pdfForm.list[index];
        fileArr.push(obj.fileId);
        markArr.push(obj.bookmark);
        if (!obj.fileId) {
          isAllFinish = false;
        }
        if (
          (obj.bookmark == undefined ||
            obj.bookmark == null ||
            obj.bookmark == "") &&
          !obj.isContent
        ) {
          isAllMark = false;
        }
      }

      if (!isAllFinish) {
        this.msgError("请先确保所有文件都已上传,再进行合并操作!");
        return false;
      }
      // if (!isAllMark) {
      //   this.msgError("请先确保所有书签信息都已填写,再进行合并操作!");
      //   return false;
      // }

      for (let index = 0; index < this.pdfForm.list.length; index++) {
        this.$set(this.pdfForm.list[index], "isFinish", true);
      }
      // this.contentPdf.id = this.rid;
      // let sqId = this.contentPdf.id;
      let postData = {
        id: this.rid.toString(),
        files: fileArr.toString(),
        booktext: markArr.toString(),
        cid: this.contentPdf.id,
        action: "merge",
        tocFile: "",
        fileList: JSON.stringify(this.pdfForm.list),
      };

      // console.log(this.pdfForm.list);
      // return false;
      this.mergeLoading = true;
      bookeditService
        .writeBookmarkByFile(this.mid, postData)
        .then((rs) => {
          this.msgSuccess("合并成功!");
          this.pdfForm.visible = false;
          this.mergeLoading = false;
        })
        .catch(() => {
          this.mergeLoading = false;
          this.msgError("操作失败，请重新尝试!");
        });
    },

    // 文件上传

    handUpload(row, index) {
      //未上传进行上传
      let tokenReq = {
        platform: "digital",
        type: "general",
        source: "system",
        public: "1",
      };
      const file = this.pdfForm.list[index].raw;
      uploadServer(tokenReq, file).then((response) => {
        if (response.code == 0) {
          this.$set(this.pdfForm.list[index], "fileId", response.data.id);
          this.$set(this.pdfForm.list[index], "url", response.data.url);
          this.$set(this.pdfForm.list[index], "path", response.data.path);
          this.$set(this.pdfForm.list[index], "hash", response.data.hash);
          this.$set(this.pdfForm.list[index], "isFinish", true);
        } else {
          this.msgError("上传失败!" + res.msg);
        }
      });
    },

    /**
     * 表格字段上移方法
     */
    moveUpward(row, index) {
      // pdfForm.list 列数据
      if (index > 0) {
        let upData = this.pdfForm.list[index - 1];
        this.pdfForm.list.splice(index - 1, 1);
        this.pdfForm.list.splice(index, 0, upData);
        console.log("移动成功");
      } else {
        console.log("第一条数据");
        this.$message({
          message: "已经是第一条，上移失败",
          type: "error",
        });
      }
    },
    /**
     * 表格字段下移方法
     */
    moveDown(row, index) {
      if (index + 1 == this.pdfForm.list.length) {
        this.$message({
          message: "已经是最后一条，下移失败",
          type: "error",
        });
      } else {
        let downData = this.pdfForm.list[index + 1];
        this.pdfForm.list.splice(index + 1, 1);
        this.pdfForm.list.splice(index, 0, downData);
      }
    },
    /**
     * 表格字段下移方法
     */
    deleteFile(row, index) {
      this.pdfForm.list.splice(index, 1);
    },
    selectContent(row, index) {
      for (let i = 0; i < this.pdfForm.list.length; i++) {
        if (index == i) {
          this.$set(this.pdfForm.list[i], "isContent", true);
          this.$set(this.pdfForm.list[i], "bookmark", "");
          continue;
        }
        this.$set(this.pdfForm.list[i], "isContent", false);
      }

      if (this.pdfForm.list[index].fileId) {
        row.isFinish = true;

      }
      this.handBookMark(row, index);
    },

    fileDiaClose() {
      this.fileData.fileList = [];
    },

    handBookMark(row, index) {
      // console.log(row.fileId +"---------------------"+ this.contentPdf.id)
      if (row.fileId != this.contentPdf.id) {
        this.bookMarkForm.textMark = "";
      } else {
        const id = this.rid || this.ids[0];
        bookeditService.getBookedit(id).then((response) => {
          let resp = response.data.row;

          this.bookMarkForm.textMark = "";
          // if (resp.file_forhead != "") {
          //   this.pdfForm.list = JSON.parse(resp.file_forhead);
          // }
          if (resp.edit_txt != "") {
            this.bookMarkForm.textMark = JSON.parse(resp.edit_txt).url;
          }
          if (resp.edit_txt == "" && resp.origin_txt != "") {
            this.bookMarkForm.textMark = JSON.parse(resp.origin_txt).url;
          }
        });
      }

      //再次编辑

      this.contentPdf = row;
      this.contentPdf.id = row.fileId;
      this.contentPdf.index = index;
      this.bookMarkForm.visible = true;
      this.bookMarkForm.action = "reEdit";


    },
    tableRowClassName({ row, rowIndex }) {
      if (row.isContent) {
        return "success-row";
      }
      return "";
    },
    getBookInfo(value) {
      //写入书签后的正文部分

      let finalPdf = JSON.parse(JSON.parse(value).FileContent);

      this.$set(
        this.pdfForm.list[this.contentPdf.index],
        "name",
        finalPdf.name
      );
      this.$set(
        this.pdfForm.list[this.contentPdf.index],
        "fileId",
        JSON.parse(value).FileId
      );
      this.$set(this.pdfForm.list[this.contentPdf.index], "url", finalPdf.url);
      this.$set(this.pdfForm.list[this.contentPdf.index], "isFinish", true);
    },

    getContent(value) {
      console.log("返回：", value);
    },
  },
};
</script>
<style rel="stylesheet/scss" scoped>
.form-data {
  margin: 0 -2rem;
}

.btn-orange {
  background-color: #ff9648;
  color: #fff;
}

.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
  background-color: #2196f3;
  color: #fff;
}

::v-deep .scrolldialog {
  max-height: 82vh;
}

::v-deep .el-dialog__body {
  height: calc(100vh - 300px);
  overflow-y: scroll;
}

.head-container {
  font-size: 14px;
}
</style>
<style lang="scss">
.bookMarkForm {
  .el-dialog {
    .el-dialog__body {
      height: auto !important;
      overflow-y: scroll;
    }
  }
}
</style>
<!-- <style rel="stylesheet/scss" scoped>
.form-data {
  margin: 0 -2rem;
}
.btn-orange {
  background-color: #ff9648;
  color: #fff;
}

.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background-color: #2196f3;
  color: #fff;
}

::v-deep .scrolldialog {
  max-height: 82vh;
}

::v-deep .el-dialog__body {
  height: calc(100vh - 300px);
  /* overflow-y: scroll; */
}

.head-container {
  font-size: 14px;
}
::v-deep .el-dialog {
  /* height: 90vh; */
  overflow: auto;
}
</style>
<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style> -->
