@import "@/styles/variables.scss"; 








































































































































































































.children-list {
  max-height: 200px;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.05);
  padding: 10px;
  margin-top: 10px;
}
