@import "@/styles/variables.scss"; 















































































































































































































































































































































































































































































.analysis {
  width: 100%;
  height: 100%;
  background: #f6f8f9 !important;
}
.box2 {
  background-color: #fff;
  border-radius: 12px;
  padding: 0px 30px 20px;
  box-sizing: border-box;
}
.box {
  background-color: #fff;
  border-radius: 12px;
  padding: 0px 30px 20px;
  box-sizing: border-box;
  &.no-radius {
    border-radius: 0px;
  }
  h4 {
    border-bottom: 1px solid #f9f9f9;
    line-height: 40px;
  }
  &-content {
    display: flex;
    justify-content: space-around;
    // &:nth-child(1) {
    //   border-right: 1px solid #f9f9f9;
    // }
    &-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .h2 {
        font-size: 36px;
        font-weight: normal;
      }
      span {
        color: #8b8b8b;
      }
    }
  }
}
.box-circle {
  padding-top: 20px;
}
.box-userIcon {
  font-size: 20px;
  padding: 0 2vw;
}
.saleOfLeave {
  padding: 0 20px;
  height: 300px;
  overflow: hidden;
  // background: pink;
  &-content {
    &-item {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      // width: 80%;
      // height: 80%;
      // margin-top: 20px;
      line-height: 60px;

      border-bottom: 1px solid #f2f2f2;
      div {
        width: 33%;
        text-align: center;
        &:nth-child(1) {
          color: #5089ed;
          text-align: left;
        }
        &:nth-child(2) {
          text-align: left;
          font-weight: bold;
        }
        &.active1 {
          color: #5089ed !important;
        }
        &.active2 {
          color: #68bbc4 !important;
        }
        & .active3 {
          color: #fbd07a !important;
        }
      }
    }
  }
}
.circles {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #0099ff;
}
