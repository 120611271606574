@import "@/styles/variables.scss"; 























































































.line {
  display: inline-block;
  width: 40px;
  height: 1px;
  background: black;
  margin: 0 5px;
}

.many {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
  .h2 {
    position: relative;
    text-align: center;
    display: inline;
    width: 100%;
    color: black;
    font-weight: bold;
    font-size: 16px;

    display: flex;
    justify-content: center;
    align-items: center;
    // &::before {
    //   content: "";
    //   display: inline-block;
    //   width: 40px;
    //   height: 1px;
    //   background: black;
    //   position: absolute;
    //   top: 50%;
    //   left: 30%;
    //   transform: translateY(-50%);
    // }
    // &::after {
    //   content: "";
    //   display: inline-block;
    //   width: 40px;
    //   height: 1px;
    //   background: black;
    //   position: absolute;
    //   top: 50%;
    //   right: 30%;
    //   transform: translateY(-50%);
    // }
  }
  &-content {
    width: 100%;
    &-box {
      width: 100%;
      &-item {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 20px;
        div {
          width: 30%;
          &:nth-child(1) {
            font-size: 16px;
            font-weight: bold;
            color: #1890ff;
          }
          &:nth-child(2) {
            color: #a5a5a5;
          }
          &:nth-child(3) {
            color: #ff0000;
            font-size: 16px;
            text-align: right;
          }
          &:nth-child(4) {
            color: #a5a5a5;
            margin-top: 10px;
            text-align: left;
          }
          &:nth-child(5) {
            color: #a5a5a5;
            margin-top: 10px;
            text-align: right;
          }
        }
      }
    }
  }

  .more {
    width: 206px;
    height: 41px;
    background-color: rgba(236, 237, 241, 0.5);
    font-size: 18px;
    line-height: 41px;
    text-align: center;
    margin-top: 30px;
    border-radius: 5px;
  }
}
