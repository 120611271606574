@import "@/styles/variables.scss"; 




























































.detail {
  padding: 0 10%;
  // background-image: url('../assets/image/background.png');
  // background-repeat: no-repeat;
  // background-attachment: fixed;
  // background-size: cover;
  .text {
    background-color: #fff;
    padding: 10px;
    min-height: 62.5rem;

    .title {
      text-align: center;
      font-weight: 900;
      font-size: 24px;
      margin-bottom: 1.25rem;
    }

    .time {
      font-size: 18px;
      color: #bbb;
      text-align: center;
      margin-bottom: 1.25rem;
    }

    .downLoad {
      color: rgb(51, 122, 183);
      margin-bottom: 5px;
    }
  }
}
