<template>
    <div class="app-container1">
        <div>
            <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="100px">
                <el-form-item label="月份" prop="datetime">
                    <datetime-select v-model="queryParams.datetime" type="month" @change="onQueryDatetimeChange" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <vxe-grid resizable ref="xGrid" border show-overflow highlight-hover-row :loading="loading"
            :toolbar-config="tableToolbar" :columns="tableColumn" :data="dataList">
            <!--自定义空数据模板-->
            <template #empty>
                <span>
                    <p>暂无数据</p>
                </span>
            </template>
        </vxe-grid>
    </div>
</template>

<script>
import { listUserScoreStatEx } from "@/api/core/system/userscore";

export default {
    data() {
        const date = new Date()
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        let quarter = 1
        if (month >= 1 && month <= 3) {
            quarter = 1
        } else if (month >= 4 && month <= 6) {
            quarter = 2
        } else if (month >= 7 && month <= 9) {
            quarter = 3
        } else if (month >= 10 && month <= 12) {
            quarter = 4
        }

        return {
            // 工具栏
            tableToolbar: {
                perfect: true,
                zoom: false,
                custom: false,
                refresh: {
                    query: this.handleQuery
                },
                slots: {
                    buttons: 'toolbar_buttons'
                }
            },
            // 列
            tableColumn: [
                { field: 'key', title: '部门' },
                { field: '0', title: year + '年' + month + '月总积分' },
                { field: '1', title: year + '年' + month + '月人均积分' },
                { field: '2', title: year + '年' + quarter + '季度总积分' },
                { field: '3', title: year + '年' + quarter + '季度人均积分' },
                { field: '4', title: year + '年总积分' },
                { field: '5', title: year + '年人均积分' },
            ],
            // 遮罩层
            loading: true,
            queryParams: {
                datetime: parseInt(new Date().getTime() / 1000)
            },
            dataList: []
        }
    },
    created() {
        this.loadData()
    },
    methods: {
        loadData() {
            if (!this.queryParams.datetime) {
                this.msgError("请选择月份");
                return
            }

            this.loading = true;
            listUserScoreStatEx({
                type: 'dept_all',
                datetime: this.queryParams.datetime,
                limit: 10
            }).then(response => {
                const date = new Date(this.queryParams.datetime * 1000)
                const year = date.getFullYear()
                const month = date.getMonth() + 1
                let quarter = 1
                if (month >= 1 && month <= 3) {
                    quarter = 1
                } else if (month >= 4 && month <= 6) {
                    quarter = 2
                } else if (month >= 7 && month <= 9) {
                    quarter = 3
                } else if (month >= 10 && month <= 12) {
                    quarter = 4
                }

                for (let i = 0; i < this.tableColumn.length; i++) {
                    const d = this.tableColumn[i]
                    if (d.field == '0') {
                        d.title = year + '年' + month + '月总积分'
                    } else if (d.field == '1') {
                        d.title = year + '年' + month + '月人均积分'
                    } else if (d.field == '2') {
                        d.title = year + '年' + quarter + '季度总积分'
                    } else if (d.field == '3') {
                        d.title = year + '年' + quarter + '季度人均积分'
                    } else if (d.field == '4') {
                        d.title = year + '年总积分'
                    } else if (d.field == '5') {
                        d.title = year + '年人均积分'
                    }
                }

                this.loading = false;
                const list = response.data.list
                const all = {
                    key: '全公司',
                    "0": 0,
                    "1": 0,
                    "2": 0,
                    "3": 0,
                    "4": 0,
                    "5": 0,
                }
                for (let i = 0; i < list.length; i++) {
                    const d = list[i]
                    for (let j = 0; j < d.values.length; j++) {
                        const key = j + ''
                        const value = d.values[j]
                        d[key] = value
                        all[key] += parseFloat(value)
                    }
                }
                list.push(all)
                this.dataList = list
            })
        },
        onQueryDatetimeChange(e) {
            if (!this.queryParams.datetime) {
                this.queryParams.datetime = parseInt(new Date().getTime() / 1000)
            }
        },
        handleQuery() {
            this.loadData()
        }
    }
}
</script>

<style scoped>
.app-container1 {
    width: 100%;
    padding-top: 10px;
}

.chart {
    width: 100%;
    height: 500px;
    margin-top: 20px;
}
</style>