@import "@/styles/variables.scss"; 

























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































::v-deep .scrolldialog {
    max-height: 82vh;
}

::v-deep .el-dialog__body {
    height: calc(100vh - 300px);
    overflow-y: scroll;
}

.building-select {
	position: relative;
	border: dashed 1px #eee;
	padding: 5px;
	.button {
        font-size: 13px;
        color: #ccc;
    }
    .clear {
        padding: 5px 8px;
        position: absolute;
        right: 0px;
        top: -5px;
		height: 30px;
        cursor: default;
    }
}
