<template>
    <div class="app-container">
        <el-form
            :model="queryParams"
            ref="queryForm"
            :inline="true"
            label-width="68px"
            label-position="left"
        >
            <el-form-item label="名称" prop="name">
                <el-input
                    v-model="queryParams.name"
                    placeholder="请输入名称"
                    clearable
                    size="small"
                    @keyup.enter.native="handleQuery"
                />
            </el-form-item>
            <el-form-item>
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleQuery"
                    >搜索</el-button
                >
                <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                >
            </el-form-item>
        </el-form>
        <!-- 
		<vxe-grid resizable ref="xGrid" row-id="id" stripe show-overflow highlight-hover-row :loading="loading"
			:toolbar-config="tableToolbar" :columns="tableColumn" :data="dataList"
			@checkbox-change="checkboxChangeEvent" @checkbox-all="checkboxChangeEvent">
			<template #toolbar_buttons>
				<el-button style="margin-left: 10px" type="primary" icon="el-icon-plus" size="mini" @click="handleAdd"
					v-hasPermi="[
					'/core/userpool/add',
				]">新增</el-button>

				<el-button type="success" icon="el-icon-edit" size="mini" :disabled="single" @click="handleUpdate"
					v-hasPermi="[
					'/core/userpool/get',
					'/core/userpool/edit',
				]">修改</el-button>

				<el-button type="danger" icon="el-icon-delete" size="mini" :disabled="multiple" @click="handleDelete"
					v-hasPermi="[
					'/core/userpool/del',
				]">删除</el-button>
			</template>

			<template #defaultopr="{ row }">
				<el-button size="mini" type="text" icon="el-icon-edit" @click.stop="handleUpdate(row)">修改
				</el-button>
				<el-button size="mini" type="text" icon="el-icon-delete" @click.stop="handleDelete(row)">删除
				</el-button>
			</template>

			<template #empty>
				<span>
					<p>暂无数据</p>
				</span>
			</template>
		</vxe-grid>

		<vxe-pager background size="small" :loading="loading" :current-page.sync="queryParams.pageNum"
			:page-size.sync="queryParams.pageSize" :total="total" @page-change="handlePageChange">
		</vxe-pager> -->

        <jf-table
            :loading="loading"
            :columns="tableColumn"
            :data="dataList"
            :toolbar-config="tableToolbar"
            :query-params="queryParams"
            @checkbox-change="checkboxChangeEvent"
            @page-change="handlePageChange"
            :total="total"
            @change="tableChange"
            defaultoprWidth="280"
        >
            <template slot="toolbar_btn_left">
                <el-button
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    @click="handleAdd"
                    v-hasPermi="['/core/userpool/add']"
                    >新增</el-button
                >

                <el-button
                    type="success"
                    icon="el-icon-edit"
                    size="mini"
                    :disabled="single"
                    @click="handleUpdate"
                    v-hasPermi="['/core/userpool/get', '/core/userpool/edit']"
                    >修改</el-button
                >

                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    :disabled="multiple"
                    @click="handleDelete"
                    v-hasPermi="['/core/userpool/del']"
                    >删除</el-button
                >
            </template>

            <template slot="toolbar_btn_row" slot-scope="{ row }">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-menu"
                    @click.stop="handleMenu(row)"
                    >菜单
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-user"
                    @click.stop="handleRole(row)"
                    >角色
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-edit"
                    @click.stop="handleUpdate(row)"
                    >修改
                </el-button>
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click.stop="handleDelete(row)"
                    >删除
                </el-button>
            </template>
        </jf-table>

        <el-dialog
            :close-on-click-modal="false"
            :title="title"
            :visible.sync="open"
            width="1080px"
            append-to-body
        >
            <el-form
                ref="form"
                :model="form"
                :rules="rules"
                label-width="160px"
            >
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="名称" prop="name">
                            <el-input
                                v-model="form.name"
                                placeholder="请输入"
                                clearable
                                :disabled="
                                    form.id != '' && form.id != undefined
                                "
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="显示名称" prop="display_name">
                            <el-input
                                v-model="form.display_name"
                                placeholder="请输入"
                                clearable
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24" v-if="title == '修改'">
                        <el-form-item label="默认角色" prop="default_roles">
                            <el-select
                                v-model="form.default_roles"
                                placeholder="请选择"
                            >
                                <el-option
                                    v-for="item in roles"
                                    :key="item.code"
                                    :label="item.name"
                                    :value="item.code"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24" v-if="title == '修改'">
                        <el-form-item
                            label="可发展下级用户角色"
                            prop="referrer_roles"
                        >
                            <el-select
                                v-model="form.referrer_roles"
                                placeholder="请选择"
                                multiple
                            >
                                <el-option
                                    v-for="item in roles"
                                    :key="item.code"
                                    :label="item.name"
                                    :value="item.code"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item
                            label="发展下级加密密钥"
                            prop="referrer_encrypt_key"
                        >
                            <el-input
                                v-model="form.referrer_encrypt_key"
                                placeholder="请输入"
                                clearable
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item
                            label="发展下级过期时间"
                            prop="referrer_expire"
                        >
                            <el-input
                                placeholder="请输入"
                                v-model.number="form.referrer_expire"
                                clearable
                                @input="updateValue"
                            >
                                <template slot="append">单位（秒）</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="默认头像" prop="default_avatar">
                            <el-upload
                                class="upload-demo"
                                accept="image/png, image/jpeg"
                                action="/file/upload"
                                :headers="uploadHeaders"
                                :data="uploadData"
                                :multiple="false"
                                :limit="1"
                                :file-list="imageList"
                                :on-success="handleUploadSuccess"
                                :on-error="handleUploadError"
                                :on-exceed="handleUploadExceed"
                                :on-remove="handleUploadRemove"
                                :http-request="uploadDss"
                                list-type="picture-card"
                            >
                                <el-button size="small" type="primary"
                                    >点击上传</el-button
                                >
                                <div slot="tip" class="el-upload__tip">
                                    只能上传jpg/png文件，且不超过500kb
                                </div>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item
                            label="验证码登录时自动注册"
                            prop="auto_register"
                        >
                            <el-switch
                                v-model="form.auto_register"
                                active-value="1"
                                inactive-value="0"
                            >
                            </el-switch>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item
                            label="三方登录时自动注册"
                            prop="tp_auto_register"
                        >
                            <el-switch
                                v-model="form.tp_auto_register"
                                active-value="1"
                                inactive-value="0"
                            >
                            </el-switch>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item
                            label="注册需要审核"
                            prop="register_review"
                        >
                            <el-switch
                                v-model="form.register_review"
                                active-value="1"
                                inactive-value="0"
                            >
                            </el-switch>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item
                            label="允许多ip登录"
                            prop="allow_multi_ip_login"
                        >
                            <el-switch
                                v-model="form.allow_multi_ip_login"
                                active-value="1"
                                inactive-value="0"
                            >
                            </el-switch>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item
                            label="允许多端登录"
                            prop="allow_multi_client_login"
                        >
                            <el-switch
                                v-model="form.allow_multi_client_login"
                                active-value="1"
                                inactive-value="0"
                            >
                            </el-switch>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item
                            label="在线人数限制"
                            prop="max_online_users"
                        >
                            <el-input
                                v-model="form.max_online_users"
                                placeholder="请输入"
                                clearable
                                @input="updateOnlineValue"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item
                            label="账号不存在提示信息"
                            prop="login_no_account_msg"
                        >
                            <el-input
                                v-model="form.login_no_account_msg"
                                placeholder="请输入"
                                clearable
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item
                            label="删除用户方式"
                            prop="delete_user_type"
                        >
                            <el-select
                                v-model="form.delete_user_type"
                                placeholder="请选择删除用户方式"
                            >
                                <el-option
                                    v-for="item in delmode"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <!--<el-col :span="24">
					<el-form-item label="" prop="website_url">
						<el-input
							v-model="form.website_url"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="favicon">
						<el-input
							v-model="form.favicon"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="password_type">
						<el-input
							v-model="form.password_type"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="password_salt">
						<el-input
							v-model="form.password_salt"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="country_codes">
						<el-input
							v-model="form.country_codes"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="default_avatar">
						<el-input
							v-model="form.default_avatar"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="default_application">
						<el-input
							v-model="form.default_application"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="tags">
						<el-input
							v-model="form.tags"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="languages">
						<el-input
							v-model="form.languages"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="theme_data">
						<el-input
							v-model="form.theme_data"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="master_password">
						<el-input
							v-model="form.master_password"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="init_score">
						<el-input-number
							v-model="form.init_score"
							placeholder="请输入"
							controls-position="right"
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="enable_soft_deletion">
						<el-input
							v-model="form.enable_soft_deletion"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="is_profile_public">
						<el-input
							v-model="form.is_profile_public"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="mfa_items">
						<el-input
							v-model="form.mfa_items"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="account_items">
						<el-input
							v-model="form.account_items"
							placeholder="请输入"
							clearable
						/>
					</el-form-item> 
				</el-col>
				<el-col :span="24">
					<el-form-item label="" prop="remark">
						<el-input
							v-model="form.remark"
							placeholder="请输入"
							clearable
							type="textarea"
						/>
					</el-form-item> 
				</el-col>-->
                    <el-col :span="24">
                        <el-tabs v-model="activeName">
                            <el-tab-pane
                                label="扩展字段"
                                name="first"
                                :lazy="true"
                            >
                                <vxe-grid
                                    ref="paramsGrid"
                                    id="index"
                                    rowId="index"
                                    row-key
                                    keep-source
                                    max-height="290"
                                    border
                                    class="sortable-row-demo"
                                    :columns="paramsColumn"
                                    :data="form.account_ext_fields"
                                    :edit-rules="validRules"
                                    :edit-config="{
                                        trigger: 'click',
                                        mode: 'cell',
                                        showStatus: true,
                                    }"
                                    :toolbar-config="tableToolbar1"
                                    v-if="open && activeName == 'first'"
                                >
                                    <template #toolbar_buttons>
                                        <el-button
                                            type="primary"
                                            icon="el-icon-plus"
                                            size="mini"
                                            @click="addParam"
                                            >添加</el-button
                                        >
                                    </template>
                                    <template #paramsdefaultopr="{ row }">
                                        <el-button
                                            size="mini"
                                            type="text"
                                            icon="el-icon-delete"
                                            @click="handleDeleteParam(row)"
                                            >删除
                                        </el-button>
                                    </template>
                                </vxe-grid>
                            </el-tab-pane>
                            <el-tab-pane
                                label="个人页设置项"
                                name="second"
                                :lazy="true"
                            >
                                <vxe-grid
                                    ref="paramsGrids"
                                    id="index"
                                    rowId="index"
                                    row-key
                                    keep-source
                                    max-height="290"
                                    border
                                    class="sortable-row-demo"
                                    :columns="paramsColumn2"
                                    :data="form.account_items"
                                    :edit-rules="validRules2"
                                    :edit-config="{
                                        trigger: 'click',
                                        mode: 'cell',
                                        showStatus: true,
                                    }"
                                    :toolbar-config="tableToolbar1"
                                    v-if="open && activeName == 'second'"
                                >
                                    <template #toolbar_buttons>
                                        <el-button
                                            type="primary"
                                            icon="el-icon-plus"
                                            size="mini"
                                            @click="addAccountParam"
                                            >添加</el-button
                                        >
                                    </template>
                                    <template #name="{ row }">
                                        <el-select
                                            v-model="row.name"
                                            placeholder="请选择"
                                            @change="selectChange"
                                        >
                                            <el-option
                                                v-for="item in nameOptions"
                                                :key="item.name"
                                                :label="item.name"
                                                :value="item.name"
                                                :disabled="item.disabled"
                                            >
                                            </el-option>
                                        </el-select>
                                    </template>
                                    <template #paramsdefaultopr="{ row }">
                                        <el-button
                                            size="mini"
                                            type="text"
                                            icon="el-icon-delete"
                                            @click="
                                                handleDeleteAccountParam(row)
                                            "
                                            >删除
                                        </el-button>
                                    </template>
                                </vxe-grid>
                            </el-tab-pane>
                        </el-tabs>
                    </el-col>
                </el-row>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button
                    type="primary"
                    :loading="submitBtnLoading"
                    @click="submitForm"
                    >确 定</el-button
                >
                <el-button @click="cancel">返 回</el-button>
            </div>
        </el-dialog>

        <!-- 菜单按钮 -->
        <el-dialog
            :close-on-click-modal="false"
            title="菜单管理"
            :visible.sync="openMenu"
            width="80%"
            append-to-body
            custom-class="scrolldialog"
        >
            <MenuPage v-if="openMenu" :userpools="menuPool" />
            <div slot="footer" class="dialog-footer">
                <el-button @click="openMenu = false">关 闭</el-button>
            </div>
        </el-dialog>

        <!-- 角色按钮 -->
        <el-dialog
            :close-on-click-modal="false"
            title="角色管理"
            :visible.sync="openRole"
            width="80%"
            append-to-body
            custom-class="scrolldialog"
        >
            <RolePage v-if="openRole" :userpools="rolePool" />
            <div slot="footer" class="dialog-footer">
                <el-button @click="openRole = false">关 闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {
    listUserPool,
    getUserPool,
    addUserPool,
    editUserPool,
    delUserPool,
} from "@/api/core/system/userpool";
import { listRole } from "@/api/core/system/role";
import { getUserFields } from "@/api/core/system/user";
import Sortable from "sortablejs";
import { TimeSelect } from "element-ui";
import MenuPage from "@/views/core/system/menu";
import RolePage from "@/views/core/system/role";
import { getAccessToken } from "@/utils/tokenTool";
import {
    uploadServer,
    uploadBigApp,
    addBigOss,
    getNameByPath,
} from "@/api/dss/fastdfsServer.js";
export default {
    name: "userpool-list",
    components: {
        MenuPage,
        RolePage,
    },
    data() {
        return {
            tableToolbar: {
                perfect: false,
                zoom: true,
                custom: false,
                refresh: {
                    query: this.handleQuery,
                },
                slots: {
                    buttons: "toolbar_buttons",
                },
            },
            tableColumn: [
                // { type: "checkbox", width: 60, fixed: "left" },
                //{ field: "id", title: "id", minWidth: 120, fixed: "left" },
                {
                    field: "name",
                    title: "名称",
                    minWidth: 120,
                },
                {
                    field: "display_name",
                    title: "显示名称",
                    minWidth: 120,
                },
                /*{ field: "website_url", title: "", minWidth: 120 },
          { field: "favicon", title: "", minWidth: 120 },
          { field: "password_type", title: "", minWidth: 120 },
          { field: "password_salt", title: "", minWidth: 120 },
          { field: "country_codes", title: "", minWidth: 120 },
          { field: "default_avatar", title: "", minWidth: 120 },
          { field: "default_application", title: "", minWidth: 120 },
          { field: "tags", title: "", minWidth: 120 },
          { field: "languages", title: "", minWidth: 120 },
          { field: "theme_data", title: "", minWidth: 120 },
          { field: "master_password", title: "", minWidth: 120 },
          { field: "init_score", title: "", minWidth: 120 },
          { field: "enable_soft_deletion", title: "", minWidth: 120 },
          { field: "is_profile_public", title: "", minWidth: 120 },
          { field: "mfa_items", title: "", minWidth: 120 },
          { field: "account_items", title: "", minWidth: 120 },
          { field: "remark", title: "", minWidth: 120 },*/
                {
                    field: "create_by",
                    title: "创建者",
                    minWidth: 120,
                    visible: false,
                },
                {
                    field: "create_time",
                    title: "创建时间",
                    minWidth: 120,
                    formatter: "formatDate",
                    visible: false,
                },
                {
                    field: "update_by",
                    title: "更新者",
                    minWidth: 120,
                    visible: false,
                },
                {
                    field: "update_time",
                    title: "更新时间",
                    minWidth: 120,
                    formatter: "formatDate",
                    form_type: "3",
                },
                // {
                // 	field: "",
                // 	title: "操作",
                // 	width: 180,
                // 	fixed: "right",
                // 	align: "center",
                // 	slots: { default: "defaultopr" },
                // },
            ],
            loading: true,
            ids: [],
            single: true,
            multiple: true,
            total: 0,
            dataList: [],
            title: "",
            open: false,
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                id: undefined,
                name: undefined,
            },
            form: {},
            rules: {
                name: [
                    {
                        required: true,
                        message: "名称不能为空",
                        trigger: "blur",
                    },
                ],
                display_name: [
                    {
                        required: true,
                        message: "显示名称不能为空",
                        trigger: "blur",
                    },
                ],
            },
            roles: [],
            tableToolbar1: {
                perfect: false,
                zoom: true,
                custom: false,
                slots: {
                    buttons: "toolbar_buttons",
                },
            },
            paramsColumn: [
                {
                    field: "index",
                    title: "序号",
                    width: 80,
                    align: "center",
                    fixed: "left",
                },
                {
                    field: "name",
                    title: "名称",
                    minWidth: 120,
                    editRender: {
                        name: "input",
                        attrs: { type: "text", placeholder: "请输入名称" },
                    },
                },
                {
                    field: "type",
                    title: "类型",
                    minWidth: 120,
                    editRender: {
                        name: "select",
                        options: [
                            { label: "", value: "" },
                            { label: "char", value: "char" },
                            { label: "varchar", value: "varchar" },
                            { label: "text", value: "text" },
                            { label: "longtext", value: "longtext" },
                            { label: "int", value: "int" },
                            { label: "bigint", value: "bigint" },
                            { label: "double", value: "double" },
                            { label: "decimal", value: "decimal" },
                            { label: "enum", value: "enum" },
                            { label: "date", value: "date" },
                            { label: "datetime", value: "datetime" },
                        ],
                    },
                },
                {
                    field: "size",
                    title: "长度",
                    width: 120,
                    editRender: {
                        name: "input",
                        attrs: { type: "text", placeholder: "请输入名称" },
                    },
                },
                {
                    field: "default",
                    title: "默认值",
                    width: 120,
                    editRender: {
                        name: "input",
                        attrs: { type: "text", placeholder: "请输入默认值" },
                    },
                },
                {
                    field: "required",
                    title: "是否必填",
                    width: 120,
                    editRender: {
                        name: "select",
                        options: [
                            { label: "", value: "" },
                            { label: "否", value: "0" },
                            { label: "是", value: "1" },
                        ],
                    },
                },
                {
                    field: "validate_tag",
                    title: "验证规则",
                    width: 120,
                    editRender: {
                        name: "input",
                        attrs: { type: "text", placeholder: "请输入名称" },
                    },
                },
                {
                    field: "validate_msg",
                    title: "验证信息",
                    width: 120,
                    editRender: {
                        name: "input",
                        attrs: { type: "text", placeholder: "请输入名称" },
                    },
                },
                {
                    field: "remark",
                    title: "备注",
                    width: 120,
                    editRender: {
                        name: "input",
                        attrs: { type: "text", placeholder: "请输入备注" },
                    },
                },
                {
                    field: "sort",
                    title: "排序",
                    width: 60,
                    align: "center",
                    slots: {
                        default: () => {
                            return [
                                <span class="drag-btn">
                                    <i class="vxe-icon--menu"></i>
                                </span>,
                            ];
                        },
                    },
                },
                {
                    field: "",
                    title: "操作",
                    resizable: false,
                    width: 80,
                    fixed: "right",
                    align: "center",
                    slots: { default: "paramsdefaultopr" },
                },
            ],
            validRules: {
                name: [{ required: true, message: "请输入名称" }],
                type: [{ required: true, message: "请选择类型" }],
                size: [{ required: true, message: "请输入长度" }],
            },
            paramsColumn2: [
                {
                    field: "index",
                    title: "序号",
                    width: 80,
                    align: "center",
                    fixed: "left",
                },
                {
                    field: "name",
                    title: "名称",
                    minWidth: 400,
                    editRender: {},
                    slots: { default: "name", edit: "name" },
                },
                {
                    field: "visible",
                    title: "是否可见",
                    minWidth: 300,
                    editRender: {
                        name: "select",
                        options: [
                            { label: "", value: "" },
                            { label: "否", value: "0" },
                            { label: "是", value: "1" },
                        ],
                    },
                },
                {
                    field: "sort",
                    title: "排序",
                    width: 60,
                    align: "center",
                    slots: {
                        default: () => {
                            return [
                                <span class="drag-btn">
                                    <i class="vxe-icon--menu"></i>
                                </span>,
                            ];
                        },
                    },
                },
                {
                    field: "",
                    title: "操作",
                    resizable: false,
                    width: 120,
                    fixed: "right",
                    align: "center",
                    slots: { default: "paramsdefaultopr" },
                },
            ],
            validRules2: {
                name: [{ required: true, message: "请选择名称" }],
            },
            activeName: "first",
            nameOptions: [],
            choiceOptions: [],
            openMenu: false,
            menuPool: "",
            openRole: false,
            rolePool: "",
            uploadHeaders: {},
            uploadData: {},
            imageList: [],
            // 删除用户方式
            delmode: [
                { value: "0", label: "物理删除" },
                { value: "1", label: "逻辑删除" },
            ],
            submitBtnLoading: false,
        };
    },
    watch: {
        open(val) {
            if (val) {
                this.rowDropParams();
            } else {
                if (this.sortableParams) {
                    this.sortableParams.destroy();
                    this.sortableParams = null;
                }
                if (this.sortableUser) {
                    this.sortableUser.destroy();
                    this.sortableUser = null;
                }
            }
        },
        activeName(val) {
            if (val == "second") {
                this.rowDropUser();
            } else {
                if (this.sortableUser) {
                    this.sortableUser.destroy();
                    this.sortableUser = null;
                }
            }
        },
    },
    created() {
        this.getList();
    },
    methods: {
        //自定义上传
        async uploadDss(request) {
            // 默认需要登录
            let tokenReq = {
                platform: "jpgfast",
                type: "general",
                source: "system",
                public: 1,
            };

            uploadServer(tokenReq, request.file).then((resp) => {
                // console.log(123);
                if (resp.code == 0) {
                    this.form.default_avatar = resp.data.url;
                } else {
                    this.$refs.fieldEditor.uploadFiles =
                        this.$refs.fieldEditor.uploadFiles.filter((e) => {
                            if (e.name !== file.name || e.status !== "ready") {
                                return e;
                            }
                        });

                    this.$message.error({
                        message: "上传失败,原因：" + res.msg,
                        duration: 2000,
                    });
                }
            });
        },
        rowDropParams() {
            this.$nextTick(() => {
                if (this.sortableParams) {
                    return;
                }
                const xTable = this.$refs.paramsGrid;
                this.sortableParams = Sortable.create(
                    xTable.$el.querySelector(
                        ".body--wrapper>.vxe-table--body tbody"
                    ),
                    {
                        handle: ".drag-btn",
                        onEnd: ({ newIndex, oldIndex }) => {
                            const currRow = this.form.account_ext_fields.splice(
                                oldIndex,
                                1
                            )[0];
                            this.form.account_ext_fields.splice(
                                newIndex,
                                0,
                                currRow
                            );
                            for (
                                let i = 0;
                                i < this.form.account_ext_fields.length;
                                i++
                            ) {
                                this.form.account_ext_fields[i].sort = i + 1;
                            }
                        },
                    }
                );
            });
        },
        rowDropUser() {
            this.$nextTick(() => {
                if (this.sortableUser) {
                    return;
                }
                const xTable = this.$refs.paramsGrids;
                this.sortableUser = Sortable.create(
                    xTable.$el.querySelector(
                        ".body--wrapper>.vxe-table--body tbody"
                    ),
                    {
                        handle: ".drag-btn",
                        onEnd: ({ newIndex, oldIndex }) => {
                            const currRow = this.form.account_items.splice(
                                oldIndex,
                                1
                            )[0];
                            this.form.account_items.splice(
                                newIndex,
                                0,
                                currRow
                            );
                            for (
                                let i = 0;
                                i < this.form.account_items.length;
                                i++
                            ) {
                                this.form.account_items[i].sort = i + 1;
                            }
                        },
                    }
                );
            });
        },
        tableChange(e) {
            console.log(e);
        },
        getList() {
            this.loading = true;
            this.ids = [];
            this.single = true;
            this.multiple = true;
            listUserPool(this.queryParams).then((response) => {
                this.dataList = response.data.list;
                this.total = response.data.total;
                this.loading = false;
            });
        },

        cancel() {
            this.open = false;
            this.reset();
        },

        reset() {
            this.uploadHeaders = {
                Authorization: getAccessToken(),
            };
            this.uploadData = {
                category: "cms",
                public: "1",
            };
            this.imageList = [];
            this.form = {
                name: undefined,
                display_name: undefined,
                website_url: undefined,
                favicon: undefined,
                password_type: undefined,
                password_salt: undefined,
                country_codes: undefined,
                default_avatar: undefined,
                default_application: undefined,
                tags: undefined,
                languages: undefined,
                theme_data: undefined,
                master_password: undefined,
                init_score: undefined,
                enable_soft_deletion: undefined,
                is_profile_public: undefined,
                mfa_items: undefined,
                account_items: [],
                account_ext_fields: [],
                default_roles: undefined,
                remark: undefined,
                referrer_roles: undefined,
                delete_user_type: undefined,
                referrer_encrypt_key: undefined,
                referrer_expire: undefined,
                auto_register: 0,
                tp_auto_register: 0,
                allow_multi_ip_login: 0,
                allow_multi_client_login: 0,
                max_online_users: undefined,
                login_no_account_msg: undefined,
            };
            this.resetForm("form");
        },

        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },

        resetQuery() {
            this.resetForm("queryForm");
            this.handleQuery();
        },

        handleAdd() {
            this.reset();
            this.open = true;
            this.title = "添加";
        },

        // 菜单按钮
        handleMenu(row) {
            // console.log(row);
            this.menuPool = row.name;
            this.openMenu = true;
        },

        // 角色
        handleRole(row) {
            // console.log(row);
            this.rolePool = row.name;
            this.openRole = true;
        },

        handleUpdate(row) {
            this.reset();
            this.activeName = "first";
            const id = row.id || (this.ids.length > 0 ? this.ids[0] : "");
            const loading = this.$loading({
                text: "正在加载",
            });
            getUserPool(id)
                .then((response) => {
                    let info = response.data;
                    // 扩展字段
                    info.account_ext_fields =
                        info.account_ext_fields == ""
                            ? []
                            : JSON.parse(info.account_ext_fields);
                    // 个人页设置项
                    info.account_items =
                        info.account_items == ""
                            ? []
                            : JSON.parse(info.account_items);
                    // 默认头像
                    if (info.default_avatar) {
                        this.imageList = [
                            {
                                url: info.default_avatar,
                            },
                        ];
                    }
                    // 可发展下级用户角色
                    info.referrer_roles = info.referrer_roles
                        ? info.referrer_roles.split(",")
                        : "";
                    // 发展下级过期时间
                    info.referrer_expire =
                        info.referrer_expire == 0 || !info.referrer_expire
                            ? ""
                            : info.referrer_expire;
                    // 在线人数限制
                    info.max_online_users =
                        info.max_online_users == 0 || !info.max_online_users
                            ? ""
                            : info.max_online_users;

                    this.form = info;

                    listRole({
                        pageSize: 100,
                        userpool: this.form.name,
                        status: "1",
                    })
                        .then((response) => {
                            this.roles = response.data.list;
                            loading.close();
                            this.open = true;
                            this.title = "修改";
                        })
                        .catch((e) => {
                            loading.close();
                        });
                    // 个人设置项-名称
                    getUserFields(this.form.name)
                        .then((response) => {
                            response.data.fields.map((item) => {
                                item.disabled = false;
                                this.form.account_items.map((val) => {
                                    if (item.name == val.name) {
                                        item.disabled = true;
                                    }
                                });
                            });
                            this.nameOptions = response.data.fields;

                            loading.close();
                        })
                        .catch((e) => {
                            loading.close();
                        });
                })
                .catch((e) => {
                    loading.close();
                });
        },
        addParam() {
            this.form.account_ext_fields.push({
                index: this.form.account_ext_fields.length + 1,
                name: "",
                type: "",
                size: "",
                default: "",
                required: "0",
                validate_tag: "",
                validate_msg: "",
                remark: "",
                sort: 0,
            });
        },
        handleDeleteParam(row) {
            let tip = "";
            if (row.id) {
                tip =
                    '删除字段将会导致数据丢失无法恢复，确定删除序号为"' +
                    row.index +
                    '"的字段?';
            } else {
                tip = '确定删除序号为"' + row.index + '"的字段?';
            }
            this.$confirm(tip, "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    const params = [];
                    let index = 1;
                    for (
                        let i = 0;
                        i < this.form.account_ext_fields.length;
                        i++
                    ) {
                        const p = this.form.account_ext_fields[i];
                        if (p.index != row.index) {
                            p.index = index++;
                            p.sort = p.index;
                            params.push(p);
                        }
                    }
                    this.form.account_ext_fields = params;
                })
                .catch(function () {});
        },
        addAccountParam() {
            this.form.account_items.push({
                index: this.form.account_items.length + 1,
                name: "",
                visible: "1",
                sort: 0,
            });
        },

        handleDeleteAccountParam(row) {
            this.$confirm(
                '确定删除序号为"' + row.index + '"的设置项?',
                "警告",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            )
                .then(() => {
                    const paramsa = [];
                    let index = 1;
                    for (let i = 0; i < this.form.account_items.length; i++) {
                        const pa = this.form.account_items[i];
                        if (pa.index != row.index) {
                            pa.index = index++;
                            pa.sort = pa.index;
                            paramsa.push(pa);
                        }
                    }
                    this.form.account_items = paramsa;
                })
                .catch(function () {});
        },

        selectChange() {
            this.nameOptions.map((item) => {
                item.disabled = false;
                this.form.account_items.map((val) => {
                    if (item.name == val.name) {
                        item.disabled = true;
                    }
                });
            });
        },
        // 发展下级过期时间
        updateValue(event) {
            if (parseInt(event) > 0) {
                this.form.referrer_expire = parseInt(event);
            } else {
                this.form.referrer_expire = 0;
            }
        },
        // 在线人数限制
        updateOnlineValue(event) {
            if (parseInt(event) > 0) {
                this.form.max_online_users = parseInt(event);
            } else {
                this.form.max_online_users = 0;
            }
        },
        submitForm() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    if (this.activeName == "first") {
                        this.$refs.paramsGrid.validate(true).then((errMap) => {
                            if (errMap) {
                                this.$message.error(
                                    "请填写扩展字段表格信息完整！"
                                );
                            } else {
                                if (
                                    this.form.account_items != "" ||
                                    this.form.account_items.length > 0
                                ) {
                                    let accountArr =
                                        this.form.account_items.filter(
                                            (item) => {
                                                if (
                                                    item.name == "" ||
                                                    item.name == undefined ||
                                                    item.name == null
                                                ) {
                                                    return item;
                                                }
                                            }
                                        );
                                    if (accountArr.length > 0) {
                                        this.$message.error(
                                            "请填写个人页设置项表格信息完整！"
                                        );
                                        this.activeName = "second";
                                    } else {
                                        this.submitFormParams(this.form);
                                    }
                                } else {
                                    this.submitFormParams(this.form);
                                }
                            }
                        });
                    } else {
                        this.$refs.paramsGrids
                            .validate(true)
                            .then((errMaps) => {
                                if (errMaps) {
                                    this.$message.error(
                                        "请填写个人页设置项表格信息完整！"
                                    );
                                } else {
                                    if (
                                        this.form.account_ext_fields != "" ||
                                        this.form.account_ext_fields.length > 0
                                    ) {
                                        let fieldsArr =
                                            this.form.account_ext_fields.filter(
                                                (item) => {
                                                    if (
                                                        item.name == "" ||
                                                        item.name ==
                                                            undefined ||
                                                        item.name == null ||
                                                        item.type == "" ||
                                                        item.type ==
                                                            undefined ||
                                                        item.type == null ||
                                                        item.size == "" ||
                                                        item.size ==
                                                            undefined ||
                                                        item.size == null
                                                    ) {
                                                        return item;
                                                    }
                                                }
                                            );
                                        if (fieldsArr.length > 0) {
                                            this.activeName = "first";
                                            this.$message.error(
                                                "请填写扩展字段表格信息完整！"
                                            );
                                        } else {
                                            this.submitFormParams(this.form);
                                        }
                                    } else {
                                        this.submitFormParams(this.form);
                                    }
                                }
                            });
                    }
                }
            });
        },
        submitFormParams(form) {
            let formInfo = this.deepCopy(form);
            // 可发展下级用户角色
            formInfo.referrer_roles =
                formInfo.referrer_roles || formInfo.referrer_roles.length > 0
                    ? formInfo.referrer_roles.join(",")
                    : "";
            // 发展下级过期时间
            formInfo.referrer_expire = formInfo.referrer_expire
                ? formInfo.referrer_expire
                : 0;
            // 在线人数限制
            formInfo.max_online_users = formInfo.max_online_users
                ? formInfo.max_online_users
                : 0;
            // 扩展字段
            formInfo.account_ext_fields = JSON.stringify(
                formInfo.account_ext_fields
            );
            // 个人页设置项
            formInfo.account_items = JSON.stringify(formInfo.account_items);

            this.submitBtnLoading = true;
            if (this.form.id != null) {
                editUserPool(formInfo)
                    .then((response) => {
                        if (response.code === 0) {
                            this.msgSuccess("修改成功");
                            this.open = false;
                            this.submitBtnLoading = false;
                            setTimeout(() => {
                                this.getList();
                            }, 300);
                        }
                    })
                    .catch((err) => {
                        this.submitBtnLoading = false;
                    });
            } else {
                addUserPool(formInfo)
                    .then((response) => {
                        if (response.code === 0) {
                            this.msgSuccess("新增成功");
                            this.open = false;
                            this.submitBtnLoading = false;
                            setTimeout(() => {
                                this.getList();
                            }, 300);
                        }
                    })
                    .catch((err) => {
                        this.submitBtnLoading = false;
                    });
            }
        },

        handleDelete(row) {
            const ids =
                row.id || (this.ids.length > 0 ? this.ids.join(",") : "");
            this.$confirm("确定删除吗?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    return delUserPool(ids);
                })
                .then(() => {
                    setTimeout(() => {
                        this.getList();
                    }, 300);
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },

        checkboxChangeEvent({ records }) {
            this.ids = records.map((item) => item.id);
            this.single = records.length != 1;
            this.multiple = !records.length;
        },

        handlePageChange({ currentPage, pageSize }) {
            if (this.queryParams.pageSize == pageSize) {
                this.getList();
            } else {
                this.queryParams.pageSize = pageSize;
                this.handleQuery();
            }
        },
        // 上传
        handleUploadSuccess(response, file, fileList) {
            // console.log(response, file, fileList);
            file.url = response.data.url;
            this.form.default_avatar = response.data.url;
        },
        handleUploadError(err) {
            // console.log(err);
            this.form.default_avatar = "";
            if (
                err &&
                (err.message.indexOf("larger than") > 0 ||
                    err.message.indexOf("413") > 0)
            ) {
                this.msgError("上传文件失败，文件大小超过限制");
            } else {
                this.msgError("上传文件失败");
            }
        },
        handleUploadExceed() {
            this.msgError("只能上传一个文件，请删除后重新上传");
        },
        handleUploadRemove() {
            this.form.default_avatar = "";
        },
    },
};
</script>
<style scoped>
.sortable-row-demo .drag-btn {
    cursor: move;
    font-size: 12px;
}
.sortable-row-demo .vxe-body--row.sortable-ghost,
.sortable-row-demo .vxe-body--row.sortable-chosen {
    background-color: #dfecfb;
}
</style>
