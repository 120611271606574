<template>
    <div class="app-container">
        <el-tabs v-model="activeName" type="card">
            <el-tab-pane label="加分设置" name="add" :lazy="true">
                <rulecategory type="0" />
            </el-tab-pane>
            <el-tab-pane label="减分设置" name="sub" :lazy="true">
                <rulecategory type="1" />
            </el-tab-pane>
            <el-tab-pane label="规则总览" name="all" :lazy="true">
                <ruleall />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import rulecategory from './rulecategory.vue'
import ruleall from './ruleall.vue'
export default {
    components: {
        rulecategory: rulecategory,
        ruleall: ruleall
    },
    data() {
        return {
            activeName: 'add'
        }
    },
    created() {

    },
    methods: {

    }
}
</script>

<style scoped>
.top {
    margin-bottom: 10px;
}
</style>