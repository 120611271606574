@import "@/styles/variables.scss"; 













































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.bookMarkForm {
  .el-dialog {
    .el-dialog__body {
      height: auto !important;
      overflow-y: scroll;
    }
  }
}
