@import "@/styles/variables.scss"; 






















































































































































































































































































































































































































































































































































































































































































































































































































































.welcom {
  font-size: 28px;
  text-align: center;
  line-height: 100px;
}

.dashboard-promote .el-row {
  margin-bottom: 20px;
  font-size: 14px;
}

.dashboard-promote .el-row .jz {
  height: 500px;
}

.dashboard-promote .el-row .kj {
  height: 300px;
}

.dashboard-promote .el-row .xx {
  height: 200px;
}

.git-res {
  margin-top: 20px;
}

.git-res .el-link {
  margin-right: 30px;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.product li {
  margin-bottom: 20px;
  float: left;
  width: 210px;
}

.el-card__header {
  div {
    h4 {
      color: #1890ff;
      border-left: 5px solid #1890ff;
      padding: 5px 0;
      padding-left: 10px;
      background-color: #bbb;
      font-family: SourceHanSansSC-regular;
    }
  }
}

.dashboard-editor-container {
  padding: 10px 0;
  /*background-color: rgb(240, 242, 245);*/
  position: relative;

  .chart-wrapper {
    background: #fff;
    padding: 16px 16px 0;
    margin-bottom: 32px;
  }

  .cardBox {
    padding: 10px;
  }

  .circle {
    background-color: #1890ff;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }

  .el-card {
    .el-card__body {
      min-height: 300px;
    }

    .el-card__header {
      padding: 1px 0px;
      border: 0px solid;
      height: 40px;
    }
  }

  .bodyer {
    margin-left: -7px;
  }

  .bottom {
    position: absolute;
    bottom: 15px;
  }

  .carousel-item {
    text-align: center;
    position: absolute;
    left: 1rem;
    bottom: 1rem;
    color: white;
    width: 880px;
    /* text-decoration-line: underline; */
    font-weight: 700;
    font-family: Georgia, "Times New Roman", Times, serif;
    font-size: 24px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .buttonGround {
    display: flex;
    justify-content: space-between;
    align-self: center;
  }

  .item {
    margin: 10px 20px;
  }

  .button {
    .icon {
      width: 65px;
      height: 65px;
    }

    .buttonTitle {
      white-space: normal;
      margin-top: 5px;
      //  overflow: hidden;
      // text-overflow: ellipsis;
    }
  }
}

@media (max-width: 1024px) {
  .chart-wrapper {
    padding: 8px;
  }
}

.visualization {
  margin: 10px 0;
  padding: 10px;
  background-color: #1890ff;
  color: #fff;
}

.bgindex-more {
  float: right;
  margin-right: 5px;
  cursor: pointer;
}
