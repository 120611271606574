@import "@/styles/variables.scss"; 



















































.__vD{
    width: 100px;
    text-align: center;
    background: #00B0F0;
}
