@import "@/styles/variables.scss"; 





































































































































































































ul,
li {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
.pre {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  background-color: #e7f3ff;
  margin-top: 10px;
  height: 167px;
  border-radius: 5px;
  font-size: 14px;
}
.pre-left {
  height: 100%;
  width: 76px;
  line-height: 167px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: #0894ec;
  text-align: center;
  color: #fff;
  font-weight: bold;
}
.pre-right {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: space-between;
  padding-left: 10px;
}
.pre-left-bottom {
  display: flex;
  justify-content: space-around;
}
.span {
  font-weight: bold;
}
