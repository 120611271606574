@import "@/styles/variables.scss"; 























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.show-pos-btns {
  margin: -2.5px;
  display: flex;
  flex-wrap: wrap;
  .btns-item {
    margin: 2.5px;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    padding: 6px 12px;
    line-height: 1;
    font-size: 12px;
    cursor: pointer;
    &.active {
      color: #ffffff;
      background-color: #1890ff;
      border-color: #1890ff;
    }
  }
}
