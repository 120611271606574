
















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































@import 'styles/index.styl';

.calc-dialog .el-dialog__body {
    padding-top: 0;
    padding-bottom: 0;
}
.children-list{
    max-height:200px;
    overflow-y: auto;
    background: rgba(0, 0, 0, 0.05);
    padding:10px;
    margin-top:10px

}
