@import "@/styles/variables.scss"; 











































































































































































































































































































































































































































































































































































































.link-dialog {
    .fields-box {
        height: 100%;
        .hide-line {
            &-top {
                position: absolute;
                top: 0;
                left: -1px;
                height: 26px;
                width: 2px;
                z-index: 2;
                background: #fff;
            }
            &-bottom {
                position: absolute;
                bottom: -2px;
                left: -1px;
                height: 41px;
                width: 2px;
                z-index: 2;
                background: #fff;
            }
        }
    }
    .fields-box-left {
        display: flex;
        flex-direction: column;
        align-items: center;
        //   position: relative;
        z-index: 1;
        background: #fff;
    }
    // .fields-box-right {
    //   margin-left: -9%;
    // }
    .link-condition-box {
        padding: 10px;
        width: 100%;
        border-radius: 5px;
        background: #f7f7f9;
    }
    .condition-box-btns {
        right: 0;
        top: -10px;
    }
    .d-flex {
        display: flex;
    }
    .condition-box-line {
        &-top {
            width: 80px;
            border-top: 1px dashed #e5e5e5;
        }
        &-left {
            height: 100%;
            border-left: 1px dashed #e5e5e5;
        }
    }
    .rule-actions {
        padding: 15px;
        border: 1px dashed #e5e5e5;
        border-radius: 5px;
        margin-bottom: 20px;
        position: relative;
        .btn {
            position: absolute;
            z-index: 1;
            top: -20px;
            width: 100%;
            display: flex;
            justify-content: center;
            // background: #fff;
        }
    }
}
