@import "@/styles/variables.scss"; 




























































































































































































































































































li,
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.success-3-top-ul {
  margin-left: 20px;
  height: 5vw;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 10px;
  li {
    width: 13vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    & > span:nth-child(1) {
      display: inline-block;
      // width: 82px;
      text-align: right;
    }
  }
}
.lines {
  width: 100%;
  height: 1px;
  background: #f6f8f9;
  margin-top: 3vh;
}
.title {
  color: #f00;
  // width: 186px;
  width: 60%;
  height: 47px;
  border-radius: 35px;
  background-color: rgba(253, 43, 4, 1);
  line-height: 47px;
  text-align: center;
  color: #fff;
  font-size: 16px;
}
.flow-list {
  width: 186px;
  transform: translateX(25%);
  .list {
    line-height: 25px;
    margin-left: -12.5px;
  }
}
.circle {
  display: inline-block;
  white-space: nowrap;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #e1dddd;
  color: #fff;
  text-align: center;
  margin-right: 5px;
  &.green {
    background-color: #38cb66 !important;
  }
  &.yellow {
    background-color: #ffd463;
  }
}
