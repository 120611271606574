@import "@/styles/variables.scss"; 















































































































































































































































































































































































.column-list {
  padding: 15px;
  max-height: 550px;
  overflow-y: auto;

  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    line-height: 1;
    padding: 10px;
    margin-top: 10px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    background: #fff;

    .icon {
      position: relative;
      z-index: 9999;
    }

    // &:hover {
    // 	border: 1px solid #1890ff;
    // 	color: #1890ff;
    // }
  }
}
