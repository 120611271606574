@import "@/styles/variables.scss"; 



































































































div.table-container {
    padding: 5px;
    border: 1px dashed #336699;
    box-sizing: border-box;

    table.table-layout {
        width: 100%;
        text-align: center;
        //border: 1px solid #c8ebfb;
        border-collapse: collapse;
        table-layout: fixed;

        ::v-deep td {
            height: 48px;
            border: 1px dashed #336699;
            padding: 3px;
            display: table-cell;
        }

        .form-widget-list {
            border: 1px dashed #336699;
            min-height: 36px;
        }
    }
}

.table-container.selected {
    outline: 2px solid $--color-primary !important;
}
.table-container .table-layout .el-form-item {
    margin: 22px 0;
}
