<template>
  <div class="map">
    <div class="chart-container" ref="chartMap"></div>

    <!-- gradient color  -->
    <div class="gradient"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import chinadata from "@/assets/map/json/china.json";
export default {
  props: {
    // map data
    mapData: {
      type: Object,
      default: () => chinadata,
    },
    // configuration items default is chineseprovince
    mapOption: {
      type: Object,
      default: () => ({
        title: {
          text: "", // 地图顶部文字
          textStyle: { color: "#fff" },
          x: "center",
        },
        // 鼠标提示内容
        tooltip: {
          trigger: "item",
          formatter: (params) => {
            return (
              params.name +
              " : " +
              params.value +
              " (" +
              Math.floor((params.value / 500) * 100) +
              "%)"
            );
          },
        },
        visualMap: {
          show: false,
          x: "left",
          y: "bottom",
          splitList: [
            { start: 500, end: 600 },
            { start: 400, end: 500 },
            { start: 300, end: 400 },
            { start: 200, end: 300 },
            { start: 100, end: 200 },
            { start: 1, end: 100 },
            { start: 0, end: 0 },
          ],
          color: [
            "#33adff",
            "#4db8ff",
            "#80ccff",
            "#a6dbff",
            "#b3e1ff",
            "#ffffff",
          ],
        },
        series: [
          {
            name: "",
            type: "map",
            mapType: "china",
            roam: true,
            label: {
              normal: {
                show: false,
              },
              emphasis: {
                show: false,
              },
            },
            itemStyle: {
              emphasis: {
                areaColor: "#ffdf33", // 鼠标悬停时省份的颜色
              },
            },
            data: [
              { name: "北京", value: Math.floor(Math.random() * 500) },
              { name: "天津", value: Math.floor(Math.random() * 500) },
              { name: "上海", value: Math.floor(Math.random() * 500) },
              { name: "重庆", value: Math.floor(Math.random() * 500) },
              { name: "河北", value: Math.floor(Math.random() * 500) },
              { name: "河南", value: Math.floor(Math.random() * 500) },
              { name: "云南", value: Math.floor(Math.random() * 500) },
              { name: "辽宁", value: Math.floor(Math.random() * 500) },
              { name: "黑龙江", value: Math.floor(Math.random() * 500) },
              { name: "湖南", value: Math.floor(Math.random() * 500) },
              { name: "安徽", value: Math.floor(Math.random() * 500) },
              { name: "山东", value: Math.floor(Math.random() * 500) },
              { name: "新疆", value: Math.floor(Math.random() * 500) },
              { name: "江苏", value: Math.floor(Math.random() * 500) },
              { name: "浙江", value: Math.floor(Math.random() * 500) },
              { name: "江西", value: Math.floor(Math.random() * 500) },
              { name: "湖北", value: Math.floor(Math.random() * 500) },
              { name: "广西", value: Math.floor(Math.random() * 500) },
              { name: "甘肃", value: Math.floor(Math.random() * 500) },
              { name: "山西", value: Math.floor(Math.random() * 500) },
              { name: "内蒙古", value: Math.floor(Math.random() * 500) },
              { name: "陕西", value: Math.floor(Math.random() * 500) },
              { name: "吉林", value: Math.floor(Math.random() * 500) },
              { name: "福建", value: Math.floor(Math.random() * 500) },
              { name: "贵州", value: Math.floor(Math.random() * 500) },
              { name: "广东", value: Math.floor(Math.random() * 500) },
              { name: "青海", value: Math.floor(Math.random() * 500) },
              { name: "西藏", value: Math.floor(Math.random() * 500) },
              { name: "四川", value: Math.floor(Math.random() * 500) },
              { name: "宁夏", value: Math.floor(Math.random() * 500) },
              { name: "海南", value: Math.floor(Math.random() * 500) },
              { name: "台湾", value: Math.floor(Math.random() * 500) },
              { name: "香港", value: Math.floor(Math.random() * 500) },
              { name: "澳门", value: Math.floor(Math.random() * 500) },
              { name: "南海诸岛", value: Math.floor(Math.random() * 500) },
            ],
          },
        ],
      }),
    },
  },
  mounted() {
    const echart = echarts.init(this.$refs.chartMap); // initialize object
    echarts.registerMap("china", this.mapData); // 注册china.json的数据到初始化的echarts对象
    echart.setOption(this.mapOption); // 绑定地图的配置参数对象，参考第二步
    echart.resize();
  },
};
</script>

<style scoped>
.chart-container {
  width: 100%;
  height: 100%;
  width: 1000px;
  height: 450px;
}

@media (max-width: 800px) {
  .chart-container {
    width: 100%;
    height: 400px;
  }
}

@media (max-width: 600px) {
  .chart-container {
    width: 100%;
    height: 300px;
  }
}
.gradient {
  width: 130px;
  height: 10px;
  background: linear-gradient(90deg, #33adff, #78c9ff, #b3e1ff);
}
</style>
