<template>
    <div class="app-container">
        <div v-if="dataList.length > 0" class="config-container">
            <el-tabs value="first" type="card">
                <el-tab-pane label="初始分设置" name="first">
                    <el-form label-position="left" label-width="120px">
                        <el-row>
                            <el-col :span="24">
                                <el-form-item label="初始积分" prop="score">
                                    <el-input v-model="form.score" placeholder="请输入初始积分" style="width: 150px;" />&nbsp;&nbsp;
                                    <el-button type="primary" @click="onSave('score')">保存</el-button>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </el-tab-pane>
            </el-tabs>
            <el-tabs value="first" type="card" style="margin-top: 30px;">
                <el-tab-pane label="自动初始化" name="first">
                    <el-form label-position="left" label-width="120px">
                        <el-row>
                            <el-col :span="24">
                                <el-form-item label="周期" prop="score">
                                    <div style="display: flex;">
                                        <div style="width: 150px;">
                                            <dict-select
                                                v-model="form.type"
                                                placeholder="请选择周期"
                                                type="user_score_init_type"
                                            />
                                        </div>
                                        &nbsp;&nbsp;
                                        <el-button type="primary" @click="onSave('type')">保存</el-button>
                                        &nbsp;&nbsp;(说明：系统将在每个周期的1号，全部员工积分重置为初始分)
                                    </div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </el-tab-pane>
            </el-tabs>
            <el-tabs value="first" type="card" style="margin-top: 30px;">
                <el-tab-pane label="手动初始化" name="first">
                    <el-form label-position="left" label-width="120px">
                        <el-row>
                            <el-col :span="24">
                                <el-form-item label="用户选择">
                                    <el-radio-group v-model="form.user_type">
                                        <el-radio label="0">全部</el-radio>
                                        <el-radio label="1">部分</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                    <div v-if="form.user_type == '1'" style="padding-left: 120px; margin-bottom: 15px;">
                        <admin-selectex size="small" placeholder="请选择用户" title="用户" idField="id"
                                    :value="form.user_ids" :label="form.user_names"
                                    @getValue='(ids, names)=>{form.user_ids=ids;form.user_names=names;}' />
                    </div>
                    <div>
                        <el-button type="primary" @click="onInit">开始执行</el-button>&nbsp;&nbsp;<span v-if="form.last_init_time_text" style="color: #ccc;">（上次初始化时间：{{form.last_init_time_text}}）</span> (说明：点击“开始执行”按钮后，员工累计积分和剩余积分都重置为初始分)
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import { listUserScoreInit, editUserScoreInit, initUserScore } from '@/api/core/system/userscore'
export default {
    data() {
        return {
            form: {
                user_type: ''
            },
            dataList: [],
        }
    },
    created() {
        this.loadData()
    },
    methods: {
        loadData() {
            const loading = this.$loading({
                text: '正在加载...'
            })
            listUserScoreInit({limit: 1}).then(res => {
                loading.close()
                if (res.data.list.length > 0) {
                    this.dataList = res.data.list
                    const form = JSON.parse(JSON.stringify(this.dataList[0]))
                    if (form.last_init_time) {
                        form.last_init_time_text = this.parseTime(new Date(form.last_init_time * 1000))
                    }
                    form.user_type = ''
                    this.form = form
                }
            }).catch(e => {
                loading.close()
            })
        },
        onSave(t) {
            const form = this.dataList[0]
            if (t == 'score') {
                if (this.form.score === undefined || this.form.score === "") {
                    this.msgError('请填写积分')
                    return
                }

                if (!/^[0-9]+$/.test(this.form.score)) {
                    this.msgError('只能填写整数')
                    return
                }

                form.score = this.form.score
            } else if (t == 'type') {
                if (this.form.type === undefined || this.form.type === "") {
                    this.msgError('请选择周期')
                    return
                }

                form.type = this.form.type
            }

            const loading = this.$loading({
                text: '正在保存...'
            })

            editUserScoreInit(form).then(res => {
                loading.close()
                this.msgSuccess('保存成功')
            }).catch(e => {
                loading.close()
            })
        },
        onInit() {
            const form = this.dataList[0]
            if (this.form.score != form.score) {
                this.msgError('初始积分有变动，请保存初始积分再执行')
                return
            }

            if (!this.form.user_type) {
                this.msgError('请选择用户')
                return
            }

            if (this.form.user_type == '1' && !this.form.user_ids) {
                this.msgError('请选择部分用户')
                return
            }

            this.$confirm('初始化后积分不可恢复，确定初始化吗？', "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
            .then(() => {
                const loading = this.$loading({
                    text: '正在初始化...'
                })

                initUserScore({
                    user_type: this.form.user_type,
                    user_ids: this.form.user_ids
                }).then(res => {
                    loading.close()
                    this.msgSuccess('初始化成功')
                    this.loadData()
                }).catch(e => {
                    loading.close()
                })
            })
        }
    }
}
</script>

<style scoped>
.app-container {
    display: flex;
    justify-content: center;
}
.config-container {
    width: 90%;
}
.config {
    padding-right: 25%;
}
.grid-content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
}
.grid-content .el-input {
    width: 100px;
}
.top {
    width: 100%;
    margin-top: 50px;
    padding-top: 30px;
    border-top: solid 1px #ccc;
}
.tip {
    width: 100%;
    margin-top: 50px;
    padding-top: 20px;
    border-top: solid 1px #ccc;
}
.bottom {
    width: 100%;
    margin-top: 20px;
    padding-top: 30px;
    border-top: solid 1px #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>