<template>
    <div class="app-container">
        <el-form :inline="true" label-width="68px" label-position="left">
            <el-form-item>
                <el-tooltip class="item" effect="light" content="同一个文件可多次导入" placement="right">
                <el-button
                    type="primary"
                    icon="el-icon-plus"
                    size="mini"
                    :loading="btnLoading"
                    @click="handleAdd"
                    >上传监测数据</el-button
                ></el-tooltip>
            </el-form-item>
        
        </el-form>

        <el-form :inline="true" label-width="68px" label-position="left">
            <el-form-item>
                <el-date-picker
                    v-model="year"
                    type="year"
                    value-format="yyyy"
                    placeholder="选择日期"
                >
                </el-date-picker>
            </el-form-item>
           
            <el-form-item>
                <el-tooltip class="item" effect="light" content="注意:下载表格后按F9重新计算" placement="right">
                <el-button
                    type="primary"
                    icon="el-icon-download"
                    size="mini"
                    :loading="btnLoading"
                    @click="handledownload"
                    >下载汇总</el-button
                >
            </el-tooltip>
                
            </el-form-item>
    
        </el-form>

        <el-dialog
            title="上传"
            :visible.sync="open"
            width="80%"
            :close-on-click-modal="false"
            append-to-body
        >
            <el-form
                v-if="open"
                ref="form"
                :model="form"
                :rules="rules"
                label-width="100px"
            >
                <el-form-item label="文件" prop="purl">
                    <el-upload
                        ref="upload"
                        :limit="1"
                        accept=".xls"
                        :headers="upload.headers"
                        :action="upload.url"
                        :disabled="upload.isUploading"
                        :on-progress="handleFileUploadProgress"
                        :on-success="handleImportSuccess"
                        :on-error="handleImportError"
                        :auto-upload="false"
                        drag
                    >
                        <el-button class="ubtn" size="small" type="primary"
                            >点击上传</el-button
                        >
                        <div slot="tip" class="el-upload__tip">
                            只能上传.xls 文件，且不超过2M
                        </div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button
                    type="primary"
                    :loading="upload.isUploading"
                    @click="submitFileForm"
                    >{{
                        upload.isUploading ? "上 传 中..." : "上 传"
                    }}</el-button
                >
                <el-button @click="open = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>



<script>
import { getAccessToken } from "@/utils/tokenTool";
import { deepClone } from "@/vform/utils/util";
import {
    sisetuUpload,
    sisetuDel,
    sisetuGet,
    sisetuSubtotal,
    zxjcdownload,
} from "@/api/form/data";
import { uploadServer } from "@/api/dss/fastdfsServer.js";
export default {
    data() {
        return {
            year: "2024",
            //上传参数
            upload: {
                // 是否显示弹出层（用户导入）
                open: false,
                // 弹出层标题（用户导入）
                title: "",
                // 是否禁用上传
                isUploading: false,
                // 是否更新已经存在的用户数据
                updateSupport: 0,
                // 设置上传的请求头部
                headers: { Authorization: getAccessToken() },
                // 上传的地址
                url:
                    process.env.VUE_APP_BASE_API + "/form/zxjc/uploaddaylydata",
            },

            subtotalinfo: null,
            pdata: {},
            open: false,
            btnLoading: false,
            form: {},
            rules: {
                pname: [
                    {
                        required: true,
                        message: "名称不能为空",
                        trigger: "blur",
                    },
                ],
                purl: [
                    {
                        required: true,
                        message: "图片不能为空",
                        trigger: "blur",
                    },
                ],
            },
            uploadHeaders: {},
            uploadData: {},
            imageList: [],
        };
    },
    created() {
        // this.initdata();
    },
    methods: {
        downfile(file) {
            const a = document.createElement("a");
            a.href = file;
            a.target = "_blank";
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(a.href);
            document.body.removeChild(a);
        },
        handledownload() {
            this.btnLoading = true;
            zxjcdownload({ year: this.year })
                .then((rs) => {
                    this.downfile(rs.data.data.file);
                    this.btnLoading = false;
                })
                .catch((_) => {
                    this.btnLoading = false;
                });
        },
        submitFileForm() {
            this.$refs.upload.submit();
        },

        // 文件上传中处理
        handleFileUploadProgress(event, file, fileList) {
            this.upload.isUploading = true;
        },
        handleImportSuccess(response, file, fileList) {
            this.upload.isUploading = false;
            if (response.code == 0) {
                this.msgSuccess("上传成功,导入数据:"+response.data.count+"条");
                this.open = false;
            } else {
                this.$refs.upload.abort(file);
                this.msgError("上传失败:"+response.msg);
            }
        },
        handleImportError(){
            this.upload.isUploading = false;
        },

        initdata() {
            this.btnLoading = true;
            sisetuGet()
                .then((rs) => {
                    this.pdata = rs.data.data || {};
                    this.btnLoading = false;
                })
                .catch((e) => {
                    this.btnLoading = false;
                });

            sisetuSubtotal().then((rs) => {
                var data = rs.data.data;
                var fxdj = ["重大风险", "较大风险", "一般风险", "低风险"];
                var info = {};
                for (var i in fxdj) {
                    var key = fxdj[i];
                    info[key] = {
                        value: data[key],
                        color: data["color_" + key],
                    };
                }
                this.subtotalinfo = info;
            });
        },
        handleDelete() {
            if (this.pdata.id != null) {
                this.$confirm("确定删除吗?", "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        return sisetuDel({ ids: this.pdata.id })
                            .then((_) => {
                                this.pdata = {};
                            })
                            .catch((e) => {
                                this.btnLoading = false;
                            });
                    })
                    .then(() => {
                        this.btnLoading = false;
                        this.msgSuccess("删除成功");
                    })
                    .catch(() => {
                        this.btnLoading = false;
                    });
            } else {
                this.msgError("请先上传图片");
            }
        },
        handleAdd() {
            this.form = {};
            this.open = true;
        },
        submitForm() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.btnLoading = true;
                    sisetuUpload(this.form).then((_) => {
                        this.open = false;
                        this.initdata();
                    });
                }
            });

            this.open = false;
        },
        //自定义上传
        async uploadDss(request) {
            // 默认需要登录
            let tokenReq = {
                platform: "jpgfast",
                type: "general",
                source: "system",
                public: 1,
            };
            this.loading = true;
            uploadServer(tokenReq, request.file).then((resp) => {
                // console.log(123);
                if (resp.code == 0) {
                    // const value =
                    // //console.log(value)
                    // this.$emit("input", value);
                    // this.$emit("change", value);
                    this.form.purl = resp.data.url;

                    // this.imageList.push({
                    //     url: resp.data.url,
                    //     name: resp.data.filename,
                    // });
                } else {
                    this.$refs.fieldEditor.uploadFiles =
                        this.$refs.fieldEditor.uploadFiles.filter((e) => {
                            if (e.name !== file.name || e.status !== "ready") {
                                return e;
                            }
                        });

                    this.$message.error({
                        message: "上传失败,原因：" + res.msg,
                        duration: 2000,
                    });
                }
            });
        },

        handleUploadSuccess(response, file, fileList) {
            // console.log(response, file, fileList);
            // file.url = response.data.url;
            // this.form.image = response.data.url;
        },
        handleUploadError(err) {
            console.log(err);
            this.form.image = "";
            if (
                err &&
                (err.message.indexOf("larger than") > 0 ||
                    err.message.indexOf("413") > 0)
            ) {
                this.msgError("上传文件失败，文件大小超过限制");
            } else {
                this.msgError("上传文件失败");
            }
        },
        handleUploadExceed() {
            this.msgError("只能上传一个文件，请删除后重新上传");
        },
        handleUploadRemove() {
            this.form.image = "";
        },
    },
};
</script>

<style rel="stylesheet/scss" scoped>
.ubtn {
    margin: 25%;
}
.__fxdj {
    display: inline-block;
    text-align: center;
    width: 21px;
    margin-right: 5px;
}
.sisetutil {
    text-align: center;
    font-size: 18px;
    line-height: 1.5;
}
.sisetudiv {
    margin: 50px;
}
.sisetudiv img {
    max-width: 100%;
}
</style>