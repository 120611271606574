@import "@/styles/variables.scss"; 



















































































































































































































































































































































































































































































































































































.btns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
  width: 100%;
  min-height: 100vh;
  margin-top: 20vh;
  // position: absolute;
  //   left: 50%;
  //   top: 50%;
  //   transform: translate(-50%, -50%);
}
.btns-an {
  width: 80px;
  height: 30px;
  border-radius: 4px;
  margin: 10px auto;
  text-align: center;
  line-height: 30px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
// .que-btn {
//   position: fixed;
//   bottom: 5%;
//   background-color: white;
//   z-index: 9;
//   width: 78vw;
//   height: 50px;
// }
.que-btn-ans {
  position: fixed;
  right: 1%;
  bottom: 15%;
  background-color: #409eff;
  width: 100px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 20px;
  color: white;
  z-index: 999 !important;
  cursor: pointer;
  font-weight: 700;
  border-radius: 12px;
  z-index: 5;
}
